import { Product } from 'src/constants/productConstant';

import * as Utils from '../../utils';
import { ProductDetail as prodDetal } from 'src/constants/productDetailConstant';
import _ from 'lodash';
interface Attribute {
  name?: string;
  value?: string;
  id?: number;
}
interface calculatedOption {
  calculatedPriceProduct?: any;
  outStock?: any;
  selectedVariant?: any;
  finalCalculatedPrice?: any;
}
interface calculatedPrice {
  saveInfomation?: any;
  displayType?: any;
  variantPrice?: any;
  salePrice?: any;
  defaultPrice?: any;
  retailPrice?: any;
  samePrice?: any;
}

export function getAttributeToDisplay(productDetail, calculatedOption) {
  let listAttributes: Attribute[] = [];
  const type = Utils.getSafeValue(productDetail, 'inventoryTracking', Product.inventoryTracking.None);
  const selectedVariant = Utils.getSafeValue(calculatedOption, 'selectedVariant', {});

  // Titles
  let skuTitle = Utils.getSafeValue(productDetail, 'skuTitle', '');
  let weightTitle = Utils.getSafeValue(productDetail, 'weightTitle', '');
  let shippingCostTitle = Utils.getSafeValue(productDetail, 'shippingCostTitle', '');
  let upcTitle = Utils.getSafeValue(productDetail, 'upcTitle', '');

  // Values
  let inventoryLevel = Utils.getSafeValue(productDetail, 'inventoryLevel', 0);
  let skuValue = Utils.getSafeValue(productDetail, 'sku', '');
  let weight = productDetail.weight;
  let upc = Utils.getSafeValue(productDetail, 'upc', '');
  let shippingFee = '';
  let fixedCostShippingPrice = Utils.getSafeValue(productDetail, 'fixedCostShippingPrice', 0);
  let isFreeShipping = Utils.getSafeValue(productDetail, 'isFreeShipping', false);

  if (selectedVariant) {
    if (!selectedVariant.outStock) {
      skuValue = Utils.getSafeValue(selectedVariant, 'sku', '');
    }
    if (selectedVariant.weight) {
      weight = selectedVariant.weight;
    }
    if (selectedVariant.upc) {
      upc = Utils.getSafeValue(selectedVariant, 'upc', '');
    }
    inventoryLevel = Utils.getSafeValue(selectedVariant, 'inventoryLevel', '');
    fixedCostShippingPrice = Utils.getSafeValue(selectedVariant, 'fixedCostShippingPrice', 0);
  }

  if (fixedCostShippingPrice !== 0) {
    shippingFee = Utils.convertCurrency(fixedCostShippingPrice, '');
  } else {
    shippingFee = isFreeShipping ? prodDetal.freeShipping : prodDetal.calculateCheckout;
  }
  listAttributes =
    skuTitle && skuTitle.length > 0 && skuValue && skuValue.length > 0
      ? [...listAttributes, { name: skuTitle, value: skuValue }]
      : [...listAttributes];

  listAttributes =
    weightTitle && weightTitle.length > 0 && weight != null
      ? [...listAttributes, { name: weightTitle, value: weight }]
      : [...listAttributes];

  listAttributes =
    shippingCostTitle && shippingCostTitle.length > 0 && shippingFee && shippingFee.length > 0
      ? [...listAttributes, { name: shippingCostTitle, value: shippingFee }]
      : [...listAttributes];
  listAttributes =
    upcTitle && upcTitle.length > 0 && upc && upc.length > 0
      ? [...listAttributes, { name: upcTitle, value: upc }]
      : [...listAttributes];

  return listAttributes;
}
export function isVariantChecking(typeOption: string) {
  return (
    typeOption === Product.TypeBigCOption.Swatch ||
    typeOption === Product.TypeBigCOption.RadioButtons ||
    typeOption === Product.TypeBigCOption.Rectangles ||
    typeOption === Product.TypeBigCOption.DropDown ||
    typeOption === Product.TypeBigCOption.ProductList ||
    typeOption === Product.TypeBigCOption.ProductListWithImage ||
    typeOption === Product.TypeBigCOption.Checkbox
  );
}
export function configPriceForVariantProduct(selectedVariant, tempProduct) {
  let calculatedPrice: calculatedPrice = {};
  calculatedPrice.saveInfomation = '';

  const priceVariant = Utils.getSafeValue(selectedVariant, 'calculatedPrice', null);
  const skuVariant = Utils.getSafeValue(selectedVariant, 'sku', '');
  const priceProduct = Utils.getSafeValue(tempProduct, 'price', null);
  const retailPriceProduct = Utils.getSafeValue(tempProduct, 'retailPrice', null);

  if (selectedVariant && priceVariant && skuVariant.length > 0) {
    calculatedPrice.displayType = 'BigCDisplayVariantPrice';
    calculatedPrice.variantPrice = priceVariant;

    if (priceVariant === 0) {
      calculatedPrice.displayType = 'BigCDisplayDefault_AND_SalePrice';
      calculatedPrice.salePrice = priceVariant;
      calculatedPrice.defaultPrice = priceProduct;

      if (retailPriceProduct && retailPriceProduct > 0) {
        calculatedPrice.displayType = 'BigCDisplayALLPrice';
        calculatedPrice.salePrice = priceVariant;
        calculatedPrice.defaultPrice = priceProduct;
        calculatedPrice.retailPrice = retailPriceProduct;

        const savePrice = retailPriceProduct - priceVariant;
        calculatedPrice.saveInfomation = 'You save '.concat(Utils.convertCurrency(savePrice, ''));
      }
    } else if (priceVariant < retailPriceProduct) {
      calculatedPrice.displayType = 'BigCDisplayRetail_AND_SalePrice';
      calculatedPrice.retailPrice = retailPriceProduct;
      calculatedPrice.salePrice = priceVariant;

      const savePrice = retailPriceProduct - priceVariant;
      calculatedPrice.saveInfomation = 'You save '.concat(Utils.convertCurrency(savePrice, ''));
    }
  } else {
    calculatedPrice = updatePriceRuleForProduct(tempProduct);
  }

  return calculatedPrice;
}
export function updatePriceRuleForProduct(productDetail) {
  const price = Utils.getSafeValue(productDetail, 'price', null);
  const salePrice = Utils.getSafeValue(productDetail, 'salePrice', null);
  const retailPrice = Utils.getSafeValue(productDetail, 'retailPrice', null);

  const hasSalePrice = salePrice && salePrice > 0;
  const hasRetailPrice = retailPrice && retailPrice > 0;

  let calculatedPrice: calculatedPrice = {};
  calculatedPrice.saveInfomation = '';

  if (hasSalePrice && hasRetailPrice) {
    if (salePrice < retailPrice && salePrice < price) {
      calculatedPrice.displayType = 'BigCDisplayALLPrice';

      calculatedPrice.retailPrice = retailPrice;
      calculatedPrice.salePrice = salePrice;
      calculatedPrice.defaultPrice = price;

      const savePrice = retailPrice - salePrice;
      calculatedPrice.saveInfomation = 'You save '.concat(Utils.convertCurrency(savePrice, ''));
    } else if (salePrice >= retailPrice && salePrice >= price) {
      calculatedPrice.displayType = 'BigCDisplaySalePrice';
      calculatedPrice.salePrice = salePrice;
    } else if (retailPrice < salePrice && salePrice < price) {
      calculatedPrice.displayType = 'BigCDisplayDefault_AND_SalePrice';
      calculatedPrice.defaultPrice = price;
      calculatedPrice.salePrice = salePrice;
    } else if ((price < salePrice && salePrice < retailPrice) || (price <= salePrice && salePrice <= retailPrice)) {
      if (retailPrice != salePrice) {
        calculatedPrice.displayType = 'BigCDisplayRetail_AND_SalePrice';
        calculatedPrice.salePrice = salePrice;
        calculatedPrice.retailPrice = retailPrice;
        const savePrice = retailPrice - salePrice;
        calculatedPrice.saveInfomation = 'You save '.concat(Utils.convertCurrency(savePrice, ''));
      } else {
        calculatedPrice.displayType = 'BigCDisplayOnePrice';
        calculatedPrice.samePrice = salePrice;
      }
    } else if (retailPrice == salePrice && salePrice == price) {
      calculatedPrice.displayType = 'BigCDisplayDefaultPrice';
      calculatedPrice.defaultPrice = price;
    } else if (retailPrice == salePrice && salePrice < price) {
      calculatedPrice.displayType = 'BigCDisplayDefault_AND_SalePrice';
      calculatedPrice.defaultPrice = price;
      calculatedPrice.salePrice = salePrice;
    } else {
      calculatedPrice.displayType = 'BigCDisplayDefaultPrice';
      calculatedPrice.defaultPrice = price;
    }
  } else if (hasRetailPrice) {
    if (price < retailPrice) {
      calculatedPrice.displayType = 'BigCDisplayRetail_AND_DefaultPrice';
      calculatedPrice.retailPrice = retailPrice;
      calculatedPrice.defaultPrice = price;

      const savePrice = retailPrice - price;
      calculatedPrice.saveInfomation = 'You save '.concat(Utils.convertCurrency(savePrice, ''));
    } else {
      calculatedPrice.displayType = 'BigCDisplayDefaultPrice';
      calculatedPrice.defaultPrice = price;
    }
  } else if (hasSalePrice) {
    if (salePrice > price) {
      calculatedPrice.displayType = 'BigCDisplaySalePrice';
      calculatedPrice.salePrice = salePrice;
    } else if (salePrice < price) {
      calculatedPrice.displayType = 'BigCDisplayDefault_AND_SalePrice';
      calculatedPrice.defaultPrice = price;
      calculatedPrice.salePrice = salePrice;
    } else {
      calculatedPrice.displayType = 'BigCDisplayDefaultPrice';
      calculatedPrice.defaultPrice = price;
    }
  } else {
    calculatedPrice.displayType = 'BigCDisplayDefaultPrice';
    calculatedPrice.defaultPrice = price;
  }

  return calculatedPrice;
}
export function calculatedOptionWithPrice(productDetail, optionsBCM, pricingRules) {
  // Define
  let calculatedOption: calculatedOption = {};
  let calculatedPriceProduct = productDetail;
  calculatedOption.calculatedPriceProduct = calculatedPriceProduct;
  calculatedOption.outStock = Utils.getSafeValue(productDetail, 'outStock', false);

  let selectedVariant;
  let applyRuleList: any[] = [];

  const variants = Utils.getSafeValue(productDetail, 'variants', []);

  const type = Utils.getSafeValue(productDetail, 'inventoryTracking', Product.inventoryTracking.None);

  let listSelectedOption = optionsBCM.filter(item => {
    return isVariantChecking(item.type) && item.idOptionSelect !== null;
  });

  let optionsTraking = optionsBCM.filter(item => {
    return isVariantChecking(item.type) && item.idOptionSelect !== null;
  });

  if (variants && variants.length > 0) {
    selectedVariant = getVariantBySelectedOption(listSelectedOption, variants);
  }

  applyRuleList = getListOfRuleApplyForSelectedOption(listSelectedOption, selectedVariant, pricingRules);
  if (applyRuleList.length > 0) {
    applyRuleList = _.sortBy(applyRuleList, function (o) {
      return o.sortOrder;
    });
    const calculatedPrice = Utils.getSafeValue(productDetail, 'calculatedPrice', 0);
    const defaultPrice = Utils.getSafeValue(productDetail, 'price', 0);
    const salePrice = Utils.getSafeValue(productDetail, 'salePrice', 0);

    let newPrice = calculatedPrice;
    let newPriceDecimal = calculatedPrice;

    const variantSKU = Utils.getSafeValue(selectedVariant, 'sku', '');
    if (selectedVariant && variantSKU.length > 0) {
      const variantPrice = Utils.getSafeValue(selectedVariant, 'price', '');
      newPriceDecimal = variantPrice;
      if (variantPrice && variantPrice !== 0) {
        newPriceDecimal = calculatedPrice;
      } else {
        calculatedPriceProduct.price = variantPrice;
      }
    }

    calculatedOption.outStock = false;
    pricingRules.forEach(rule => {
      const ruleOutStock = Utils.getSafeValue(rule, 'purchasingDisabled', false);
      calculatedOption.outStock = ruleOutStock;

      const priceAdjuster = Utils.getSafeValue(rule, 'priceAdjuster', {});
      const adjuster = Utils.getSafeValue(priceAdjuster, 'adjuster', null);
      const adjusterValue = Utils.getSafeValue(priceAdjuster, 'adjusterValue', 0);

      if (adjuster === 'percentage') {
        newPriceDecimal = getPriceAsPercentageRule(adjusterValue, newPriceDecimal);
      } else if (adjuster === 'relative') {
        newPriceDecimal = newPriceDecimal + adjusterValue;
      } else {
        newPriceDecimal = adjusterValue;
      }

      newPrice = newPriceDecimal;

      if (calculatedPrice == defaultPrice) {
        if (newPrice > defaultPrice) {
          calculatedPriceProduct.price = newPriceDecimal;
        } else if (newPrice < defaultPrice) {
          calculatedPriceProduct.salePrice = newPriceDecimal;
        }
      }

      if (calculatedPrice == salePrice) {
        calculatedPriceProduct.salePrice = newPriceDecimal;
      }

      calculatedPriceProduct.calculatedPrice = newPriceDecimal;

      calculatedOption.calculatedPriceProduct = calculatedPriceProduct;
    });
  } else {
    if (selectedVariant) {
      calculatedOption.selectedVariant = selectedVariant;
    }
  }

  const finalCalculatedPrice = configPriceForVariantProduct(selectedVariant, calculatedOption.calculatedPriceProduct);
  calculatedOption.finalCalculatedPrice = finalCalculatedPrice;

  return calculatedOption;
}
export function getPriceAsPercentageRule(percentRule: number, price: number) {
  let newPrice = 0;
  const tempPrice = (percentRule / 100) * price;
  newPrice = price + tempPrice;
  return newPrice;
}
export function getVariantBySelectedOption(listSelectedOption, variants) {
  let variantSelected;
  listSelectedOption = _.sortBy(listSelectedOption, function (o) {
    return o.id;
  });
  const combineKeyOfOptionsTracking = getCombineKeyWithCurrentSelectedOption(listSelectedOption);

  variants.forEach(variant => {
    let optionValues = Utils.getSafeValue(variant, 'optionValues', []);
    optionValues = _.sortBy(optionValues, function (o) {
      return o.optionId;
    });
    const combineKeyOfOptionsVariant = buildKeyForVariant(optionValues);

    if (combineKeyOfOptionsTracking === combineKeyOfOptionsVariant) {
      variantSelected = variant;
      // break;
    }
  });

  return variantSelected;
}
export function getCombineKeyWithCurrentSelectedOption(listSelectedOption) {
  let key = '';
  listSelectedOption.forEach(option => {
    key = key.concat(option.id, '_', option.idOptionSelect, '_');
  });

  return key;
}
export function buildKeyForVariant(optionValues) {
  let key = '';
  optionValues.forEach(option => {
    key = key.concat(option.optionId, '_', option.id, '_');
  });
  return key;
}
export function getListOfRuleApplyForSelectedOption(listSelectedOption, selectedVariant, pricingRules) {
  let listRule: any = [];
  listSelectedOption = _.sortBy(listSelectedOption, function (o) {
    return o.id;
  });
  const combineKeyOfOptionsTracking = getCombineKeyWithCurrentSelectedOption(listSelectedOption);
  let listEnabledRules = getEnableRule(pricingRules);

  listEnabledRules.forEach(rule => {
    const conditions = Utils.getSafeValue(rule, 'conditions', []);
    let combineKeyOfOptionsInRule = '';
    conditions.forEach(condition => {
      const variantIdCondition = Utils.getSafeValue(condition, 'variantId', null);
      if (selectedVariant && variantIdCondition && selectedVariant.id === variantIdCondition) {
        listRule = [...listRule, rule];
      } else {
        combineKeyOfOptionsInRule = combineKeyOfOptionsInRule.concat(condition.optionId, '_', condition.id, '_');
      }
    });

    if (combineKeyOfOptionsTracking === combineKeyOfOptionsInRule) {
      listRule = [...listRule, rule];
    }
  });

  return listRule;
}
export function getEnableRule(pricingRules) {
  let rules = getRuleListNotDisable(pricingRules);
  rules = rules.filter(rule => {
    return !rule.stop;
  });

  return rules;
}
export function getRuleListNotDisable(pricingRules) {
  let listEnabled = pricingRules.filter(pricingRule => {
    return pricingRule.enabled;
  });
  listEnabled = _.sortBy(listEnabled, function (o) {
    return o.sortOrder;
  });

  return listEnabled;
}
