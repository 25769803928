import React, { useEffect, useState } from 'react';
import './style.scss';
import { callApiACT } from '../../redux/callApiRDC';
import { compose } from 'redux';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as AppURL from '../../services/urlAPI';
import { setGlobalAPI } from '../../services/callDataAPI';
import * as Utils from '../../utils';
import { reducerNameHOR } from '../../redux';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import _ from 'lodash';
import { Redirect, useHistory, useLocation } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import { LoadingScreen } from '../../components/loadingScreen';
import { Form } from 'react-bootstrap';
import CheckLogin from '../../components/checkLogin';
import { IoIosClose, IoMdCloseCircle } from 'react-icons/io';
import store from '../../redux';
import Modal from 'react-bootstrap/Modal';
import { Scrollbars } from 'react-custom-scrollbars';
import ModalAlert from '../../components/modalAlert/modalAlert';
import { useTranslation, withTranslation } from 'react-i18next';

interface Props {
  callApiACT: (action) => void;
  loginUser: any;
}

export interface QueryParams {
  pageSize: number;
  pageNumber: number;
}

function LoginAsCompany({ callApiACT, loginUser }: any) {
  const methods = useForm();

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [listCompanies, setListCompanies] = useState([]);
  const [listStore, setListStore] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoginAsCompany, setIsLoginAsCompany] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const [redirectLoginPage, setRedirectLoginPage] = useState(false);
  const [listOrg, setListOrg] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const location = useLocation();
  const history = useHistory();

  const [queryParams, setQueryParams] = useState<QueryParams>(() => {
    return { pageSize: 12, pageNumber: 1 };
  });

  useEffect(() => {
    if (window.localStorage.getItem(Constants.KeyAsyncStore.userNameDev) === null) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  useEffect(() => {
    if (localStorage.get(Constants.KeyAsyncStore.userNameDev, '') != '') {
      getDataApi();
    }
  }, []);

  useEffect(() => {
    if (isLoginAsCompany) {
      window.location.href = `${window.location.origin}/`;
    }
  }, [isLoginAsCompany]);

  const getDataApi = async () => {
    setIsLoading(true);
    let header: string = localStorage.getObject('headerDevLogin', '');
    let headerBase = Utils.safeParseJson(header);
    // let appKey = localStorage.get('appKey', '');
    let newHeader = {
      [Constants.HeaderKey.JM360_KP_VER]: Utils.getSafeValue(headerBase, 'jm360_kp_ver', ''),
      [Constants.HeaderKey.JM360_KP]: Utils.getSafeValue(headerBase, 'jm360_kp', ''),
      [Constants.HeaderKey.JM360_APP_TYPE]: 3,
    };

    setGlobalAPI(undefined, newHeader);

    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.GET,
        name: reducerNameHOR.getCompanies,
        url: AppURL.getCompaniesAssignedToSale,
      });
      let list = Utils.getSafeValue(data, 'list', []);
      setListCompanies(list);
      setListOrg(list);
    } catch (e) {
      console.log('error message get companies: ', e);
      setErrorMessage(e);
    }
    setIsLoading(false);
  };

  const getStore = async () => {
    setIsLoading(true);
    setShowSelectStore(true);
    try {
      let storeData = await callApiACT({
        typeRequest: Constants.TypeRequest.GET,
        // url: AppURL.getCompaniesAgentStore
        url: Utils.replaceStrUrl(AppURL.getCompaniesAgentStore, [queryParams.pageNumber, queryParams.pageSize]),
      });
      let storeList = Utils.getSafeValue(storeData, 'list', []);
      setListStore(storeList);
    } catch (e) {
      console.log('error message get companies store: ', e);
      setErrorMessage(e);
    }

    setIsLoading(false);
  };

  const changeLanguage = lng => {
    localStorage.set(Constants.KeyAsyncStore.appLang, lng);
    i18n.changeLanguage(lng);
  };

  const LoginAsCompany = async dataLogin => {
    setIsLogging(true);
    // let params = _.assign(data, {appKey: localStorage.get('appKey', '')});
    let params = dataLogin;
    let toastMessageSuccess = 'Login as Company Success';

    localStorage.set(Constants.KeyAsyncStore.appKey, dataLogin.appKey);
    localStorage.set([Constants.KeyAsyncStore.deviceKey], Constants.deviceKey);
    localStorage.set([Constants.KeyAsyncStore.appTypeCode], Constants.appTypeCode);

    changeLanguage(dataLogin.lang);

    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.POST,
        url: AppURL.loginAsCompany,
        params: params,
        isToastSuccess: false,
        toastMessageSuccess: toastMessageSuccess,
      });
      setNewHeader(data, dataLogin.lang);
      let customer = Utils.getSafeValue(data, 'customer', {});
      await localStorage.setObject(Constants.KeyAsyncStore.customerAssign, customer);

      // if (location.state){
      //     history.push(location.state.linkHistory)
      // } else {
      setIsLoginAsCompany(true);
      // } // redirect to home, case redirect to previous move to phase 2
    } catch (e) {
      console.log('error message get companies: ', e);
      setErrorMessage(e);
    }
    setIsLogging(false);
  };

  const LoginAsGuest2 = async dataLogin => {
    setIsLogging(true);

    localStorage.set(Constants.KeyAsyncStore.appKey, dataLogin.appKey);
    localStorage.set([Constants.KeyAsyncStore.deviceKey], Constants.deviceKey);
    localStorage.set([Constants.KeyAsyncStore.appTypeCode], Constants.appTypeCode);

    changeLanguage(dataLogin.lang);

    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.POST,
        url: AppURL.loginAsGuest,
        isToastSuccess: false,
      });
      setNewHeader(data, dataLogin.lang);
      let customer = Utils.getSafeValue(data, 'customer', {});
      await localStorage.setObject(Constants.KeyAsyncStore.customerAssign, customer);

      setIsLoginAsCompany(true);
    } catch (e) {
      console.log('error message get companies: ', e);
      setErrorMessage(e);
    }
    setIsLogging(false);
  };

  const LoginAsGuest = () => {
    let customer =
      localStorage.getObject(Constants.KeyAsyncStore.customer, {}) ||
      Utils.getSafeValue(loginUser.response, 'customer', {});
    localStorage.setObject(Constants.KeyAsyncStore.customerAssign, customer);
    setIsLoginAsCompany(true);
  };

  const setNewHeader = (dataLogin, lang) => {
    let keypairVersion = Utils.getSafeValue(dataLogin.kpData, 'keypairVersion', '');
    let keypairData = Utils.getSafeValue(dataLogin.kpData, 'keypairData', '');
    let keyPairExpiration = dataLogin.kpData.keyPairExpiration;
    // let keyPairExpiration = dataLogin.kpData.keyPairExpiration;
    let newHeader = {
      [Constants.HeaderKey.JM360_KP]: keypairData,
      [Constants.HeaderKey.JM360_SO_KP_EXP]: keyPairExpiration,
      [Constants.HeaderKey.JM360_KP_VER]: keypairVersion,
      [Constants.HeaderKey.JM360_ACCEPT_LANGUAGE]: lang,
    };
    setGlobalAPI(undefined, newHeader);
  };

  const onSearch = data => {
    const newList = _.filter(listOrg, itemCompany => {
      const name = Utils.getSafeValue(itemCompany, 'name', '');
      const addressCompany = Utils.getSafeValue(itemCompany, 'address', '');
      const companyCode = Utils.getSafeValue(itemCompany, 'companyCode', '');

      const lowerText = _.toLower(data['search']);
      const lowerName = _.toLower(name);
      const lowerAddress = _.toLower(addressCompany);
      const lowerCompanyCode = _.toLower(companyCode);

      return (
        _.includes(lowerName, lowerText) ||
        _.includes(lowerAddress, lowerText) ||
        _.includes(lowerCompanyCode, lowerText)
      );
    });
    if (data['search'] !== '') {
      setListCompanies(newList);
    } else {
      setListCompanies(listOrg);
    }
  };

  const logOut = () => {
    store.dispatch({ type: 'USER_LOGOUT' });
    setRedirectLoginPage(true);
    localStorage.clear();
    setGlobalAPI(undefined, { [Constants.HeaderKey.JM360_ACCEPT_LANGUAGE]: 'en' });
  };
  const resetSearch = () => {
    methods.reset();
    setListCompanies(listOrg);
  };

  const onCloseModal = () => {
    setErrorMessage('');
  };

  const closeSelectStore = () => {
    setShowSelectStore(false);
    setListCompanies(listOrg);
  };

  const [confirmLogout, setConfirmLogout] = useState(false);
  const [showSelectStore, setShowSelectStore] = useState(false);

  return (
    <div className="loginAsCompany">
      {redirectLoginPage && <Redirect to={`/login`} />}
      {/* {isLoginAsCompany &&
      // <Redirect to={`/`} />
      } */}
      {isLogging && <LoadingScreen />}

      {!showSelectStore && (
        <div className="loginAsCompanyInner">
          <div className="actionBox">
            <div className="titlePage">Select a company that you would like to shop for</div>
            <div
              className="btnGuestLogin cursor-pointer"
              onClick={() => {
                getStore();
              }}
            >
              Continue As Agent
            </div>
            <div className="btnLogout cursor-pointer" onClick={() => setConfirmLogout(true)}>
              Log Out
            </div>
          </div>
          <div className="loginCompanyForm">
            {listOrg.length != 0 && (
              <div className="bar-header">
                <Form onSubmit={methods.handleSubmit(onSearch)}>
                  <div className="search-box">
                    <input
                      className="txt_text"
                      type="text"
                      onChange={methods.handleSubmit(onSearch)}
                      placeholder={`Search company...`}
                      name="search"
                      ref={methods.register}
                    />
                    <button className="btnClearSearch" type="button" onClick={() => resetSearch()}>
                      <IoMdCloseCircle />
                    </button>
                  </div>
                </Form>
              </div>
            )}

            <div className="list-company">
              {isLoading ? (
                <ul>
                  {_.times(20, i => (
                    <li key={i}>
                      <Skeleton variant="text" width="40%" />
                      <Skeleton variant="text" width="70%" />
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  {listCompanies.length != 0 && (
                    <ul>
                      {_.map(listCompanies, (company, index) => {
                        return (
                          <li key={index}>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                LoginAsCompany(company);
                              }}
                            >
                              <div className="name-company">{company['name']}</div>
                              <div className="company-code">{company['companyCode']}</div>
                              <div className="address-company">{company['address']}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </>
              )}
              {listOrg.length === 0 && !isLoading && <div className="noFound">You are not assigned to any company</div>}
              {listCompanies.length === 0 && listOrg.length != 0 && !isLoading && (
                <div className="noFound">No Company Found</div>
              )}
            </div>
          </div>
        </div>
      )}

      {showSelectStore && (
        <div className="loginAsCompanyInner">
          <div className="actionBox">
            <div className="titlePage">Please select a store</div>
            <div
              className="btnLogout cursor-pointer"
              onClick={() => {
                closeSelectStore();
              }}
            >
              Back
            </div>
          </div>
          <div className="loginCompanyForm">
            <div className="list-company">
              {isLoading ? (
                <ul>
                  {_.times(10, i => (
                    <li key={i}>
                      <Skeleton variant="text" width="40%" />
                      <Skeleton variant="text" width="70%" />
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  {listStore.length != 0 && (
                    <ul>
                      {_.map(listStore, (item, index) => {
                        return (
                          <li key={index}>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                LoginAsGuest2(item);
                              }}
                            >
                              <div className="name-company">{item['name']}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </>
              )}
              {listStore.length === 0 && !isLoading && <div className="noFound">You are not assigned to any store</div>}
            </div>
          </div>
        </div>
      )}

      <ModalAlert message={errorMessage} onCloseModal={() => onCloseModal()} />

      <Modal
        dialogClassName="confirmLogout"
        show={confirmLogout}
        scrollable={false}
        onHide={() => setConfirmLogout(false)}
      >
        <Modal.Body className="modalBody">
          <div className="text-message">Do you want to log out ?</div>
          <div className="btnGroup">
            <button type="button" onClick={() => setConfirmLogout(false)} className="btnCancel">
              No
            </button>
            <button type="button" onClick={() => logOut()} className="btnLogout">
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loginUser: state.loginUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(withTranslation()(LoginAsCompany));
