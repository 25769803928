import { reducerNameHOR } from '.';
import produce from 'immer';

export const LIST_CATEGORY = 'LIST_CATEGORY';

export const initialState = {
  listCategory: null,
};
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LIST_CATEGORY:
        draft.listCategory = action.params;
        break;
    }
  });

export function getCategoriesTree(params) {
  return {
    type: LIST_CATEGORY,
    params,
    name: reducerNameHOR.getCategoriesTree,
  };
}

export default reducer;
