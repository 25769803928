/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';

import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, TextField } from '@material-ui/core';

import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import useProductList from 'src/containers/business/hooks/useProductList';
import { FilterItem, FilterOption, FilterOptionItem } from 'src/types/productList';
import { Image } from 'react-bootstrap';
import { Images } from 'src/assets/Images';
import StyledCheckbox from './components/Checkbox';
import * as Utils from '../../utils';

interface Props {
  option: FilterOption;
  currencySymbol?: string;
  currencyCode?: string;
  onPriceError?: any;

  onChangeFilterCb: any;
  isChangePrice?: boolean;
  handleChangeFilterOption?: any;
  currentFilters: FilterOption[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '185px',
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      // marginTop: "40px"
    },
    filterWrapper: {
      //   overflowY: 'auto',
      //   maxHeight: '300px',
      //   background: `linear-gradient(#ffffff 33%, rgb(255,255,255)),
      // linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
      // radial-gradient(farthest-side at 50% 0, rgb(188,188,188), rgba(0,0,0,0)),
      // radial-gradient(farthest-side at 50% 100%, rgb(188,188,188), rgba(0,0,0,0)) 0 100%`,
      //   backgroundColor: '#ffffff',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundAttachment: 'local, local, scroll, scroll',
      //   backgroundSize: '100% 15px, 100% 15px, 100% 5px, 100% 5px',
    },
    nested: {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,

      // backgroundColor: "rgba(255,255,132,0.3)"
    },
    nestedPrice: {
      width: '100%',
    },
    text: {
      fontFamily: 'FuturaPTBook',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
      color: '#000000',
    },
    listRootText: {
      fontWeight: 500,
      lineHeight: 1.5,
    },
    listItem: {
      background: 'none !important',
      '&:hover': {
        background: 'none',
      },
    },
    icon: {
      minWidth: 16,
      cursor: 'pointer',
    },
    separateItem: {
      maxWidth: '158px',
      marginLeft: '14px',
      marginRight: '13px',
      height: '1px',
      backgroundColor: '#000000',
    },
    input: {
      width: '100% ',
      maxWidth: '56px ',
      height: '30px ',
      // border: "solid 0.7px #000000 ",
      backgroundColor: '#ffffff ',
    },
    applyPrice: {
      width: '100%',
      maxWidth: '153px',
      height: '43px',
      marginTop: '8px',
      marginBottom: '18px',
      paddingTop: '10px',
      paddingBottom: '12px',
    },
    applyText: {
      color: '#fff',
      border: 'solid 1px #ffffff',
      backgroundColor: '#000000',
      cursor: 'pointer',
    },
    priceText: {
      fontSize: '11.2px',
      letterSpacing: '0.35px',
      textAlign: 'center',
      color: '#000000',
    },
    applyPriceEmpty: {
      width: '100%',
      maxWidth: '153px',
      height: '43px',
      border: 'solid 1px #ffffff',
      backgroundColor: '#e3e3e3',
    },
    applyTextEmpty: {
      opacity: 0.2,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorPrice: {
      height: '12px',
    },
    errorText: {
      fontSize: '9px',
      fontWeight: 500,
      letterSpacing: '0.44px',
      color: '#f70101',
    },
    errorPriceInput: {
      border: 'solid 0.7px #f70101 ',
      backgroundColor: '#FFF',
    },
  })
);
const PriceFilterItem = ({ option, onChangePrice, isChangePrice, onChangeFilterCb }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [hasPrice, setHasPrice] = useState(false);
  const [priceError, setPriceError] = useState({ error: false, message: '' });
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  useEffect(() => {
    setHasPrice(min !== '' || max !== '');
    const minValue = Utils.safeParseInt(min);
    const maxValue = Utils.safeParseInt(max);
    if (max && minValue > maxValue) {
      setPriceError({
        error: true,
        message: t('services.multipleFilter.validatePriceMinMax'),
      });
    } else {
      setPriceError({ error: false, message: '' });
    }
  }, [min, max]);
  const onChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setMin(value);
  };

  const onChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setMax(value);
  };
  const onClickSubmit = () => {
    if (!hasPrice || priceError.error) {
      return;
    }
    const minPrice = Utils.safeParseInt(min);
    const maxPrice = Utils.safeParseInt(max);
    const minObj = (min && { minPrice }) || {};
    const maxObj = (max && { maxPrice }) || {};
    onChangePrice({ ...minObj, ...maxObj });
    onChangeFilterCb(true);
    setMin('');
    setMax('');
  };
  return (
    <List component="div" disablePadding>
      <ListItem role={undefined} dense button className={clsx(classes.nestedPrice, classes.listItem)} disableRipple>
        <ListItemText
          className={classes.text}
          primary="Min"
          primaryTypographyProps={{
            className: classes.text,
          }}
        />
        <TextField
          value={min}
          onChange={onChangeMin}
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            disableUnderline: true,
          }}
          inputProps={{
            error: priceError.error,
          }}
        />
      </ListItem>
      {priceError.error && (
        <ListItem
          role={undefined}
          dense
          button
          className={clsx(classes.nestedPrice, classes.listItem, classes.errorPrice)}
          disableRipple
        >
          <ListItemText
            className={clsx(classes.text, classes.center)}
            primary={priceError.message}
            primaryTypographyProps={{
              className: clsx(classes.text, classes.errorText),
            }}
          />
        </ListItem>
      )}
      <ListItem role={undefined} dense button className={clsx(classes.nestedPrice, classes.listItem)} disableRipple>
        <ListItemText
          className={classes.text}
          primary="Max"
          primaryTypographyProps={{
            className: classes.text,
          }}
        />
        <TextField
          value={max}
          onChange={onChangeMax}
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            className: classes.input,
            disableUnderline: true,
          }}
        />
      </ListItem>

      <ListItem className={classes.center}>
        <div
          className={clsx(
            { [classes.applyPrice]: isChangePrice || hasPrice },
            { [classes.applyPriceEmpty]: !(isChangePrice || hasPrice) },
            classes.text,
            classes.center,

            { [classes.applyText]: isChangePrice || hasPrice },
            { [classes.applyTextEmpty]: !(isChangePrice || hasPrice) }
          )}
          onClick={onClickSubmit}
        >
          {(isChangePrice && <Image width={22} src={Images.loadingWhite} />) || <span>{t('productList.apply')}</span>}
        </div>
      </ListItem>
    </List>
  );
};
const OptionFilterItem = ({ option, handleChangeFilterOption, currentFilters }) => {
  const classes = useStyles();
  const { items } = option;
  const matchOptionHeader = currentFilters.find(opt => opt.key === option.key);
  const headerItems = (matchOptionHeader && matchOptionHeader.items) || [];

  // console.log(headerItems);
  const onClickItemHOF = (filterOption: FilterOption, optionValue: FilterItem) => {
    return () => {
      handleChangeFilterOption(filterOption, optionValue);
    };
  };

  return (
    <List component="div">
      {items
        .filter(opt => !headerItems.find(item => item.id === opt.id))
        .map(optionValue => {
          return (
            <ListItem key={optionValue.id} className={classes.nested} onClick={onClickItemHOF(option, optionValue)}>
              <ListItemText
                primary={optionValue.label}
                primaryTypographyProps={{
                  className: classes.text,
                }}
              />
              <ListItemIcon className={classes.icon}>
                <StyledCheckbox />
              </ListItemIcon>
            </ListItem>
          );
        })}
    </List>
  );
};
function NestedList({
  option,
  onChangeFilterCb,
  currencyCode,
  currentFilters,
  isChangePrice,
  handleChangeFilterOption,
}: Props) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const { onChangePrice } = useProductList();
  const handleClickCollapse = () => {
    setOpen(openPrev => !openPrev);
  };

  return (
    <List component="div">
      <ListItem
        // className={classes.optionListItem}
        className={classes.root}
        onClick={handleClickCollapse}
      >
        <ListItemText
          primary={option.title.toUpperCase().concat((option.type === 'PRICE_RANGE' && ` (${currencyCode})`) || '')}
          primaryTypographyProps={
            {
              // className: classes.optionList,
            }
          }
        />

        {open ? <GoTriangleDown /> : <GoTriangleUp />}
      </ListItem>
      {!open && <Divider className={classes.separateItem} />}
      <div className={classes.filterWrapper}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {(Utils.getSafeValue(option, 'type', '') === 'PRICE_RANGE' && (
            <PriceFilterItem
              option={option}
              onChangePrice={onChangePrice}
              isChangePrice={isChangePrice}
              onChangeFilterCb={onChangeFilterCb}
            />
          )) || (
            <OptionFilterItem
              option={option}
              handleChangeFilterOption={handleChangeFilterOption}
              currentFilters={currentFilters}
            />
          )}
        </Collapse>
      </div>
    </List>
  );
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  error?: any;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, error, ...other } = props;
  const classes = useStyles();
  return (
    <NumberFormat
      {...other}
      className={classes.priceText}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      style={{
        width: '100% ',
        maxWidth: '56px ',
        height: '30px ',
        border: 'solid 0.7px',
        borderColor: error ? '#f70101' : '#000000',
        backgroundColor: '#ffffff ',
        minWidth: '40px',
        padding: 0,
      }}
    />
  );
}

export default NestedList;
