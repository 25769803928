import { ProductList } from 'src/types/productList';

const initProductList: ProductList = {
  currencyCode: '',
  error: {},
  filters: [],
  item: null,
  list: null,
  meta: {
    pagination: {},
    sortList: [],
  },
  products: [],
  success: null,
};
interface ProductListState {
  loading: boolean;
  data: ProductList;
}
export const initProductListState: ProductListState = {
  loading: false,
  data: initProductList,
};
export enum ProductsListActionType {
  LOADING = 'LOADING',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
}

type ProductListAction =
  | { type: ProductsListActionType.LOADING }
  | { type: ProductsListActionType.FETCH_SUCCESS; payload: ProductList };

export const productListReducer = (state: ProductListState, action: ProductListAction) => {
  switch (action.type) {
    case ProductsListActionType.LOADING: {
      const newState: ProductListState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case ProductsListActionType.FETCH_SUCCESS: {
      const newState: ProductListState = {
        ...state,
        loading: false,
        data: action.payload,
      };
      return newState;
    }

    default: {
      return state;
    }
  }
};
