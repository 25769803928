import React, { useEffect, useState } from 'react';
// import '../../../node_modules/react-table/react-table.css';
import { useForm } from 'react-hook-form';
import { Image, Table, Modal } from 'react-bootstrap';
import './style.scss';
import { Link, useHistory } from 'react-router-dom';
import { format, addDays, isValid } from 'date-fns';
import { connect, useSelector } from 'react-redux';
import _, { get, isEmpty } from 'lodash';
import { compose } from 'redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { IoIosAdd, IoIosClose, IoIosPlay, IoIosRemove, IoMdCheckmark } from 'react-icons/io';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router';
import { useTranslation, withTranslation } from 'react-i18next';
import ModalAlert from '../../components/modalAlert/modalAlert';
import * as Constants from '../../constants';
import { backTop, getSafeValue } from '../../utils';
import defaultImage from '../../assets/defaultProductImg.jpg';
import loading from '../../assets/loadingx43px.svg';
import { ListProductSkeleton } from '../../components/skeleton/listProductSkeleton';
import DatePickerInput from '../../components/datePicker';
import localStorage from '../../utils/LocalStorage';
import { TypeRequest } from '../../constants';
import * as Utils from '../../utils';
import * as AppURL from '../../services/urlAPI';
import { callApiACT } from '../../redux/callApiRDC';
import { callApiListCart } from '../../redux/getListCartRDC';
import { ProductListQueryParams } from 'src/types/productList';
import { getEnableBackOrder } from 'src/redux/selector/appConfig.selector';
import { validateQuantity } from 'src/utils/product.helpers';
import { GenericObject } from 'src/types/common';
import { RootState } from '../../redux';

interface PropsRow {
  columns: any;
  productDetails: any;
  callApiListCart: (action) => void;
  methods: any;
  // productCart:any,
  isLoading: boolean;
  isDisplayDetail?: boolean;
  positionOfButton?: string;
  userSelections?: any;
  isEnableBackorder?: boolean;
  onAddCartSuccess?: () => void;
  getTabIndex?: any;
}

interface PropsActionButtons {
  productDetails: any;
  addProductToCart: () => void;
  animationDefault?: boolean;
  animationLoading?: boolean;
  animationAdded?: boolean;
  isEnableCartButton?: boolean;
  tabIndex?: number;
}

interface PropsTableOrder {
  id: number;
  callApiACT: (action) => void;
  callApiListCart: (action) => void;
  data?: any;
  isDisplayDetail?: boolean;
  positionOfButton?: string;
  appConfig?: any;
  // isAgent?: boolean;
}

function ProductRow({
  columns,
  productDetails,
  callApiListCart,
  methods,
  isLoading,
  isDisplayDetail,
  positionOfButton,
  userSelections,
  isEnableBackorder,
  onAddCartSuccess,
  getTabIndex,
}: PropsRow) {
  const [lastProduct, setLastProduct] = useState([]);
  const [animationDefault, setAnimationDefault] = useState(true);
  const [animationLoading, setAnimationLoading] = useState(false);
  const [animationAdded, setAnimationAdded] = useState(false);
  const [_isDisplayDetail, _setIsDisplayDetail] = useState(isDisplayDetail || false);
  const [_positionOfButton, __setPositionOfButton] = useState(positionOfButton || 'bottom');
  const [isEnableCartButton, setIsEnableCartButton] = useState(false);
  const [errorScreen, setErrorScreen] = useState('');

  const cartRedux = useSelector((state: RootState) => state.getCartList);

  const { t } = useTranslation();

  useEffect(() => {
    let hasQty = false;
    let hasError = false;

    // const hasError = !_.isEmpty(methods.errors);
    if (!productDetails.variantMatrix) return;
    for (let i = 0; i < productDetails.variantMatrix.length; i++) {
      const quantityItem = parseInt(Utils.getSafeValue(userSelections, `quantity[${i}]`, ''), 10);
      if (userSelections && quantityItem > 0) {
        hasQty = true;
        break;
      }
    }

    if (isEnableBackorder) {
      let errorArr: any = [];
      if (!_.isEmpty(methods.errors)) {
        errorArr = _.map(
          Utils.getSafeValue(methods.errors, 'quantity', []).filter(err => !isEmpty(err)),
          (item, index) => {
            return item.type;
          }
        );

        if (
          _.includes(errorArr, 'outStock') ||
          _.includes(errorArr, 'notEnoughStock') ||
          _.includes(errorArr, 'maxQuantity') ||
          _.includes(errorArr, 'minQuantity')
        ) {
          hasError = true;
        }
      }
    } else {
      hasError = !_.isEmpty(methods.errors);
    }

    setIsEnableCartButton(hasQty && !hasError);
  }, [userSelections, methods]);
  // useEffect( ()=>{

  //     if(productCart['quantity']>0){
  //         let modifierSelections = _.map(productCart['optionValues'],(item,index)=>{
  //             return {
  //                 "optionId":item.optionId,
  //                 "optionValue": item.id
  //             }
  //         });
  //         let optionSelections = _.map(productCart['optionValues'],(item,index)=>{
  //             return {
  //                 "optionId":item.optionId,
  //                 "optionValue": item.id
  //             }
  //         });
  //         let customProduct = {
  //             "modifierSelections":modifierSelections,
  //             "optionSelections":optionSelections,
  //             "productId": productDetails.id,
  //             "quantity":productCart['quantity'],
  //             "price":productCart['calculatedPrice'],
  //             "deliveryDate": productCart['deliveryDate'],
  //             "optionValues": productCart['optionValues']
  //         };
  //         let arr:any = [];
  //         arr.push(customProduct);
  //         if(typeof _.find(lastProduct, {optionValues:customProduct['optionValues']})!=="undefined"){
  //             let newArr:any =  _.map(lastProduct,(item,index)=>{
  //                 return (customProduct['optionValues']===item['optionValues'])?customProduct:item;
  //             });
  //             setLastProduct(newArr);
  //         }else {
  //             setLastProduct(lastProduct.concat(arr));
  //         }

  //     }
  // },[productCart]); // get list product, which will add to cart

  const createLineItems = () => {
    // prepare data for submit

    const lineItems = productDetails.variantMatrix.reduce((lines, variant, index) => {
      const itemQuantityInput = Utils.getSafeValue(userSelections, `quantity[${index}]`, '');
      const deliveryDateInput = _.get(userSelections, `deliveryDate[${index}]`, undefined);

      const quantity = Utils.safeParseInt(itemQuantityInput);

      const delivery =
        (isValid(deliveryDateInput) && moment(deliveryDateInput).format(Constants.DateFormat.serverDateFormat)) || '';

      if (quantity > 0) {
        return lines.concat({
          quantity,
          deliveryDate: delivery,
          productId: variant.productId,
          optionSelections: variant.optionValues.map(option => ({
            optionId: option.optionId,
            optionValue: option.id,
          })),
        });
      }
      return lines;
    }, []);

    return lineItems;
  };

  const createCart = async () => {
    setAnimationDefault(false);
    setAnimationLoading(true);

    const params = {
      lineItems: createLineItems(),
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
    };
    const toastMessageSuccess = `${t('tableOrderScreen.addedSuccess')}`;
    try {
      const newCart = await callApiListCart({
        typeRequest: TypeRequest.POST,
        url: AppURL.createCart,
        params,
        isToastSuccess: true,
        toastMessageSuccess,
      });
      const cart = Utils.getSafeValue(newCart, 'cart', {});
      localStorage.set('cartId', cart.id);
      // setLastProduct([]);
      // methods.reset({'quantity':NaN});
      if (onAddCartSuccess) {
        onAddCartSuccess();
      }

      setAnimationLoading(false);
      setAnimationAdded(true);

      setTimeout(() => {
        setAnimationAdded(false);
        setAnimationDefault(true);
      }, 2000);
      // backTop();
    } catch (e) {
      console.log('error message creat cart', e);
      setErrorScreen(e);
      setAnimationLoading(false);
      setAnimationDefault(true);
    }
  };

  const addProductToCart = async () => {
    const params = {
      lineItems: createLineItems(),
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
    };
    const toastMessageSuccess = `${t('tableOrderScreen.addedSuccess')}`;

    const id = getSafeValue(cartRedux?.cart, 'id', '') || '';

    if (!id) {
      createCart();
    } else {
      setAnimationDefault(false);
      setAnimationLoading(true);

      try {
        let newCart = await callApiListCart({
          typeRequest: TypeRequest.PUT,
          url: Utils.replaceStrUrl(AppURL.addProductToExistCart, [id]),
          params: params,
          isToastSuccess: true,
          toastMessageSuccess: toastMessageSuccess,
        });

        // setLastProduct([]);
        // methods.reset({'quantity':NaN})
        if (onAddCartSuccess) {
          onAddCartSuccess();
        }
        // backTop();
        setAnimationLoading(false);
        setAnimationAdded(true);

        setTimeout(() => {
          setAnimationAdded(false);
          setAnimationDefault(true);
        }, 2000);
      } catch (e) {
        console.log('error message add product to cart', e);
        setErrorScreen(e);
        setAnimationLoading(false);
        setAnimationDefault(true);
      }
    }
  };
  const renderBottomActionButton = () => {
    return (
      <div className="btnGroupBottom">
        <ActionButtons
          addProductToCart={addProductToCart}
          animationDefault={animationDefault}
          animationLoading={animationLoading}
          animationAdded={animationAdded}
          productDetails={productDetails}
          isEnableCartButton={isEnableCartButton}
          tabIndex={getTabIndex(-1)}
        />
      </div>
    );
  };
  return (
    <>
      <form>
        <div className="bulkOrderForm">
          {_isDisplayDetail && (
            <>
              {isLoading ? (
                <div className="d-flex-row w-80 p-3">
                  <Skeleton variant="rect" width={200} height={200} />
                  <div className="ml-4 w-100">
                    <Skeleton variant="text" width="80%" height={40} />
                    <Skeleton variant="text" width="100%" height={30} />
                    <Skeleton variant="text" width="100%" height={30} />
                    <Skeleton variant="text" width="100%" height={30} />
                    <div className="d-flex-row mt-3">
                      <Skeleton variant="rect" width={150} height={60} style={{ marginRight: '20px' }} />
                      <Skeleton variant="rect" width={150} height={60} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="headerBulkOrder">
                  <div className="headerBulkOrderInner">
                    <div className="img">
                      {productDetails.images &&
                        productDetails.images.length > 1 &&
                        _.map(productDetails.images, (item, index) => {
                          if (item.isThumbnail === true) {
                            return <Image key={index} src={item.urlThumbnail} />;
                          }
                        })}
                      {productDetails.images && productDetails.images.length === 1 && (
                        <Image src={productDetails.images[0].urlThumbnail} />
                      )}
                    </div>

                    <div className="details">
                      <div className="title-block">{productDetails.name}</div>
                      {productDetails.sku && <div className="productSku">SKU: {productDetails.sku}</div>}

                      {_positionOfButton === 'top' && (
                        <ActionButtons
                          addProductToCart={addProductToCart}
                          animationDefault={animationDefault}
                          animationLoading={animationLoading}
                          animationAdded={animationAdded}
                          productDetails={productDetails}
                          isEnableCartButton={isEnableCartButton}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="tableBulkOrderWrap">
            <Table className="tableItems">
              <thead>
                <tr>
                  {_.map(columns, (item, index) => {
                    return <th key={index}>{item.name}</th>;
                  })}
                </tr>
              </thead>
              {isLoading ? (
                <ListProductSkeleton />
              ) : (
                <tbody>
                  {_.map(productDetails.variantMatrix, (item, index) => {
                    // Check item is out of stock or not
                    let isOutOfStock = false;
                    if (item?.inventoryLevel === 0) isOutOfStock = true;
                    const check = _.find(item.optionValues, {
                      optionDisplayName: 'Coat & Glaser checkbox',
                    }) || { label: 'Yes' };
                    if (check.label === 'Yes') {
                      return (
                        <tr key={index}>
                          {_.map(columns, (col, indexCol) => {
                            // Do not show Est. Delivery Date if item is not out of stock
                            if (!isOutOfStock && col?.name === t('tableColumnName.estDeliveryDate')) {
                              return <td>---</td>;
                            } else
                              return (
                                <td className={col.className} key={indexCol}>
                                  {col.cell ? col.cell({ item }, index) : item[col.selector]}
                                </td>
                              );
                          })}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              )}
            </Table>
          </div>
          {!isLoading && renderBottomActionButton()}
        </div>
      </form>
      <ModalAlert
        message={errorScreen}
        onCloseModal={() => {
          setErrorScreen('');
        }}
      />
    </>
  );
}

export const ActionButtons = ({
  addProductToCart,
  productDetails,
  isEnableCartButton,
  animationDefault,
  animationLoading,
  animationAdded,
  tabIndex,
}: PropsActionButtons) => {
  const [confirmLogoutCompany, setConfirmLogoutCompany] = useState<boolean>(false);
  const [isAgentLogin, setIsAgentLogin] = useState<boolean>();
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const isAgentLogin = Utils.getSafeValue(
      localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {}),
      'loginAsCompany',
      null
    );
    setIsAgentLogin(isAgentLogin);
  }, []);

  const handleClickSwitch = () => {
    localStorage.removeItem(Constants.KeyAsyncStore.customerAssign);
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem('stepCheckout');
    localStorage.removeItem('selectedShippingMethod');
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedAddress');
    history.push({
      pathname: '/login-as-company',
      state: { linkHistory: location.pathname },
    });
  };

  return (
    <>
      <div className="btnGroup">
        <Link className="btn btnViewProduct" onClick={() => backTop()} to={`/products/${productDetails.id}`}>
          {t('tableOrderScreen.viewDetails')}
        </Link>
        {isAgentLogin ? (
          <button
            onClick={addProductToCart}
            type="button"
            className={`btn btnAddToCart ${isEnableCartButton ? '' : 'disabled'} ${
              animationLoading || animationAdded ? 'loading' : ''
            }`}
            disabled={!isEnableCartButton}
            tabIndex={tabIndex}
          >
            {animationDefault && `${t('tableOrderScreen.addToCart')}`}
            {animationLoading && (
              <>
                <Image width={22} src={loading} /> {t('tableOrderScreen.adding')}
              </>
            )}
            {animationAdded && (
              <>
                <IoMdCheckmark style={{ fontSize: '30px' }} /> {t('tableOrderScreen.added')}
              </>
            )}
            <span className="arrow">
              <IoIosPlay />
            </span>
          </button>
        ) : (
          <button
            onClick={() => setConfirmLogoutCompany(true)}
            type="button"
            className={`btn btnAddToCart ${isEnableCartButton ? '' : 'disabled'} ${
              animationLoading || animationAdded ? 'loading' : ''
            }`}
            disabled={!isEnableCartButton}
            tabIndex={tabIndex}
          >
            {animationDefault && `${t('tableOrderScreen.addToCart')}`}
            {animationLoading && (
              <>
                <Image width={22} src={loading} /> {t('tableOrderScreen.adding')}
              </>
            )}
            {animationAdded && (
              <>
                <IoMdCheckmark style={{ fontSize: '30px' }} /> {t('tableOrderScreen.added')}
              </>
            )}
            <span className="arrow">
              <IoIosPlay />
            </span>
          </button>
        )}
      </div>
      <Modal
        dialogClassName="confirmToSelectCompany"
        show={confirmLogoutCompany}
        scrollable={false}
        onHide={() => setConfirmLogoutCompany(false)}
      >
        <Modal.Body className="modalBody">
          <div className="text-message line1">{t('confirmModal.msgDialogRedirectSelectCompany1')}</div>
          <div className="text-message line2">{t('confirmModal.msgDialogRedirectSelectCompany2')}</div>
          <div className="btnGroup">
            <button type="button" onClick={() => setConfirmLogoutCompany(false)} className="btnCancel">
              {t('confirmModal.no')}
            </button>
            <button type="button" onClick={() => handleClickSwitch()} className="btnLogout">
              {t('confirmModal.yes')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

function TableOrder({
  id,
  callApiACT,
  callApiListCart,
  data,
  isDisplayDetail,
  positionOfButton,
  appConfig,
  getTabIndex,
  params = {},
}: // isAgent
any) {
  const methods = useForm();

  const { t, i18n } = useTranslation();
  const enableBackOrder = useSelector(getEnableBackOrder);

  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState<GenericObject>({
    variants: [],
    variantMatrix: [],
    name: '',
  });
  const [userSelections, setUserSelections] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [qtyValue, setQtyValue] = useState<any>();
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [isEnableBackorder, setIsEnableBackorder] = useState<boolean>(false);

  useEffect(() => {
    const itemsQty: Array<number> = [];
    _.forEach(productDetails.variantMatrix, (v, i) => {
      itemsQty.push(0);
      methods.register({ name: `deliveryDate[${i}]` });
    });
    setQtyValue(itemsQty);
  }, [productDetails]);

  const [currency, setCurrency] = useState('');
  useEffect(() => {
    if (appConfig.response.appData) {
      const appMetadataRaw = Utils.getSafeValue(appConfig.response.appData.appMetadata, 'data', '');
      const appMetadata: any = Utils.safeParseJson(appMetadataRaw);
      const { currency: currencyData } = appMetadata;
      setCurrency(currencyData);

      const isEnableBackorderData = Utils.getSafeValue(appMetadata.saleSettings, 'enableBackorder', false);
      if (isEnableBackorderData) {
        setIsEnableBackorder(appMetadata.saleSettings.enableBackorder);
      }
    }
  }, [appConfig]);

  const [isAgentLogin, setIsAgentLogin] = useState<boolean>();

  useEffect(() => {
    const isAgentLogin = Utils.getSafeValue(
      localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {}),
      'loginAsCompany',
      null
    );
    setIsAgentLogin(isAgentLogin);
  }, []);

  const onChangeDeliveryDate = (index: number) => {
    return (date: Date | [Date, Date] | /* for selectsRange */ null, event: React.SyntheticEvent<any> | undefined) => {
      methods.setValue(`deliveryDate[${index}]`, date?.valueOf() || '');
      setUserSelections(methods.getValues());
    };
  };

  const updateQty = (index: number, value: number) => {
    const qtyValueCopy = [...qtyValue];
    qtyValueCopy[index] = value;
    setQtyValue(qtyValueCopy);
    methods.setValue(`quantity[${index}]`, value);
    setUserSelections(methods.getValues());
  };

  const increaseQty = (row: any, index: number) => {
    if (row.item.item.stockLevelStatus == 0) {
      setOpenPopup(true);
      return;
    }
    updateQty(index, qtyValue[index] + 1);
  };
  const decreaseQty = (item: any, index: number) => {
    if (qtyValue[index] > 0) {
      updateQty(index, qtyValue[index] - 1);
    }
  };

  const onChangeQty = async (e: { value: string; row: any; target: any }, index) => {
    if (!e.target.value) {
      updateQty(index, 0);
    } else {
      updateQty(index, parseInt(e.value, 10));
    }
  };
  const onBlurQuantity = (index: number) => {
    return (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const {
        orderQuantityMinimum: min,
        orderQuantityMaximum: max,
        inventoryLevel,
        backOrderActualStock,
        binPickingNumber = '',
      } = Utils.getSafeValue(productDetails, ['variantMatrix', index], {});

      const backOrderProduct = enableBackOrder && !isEmpty(binPickingNumber);
      const inStock = backOrderProduct ? backOrderActualStock : inventoryLevel;

      const productName = Utils.getSafeValue(productDetails, 'name', '');
      const estDate = Utils.getSafeValue(productDetails, ['variantMatrix', index, 'estimateDeliveryDate'], '');
      const estDeliveryDate =
        (estDate &&
          Utils.convertDateFormat(estDate, Constants.DateFormat.serverDateFormat, Constants.DateFormat.valueShow)) ||
        '';
      const backOrderWaring = t('tableOrderScreen.messageBackOrderWarning', {
        estDeliveryDate,
      });
      const stockWaring = t('tableOrderScreen.messageCheckStockWarning', {
        productName,
      });
      const { quantity, error } = validateQuantity(Utils.safeParseInt(value), { min, max, inStock, productName });

      if (Utils.safeParseInt(value) !== 0 && error.isError) {
        methods.setError(`quantity[${index}]`, { type: 'error', message: error.message });
      } else if (quantity > inventoryLevel) {
        methods.setError(`quantity[${index}]`, { type: 'warning', message: `${stockWaring} <br/> ${backOrderWaring}` });
      } else {
        methods.setError(`quantity[${index}]`, { type: 'default', message: '' });
      }
    };
  };
  // const validateQuantity = (e: { value: string; row: any }, index) => {
  //   const newValue = parseInt(e.value, 10);
  //   const totalStock = parseInt(e.row.item.backOrderActualStock, 10);
  //   const binPickingNumberFormat = moment(e.row.item.binPickingNumber, Constants.DateFormat.serverDateFormat).format(
  //     Constants.DateFormat.valueInput
  //   );
  //   if (newValue > 0 && e.row.item.outStock) {
  //     methods.setError(`quantity[${index}]`, { type: 'outStock', message: t('tableOrderScreen.outStock') });
  //   } else if (newValue > 0 && newValue > e.row.item.inventoryLevel) {
  //     if (newValue <= totalStock && isEnableBackorder) {
  //       methods.setError(`quantity[${index}]`, {
  //         type: 'enoughBackOrder',
  //         message: t('tableOrderScreen.enoughBackOrder', { productDetails, binPickingNumberFormat }),
  //       });
  //     } else {
  //       methods.setError(`quantity[${index}]`, {
  //         type: 'notEnoughStock',
  //         message: t('tableOrderScreen.notEnoughStock', { productDetails }),
  //       });
  //     }
  //   } else if (newValue > 0 && e.row.item.orderQuantityMinimum > e.row.item.inventoryLevel) {
  //     if (newValue <= totalStock && isEnableBackorder) {
  //       methods.setError(`quantity[${index}]`, {
  //         type: 'enoughBackOrder',
  //         message: t('tableOrderScreen.enoughBackOrder', { productDetails, binPickingNumberFormat }),
  //       });
  //     } else {
  //       methods.setError(`quantity[${index}]`, {
  //         type: 'notEnoughStock',
  //         message: t('tableOrderScreen.notEnoughStock', { productDetails }),
  //       });
  //     }
  //   } else if (newValue > 0 && e.row.item.orderQuantityMaximum > 0 && newValue > e.row.item.orderQuantityMaximum) {
  //     methods.setError(`quantity[${index}]`, {
  //       type: 'maxQuantity',
  //       message: t('tableOrderScreen.maxQuantity', { e }),
  //     });
  //   } else if (newValue > 0 && e.row.item.orderQuantityMinimum > 0 && newValue < e.row.item.orderQuantityMinimum) {
  //     methods.setError(`quantity[${index}]`, {
  //       type: 'minQuantity',
  //       message: t('tableOrderScreen.minQuantity', { e }),
  //     });
  //   } else {
  //     methods.clearErrors([`quantity[${index}]`]);
  //   }
  // };

  const validateStock = (e, index, item) => {
    let itemValue = e.target.value;
    _.debounce(async (e: { target: any }, index, item) => {
      if (!e.target.value || e.target.value == 'NaN') {
        updateQty(index, 0);
      }
    }, 100);
  };

  const columns = [
    {
      name: '',
      selector: '',
      className: 'colImageOrder',
      cell: row => {
        const image = Utils.getSafeValue(row.item, 'imageUrl', '');
        return (
          <div className="wrapImage">
            <img src={image ? image : defaultImage} alt="" />
          </div>
        );
      },
    },
    {
      name: `${t('tableColumnName.color')}`,
      selector: '',
      className: 'colColorOrder',
      cell: (row, index) => {
        const optionValues = Utils.getSafeValue(row.item, 'optionValues', []);
        const color = _.find(optionValues, { optionDisplayName: 'Color' }) || {
          undefined: true,
        };
        if (!color.undefined) {
          return (
            <div>
              {_.map(color.colors, (item, indexItem) => {
                return (
                  <React.Fragment key={indexItem}>
                    <div
                      className="item-color m-auto"
                      style={{ backgroundColor: item }}
                      data-tip={color.label}
                      data-for={`itemTooltipColor${index}`}
                    />
                    <ReactTooltip effect="solid" clickable id={`itemTooltipColor${index}`} />
                  </React.Fragment>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      name: `${t('tableColumnName.size')}`,
      className: 'colSizeOrder',
      selector: '',
      cell: row => {
        const optionValues = Utils.getSafeValue(row.item, 'optionValues', []);
        const size = _.find(optionValues, { optionDisplayName: 'Size' }) || {
          undefined: true,
        };
        if (!size.undefined) {
          return <div className=" m-auto">{size.label}</div>;
        }
      },
    },
    {
      name: `${t('tableColumnName.stock')}`,
      className: 'colStockOrder',
      selector: '',
      cell: (row, index) => {
        let msg = '';
        if (row.item.stockLevelStatus == 2) {
          // msg = 'In Stock';
          msg = t('productDetail.inStock');
        } else if (row.item.stockLevelStatus == 1) {
          // msg = 'Low Stock';
          msg = t('productDetail.lowStock');
        } else if (row.item.stockLevelStatus == 0) {
          // msg = 'Out of Stock';
          msg = t('productDetail.outOfStock');
        }
        const stockTypeDisplay = Utils.getSafeValue(productDetails, 'stockTypeDisplay', '');
        return (
          <div className="text-center">
            {stockTypeDisplay == 'color' && (
              <>
                <div
                  className={`item-stock itemStock${row.item.stockLevelStatus}`}
                  data-tip={msg}
                  data-for={`itemTooltip${index}`}
                />
                <ReactTooltip effect="solid" clickable id={`itemTooltip${index}`} />
              </>
            )}
            {stockTypeDisplay === 'number' && (
              <>
                <span>
                  {row.item.inventoryLevel > 9999 ? '9999+' : Utils.getSafeValue(row, 'item.inventoryLevel', 0)}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      name: `${t('tableColumnName.orderQty')}`,
      className: 'colQtyOrder',
      selector: '',
      cell: (row, index) => {
        const errorMessage = Utils.getSafeValue(methods.errors, `quantity[${index}].message`, '');
        const errorStatus = Utils.getSafeValue(methods.errors, `quantity[${index}].type`, '');

        return (
          <div className="qtyBox">
            <span className="btnQtyChange" onClick={() => decreaseQty({ item: row }, index)}>
              <IoIosRemove />
            </span>
            <div className="product-input-quantity">
              <input
                style={{ width: '50px' }}
                name={`quantity[${index}]`}
                className={`input-quantity ${errorStatus}`}
                type="number"
                onChange={e => onChangeQty({ value: e.target.value, row: row, target: e.target }, index)}
                onFocus={e => {
                  e.target.select();
                }}
                onMouseUp={e => e.preventDefault()}
                onBlur={onBlurQuantity(index)}
                // onBlur={(e) => validateStock({target: e.target}, index, row.item)}
                pattern="\d*"
                tabIndex={getTabIndex(index + 1)}
                ref={methods.register}
                value={qtyValue[index]}
                min="0"
                title=""
                max={row.item.orderQuantityMaximum}
              />

              {['error', 'warning'].includes(errorStatus) && (
                <span
                  className={`input_error ${errorStatus === 'warning' ? 'backorder-waring' : ''}`}
                  data-for={`quantityError[${index}]`}
                  data-tip={errorMessage}
                >
                  <ReactTooltip
                    clickable
                    effect="solid"
                    id={`quantityError[${index}]`}
                    multiline
                    className="errorTooltip"
                  />
                </span>
              )}
            </div>

            <span className="btnQtyChange" onClick={() => increaseQty({ item: row }, index)}>
              <IoIosAdd />
            </span>
          </div>
        );
      },
    },
    {
      name: `${t('tableColumnName.priceRRP')}`,
      className: 'colPriceOrder',
      selector: '',
      cell: row => (
        <div>{row.item.retailPrice != null ? <>{Utils.convertCurrency(row.item.retailPrice, currency)}</> : '---'}</div>
      ),
    },
    {
      name: `${t('tableColumnName.priceTrade')}`,
      className: 'colPriceTradeOrder',
      selector: '',
      cell: row => (
        <div>
          {!isAgentLogin ? (
            '---'
          ) : row.item.calculatedPrice != null ? (
            <>{Utils.convertCurrency(row.item.calculatedPrice, currency)}</>
          ) : (
            '---'
          )}
        </div>
      ),
    },
    {
      name: `${t('tableColumnName.estDeliveryDate')}`,
      selector: '',
      cell: row => (
        <div>
          {row.item.estimateDeliveryDate != null
            ? moment(row.item.estimateDeliveryDate, Constants.DateFormat.serverDateFormat).format(
                Constants.DateFormat.valueInput
              )
            : ''}
        </div>
      ),
    },
    {
      name: `${t('tableColumnName.deliveryDate')}`,
      className: 'colDeliveryDateOrder',
      selector: '',
      cell: (row, index) => {
        let selectedDate: Date | undefined;
        let minDate: Date = new Date();
        let highlightDates: Date[] = [];
        const now = new Date();

        const estimateDeliveryDate = Utils.safeParseDate(row.item.estimateDeliveryDate);
        if (!estimateDeliveryDate) {
          now.setDate(now.getDate() + 1);
          highlightDates.push(now);
        } else if (row.item.inventoryLevel === 0) {
          selectedDate = estimateDeliveryDate;
          minDate = estimateDeliveryDate;
          highlightDates.push(estimateDeliveryDate);
        } else if (row.item.inventoryLevel > 0) {
          now.setDate(now.getDate() + 1);
          highlightDates.push(now);
        }

        const formDeliveryValue = get(methods.getValues(), `deliveryDate[${index}]`, 0);
        if (formDeliveryValue) {
          const formDelivery = moment(formDeliveryValue).toDate();
          selectedDate = (moment(formDelivery).isValid() && formDelivery) || undefined;
          highlightDates = [];
        }

        return (
          <DatePickerInput
            className="txt_text text-center"
            selected={selectedDate}
            onChange={onChangeDeliveryDate(index)}
            name={`deliveryDate[${index}]`}
            minDate={minDate}
            highlightDates={highlightDates}
            tabIndex={-1}
          />
        );
      },
    },
  ];

  const onAddCartSuccess = () => {
    setShowSuccessMsg(true);
    setTimeout(() => {
      setShowSuccessMsg(false);
    }, 3000);
    // reset form value
    // setQtyValue(productDetails.variantMatrix.map(v => 0));
    // methods.reset();
  };

  useEffect(() => {
    if (_.isEmpty(data)) {
      getProductDetails();
    } else {
      setProductDetails(data);
    }
  }, []);

  // const appKey = localStorage.get('appKey', '');
  // const priceFilter = localStorage.getObject('priceFilter', {});
  // const currentFilters = localStorage.getObject('currentFilters', {});

  const getProductDetails = async () => {
    setIsLoading(true);
    // params = props.params  if need to search product details by variant
    const appKey = Utils.getSafeValue({}, 'appKey', localStorage.get('appKey', ''));
    const priceFilter = Utils.getSafeValue({}, 'priceFilter', {});
    const currentFilters = Utils.getSafeValue({}, 'filters', {});

    const paramsDetails = {
      productId: id,
      appKey,
      appTypeCode: 'bcm',
      deviceKey: '03670aa8423b6686237ece50f2de4947',
      priceFilter,
      filters: currentFilters,
    };
    try {
      const productDetailsData = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: AppURL.getProductsDetails,
        params: paramsDetails,
      });
      getSomeData(productDetailsData);
    } catch (e) {
      console.log('error get product: ', e);
    }
    setIsLoading(false);
  };

  const getSomeData = async productDetailsData => {
    const product = await Utils.getSafeValue(productDetailsData, 'product', { s: 1 });
    setProductDetails(product);
  };

  return (
    <div className="tableOrder">
      {/* // <Modal */}
      {/* //     dialogClassName={"tableOrderMsg"} */}
      {/* //     size="lg" */}
      {/* //     show={openPopup} */}
      {/* //     onHide={() => setOpenPopup(false)} */}
      {/* // > */}
      {/* //     <Modal.Body> */}
      {/* //         <div className="dialogMsg">This product is currently out of stock.</div> */}
      {/* //         <div className="dialogBtnGroup"> */}
      {/* //             <button className="btnCloseModal" onClick={() => setOpenPopup(false)}> */}
      {/* //                 OK */}
      {/* //             </button> */}
      {/* //         </div> */}
      {/* //     </Modal.Body> */}
      {/* // </Modal> */}

      {showSuccessMsg && (
        <div className="addedSuccessBlock">
          <Container>
            <div className="addedSuccess">
              {t('tableOrderScreen.msgAddedCart')}
              <button
                type="button"
                onClick={() => {
                  setShowSuccessMsg(false);
                }}
                className="btnCloseMsg"
              >
                <IoIosClose />
              </button>
            </div>
          </Container>
        </div>
      )}
      <div className="wr-tb">
        <ProductRow
          columns={columns}
          productDetails={productDetails}
          callApiListCart={callApiListCart}
          methods={methods}
          // productCart={productCart}
          userSelections={userSelections}
          isLoading={isLoading}
          isDisplayDetail={isDisplayDetail}
          positionOfButton={positionOfButton}
          onAddCartSuccess={onAddCartSuccess}
          isEnableBackorder={isEnableBackorder}
          getTabIndex={getTabIndex}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    listCategory: state.getCategoriesTree,
    appConfig: state.appConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiListCart: params => dispatch(callApiListCart(params)),
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
// export default compose(withConnect)(TableOrder);
export default compose(withConnect(withTranslation()(TableOrder)));
