import React, { ReactElement, useEffect } from 'react';
import { Collapse } from '@material-ui/core';
import { IoIosAdd, IoIosPlay } from 'react-icons/io';
import _ from 'lodash';

interface Props {
  item?: any;
}

export default function Item({ item }: Props): ReactElement {
  const [open, setOpen] = React.useState(false);

  const handleClickCollapse = (): void => {
    setOpen(!open);
  };
  return (
    <div className="itemProductCare">
      <div className={`btnShow ${open ? 'isOpened' : ''}`} onClick={() => handleClickCollapse()}>
        <span className="icnIconWrap">
          <IoIosPlay className={'icnIcon'} />
        </span>
        {item.title}
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="itemCollapse">
          {_.map(item.itemList, (item, index) => {
            return (
              <div className="item" key={index}>
                {item.itemTitle && <div className="titleContent">{item.itemTitle}</div>}
                {item.itemContent && (
                  <div className="contentItemDesc">
                    {_.map(item.itemContent, (item2, index2) => {
                      return <p key={index2}>{item2}</p>;
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
}
