import React, { useEffect, useState, Fragment } from 'react';
// import '../../../node_modules/react-table/react-table.css';
import { Table } from 'react-bootstrap';
import _, { isEmpty } from 'lodash';
import './style.scss';
import { useForm } from 'react-hook-form';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProductListQueryParams } from 'src/types/productList';

import { getSafeValue } from 'src/utils';
import clsx from 'clsx';
import { ProductLineItemType } from 'src/types/cart';
import { getTabIndex } from './helpers';
import { getProductsLayout } from '../business/hooks/list.selector';
import TableOrder from './tableOrder';
import { ListProductSkeleton } from '../../components/skeleton/listProductSkeleton';

export type GenericObject = { [key: string]: any };
interface SubInlineProductProps {
  productDetails: GenericObject;
  currency?: GenericObject;
  columns: GenericObject[];
  firstLine?: boolean;
}
export const SubInlineProduct = ({ productDetails, currency, columns, firstLine }: SubInlineProductProps) => {
  const isBackOrder = getSafeValue(productDetails, 'backOrder', false);

  const renderBackorder = (columnItem: GenericObject) => {
    if (columnItem.backOrderCell && typeof columnItem.backOrderCell === 'function') {
      return columnItem.backOrderCell(productDetails);
    }
    return <div className="no-effect" />;
  };
  const lastValidColumn = columns.map(el => !isEmpty(el)).lastIndexOf(true);

  return (
    <tr className="rowSeparatorQty">
      {columns.map((column, id) => {
        const classNames = clsx(
          column.className || 'undefined',
          { [`item-${column.selector}`]: column.selector },
          { 'product-item__backorder': isBackOrder },
          {
            'first-subline': firstLine,
            'end-TD': id === lastValidColumn,
          }
        );

        return (
          <td
            style={{ width: column.width, height: 1, paddingBottom: 0, borderBottom: 0, padding: 0 }}
            className={classNames}
          >
            {renderBackorder(column)}
          </td>
        );
      })}
    </tr>
  );
};

export interface PropsTbody {
  columns: any[];
  data?: any;
  itemData: any;
  expand?: boolean;
  itemIndex?: any;
  params?: ProductListQueryParams;
  currency?: GenericObject;
  // isAgent?: boolean,
}

function TbodyItem({
  itemIndex,
  columns,
  expand,
  itemData,
  params = {} as ProductListQueryParams,
  currency,
}: // isAgent
PropsTbody) {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    setToggle(false);
  }, [itemData]);
  const getTabIndexHOF = (id: number) => {
    return (num: number) => {
      // start from 100 = 10**2
      return getTabIndex(id + 2, num);
    };
  };
  const isBackOrder = getSafeValue(itemData, 'backOrder', false);

  const itemType = getSafeValue(itemData, 'type', ProductLineItemType.NORMAL);

  let groupItems = getSafeValue(itemData, 'groupItems', []);
  const isBackOrderItem = isBackOrder || groupItems.length > 0;
  if (isBackOrder && itemType === ProductLineItemType.NORMAL) {
    groupItems = [itemData];
  }
  return (
    <tbody key={itemIndex} className="product-item">
      <tr className={clsx('trRow', { 'product-item__backorder': isBackOrderItem })}>
        {expand && (
          <td className="btnFieldExpand">
            <div className="columnInner" onClick={() => setToggle(!toggle)}>
              <button className={`btnArrow ${toggle ? 'isExpand' : ''}`} type="button">
                <IoMdArrowDropdown />
              </button>
            </div>
          </td>
        )}
        {_.map(columns, (item, index) => {
          const classNames = clsx(
            item.className || 'undefined',
            { [`item-${item.selector}`]: item.selector },
            { 'product-item__backorder': isBackOrderItem }
          );

          return expand ? (
            <td
              style={{ width: item.width }}
              key={index}
              className={`${item.selector ? `item-${item.selector}` : ''} ${item.className}`}
            >
              <div className="columnInner" onClick={() => setToggle(!toggle)}>
                {item.cell ? item.cell(itemData, itemIndex) : itemData[item.selector]}
              </div>
            </td>
          ) : (
            <td style={{ width: item.width }} key={index} className={classNames}>
              <div className="columnInner">{item.cell ? item.cell(itemData, itemIndex) : itemData[item.selector]}</div>
            </td>
          );
        })}
      </tr>
      {groupItems.map((subLineItem, index) => (
        <SubInlineProduct productDetails={subLineItem} currency={currency} columns={columns} firstLine={index === 0} />
      ))}
      {expand && toggle && (
        <tr className="trExpand">
          <td
            colSpan={1000}
            className="position-relative"
            style={{ borderTop: 'none', minHeight: '50px', paddingLeft: 0, paddingRight: 0 }}
          >
            <div className="contentExpand">
              {toggle && (
                <TableOrder
                  // isAgent={isAgent}
                  getTabIndex={getTabIndexHOF(itemIndex)}
                  id={itemData.id}
                  isDisplayDetail
                  positionOfButton="top"
                  params={params}
                />
              )}
            </div>
          </td>
        </tr>
      )}
      <tr className={clsx([(isBackOrderItem && 'product-item__separate-line') || 'no-effect'])}>
        <td colSpan={1000} />
      </tr>
    </tbody>
  );
}

export interface Props {
  className?: string;
  expand?: boolean;
  columns: any[];
  data: any[];
  isLoading?: boolean;
  isSearch?: boolean;
  pagination?: any;
  onPageChange?: (param) => void;
  btnToggle?: any;
  // isAgent?: boolean,
}

function TableProductList({
  className,
  columns,
  data,
  expand,
  isLoading,
  isSearch,
  pagination,
  onPageChange,
  btnToggle,
  params = {},
  currency,
}: // isAgent

any) {
  const { t } = useTranslation();
  const productsLayout = useSelector(getProductsLayout);

  const methods = useForm();
  methods.register({ name: 'display' }, {});
  const newData: any[] = data || [];

  function renderTableInner() {
    return (
      <>
        {isLoading ? (
          <Table>
            <ListProductSkeleton />
          </Table>
        ) : (
          <>
            {!_.isEmpty(data) ? (
              <Table className={`table-list-product ${className || ''}`}>
                <thead className="config-thead">
                  <tr>
                    {expand && <th></th>}
                    {_.map(columns, (item, index) => {
                      return (
                        <th
                          style={{ width: item.width }}
                          key={index}
                          className={`${item.selector ? `item-${item.selector}` : ''} ${item.className}`}
                        >
                          {item.nameAction ? btnToggle : item.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {_.map(data, (itemData, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TbodyItem
                        itemIndex={index}
                        itemData={itemData}
                        columns={columns}
                        data={data}
                        // isAgent={isAgent}
                        expand={expand}
                        params={params}
                        currency={currency}
                      />
                    </React.Fragment>
                  );
                })}
              </Table>
            ) : (
              <>
                {!isSearch ? (
                  <div className="noProduct">{t('tableOrderScreen.noProduct')}</div>
                ) : (
                  <div className="noProduct">{t('tableOrderScreen.noItemsFound')}</div>
                )}
              </>
            )}
          </>
        )}

        {/* {!isLoading && newData.length===0 && <div style={{padding: '40px 10px'}} className='text-center fs-18'>No product to display!</div>} */}
        {pagination &&
          pagination.totalPages &&
          pagination.currentPage === pagination.totalPages &&
          newData.length > 0 && <p style={{ textAlign: 'center' }}>{/* <b>No more product to show</b> */}</p>}
      </>
    );
  }

  // const handlePageClick = item => {
  //   onPageChange(item.selected + 1);
  // };

  return (
    <>
      <div className="productTable" id="productTable">
        {renderTableInner()}
      </div>
      {/* <ReactPaginate */}
      {/* previousLabel={'previous'} */}
      {/* nextLabel={'next'} */}
      {/* breakLabel={'...'} */}
      {/* breakClassName={'break-me'} */}
      {/* pageCount={pagination.totalPages} */}
      {/* marginPagesDisplayed={2} */}
      {/* pageRangeDisplayed={5} */}
      {/* onPageChange={(item)=>handlePageClick(item)} */}
      {/* containerClassName={'pagination'} */}
      {/* subContainerClassName={'pages pagination'} */}
      {/* activeClassName={'active'} */}
      {/* /> */}
    </>
  );
}
export default withTranslation()(TableProductList);

// columns:
// property: type -> required
// name: string -> no -> name of column
// selector: string-> -> key of data
// cell: func
