import React, { useEffect, useState } from 'react';
import './style.scss';
import TableOrder from '../tableListProduct/tableOrder';
import { callApiACT } from '../../redux/callApiRDC';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from 'react-icons/io';

export interface Props {
  callApiACT: (action) => void;
  product: any;
  setProduct: (param) => void;
  popupOrderModal: boolean;
  popupClass?: string;
  setPopupOrderModal: (param) => void;
}

function PopupOrder({ callApiACT, product, setProduct, popupOrderModal, setPopupOrderModal, popupClass }: Props) {
  const closeModal = () => {
    setProduct(null);
    setPopupOrderModal(false);
  };

  return (
    <Modal dialogClassName={`bulkOrderModal ${popupClass}`} size="xl" show={popupOrderModal} onHide={closeModal}>
      <div className="bodyModal">
        <div className="inner-wr-popup-order">
          <button type="button" className="btnCloseModal" onClick={closeModal}>
            <IoMdClose />
          </button>

          <TableOrder positionOfButton={'top'} id={product['id']} isDisplayDetail={true} />
        </div>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PopupOrder);
