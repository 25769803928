import Immutable from 'seamless-immutable';
import Mergers from 'seamless-immutable-mergers';
import { isUndefined } from 'lodash';
export const API_CALL_REQUEST = 'API_CALL_REQUEST';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';
export const API_CALL_FAILURE = 'API_CALL_FAILURE';

const initialState = Immutable({
  isFetching: false,
  response: {},
  errMessage: '',
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_REQUEST: {
      var callRequestState = {
        isFetching: true,
        errMessage: '',
      };
      return state.merge(callRequestState);
    }
    // return {
    //   ...state,
    //   isFetching: true,
    //   errMessage: ""
    // };
    case API_CALL_SUCCESS: /**
     * Handle response when isRefresh call success here.
     */ {
      var mergeState = {
        isFetching: false,
        response: action.response,
      };

      var newStateRefresh = state.merge(mergeState, {
        merger: Mergers.equalityArrayMerger,
      });

      var newState = state.merge(mergeState, {
        merger: Mergers.concatArrayMerger,
      });

      var stateCallSucess = action.isRefresh ? newStateRefresh : newState;
      return stateCallSucess;
    }
    case API_CALL_FAILURE: /**
     * Handle response when isRefresh call fail here.
     */ {
      var stateFail = {
        isFetching: false,
        errMessage: action.errMessage,
      };

      return state.merge(stateFail);
    }
    default:
      return state;
  }
}

// export function actGetDataAPI(action) {
//   return { ...action, type: API_CALL_REQUEST };
// }

export function callApiACT(action) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: API_CALL_REQUEST,
        url: action.url || '',
        isLoading: action.isLoading || true,
        isToastErr: !isUndefined(action.isToastErr) ? action.isToastErr : true,
        name: action.name || '',
        params: action.params || {},
        typeRequest: action.typeRequest || 'GET',
        toastMessageSuccess: action.toastMessageSuccess || '',
        isToastSuccess: !isUndefined(action.isToastSuccess) ? action.isToastSuccess : false,
        autoCLoseErrorMessage: action.autoCLoseErrorMessage || '',
        resolve,
        reject,
      });
    });
  };
}

export default reducer;
