import React, { useState } from 'react';
import './style.scss';

interface Props {
  name: string;
  propRef?: any;
  onFocus?: () => void;
  onChange?: (e) => void;
  style?: any;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
}

function InputPassword({ name, propRef, onFocus, onChange, placeholder, style, disabled, className }: Props) {
  const [toggle, setToggle] = useState(true);

  const toggleText = () => {
    setToggle(!toggle);
  };

  return (
    <div className="input-password">
      <input
        className={`${className ? className : ''}`}
        type={toggle ? 'password' : 'text'}
        onFocus={onFocus}
        onChange={onChange}
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        ref={propRef}
      />
      <div className="icon-toggle" onClick={() => toggleText()}>
        {!toggle ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 21 14">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#000"
                d="M8.986 0c1.081 0 2.15.247 3.197.708.895.394 1.758.936 2.59 1.599.676.54 1.31 1.14 1.905 1.786.296.321.55.615.803.924l.322.402a.93.93 0 0 1 0 1.147c-.407.514-.781.96-1.225 1.438a16.813 16.813 0 0 1-1.917 1.781C12.878 11.188 10.987 12 9 12c-1.058 0-2.108-.241-3.14-.691-.885-.386-1.743-.92-2.57-1.572A16.986 16.986 0 0 1 1.366 7.95 19.148 19.148 0 0 1 .19 6.555a.93.93 0 0 1 .012-1.142c.25-.311.437-.54.655-.791l.217-.246c.47-.526.969-1.03 1.503-1.506.891-.795 1.824-1.458 2.8-1.95C6.56.325 7.765 0 8.986 0zm0 .912c-3.614 0-6.614 3.282-7.77 4.677l-.327.403.262.334C2.353 7.823 5.367 11.088 9 11.088c3.936 0 7.015-3.706 8.111-5.096l-.335-.418C15.57 4.108 12.618.912 8.986.912zM9 2.172c2.04 0 3.694 1.704 3.694 3.805 0 2.102-1.654 3.805-3.694 3.805S5.305 8.079 5.305 5.977C5.305 3.876 6.96 2.173 9 2.173zm-.003.998c-1.51 0-2.736 1.257-2.736 2.807s1.225 2.808 2.736 2.808c1.511 0 2.736-1.257 2.736-2.808 0-1.55-1.225-2.807-2.736-2.807z"
              />
              <path d="M-2-5h22v22H-2z" />
            </g>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 19 16">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#000"
                fillRule="nonzero"
                d="M15.888.159a.495.495 0 0 1 .684 0 .484.484 0 0 1 0 .683l-9.45 9.45-.002.003-.004.003-4.86 4.86a.48.48 0 0 1-.684 0 .484.484 0 0 1 0-.683l2.58-2.58C1.709 10.266.155 8.032.086 7.932a.486.486 0 0 1 0-.549c.158-.229 3.93-5.616 8.987-5.616 1.336 0 2.702.386 4.067 1.14zM14.67 3.906c2.043 1.55 3.327 3.387 3.39 3.478a.486.486 0 0 1 0 .549c-.158.23-3.933 5.616-8.987 5.616-1.083 0-2.185-.258-3.29-.755l.732-.733c.866.345 1.722.522 2.558.522 3.94 0 7.198-3.896 7.988-4.925-.43-.56-1.546-1.916-3.082-3.06zm-2.207 2.208c.22.479.338 1.002.338 1.545a3.733 3.733 0 0 1-3.728 3.728 3.696 3.696 0 0 1-1.545-.339l.747-.746a2.765 2.765 0 0 0 3.441-3.441zm-3.39-3.38c-3.938 0-7.197 3.897-7.988 4.925.492.641 1.88 2.322 3.768 3.537l1.267-1.268a3.695 3.695 0 0 1-.775-2.27 3.733 3.733 0 0 1 3.728-3.727c.832 0 1.621.274 2.27.775l1.085-1.085c-1.137-.588-2.263-.887-3.355-.887zm0 2.163A2.765 2.765 0 0 0 6.31 7.659c0 .574.176 1.12.498 1.58l3.844-3.843a2.738 2.738 0 0 0-1.58-.5z"
              />
              <path d="M-2-3h22v22H-2z" />
            </g>
          </svg>
        )}
      </div>
    </div>
  );
}
export default InputPassword;
