import React, { ReactElement, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, TextField, Button } from '@material-ui/core';
import { backTop } from 'src/utils';
import Icon, { ICONS } from 'src/components/Icon';
interface Props {
  item?: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '893px',
      backgroundColor: theme.palette.background.paper,
    },
    text: {
      fontFamily: 'FuturaPTBook',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
      color: '#000000',
    },
    titleText: {
      fontWeight: 500,
      lineHeight: 1.7,
      fontSize: '20px',
      letterSpacing: '0.77px',
    },
    listItem: {
      background: 'none !important',
      '&:hover': {
        background: 'none',
      },
      marginBottom: '7px',
    },
    icon: {
      minWidth: 16,
    },
    separateItem: {
      maxWidth: '893px',
      marginLeft: '14px',
      marginRight: '14px',
      height: '1px',
      backgroundColor: '#000000',
      marginBottom: '20px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    innerItem: {
      marginBottom: '60px',
    },
    infoText: {
      marginLeft: '16px',
      marginBottom: '8px',
    },
  })
);
export default function HelpItem({ item }: Props): ReactElement {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickCollapse = (): void => {
    setOpen(!open);
  };
  return (
    <List component="div" aria-labelledby="nested-list-subheader">
      <ListItem button onClick={handleClickCollapse} disableRipple className={clsx(classes.listItem, classes.root)}>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{ className: clsx(classes.text, classes.titleText) }}
        />
        {open ? <Icon name={ICONS.MINUS} /> : <Icon name={ICONS.PLUS} />}
      </ListItem>
      <Divider className={classes.separateItem} />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.innerItem}>
          {item.info.map((info, index) => {
            return (
              <p key={index} className={clsx(classes.text, classes.infoText)}>
                {info}
              </p>
            );
          })}
        </div>
      </Collapse>
    </List>
  );
}
