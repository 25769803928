import filter from 'lodash/filter';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { CurrencyKey } from 'src/constants';
import { getSafeValue } from 'src/utils';

export const AppConfig = state => state.appConfig;
export const getAppConfig = createSelector(AppConfig, state => state.response.appData);

export const getCategories = createSelector(getAppConfig, appConfig => {
  if (!appConfig) return [];
  const catalogModules = filter(appConfig.modules, {
    moduleType: 'ECOMMERCE_CATALOG',
  });
  const categories = catalogModules.map(module => {
    const dataModule = getSafeValue(module, 'moduleMetaData.data', '');
    const data = JSON.parse(dataModule || null);
    const parsedModule = {
      ...module,
      moduleMetaData: {
        ...module.moduleMetaData,
        data,
      },
    };
    return get(parsedModule, 'moduleMetaData.data.categoryTree[0]');
  });
  return categories;
});

export const getProductsLayout = createSelector(AppConfig, state => {
  return getSafeValue(state, 'response.adminSetting.productLayout', 'simple');
});
export const getAppMetaSettings = createSelector(getAppConfig, appConfig => {
  const dataSettings = getSafeValue(appConfig, 'appMetadata.data', '{}');
  return JSON.parse(dataSettings);
});
export const getEnableBackOrder = createSelector(getAppMetaSettings, settings => {
  return getSafeValue(settings, 'saleSettings.enableBackorder', false);
});
export const getCurrency = createSelector(getAppMetaSettings, settings => {
  return getSafeValue(settings, 'currency', CurrencyKey.KEY_USD);
});
