import React, { createContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { ProductListQueryParams } from 'src/types/productList';
import { initProductsParams, ProductListDispatch, productsParamsReducer } from './params.reducer';

export const ProductListStateContext = createContext<ProductListQueryParams>(initProductsParams);
export const ProductListDispatchContext = createContext<ProductListDispatch | undefined>(undefined);
export const ProductListProvider = ({ children }) => {
  const { category, key: queryKeyword } = useParams() as { category: number | string; key: string };
  const initParams = {
    ...initProductsParams,
    categoryId: category,
    query: queryKeyword,
    appKey: localStorage.getItem('appKey') || initProductsParams.appKey,
    deviceKey: localStorage.getItem('deviceKey') || initProductsParams.deviceKey,
    appTypeCode: localStorage.getItem('appTypeCode') || initProductsParams.appTypeCode,
  };
  const [params, dispatchParams] = useReducer(productsParamsReducer, initParams);

  return (
    <ProductListStateContext.Provider value={params}>
      <ProductListDispatchContext.Provider value={dispatchParams}>{children}</ProductListDispatchContext.Provider>
    </ProductListStateContext.Provider>
  );
};
