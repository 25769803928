/* eslint-disable no-use-before-define */
import React, { useEffect, useReducer, useState } from 'react';
import './styles.scss';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import isValid from 'date-fns/isValid';

import _ from 'lodash';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Images } from 'src/assets/Images';
import CustomLoading from 'src/components/loadingScreen/CustomLoading';
import { reducerNameHOR } from 'src/redux';
import { callApiACT } from 'src/redux/callApiRDC';
import MultiFilter from 'src/components/MultiFilter';
import { IoMdArrowDropdown } from 'react-icons/io';
import CustomSelect from 'src/components/CustomSelect';
import { ActionButtonAddToCart, ActionButton, AddToCartStatus } from 'src/components/Button/ActionButton';
import DatePicker from 'src/components/datePicker';
import { makeStyles } from '@material-ui/core';
import { callApiListCart } from 'src/redux/getListCartRDC';
import localStorage from 'src/utils/LocalStorage';
import { validateQuantity } from 'src/utils/product.helpers';
import ReactTooltip from 'react-tooltip';
import { FilterItem, FilterOption } from 'src/types/productList';
import { getCategories, getCurrency, getEnableBackOrder, getProductsLayout } from './hooks/list.selector';
import * as AppURL from '../../services/urlAPI';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import TableProductList from '../tableListProduct';

import { useMultiFilters, useProductList, useProducts, useSimpleProduct } from './hooks/useProductList';
import { ProductListProvider } from './hooks/productList.provider';
import { RootState } from '../../redux';
import { getSafeValue } from '../../utils';

const useStyles = makeStyles(() => ({
  paginationRoot: {
    '&.Mui-selected': {
      backgroundColor: '#fff',
      textDecoration: 'underline',
    },
  },
}));

// NOTE: dispatch redux async action need 'await' keyword  although it may be suggested as 'no effect'

const ProductList = () => {
  const listPerPage: any = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { category, key: queryKeyword } = useParams() as { category: number | string; key: string };
  const dispatch = useDispatch();

  const categories = useSelector(getCategories);

  const enableBackOrder = useSelector(getEnableBackOrder);
  const currency = useSelector(getCurrency);
  const reduxDispatch = useDispatch();

  const dispatchCallAPI = params => {
    return reduxDispatch(callApiACT(params));
  };
  const { params, changeCategory, changeSortOrder, changePagination, changeKeyword, changePerpage } = useProductList();
  const { productList, setLoadingProducts, setSuccessProducts, setErrorProducts } = useProducts();
  const { filterList, setLoadingFilterList, setSuccessFilterList, setErrorFilterList } = useMultiFilters();
  const {
    selectedProducts,
    changeSelectedDeliveryDate,
    changeSelectedQuantity,
    setDefaultStatus,
    changeErrorQuantity,
    changeButtonStatus,
  } = useSimpleProduct();

  const [sortSelected, setSortSelected] = useState();
  const [changePage, setChangePage] = useState(false);
  const [perPageSelected, setPerPageSelected] = useState(localStorage.getObject('itemPerPage', listPerPage[0]));

  const cartRedux = useSelector((state: RootState) => state.getCartList);

  /**
   * There are 3 different loading component here:
   * - skeleton loading when change page, change table params: pagination, sort order
   * - modal loading when change multi filter option
   * - indicator loading when change price filter
   *  should consider moving all loading state to context/reducer to prevent nested props-passthrough : indicator loading
   */

  const [changeTableParams, setChangeTableParams] = useState(false);
  const [changeMultiFilterOption, setChangeMultiFilterOption] = useState(false);
  const [changePriceFilter, setChangePriceFilter] = useState(false);

  const pathCategory = Utils.getPathCategoryTree(categories, 'children', 'id', category);
  const currentCategory = pathCategory[pathCategory.length - 1];
  const sortList = Utils.getSafeValue(productList, 'data.meta.sortList', []).map(item => {
    return {
      value: `${item.sortBy}_${item.direction}`,
      label: item.display,
      direction: item.direction,
      sortBy: item.sortBy,
      selected: item.selected,
    };
  });
  useEffect(() => {
    setChangePage(true);
    if (_.isUndefined(queryKeyword)) {
      changeCategory(category);
    } else {
      changeKeyword(queryKeyword);
    }
  }, [category, queryKeyword]);

  useEffect(() => {
    const getData = async () => {
      await getListFilter();
      await getProducts();
      handleAfterFetchData();
    };
    getData();
  }, [JSON.stringify(params)]);
  useEffect(() => {
    const selectedSortItem = _.find(sortList, { selected: true });

    if (selectedSortItem) {
      setSortSelected(selectedSortItem);
    }
  }, [productList]);

  const columnsConfig = [
    {
      name: '',
      selector: '',
      cell: row => (
        <div className="image-tiny cursor-pointer">
          {row.images.length > 1 &&
            _.map(row.images, (item, index) => {
              if (item.isThumbnail) {
                return <Image key={index} src={item.urlThumbnail} style={{ maxWidth: '100%', maxHeight: '100%' }} />;
              }
            })}
          {row.images.length === 1 && (
            <Image src={row.images[0].urlThumbnail} style={{ maxWidth: '100%', maxHeight: '100%' }} />
          )}
        </div>
      ),
      className: 'colImage',
      width: '100px',
    },
    {
      name: `${t('tableColumnName.sku')}`,
      selector: 'sku',
      className: 'colID',
      width: '120px',
    },
    {
      name: `${t('tableColumnName.productName')}`,
      selector: 'name',
      className: 'colProductName',
      width: '40%',
    },
    {
      name: `${t('tableColumnName.color')}`,
      selector: 'colour',
      cell: row => {
        let colors = Utils.getSafeValue(row, 'optionDisplay.Color', []);
        const productType = Utils.getSafeValue(row, 'productType', '');
        if (colors.length === 0) {
          if (['simpleWithoutOption', 'simpleWithOption'].includes(productType)) {
            // fix color is null -  solution by Khanh Nguyen: use label instead of colorCode
            colors = colors.map(colorChild => {
              return { ...colorChild, colors: [colorChild.label] };
            });
          }
        }

        return (
          <div className="clearfix">
            {_.map(colors, (item, index) => {
              return (
                <div key={index} className="item-color" style={{ marginRight: '5px' }}>
                  {_.map(Utils.getSafeValue(item, 'colors', []), (itemColor, indexColor) => {
                    return <span key={indexColor} style={{ backgroundColor: itemColor }} />;
                  })}
                </div>
              );
            })}
          </div>
        );
      },

      width: '19%',
      className: 'colColor',
    },
    {
      name: `${t('tableColumnName.size')}`,
      selector: '',
      cell: row => {
        const size = Utils.getSafeValue(row, 'optionDisplay.Size', []);
        const listSize = _.map(size, item => {
          return item.label || '';
        }).join(', ');
        return <div className="listSize">{listSize}</div>;
      },
      className: 'colSize',
      width: '19%',
    },
  ];

  const getListFilter = async () => {
    setLoadingFilterList();
    try {
      const data: any = await dispatchCallAPI({
        url: AppURL.getFilter,
        typeRequest: Constants.TypeRequest.POST,
        name: reducerNameHOR.getFilter,
        params,
      });
      setSuccessFilterList(data);
    } catch (error) {
      setErrorFilterList(error);
    }
  };

  const getProducts = async () => {
    setLoadingProducts();
    let paramGetList = { ...params, pageSize: perPageSelected.value };

    try {
      const data: any = await dispatchCallAPI({
        typeRequest: Constants.TypeRequest.POST,
        name: reducerNameHOR.listProductSearch,
        url: AppURL.getProductsList,
        // params,
        params: paramGetList,
        isToastErr: false,
        isToastSuccess: false,
      });
      setSuccessProducts(data);

      const length = Utils.getSafeValue(data, 'products.length', 0);
      setDefaultStatus(length);
      if (length > 0 && !_.isUndefined(queryKeyword)) {
        const currentHistory = localStorage.getObject(Constants.KeyAsyncStore.recentSearch, []);

        const newHistorySearch = _.uniq([queryKeyword, ...currentHistory]);

        localStorage.setObject(Constants.KeyAsyncStore.recentSearch, newHistorySearch);
      }
    } catch (error) {
      setErrorProducts(error);
    }
  };
  const onFocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const onChangeDelivery = (index: number) => {
    return (date: Date) => {
      changeSelectedDeliveryDate(index, date);
    };
  };
  const onChangeQuantity = (index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      changeSelectedQuantity(index, Utils.safeParseInt(value));
    };
  };

  const onBlurQuantity = (index: number) => {
    return (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const {
        orderQuantityMinimum: min,
        orderQuantityMaximum: max,
        inventoryLevel,
        backOrderActualStock,
      } = Utils.getSafeValue(productList, `data.products[${index}]`, {});

      const inStock = enableBackOrder ? backOrderActualStock : inventoryLevel;

      const productDetails = productList.data.products[index];

      const productName = Utils.getSafeValue(productDetails, 'name', '');
      const estDate = Utils.getSafeValue(productDetails, ['variantMatrix', index, 'estimateDeliveryDate'], '');
      const estDeliveryDate =
        (estDate &&
          Utils.convertDateFormat(estDate, Constants.DateFormat.serverDateFormat, Constants.DateFormat.valueShow)) ||
        '';
      const backOrderWaring = t('tableOrderScreen.messageBackOrderWarning', {
        estDeliveryDate,
      });
      const stockWaring = t('tableOrderScreen.messageCheckStockWarning', {
        productName,
      });
      const { quantity, error } = validateQuantity(Utils.safeParseInt(value), { min, max, inStock, productName });

      // reset quantity
      // dispatchSelectProduct({ type: SimpleProductAction.CHANGE_QUANTITY, index, payload: quantity });

      if (Utils.safeParseInt(value) !== 0 && error.isError) {
        changeErrorQuantity(index, { type: 'error' as const, message: error.message });
      } else if (quantity > inventoryLevel) {
        changeErrorQuantity(index, {
          type: 'warning' as const,
          message: `${stockWaring} <br/> ${backOrderWaring}`,
        });
      } else {
        changeErrorQuantity(index, {
          type: 'default' as const,
          message: '',
        });
      }
    };
  };
  const createLineItems = (index: number) => {
    const itemQuantityInput = Utils.getSafeValue(selectedProducts, `[${index}].quantity`, 0);
    const deliveryDateInput = _.get(selectedProducts, `[${index}].deliveryDate`, undefined);

    const quantity = Utils.safeParseInt(itemQuantityInput);

    const delivery =
      (isValid(deliveryDateInput) && moment(deliveryDateInput).format(Constants.DateFormat.serverDateFormat)) || '';

    if (quantity > 0) {
      return [
        {
          quantity,
          deliveryDate: delivery,
          productId: productList.data.products[index].id,
          optionSelections: [],
        },
      ];
    }

    return [];
  };

  const onClickAddSimpleProductToCart = (index: number) => {
    return () => addSimpleProductToCart(index);
  };
  const addSimpleProductToCart = async (index: number) => {
    changeButtonStatus(index, 'loading');

    const paramsAddToCart = {
      lineItems: createLineItems(index),
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
    };
    const toastMessageSuccess = `${t('tableOrderScreen.addedSuccess')}`;

    const cartId = getSafeValue(cartRedux?.cart, 'id', '') || '';

    console.log('cartId', cartId);
    if (cartId) {
      try {
        // need await here, ts auto refactor is stupid when set redux action as any too much
        const newCart = await dispatch(
          callApiListCart({
            typeRequest: Constants.TypeRequest.PUT,
            url: Utils.replaceStrUrl(AppURL.addProductToExistCart, [cartId]),
            params: paramsAddToCart,
            isToastSuccess: true,
            toastMessageSuccess,
          })
        );
        console.log(newCart);
        changeButtonStatus(index, 'success');
      } catch (e) {
        console.log('error message add product to cart', e);
        changeButtonStatus(index, 'error');
      }
    } else {
      try {
        // need await here, ts auto refactor is stupid when set redux action as any too much

        const newCart = await dispatch(
          callApiListCart({
            typeRequest: Constants.TypeRequest.POST,
            url: AppURL.createCart,
            params: paramsAddToCart,
            isToastSuccess: true,
            toastMessageSuccess,
          })
        );
        changeButtonStatus(index, 'success');

        const cart = Utils.getSafeValue(newCart, 'cart', {});
        localStorage.set('cartId', cart.id);
      } catch (e) {
        console.log('error message creat cart', e);

        changeButtonStatus(index, 'error');
      }
    }

    setTimeout(() => {
      changeButtonStatus(index, 'default');
    }, 2000);
  };

  const onChangeSortOrder = sortItem => {
    setSortSelected(sortItem);
    const { sortBy, direction } = sortItem;
    changeSortOrder(sortBy, direction);
    setChangeTableParams(true);
  };

  const handleChangePerPage = item => {
    let itemPerPage = item.value;
    changePerpage(itemPerPage);
    setChangeTableParams(true);
    setPerPageSelected(item);
    localStorage.setObject('itemPerPage', item);
  };

  const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number): void => {
    changePagination(page);
    setChangeTableParams(true);
  };

  const handleChangeFilter = (fromPriceSubmit = false) => {
    if (fromPriceSubmit) {
      setChangePriceFilter(true);
    } else {
      setChangeMultiFilterOption(true);
    }
  };
  const handleAfterFetchData = () => {
    setChangePage(false);
    setChangeMultiFilterOption(false);
    setChangeTableParams(false);
    setChangePriceFilter(false);
  };

  const stylee = {
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: 'red',
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  };

  const perPageStyle = {
    // control: styles => ({ ...styles, backgroundColor: 'blue' }),
    // input: styles => ({ ...styles, ...stylee }),
    // placeholder: styles => ({ ...styles, ...stylee }),
    // singleValue: (styles, { data }) => ({ ...styles, ...stylee })
    singleValue: (provided, state) => ({
      ...provided,
      alignItems: 'center',
      display: 'flex',
      ':before': {
        content: `"${t('productList.show')}: "`,
        display: 'block',
        marginRight: 8,
      },
    }),
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: 'flex' }}>
      <div>Show: </div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>{label}</div>
    </div>
  );

  // render
  const renderMultiFilterLoading = () => {
    return (
      <>
        <Skeleton variant="rect" width="83px" height="24px" />
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num, index) => {
          return (
            <React.Fragment key={index}>
              <div style={{ width: '109px', height: '14px' }} />
              <Skeleton variant="rect" width="109px" height="14px" />
            </React.Fragment>
          );
        })}
        <div style={{ width: '109px', height: '47px' }} />
        <Skeleton variant="rect" width="83px" height="24px" />
        {[0, 1].map((num, index2) => {
          return (
            <React.Fragment key={index2}>
              <div style={{ width: '109px', height: '15px' }} />
              <Skeleton variant="rect" width="109px" height="15px" />
            </React.Fragment>
          );
        })}
        <div style={{ width: '109px', height: '47px' }} />
        <Skeleton variant="rect" width="83px" height="24px" />
        {[0, 1].map((num, index3) => {
          return (
            <React.Fragment key={index3}>
              <div style={{ width: '109px', height: '15px' }} />
              <Skeleton variant="rect" width="109px" height="15px" />
            </React.Fragment>
          );
        })}
      </>
    );
  };
  const renderPerPage = () => {
    const { perPage = 0, currentPage = 0, total = 0 } = productList.data.meta?.pagination;
    const pageSize = Utils.safeParseInt(perPage);
    const pageNumber = Utils.safeParseInt(currentPage);
    const totalItem = Utils.safeParseInt(total);
    const begin = (pageNumber - 1) * pageSize + 1;
    const end = pageNumber * pageSize < totalItem ? pageNumber * pageSize : totalItem;
    return (
      <div className="countPagination">
        <span className="semiBold">
          {begin} - {end}
        </span>
        <span>{t('productList.of')}</span>
        <span className="semiBold">{totalItem}</span>
      </div>
    );
  };
  const renderPagination = () => {
    const { totalPages, currentPage } = productList.data.meta.pagination;

    const pages = Utils.safeParseInt(totalPages);
    const current = Utils.safeParseInt(currentPage);
    if (pages <= 1) {
      return <div />;
    }
    return (
      <Pagination
        count={pages}
        boundaryCount={2}
        page={current}
        onChange={handleChangePagination}
        hideNextButton={current === pages}
        hidePrevButton={current === 1}
        renderItem={item => <PaginationItem {...item} classes={{ selected: classes.paginationRoot }} />}
      />
    );
  };
  return (
    <div>
      {changeMultiFilterOption && (
        <CustomLoading
          imageSrc={Images.loadingBlack}
          message={`${t('productList.pleaseWait')}`}
          styles={{ width: '60px', height: '60px' }}
        />
      )}

      <div className="product-list-header">
        <Container>
          <Row>
            <Col md={12}>
              <div className="left-header">
                {(_.isUndefined(queryKeyword) && (
                  <>
                    <div className="breadcrumbs">
                      <ul>
                        <li>
                          <Link to="/">{t('productList.home')}</Link>
                        </li>

                        {pathCategory.map((item, index) => {
                          return (
                            <li className="itemBreadcrumbs" key={index}>
                              <Link to={`/business/${item.id}`}>{item.name}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="blockBottom">
                      <div className="category-name">{currentCategory?.name}</div>
                      <div className="right-header">
                        {currentCategory?.children.length > 0 && (
                          <div className="listSubCats">
                            <div className="sub-category-title">{t('productList.subcategories')}:</div>
                            <div className="list-category">
                              {currentCategory?.children?.map(cat => {
                                return (
                                  <Link to={`/business/${cat.id}`} key={cat.id}>
                                    <span className="text" style={{ marginRight: 20, display: 'inline-block' }}>
                                      {cat.name}
                                    </span>
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )) || <div className="category-name">{`${t('productList.searchResultsFor')} "${queryKeyword}"`}</div>}
              </div>
            </Col>
            {/*<Col md={8}>*/}
            {/**/}
            {/*</Col>*/}
          </Row>
        </Container>
      </div>

      <Container>
        <div className="main-content">
          <Row>
            <Col md={2}>
              {(changePage && renderMultiFilterLoading()) || (
                <MultiFilter
                  filters={filterList.data.filters}
                  currentFilters={filterList.currentFilters}
                  onChangeFilterCb={handleChangeFilter}
                  currencyCode={productList.data.currencyCode}
                  isChangePrice={changePriceFilter}
                />
              )}
            </Col>
            <Col md={10}>
              <div className="table-toolbar">
                {(productList.loading && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: '10px',
                        marginBottom: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Skeleton variant="text" width="100px" height={20} />
                      <Skeleton variant="text" width="200px" height={40} />
                    </div>
                  </div>
                )) ||
                  (Utils.getSafeValue(productList, 'data.products.length', 0) !== 0 && (
                    <Row>
                      <div className="col-lg-4 col-7" style={{ padding: 0 }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: '1', marginRight: '9px', maxWidth: '130px' }}>
                            <CustomSelect
                              className="selectPerPage selectSort"
                              classNamePrefix="selectSort"
                              name="pageSize"
                              options={listPerPage}
                              value={perPageSelected}
                              isSearchable={false}
                              styles={perPageStyle}
                              arrowRenderer={<IoMdArrowDropdown />}
                              onChange={item => {
                                handleChangePerPage(item);
                              }}
                            />
                          </div>
                          {renderPerPage()}
                        </div>
                      </div>

                      <Col md={5}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {renderPagination()}
                        </div>
                      </Col>
                      <Col md={3}>
                        <CustomSelect
                          className="selectSort"
                          classNamePrefix="selectSort"
                          inputProps={{
                            readOnly: true,
                          }}
                          name="display"
                          options={sortList}
                          value={sortSelected}
                          isSearchable={false}
                          arrowRenderer={<IoMdArrowDropdown />}
                          onChange={onChangeSortOrder}
                        />
                      </Col>
                    </Row>
                  ))}
              </div>

              <TableProductList
                columns={columnsConfig}
                data={productList.data.products}
                isLoading={changePage || changeTableParams}
                expand
                // isAgent={isAgent}
                // pagination={pagination}
                isSearch={!_.isUndefined(queryKeyword)}
                // onPageChange={e => console.log(e)}
                params={params}
              />
              {Utils.getSafeValue(productList, 'data.products.length', 0) !== 0 && (
                <Row>
                  <Col md={2} />
                  <Col md={7}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {renderPagination()}
                    </div>
                  </Col>
                  <Col md={3} />
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

const ProductListWrapper = () => {
  return (
    <ProductListProvider>
      <ProductList />
    </ProductListProvider>
  );
};
export default ProductListWrapper;
