import React, { ReactElement, useEffect, useState } from 'react';
import Item from './itemProductCare';
import './style.scss';
import { backTop } from 'src/utils';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import _ from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';

function ProductCare(): ReactElement {
  const { t } = useTranslation();

  const [productCareData, setProductCareData] = useState({});

  useEffect(() => {
    backTop();
    let productCareData = i18next.t('productCare', { returnObjects: true });
    setProductCareData(productCareData);
  }, []);

  return (
    <div className="productCarePage">
      <Container>
        <Row>
          <div className="col-2"></div>
          <div className="col-8">
            <div className="content">
              <div className="titlePage">{productCareData['pageTitle']}</div>
              {productCareData['pageDesc'] && (
                <div className="descBlock">
                  {_.map(productCareData['pageDesc'], (item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}
              <div className="listItem">
                <div className="titleList">{productCareData['selectYourProduct']}</div>
                <Row>
                  {_.map(productCareData['productCareLists'], (item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="col-6">
                          <Item item={item} />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </Row>
      </Container>
    </div>
  );
}

export default withTranslation()(ProductCare);
