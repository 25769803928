import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { IoIosPlay } from 'react-icons/io';
import {
  createStyles,
  fade,
  Theme,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import moment from 'moment';

//some overrides rules will change in pickersV4, be careful to update
type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};
type CustomTypeForMissingOverridesProps = {
  MuiPickersBasePicker: {
    pickerViewLandscape: {
      padding?: string;
    };
  };
};
declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  export interface ComponentNameToClassKey extends CustomTypeForMissingOverridesProps {}
}

interface Props extends KeyboardDatePickerProps {
  selected?: any;
  onChangeCb?: any;
}
const customTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerViewLandscape: {
        padding: 0,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        background: '#000',
        marginTop: 0,
        marginBottom: 0,
      },
      iconButton: {
        backgroundColor: '#000',
        color: '#fff',
      },
      transitionContainer: {
        color: '#fff',
      },
      daysHeader: {
        backgroundColor: '#000',
        minHeight: 32,
      },
      dayLabel: {
        color: '#fff',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: '#000',
        },
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        flexGrow: 1,
      },
    },
  },
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      border: '1px solid #000',
      height: 43,
      backgroundColor: 'white',
    },
    inputField: {
      height: '43px !important',
      border: 'none !important',
    },
    button: {
      color: '#000',
      height: 43,
      width: 43,
      borderRadius: 0,
      borderLeft: '1px solid #000',
    },
    adornment: {
      margin: 0,
      borderLeft: '1px solid #000',
    },
    arrowDropdownSelect: {
      borderTop: '11px solid #000',
      borderLeft: ' 7px solid transparent',
      borderRight: '7px solid transparent',
    },
  })
);

const MdDatePicker = (props: Props) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<ParsableDate>(null);
  //remove value and onChange (required in KeyboardDatePickerProps),
  //next version should use Omit<Props,'value'|'onChange'>
  const { value, onChange, ...other } = props;
  const getMomentDate = date => {
    return date && moment(date).isValid() ? moment(date) : undefined;
  };
  const maxDateProps = getMomentDate(props.maxDate);
  const minDateProps = getMomentDate(props.minDate);
  const initHighlightDate = () => {
    let initHighlight = moment();
    if (maxDateProps != null) {
      initHighlight = maxDateProps.isBefore(initHighlight) ? maxDateProps : initHighlight;
    }
    if (minDateProps != null) {
      initHighlight = minDateProps.isAfter(initHighlight) ? minDateProps : initHighlight;
    }
    return initHighlight;
  };
  useEffect(() => {
    if (props.value) {
      setSelectedDate(props.value);
    } else {
      setSelectedDate(null);
    }
  }, [props.value, props.inputValue]);
  const handleDateChange = date => {
    setSelectedDate(date);
    props.onChangeCb(date);
  };
  return (
    <ThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...other}
          clearable
          value={selectedDate}
          placeholder="dd-mm-yyyy"
          onChange={date => handleDateChange(date)}
          // minDate={new Date()}
          format="DD-MM-YYYY"
          keyboardIcon={<div className={classes.arrowDropdownSelect} />}
          variant="inline"
          autoOk={true}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: classes.textField,
          }}
          inputProps={{
            className: classes.inputField,
          }}
          KeyboardButtonProps={{
            disableFocusRipple: true,
            disableRipple: true,
            className: classes.button,
          }}
          PopoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
          disableToolbar
          InputAdornmentProps={{
            className: classes.adornment,
          }}
          rightArrowIcon={<IoIosPlay />}
          leftArrowIcon={<IoIosPlay style={{ transform: 'rotate(180deg)' }} />}
          minDateMessage=""
          maxDateMessage=""
          initialFocusedDate={initHighlightDate().toDate()}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
export default MdDatePicker;
