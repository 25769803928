import React, { useEffect } from 'react';
import './style.scss';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { backTop } from '../../../utils';
import { useTranslation, withTranslation } from 'react-i18next';

function LayeringGuide() {
  const { t } = useTranslation();

  useEffect(() => {
    backTop();
  }, []);

  const LayeringGuideList = [
    {
      titleSizeBlock: `${t('layeringGuidePage.item1.title')}`,
      topDesc: `${t('layeringGuidePage.item1.topDesc')}`,
      bottomDesc: `${t('layeringGuidePage.item1.bottomDesc')}`,
    },
    {
      titleSizeBlock: `${t('layeringGuidePage.item2.title')}`,
      topDesc: `${t('layeringGuidePage.item2.topDesc')}`,
      bottomDesc: `${t('layeringGuidePage.item2.bottomDesc')}`,
    },
    {
      titleSizeBlock: `${t('layeringGuidePage.item3.title')}`,
      topDesc: `${t('layeringGuidePage.item3.topDesc')}`,
      bottomDesc: `${t('layeringGuidePage.item3.bottomDesc')}`,
    },
  ];
  return (
    <>
      <div className="layerGuidePage">
        <Container>
          <Row>
            <div className="col-2"></div>
            <div className="col-8">
              <div className="titlePage">{t('layeringGuidePage.titlePage')}</div>
              {_.map(LayeringGuideList, (item, index) => {
                return (
                  <div className="blockLayerGuide" key={index}>
                    <div className="titleBlock">{item.titleSizeBlock}</div>
                    <div className="topDesc">
                      <span dangerouslySetInnerHTML={{ __html: item.topDesc }} />
                    </div>
                    <div className="bottomDesc">
                      <span dangerouslySetInnerHTML={{ __html: item.bottomDesc }} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-2"></div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default withTranslation()(LayeringGuide);
