import React, { useState, useEffect } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import './style.scss';
import BillingInformation from './steppers/BillingInformation';
import ShippingMethod from './steppers/ShippingMethod';
import OrderReview from './steppers/OrderReview';
import Thankyou from './steppers/Thankyou';
import localStorage from '../../utils/LocalStorage';
import CheckLogin from '../../components/checkLogin';
import * as Constants from '../../constants';
import { useHistory } from 'react-router-dom';
import loading from '../../assets/loading60Black.svg';
import { useTranslation, withTranslation } from 'react-i18next';

export const steps = {
  Address: 0,
  Method: 1,
  'Order Review': 2,
  'Thank you': 3,
};

function Checkout() {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(parseInt(localStorage.get('stepCheckout', 0)) || 0);
  const cartId = localStorage.get('cartId', '');
  const [loadingState, setLoadingState] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  let termSettings = localStorage.getObject(Constants.KeyAsyncStore.termSettings, {});

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <BillingInformation
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            steps={steps}
            setLoadingState={setLoadingState}
            loadingState={loadingState}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 1:
        return (
          <ShippingMethod
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            steps={steps}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      case 2:
        return (
          <OrderReview
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  // const getStepTitle = (stepIndex) => {
  //     switch (stepIndex) {
  //         case 0:
  //             return '1. Address';
  //         case 1:
  //             return '4. Method';
  //         case 2:
  //             return '5. Order Review';
  //         case 3:
  //             return 'Thank you';
  //         default:
  //             return 'Unknown stepIndex';
  //     }
  // };

  const handleNext = step => {
    setActiveStep(step + 1);
    localStorage.set(Constants.KeyAsyncStore.stepCheckout, step + 1);
  };

  const handleBack = step => {
    setActiveStep(step - 1);
    localStorage.set(Constants.KeyAsyncStore.stepCheckout, step - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const history = useHistory();
  const cancelCheckout = () => {
    history.push('/cart');
    localStorage.removeItem(Constants.KeyAsyncStore.stepCheckout);
    localStorage.removeItem(Constants.KeyAsyncStore.newAddresses);
    localStorage.removeItem(Constants.KeyAsyncStore.selectedAddress);
  };

  useEffect(() => {
    setLoadingState(true);
  }, []);

  return (
    <div className="wr-checkout">
      {cartId === '' && <Redirect to={`/cart`} />}
      <div
        className={`checkoutInner ${!termSettings['isEnabled'] ? 'termDisable' : ''} ${
          currentStep >= 3 ? 'hasPadding' : ''
        }`}
      >
        <div className={`checkoutStep checkoutStep-${activeStep}`}>{getStepContent(0)}</div>
        <div className={`checkoutStep checkoutStep-${activeStep} ${currentStep < 2 ? 'inactive' : ''}`}>
          {getStepContent(1)}
        </div>
        <div className={`checkoutStep checkoutStep-${activeStep} ${currentStep < 3 ? 'inactive' : ''}`}>
          {getStepContent(2)}
        </div>
      </div>
      <Modal
        dialogClassName={'modalWaiting'}
        size="lg"
        // show={true}
        show={loadingState}
        // onHide={()=>setOpenPopup(false)}
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="dialogMsg">{t('checkout.pleaseWait')}</div>
            <div className="iconWaiting">
              <img src={loading} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default withTranslation()(Checkout);
