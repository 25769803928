import React, { useState, useEffect, useRef, Suspense } from 'react';
import { NavLink, Link, Redirect, useHistory } from 'react-router-dom';
import { Image, Button, Modal, Form } from 'react-bootstrap';
import './style.scss';
import logo from '../../assets/logo.svg';
import { useForm } from 'react-hook-form';
import * as Constants from '../../constants';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import localStorage from '../../utils/LocalStorage';
import { reducerNameHOR, RootState } from '../../redux';
import { callApiACT } from '../../redux/callApiRDC';
import * as AppURL from '../../services/urlAPI';
import * as Utils from '../../utils';
import _ from 'lodash';
import SideMenu from './SideMenu';
import { TypeRequest } from '../../constants';
import store from '../../redux';
import { useLocation } from 'react-router';
import { callApiListCart } from '../../redux/getListCartRDC';
import ImportCsvOrder from '../../containers/importCSV';
import { setCart } from '../../redux/dataCartCSV';
import { useTranslation, withTranslation } from 'react-i18next';
import { setGlobalAPI } from '../../services/callDataAPI';
import { getSafeValue } from '../../utils';
export interface Props {
  callApiACT: (action) => void;
  callApiListCart: (action) => void;
  appConfig: any;
  loginUser: any;
  listProductSearch: any;
  location;
  getCartList: any;
  listCategoriesTree: any;
  createNewOrder: any;
  className?: string;
  getCartCount?: string;
  shoppingCart?: any;
  setCart: any;
}

function MainHeader({
  callApiACT,
  callApiListCart,
  appConfig,
  location,
  loginUser,
  getCartList,
  createNewOrder,
  className,
  listProductSearch,
  listCategoriesTree,
  getCartCount,
  shoppingCart,
  setCart,
}: any) {
  const methods = useForm();
  const history = useHistory();
  let currentUrl = useLocation();
  const { t, i18n } = useTranslation();

  const [keyword, setKeyword] = useState('');
  const [redirectSearchPage, setRedirectSearchPage] = useState(false);
  const [redirectLoginPage, setRedirectLoginPage] = useState(false);
  const [logoutCompany, setLogoutCompany] = useState(false);
  const [isLogin, setIsLogin] = useState(localStorage.get(Constants.KeyAsyncStore.userNameDev, '') === '');
  const [sideMenuData, setSideMenuData] = useState();
  const [appSettings, setAppSettings] = useState('');
  const [cartSubTotal, setCartSubTotal] = useState<number>(0);
  const [cartCount, setCartCouut] = useState(0);
  const [currencyCode, setCurrencyCode] = useState('');

  const [confirmLogout, setConfirmLogout] = useState(false);
  const [showDialogImportCSV, setShowDialogImportCSV] = useState(false);

  const [showRecentSearch, setShowRecentSearch] = useState(false);
  const [keyValue, setKeyValue] = useState<string>('');
  const [matchingCategories, setMatchingCategories] = useState<any>([]);
  const [redirectResetPassword, setRedirectResetPassword] = useState<boolean>(false);

  let permission = localStorage.getObject('permissions', {});
  let customer = localStorage.getObject(Constants.KeyAsyncStore.customer, {});
  let customerAssign = localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {});
  const [recentSearch, setRecentSearch] = useState<any>(
    localStorage.getObject(Constants.KeyAsyncStore.recentSearch, [])
  );

  const cartHead = useSelector((state: RootState) => state.getCartList);

  useEffect(() => {
    checkToRedirect(customer);
  }, [customerAssign]);

  const checkToRedirect = customer => {
    if (!_.isEmpty(customer)) {
      let permissions = Utils.getSafeValue(customer, 'permission', {});

      if (customer['resetPassword'] == 1) {
        if (_.isEmpty(customerAssign)) {
          LogOutCompany();
        }
        setRedirectResetPassword(false);
      } else if (customer['resetPassword'] == 0) {
        setRedirectResetPassword(true);
      }
    }
  };

  useEffect(() => {
    if (appConfig.response['appData']) {
      let appMetadataRaw = Utils.getSafeValue(appConfig.response['appData']['appMetadata'], 'data', '');
      let appMetadata = Utils.safeParseJson(appMetadataRaw);
      let currency = appMetadata['currency'];
      setCurrencyCode(currency);
    }
  }, [appConfig]);

  const Logout = () => {
    store.dispatch({ type: 'USER_LOGOUT' });
    setConfirmLogout(false);
    setRedirectLoginPage(true);
    localStorage.clear();
    setGlobalAPI(undefined, { [Constants.HeaderKey.JM360_ACCEPT_LANGUAGE]: 'en' });
  };

  const LogOutCompany = () => {
    localStorage.removeItem(Constants.KeyAsyncStore.customerAssign);
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem('stepCheckout');
    localStorage.removeItem('selectedShippingMethod');
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedAddress');
    setLogoutCompany(true);
  };
  const handleClickSwitch = () => {
    localStorage.removeItem(Constants.KeyAsyncStore.customerAssign);
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem('stepCheckout');
    localStorage.removeItem('selectedShippingMethod');
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedAddress');
    history.replace('/login-as-company');
    store.dispatch({ type: 'SWITCH_ACCOUNT' });
  };
  const onSearch = async data => {
    if (data['searchBox'] !== '') {
      await setKeyword(data['searchBox']);
      setShowRecentSearch(false);
      methods.setValue('searchBox', '');
      setKeyValue('');
      searchBoxRef.current && searchBoxRef.current.blur();
      history.push(`/business/search/keyword=${data.searchBox}`);
      // setRedirectSearchPage(true);
    }
  };

  const [showSideMenu, setShowSideMenu] = useState(false);

  let appKey = localStorage.get('appKey', '');
  let deviceKey = localStorage.get('deviceKey', '');
  let cartId = localStorage.get('cartId', '');
  let appLang = localStorage.get('appLang', '');

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // let customer = localStorage.getObject("customer", {});
    // let cartID = getCartList.cart.id || cartId;
    let cartID = getSafeValue(cartHead?.cart, 'id', '') || undefined;
    if (!_.isEmpty(loginUser.response) || !_.isEmpty(customer)) {
      if (cartID) {
        getCountCart(cartID);
      }
      checkForceLogout();
    }
  }, [loginUser]);

  useEffect(() => {
    if (!_.isEmpty(appKey)) {
      getAppConfig();
      changeLanguage(appLang);
    }
  }, [appKey]);

  const checkForceLogout = async () => {
    try {
      let data = await callApiACT({
        url: AppURL.getUserInfo,
        name: reducerNameHOR.getUserInfo,
        typeRequest: Constants.TypeRequest.GET,
      });
      let keyPairExpired = Utils.getSafeValue(data['kpData'], 'keyPairExpired', false);
      if (keyPairExpired === true && !_.isEmpty(customerAssign)) {
        Logout();
      }
    } catch (e) {
      console.log('error message get user info: ', e);
    }
  };

  const getAppConfig = async () => {
    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.GET,
        name: reducerNameHOR.getAppConfig,
        url: Utils.replaceStrUrl(AppURL.getAppConfig, appKey),
      });
      let modules = await Utils.getSafeValue(data['appData'], 'modules', []);
      let modulesData: any = _.find(modules, { moduleType: 'MAIN_MENU' });
      let appMetadata = await Utils.getSafeValue(data['appData']['appMetadata'], 'data', '');

      const sidebarItems = modulesData.moduleDataItems.map(c => {
        return {
          ...c,
          data: JSON.parse(c.data),
        };
      });
      setSideMenuData(sidebarItems);

      let appMetadataJson = Utils.safeParseJson(appMetadata); // app setting in jam
      let termSettings = appMetadataJson['checkoutSettings']['termsAndConditions'];

      let orderSuccessLogo = appMetadataJson['productOrderingSettings']['orderSuccessLogo'];
      if (!_.isEmpty(orderSuccessLogo)) {
        localStorage.set(Constants.KeyAsyncStore.orderSuccessLogo, orderSuccessLogo);
      } else {
        localStorage.removeItem(Constants.KeyAsyncStore.orderSuccessLogo);
      }

      localStorage.set(Constants.KeyAsyncStore.showTaxInCart, appMetadataJson['showTaxInCart']);
      termSettings && localStorage.setObject(Constants.KeyAsyncStore.termSettings, termSettings);

      // let appMetadata = await Utils.getSafeValue(data['appData']['appMetadata'], 'data', '');
    } catch (e) {
      console.log('error message get side menu: ', e);
    }
  };

  // useEffect(() => {
  //   let customer = localStorage.getObject('customer', {});
  //   // let cartID = getCartList.cart.id || cartId;
  //   let cartID = !_.isEmpty(getCartList.cart) ? getCartList.cart.id : null;
  //   if (!_.isEmpty(loginUser.response) || !_.isEmpty(customer)) {
  //     getCountCart(cartID);
  //   }
  //   if (!_.isEmpty(getCartList && !_.isEmpty(getCartList.cart))) {
  //     let cart = Utils.getSafeValue(getCartList, 'cart', {});
  //     getCartSubtotal(cart);
  //     console.log(cart);
  //     const payload = {
  //       shoppingCart: cart,
  //     };
  //     setCart(payload);
  //   } else {
  //     setCartSubTotal(0);
  //   }
  // }, [getCartList, createNewOrder]);

  // useEffect(() => {
  //   getCart();
  // }, ['', createNewOrder]);

  useEffect(() => {
    if (!_.isEmpty(cartHead) && !_.isEmpty(cartHead.cart) && !_.isEmpty(cartHead.cart.id)) {
      let cart = Utils.getSafeValue(cartHead, 'cart', {});
      getCartSubtotal(cart);
      getCountCart(cart.id);
    } else {
      setCartSubTotal(0);
      setCartCouut(0);
    }
  }, [cartHead]);

  useEffect(() => {
    let recentSearch = localStorage.getObject(Constants.KeyAsyncStore.recentSearch, []);
    setRecentSearch(recentSearch);
  }, [listProductSearch]);

  const getCountCart = async cartID => {
    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.GET,
        url: Utils.replaceStrUrl(AppURL.getCartCount, [appKey, deviceKey, cartID]),
        name: reducerNameHOR.getCartCount,
      });

      let count = Utils.getSafeValue(data, 'count', 0);
      setCartCouut(count);
    } catch (e) {}
  };

  // const getCart = async () => {
  //   if (!cartId) {
  //     return;
  //   }
  //   let params = [appKey, deviceKey, cartId];
  //   try {
  //     let data = await callApiListCart({
  //       url: Utils.replaceStrUrl(AppURL.getCartGroupItem, params),
  //       typeRequest: Constants.TypeRequest.GET,
  //     });
  //
  //     let cart = Utils.getSafeValue(data, 'cart', { id: null });
  //     // console.log(cart);
  //     getCartSubtotal(cart);
  //   } catch (e) {
  //     console.log('error message get cart: ', e);
  //   }
  // };

  const getCartSubtotal = cart => {
    setCartSubTotal(cart['cartAmountExclTax']);
  };

  const onChangeSearch = e => {
    // setKeyValue(e.target.value);
    let valueKey = e.target.value;
    setKeyValue(valueKey);
    if (valueKey.length >= 1) {
      findMatchingCategory(valueKey);
    } else {
      setMatchingCategories([]);
    }
  };

  const findMatchingCategory = query => {
    if (appConfig.response && appConfig.response.appData) {
      //=====================
      let listAllCategory: any[] = [];
      let allCategories: any[] = [];
      _.forEach(appConfig.response.appData.modules, module => {
        const moduleType = Utils.getSafeValue(module, 'moduleType', '');
        if (moduleType !== 'ECOMMERCE_CATALOG') {
          return;
        }

        let dataModule = Utils.getSafeValue(module, 'moduleMetaData.data', '');
        let idModuleParent = Utils.getSafeValue(module, '_id', '');
        let dataModuleObj = Utils.safeParseJson(dataModule);
        let categoryTree = Utils.getSafeValue(dataModuleObj, 'categoryTree', []);

        let listCustomModule = Utils.getAllEcomCategory(categoryTree, 'children', idModuleParent);
        listAllCategory = _.concat(listAllCategory, listCustomModule);
      });

      const _matchingCategories = _.filter(
        listAllCategory,
        cat => cat.name && cat.name.toLowerCase().includes(query.toLowerCase())
      );

      //=====================

      //============== no use
      // const catalogModules = _.filter(appConfig.response.appData.modules, { moduleType: "ECOMMERCE_CATALOG" });
      // const categories = catalogModules.map(m => ({
      //     ...m,
      //     moduleMetaData: {
      //         ...m.moduleMetaData,
      //         data: JSON.parse(m.moduleMetaData.data || null)
      //     }
      // }));
      //
      // const _matchingCategories = _.filter(
      //     categories,
      //     cat => cat.moduleName && cat.moduleName.toLowerCase().includes(query.toLowerCase())
      // );

      //============ no use

      setMatchingCategories(_matchingCategories);
      if (_matchingCategories.length > 0) {
        setShowRecentSearch(true);
      }
    }
  };

  const onSetKeyword = item => {
    setKeyValue(item);
    onSearch({ searchBox: item });
    // setShowRecentSearch(false);
  };

  const searchBoxRef = useRef<HTMLInputElement>();

  const getKeyWord = () => {
    let recentSearch = localStorage.getObject(Constants.KeyAsyncStore.recentSearch, []);
    setRecentSearch(recentSearch);
  };

  const onFocusSearch = () => {
    getKeyWord();
    if (!_.isEmpty(recentSearch)) {
      setShowRecentSearch(true);
    }
  };
  const onBlur = _.debounce(() => {
    getKeyWord();
    setShowRecentSearch(false);
  }, 200);

  const clearRecentSearch = () => {
    setRecentSearch([]);
    setShowRecentSearch(false);
    localStorage.setObject(Constants.KeyAsyncStore.recentSearch, []);
  };

  return (
    <>
      {/*<button onClick={() => changeLanguage('de')} type={'button'}>de</button>*/}
      {/*<button onClick={() => changeLanguage('en')} type={'button'}>en</button>*/}
      <div className={`mainHeader ${className}`}>
        {redirectLoginPage && <Redirect to={`/login`} />}
        {redirectSearchPage && <Redirect to={`/business/search/keyword=${keyword}`} />}
        {logoutCompany && permission['loginAsCustomer'] && <Redirect to={`/login-as-company`} />}
        {redirectResetPassword && <Redirect to={`/change-password`} />}
        <div className="container">
          <div className="mainHeaderInner">
            <div className={`headerCompanyName ${!customerAssign['loginAsCompany'] ? 'colorWhite' : ''}`}>
              {!_.isEmpty(customerAssign['assignCompany'])
                ? customerAssign['assignCompany']['name']
                : customerAssign['linkToCompany']}
              {!customerAssign['loginAsCompany'] && `${t('header.youAreAsAgent')}`}
            </div>
            <div className="mainHeaderRow">
              <div className="logo">
                <h1>
                  <Link to={`/`}>
                    <Image className="logoImg" src={logo} />
                  </Link>
                </h1>
              </div>
              <div className="headerNav">
                <SideMenu
                  menuData={sideMenuData}
                  currentUrl={currentUrl}
                  // numberItem={7}
                />
              </div>

              <div className="groupItems">
                <div className="item headerSearch">
                  <div className="headerSearchForm">
                    <Form onSubmit={methods.handleSubmit(onSearch)}>
                      <div className="search-box">
                        <input
                          className="txt_text"
                          type="text"
                          name="searchBox"
                          placeholder={`${t('header.placeHolderInputSearch')}`}
                          onFocus={() => onFocusSearch()}
                          onBlur={() => onBlur()}
                          onChange={e => onChangeSearch(e)}
                          value={keyValue}
                          // ref={methods.register}
                          ref={(e: HTMLInputElement) => {
                            methods.register(e);
                            searchBoxRef.current = e; // you can still assign to your own ref
                          }}
                        />
                      </div>
                      {showRecentSearch && (
                        <>
                          <div className="searchResult">
                            {keyValue.length < 1 && recentSearch.length > 0 && (
                              <div className="recentSearchBox">
                                <div className="titleBlock">
                                  <div className="titleBox">{t('header.recentSearch')}</div>
                                  <button
                                    className="clearReccentSearch"
                                    type="button"
                                    onClick={() => clearRecentSearch()}
                                  >
                                    {t('header.clearAll')}
                                  </button>
                                </div>
                                <div className="listKeyWord">
                                  {_.map(recentSearch, (item, index) => {
                                    if (parseInt(index) < 12) {
                                      return (
                                        <div className="item" onClick={() => onSetKeyword(item)}>
                                          {item}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            )}

                            {matchingCategories.length > 0 && (
                              <div className="matching-category">
                                <div className="titleBlock">
                                  <div className="titleBox">{t('header.matchingCategory')}</div>
                                </div>
                                <div className="listKeyWord">
                                  {_.map(matchingCategories, (item, index) => {
                                    return (
                                      <div className="item" key={index}>
                                        <Link
                                          className="cursor-pointer truncate-text"
                                          // onClick={() => saveRecentSearchMatchCategory(c)}
                                          onClick={() => setShowRecentSearch(false)}
                                          // to={`/business/${item.moduleMetaData.data.categoryId}`}
                                          to={`/business/${item.id}`}
                                        >
                                          {item.name}
                                        </Link>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
                <div className="item headerAccount">
                  <span className="btnOpenDropdown"></span>
                  <div className="dropdownMenu">
                    <div className="dropdownMenuInner">
                      <ul>
                        <li>
                          <div className="cursor-pointer" onClick={() => history.push('/my-account/info')}>
                            {t('header.myAccount')}
                          </div>
                        </li>
                        {permission['loginAsCustomer'] === true && (
                          <li>
                            <div className="cursor-pointer" onClick={() => handleClickSwitch()}>
                              {customerAssign['loginAsCompany']
                                ? `${t('header.switchCompany')}`
                                : `${t('header.loginAsCompany')}`}
                            </div>
                          </li>
                        )}
                        <li>
                          <div className="cursor-pointer" onClick={() => setConfirmLogout(true)}>
                            {t('header.logOut')}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="item headerCart">
                  {/*<Link to={`/cart`} className="btnOpenDropdown">*/}
                  <span className="btnOpenDropdown">
                    {cartCount > 0 && <span className="span-number">{cartCount}</span>}
                    {Utils.convertCurrency(cartSubTotal, currencyCode)}
                  </span>
                  {/*</Link>*/}
                  <div className="dropdownMenu">
                    <div className="dropdownMenuInner">
                      <div className="mini-cart">
                        <ul>
                          {/*<li>*/}
                          {/*<Link to={'/checkout'}>*/}
                          {/*<span className="btnToCheckout">{t('header.checkout')}</span>*/}
                          {/*</Link>*/}
                          {/*</li>*/}
                          <li>
                            <Link to={'/cart'}>
                              <span className="view-sc">
                                {t('header.viewShoppingCart')}/{t('header.checkout')}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <div className="importCsv cursor-pointer" onClick={() => setShowDialogImportCSV(true)}>
                              <span className="btnImportCsv">{t('header.bulkOrderviaCSV')}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="confirmLogout"
        show={confirmLogout}
        scrollable={false}
        onHide={() => setConfirmLogout(false)}
      >
        <Modal.Body className="modalBody">
          <div className="text-message">{t('confirmModal.msgConfirmLogout')}</div>
          <div className="btnGroup">
            <Button onClick={() => setConfirmLogout(false)} className="btnCancel">
              {t('confirmModal.no')}
            </Button>
            <Button onClick={() => Logout()} className="btnLogout">
              {t('confirmModal.yes')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="sideMenuModal"
        show={showSideMenu}
        scrollable={false}
        onHide={() => setShowSideMenu(false)}
      >
        <Modal.Body className="modalBody">
          <div className="modalBodyInner">
            <div className="logoSideMenu">
              <Link to={'/'} onClick={() => setShowSideMenu(false)}>
                <Image src={logo} />
              </Link>
            </div>
            <div className="itemsSideMenu">
              <SideMenu menuData={sideMenuData} setShowSideMenu={setShowSideMenu} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ImportCsvOrder showDialogImportCSV={showDialogImportCSV} setShowDialogImportCSV={setShowDialogImportCSV} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
    getCartList: state.getCartList,
    createNewOrder: state.createNewOrder,
    getCartCount: state.getCartCount,
    loginUser: state.loginUser,
    listProductSearch: state.listProductSearch,
    shoppingCart: state.dataCartCSV.shoppingCart,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
    callApiListCart: params => dispatch(callApiListCart(params)),
    setCart: params => dispatch(setCart(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect(withTranslation()(MainHeader)));
