import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import Lodash from 'lodash';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { deleteAllCookies } from '../utils/CartConvert';
/**
 * import reducer
 */
import rootSaga from '../sagas';
import showAlert from './showAlert';
import getListCategory from './getListCategory';
import callApiRDC, { callApiACT } from './callApiRDC';
import getCartCountRDC from './getCartCountRDC';
import getListCartRDC from './getListCartRDC';
import userInfoRDC from './userInfoRDC';
import WishListRDC from './WishListRDC';
import loadingAddressRDC from './loadingAddressRDC';
import modalEditProfileRDC from './modalEditProfileRDC';
import indexRDC from './indexRDC';
import dataCartCSV from './dataCartCSV';

const history = createBrowserHistory();

export const reducerNameHOR = {
  getFilter: 'getFilter',
  getEndPoint: 'getEndPoint',
  loginUser: 'loginUser',
  getListApp: 'getListApp',
  getListLanguage: 'getListLanguage',
  getCartList: 'getCartList',
  getSignUpForm: 'getSignUpForm',
  getUserInfo: 'getUserInfo',
  deleteCartItem: 'deleteCartItem',
  deleteCartItems: 'deleteCartItems',
  editCartItemQuantity: 'editCartItemQuantity',
  editCart: 'editCart',
  applyCouponCart: 'applyCouponCart',
  removeCouponCart: 'removeCouponCart',
  getListCartCrossSell: 'getListCartCrossSell',
  registerApp: 'registerApp',
  syncApp: 'syncApp',
  getCartCount: 'getCartCount',
  addItemToNewCart: 'addItemToNewCart',
  addItemIntoExistingCart: 'addItemIntoExistingCart',
  updateItemIntoExistingCart: 'updateItemIntoExistingCart',
  loginMagento: 'loginMagento',
  searchSuggestion: 'searchSuggestion',
  listProductSearch: 'listProductSearch',
  forgetPass: 'forgetPass',
  registerMagento: 'registerMagento',
  getRegionList: 'getRegionList',
  createUserAddress: 'createUserAddress',
  getUserAddress: 'getUserAddress',
  editUserAddress: 'editUserAddress',
  setCartAddress: 'setCartAddress',
  detailProduct: 'detailProduct',
  reviewDetailProduct: 'reviewDetailProduct',
  configReviewDetailProduct: 'configReviewDetailProduct',
  upSellProduct: 'upSellProduct',
  relatedProduct: 'relatedProduct',
  setCartShippingMethod: 'setCartShippingMethod',
  getTokenPayment: 'getTokenPayment',
  userLogOut: 'userLogOuts',
  editProfile: 'editProfile',
  reloadProfile: 'reloadProfile',
  deleteUserAddress: 'deleteUserAddress',
  wishList: 'wishList',
  updateWishList: 'updateWishList',
  removeWishList: 'removeWishList',
  addReviewProduct: 'addReviewProduct',
  addProductToWatchList: 'addProductToWatchList',
  removeProductFromWatchList: 'removeProductFromWatchList',
  updateProductInWatchList: 'updateProductInWatchList',
  getOrderList: 'getOrderList',
  getOrderDetails: 'getOrderDetails',
  sendEnquiry: 'sendEnquiry',
  updatePaymentComplete: 'updatePaymentComplete',
  registerPushNotifi: 'registerPushNotifi',
  associateSourceStripe: 'associateSourceStripe',
  submitChargesStripe: 'submitChargesStripe',
  initPaymentIntent: 'initPaymentIntent',
  completeOrderStripe: 'completeOrderStripe',
  getPriceSaleProduct: 'getPriceSaleProduct',
  validatePayment: 'validatePayment',
  showAlert: 'showAlert',
  getProductsList: 'getProductsList',
  getCategoriesTree: 'getCategoriesTree',
  getAppConfig: 'getAppConfig',
  getCompanies: 'getCompanies',
  isLoginAsCompany: 'isLoginAsCompany',
  changePassword: 'changePassword',
  forceChangePassword: 'forceChangePassword',
  isLogin: 'isLogin',
  createNewOrder: 'createNewOrder',
  getAddress: 'getAddress',
  getCompanyOrderList: 'getCompanyOrderList',
  getCompanyOrderStatusList: 'getCompanyOrderStatusList',
  getListAddressFields: 'getListAddressFields',
};

function createNamedWrapperReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { name } = action;
    const isInitializationCall = state === undefined;

    if (reducerName !== name && !isInitializationCall) {
      return state;
    }

    return reducerFunction(state, action);
  };
}

const appReducer = combineReducers({
  dataCartCSV,
  loadingAddressRDC: loadingAddressRDC,
  router: connectRouter(history),
  modalEditProfileRDC: modalEditProfileRDC,
  getPriceSaleProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getPriceSaleProduct),
  syncApp: createNamedWrapperReducer(callApiRDC, reducerNameHOR.syncApp),
  registerPushNotifi: createNamedWrapperReducer(callApiRDC, reducerNameHOR.registerPushNotifi),
  removeProductFromWatchList: createNamedWrapperReducer(callApiRDC, reducerNameHOR.removeProductFromWatchList),
  updateProductInWatchList: createNamedWrapperReducer(callApiRDC, reducerNameHOR.updateProductInWatchList),
  addProductToWatchList: createNamedWrapperReducer(callApiRDC, reducerNameHOR.addProductToWatchList),
  addReviewProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.addReviewProduct),
  relatedProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.relatedProduct),
  upSellProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.upSellProduct),
  configReviewDetailProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.configReviewDetailProduct),
  reviewDetailProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.reviewDetailProduct),
  detailProduct: createNamedWrapperReducer(callApiRDC, reducerNameHOR.detailProduct),
  listProductSearch: createNamedWrapperReducer(callApiRDC, reducerNameHOR.listProductSearch),
  searchSuggestion: createNamedWrapperReducer(callApiRDC, reducerNameHOR.searchSuggestion),
  registerApp: createNamedWrapperReducer(callApiRDC, reducerNameHOR.registerApp),
  loginUser: createNamedWrapperReducer(callApiRDC, reducerNameHOR.loginUser),
  getListLanguage: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getListLanguage),
  getEndPoint: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getEndPoint),
  getListApp: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getListApp),
  getSignUpForm: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getSignUpForm),
  getUserInfo: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getUserInfo),
  getCartList: createNamedWrapperReducer(getListCartRDC, reducerNameHOR.getCartList),
  deleteCartItem: createNamedWrapperReducer(callApiRDC, reducerNameHOR.deleteCartItem),
  deleteCartItems: createNamedWrapperReducer(callApiRDC, reducerNameHOR.deleteCartItems),
  editCartItemQuantity: createNamedWrapperReducer(callApiRDC, reducerNameHOR.editCartItemQuantity),
  applyCouponCart: createNamedWrapperReducer(callApiRDC, reducerNameHOR.applyCouponCart),
  removeCouponCart: createNamedWrapperReducer(callApiRDC, reducerNameHOR.removeCouponCart),
  getListCartCrossSell: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getListCartCrossSell),
  getCartCount: createNamedWrapperReducer(getCartCountRDC, reducerNameHOR.getCartCount),
  updateItemIntoExistingCart: createNamedWrapperReducer(callApiRDC, reducerNameHOR.updateItemIntoExistingCart),
  addItemIntoExistingCart: createNamedWrapperReducer(callApiRDC, reducerNameHOR.addItemIntoExistingCart),
  userInfoRDC: createNamedWrapperReducer(userInfoRDC, reducerNameHOR.loginMagento),
  forgetPass: createNamedWrapperReducer(callApiRDC, reducerNameHOR.forgetPass),
  registerMagento: createNamedWrapperReducer(callApiRDC, reducerNameHOR.registerMagento),
  getRegionList: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getRegionList),
  createUserAddress: createNamedWrapperReducer(callApiRDC, reducerNameHOR.createUserAddress),
  getUserAddress: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getUserAddress),
  getListAddressFields: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getListAddressFields),
  editUserAddress: createNamedWrapperReducer(callApiRDC, reducerNameHOR.editUserAddress),
  setCartAddress: createNamedWrapperReducer(callApiRDC, reducerNameHOR.setCartAddress),
  setCartShippingMethod: createNamedWrapperReducer(callApiRDC, reducerNameHOR.setCartShippingMethod),
  getTokenPayment: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getTokenPayment),
  userLogOut: createNamedWrapperReducer(callApiRDC, reducerNameHOR.userLogOut),
  editProfile: createNamedWrapperReducer(callApiRDC, reducerNameHOR.editProfile),
  reloadProfile: createNamedWrapperReducer(callApiRDC, reducerNameHOR.reloadProfile),
  showAlert: createNamedWrapperReducer(showAlert, reducerNameHOR.showAlert),
  deleteUserAddress: createNamedWrapperReducer(callApiRDC, reducerNameHOR.deleteUserAddress),
  wishList: createNamedWrapperReducer(WishListRDC, reducerNameHOR.wishList),
  updateWishList: createNamedWrapperReducer(callApiACT, reducerNameHOR.updateWishList),
  removeWishList: createNamedWrapperReducer(callApiACT, reducerNameHOR.removeWishList),
  getOrderList: createNamedWrapperReducer(callApiACT, reducerNameHOR.getOrderList),
  getOrderDetails: createNamedWrapperReducer(callApiACT, reducerNameHOR.getOrderDetails),
  sendEnquiry: createNamedWrapperReducer(callApiACT, reducerNameHOR.sendEnquiry),
  updatePaymentComplete: createNamedWrapperReducer(callApiACT, reducerNameHOR.updatePaymentComplete),
  associateSourceStripe: createNamedWrapperReducer(callApiACT, reducerNameHOR.associateSourceStripe),
  submitChargesStripe: createNamedWrapperReducer(callApiACT, reducerNameHOR.submitChargesStripe),
  initPaymentIntent: createNamedWrapperReducer(callApiACT, reducerNameHOR.initPaymentIntent),
  validatePayment: createNamedWrapperReducer(callApiACT, reducerNameHOR.validatePayment),
  getProducts: createNamedWrapperReducer(callApiACT, reducerNameHOR.getProductsList),
  createNewOrder: createNamedWrapperReducer(callApiACT, reducerNameHOR.createNewOrder),
  getCompanies: createNamedWrapperReducer(callApiACT, reducerNameHOR.getCompanies),
  getAddress: createNamedWrapperReducer(callApiACT, reducerNameHOR.getAddress),
  getCompanyOrderList: createNamedWrapperReducer(callApiACT, reducerNameHOR.getCompanyOrderList),
  getCompanyOrderStatusList: createNamedWrapperReducer(callApiACT, reducerNameHOR.getCompanyOrderStatusList),
  changePassword: createNamedWrapperReducer(callApiACT, reducerNameHOR.changePassword),
  forceChangePassword: createNamedWrapperReducer(callApiACT, reducerNameHOR.forceChangePassword),
  getCategoriesTree: createNamedWrapperReducer(getListCategory, reducerNameHOR.getCategoriesTree),
  isLoginAsCompany: createNamedWrapperReducer(indexRDC, reducerNameHOR.isLoginAsCompany),
  isLogin: createNamedWrapperReducer(callApiACT, reducerNameHOR.isLogin),
  appConfig: createNamedWrapperReducer(callApiRDC, reducerNameHOR.getAppConfig),
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // const Script = document.getElementById('klaviyo');
    // document.cookie = 'KL_FORMS_MODAL= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    deleteAllCookies();
    // if (Script) {
    //   document.body.removeChild(Script);
    // }
    return appReducer(undefined, action);
  }
  if (action.type === 'SWITCH_ACCOUNT') {
    deleteAllCookies();
    return appReducer({ ...state, getCartList: {}, getCartCount: {} }, action);
  }
  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const persistConfig = {
  key: 'persistedStore',
  storage,
  whitelist: ['getCartList', 'getCartCount'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(rootSaga);
  return store;
}

const store = configureStore();
export const persistorStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
