import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import image from '../../assets/Ocean-new.png';
import { callApiACT } from '../../redux/callApiRDC';
import { connect } from 'react-redux';
import * as AppURL from '../../services/urlAPI';
import { mergeHeader, setGlobalAPI } from '../../services/callDataAPI';
import * as Utils from '../../utils';
import { reducerNameHOR } from '../../redux';
import { HeaderKey, TypeRequest } from '../../constants';
import localStorage from '../../utils/LocalStorage';
import _ from 'lodash';
import { compose } from 'redux';
import CheckLogin from '../../components/checkLogin';

interface Props {
  callApiACT: (action) => void;
}

function Products({ callApiACT }: Props) {
  const List = [
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
    {
      link: '/',
      image: image,
      name: 'ocean',
    },
  ];

  let appKey = localStorage.get('appKey', '');
  useEffect(() => {
    getCategoriesTree();
  }, []);

  const getCategoriesTree = () => {
    const params = {
      appKey: appKey,
      deviceKey: '03670aa8423b6686237ece50f2de4947',
    };
    try {
      let CategoriesTree = callApiACT({
        typeRequest: TypeRequest.POST,
        name: reducerNameHOR.getCategoriesTree,
        url: AppURL.getCategoriesTree,
        params: params,
      });
    } catch (e) {
      console.log('error getCategoriesTree: ', e);
    }
  };

  return (
    <div className="wr-products">
      <h1>Products</h1>
      <div className="fishing">
        <div className="fishing-container">
          <div className="fishing-row">
            {_.map(List, (item, index) => {
              return (
                <div className="box" key={index} style={{ backgroundImage: `url(${item.image})` }}>
                  <Link to={`${item.link}`}>
                    <h3>{item.name}</h3>
                    <span className="shop-now">shop now</span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Products);
