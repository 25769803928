import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Modal } from 'react-bootstrap';
import '../../style/global.scss';
import '../../style/inputQty.scss';
import './style.scss';
import { callApiACT } from '../../redux/callApiRDC';
import Login from '../login';
import LoginAsCompany from '../loginAsCompany';
import ChangePassword from '../changePassword';
import RequestEmail from '../forgotPassword/requestEmail';
import ChangeForgotPassword from '../forgotPassword/changeForgotPassword';
import localStorage from '../../utils/LocalStorage';
import HomeRedirect from '../homeRedirect/homeRedirect';
import { AppContext } from '../../utils/appContext';
import Alert from './alert';
import _ from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import { deleteAllCookies } from '../../utils/CartConvert';

export interface Props {
  callApiACT: (action) => void;
  loginUser?: any;
}

function App({ loginUser, callApiACT }: any) {
  const { t } = useTranslation();

  const appContext: any = useContext(AppContext);
  const [isLogin, setIsLogin] = useState(true);
  const [showPrompt, setShowPrompt] = useState(false);

  let locationPath = window.location.pathname;

  let appLang = localStorage.get('appLang', '');
  const appKey = localStorage.get('appKey', '');
  if (!appLang) {
    switch (locationPath.split('/')[1]) {
      case 'de':
        appLang = 'de';
        break;
      case 'eu':
        appLang = 'eu';
        break;
      case 'fr':
        appLang = 'fr';
        break;
      default:
        appLang = 'gb';
        break;
    }
  }
  if (appLang == 'en') {
    if (appKey == '5f0bdcae96f42700019b3745' || appKey == '5f0721b52469711312b8c198') {
      appLang = 'eu';
    } else {
      appLang = 'gb';
    }
  }

  useEffect(() => {
    let customer = localStorage.getObject('customer', {});
    if (_.isEmpty(loginUser.response) && _.isEmpty(customer)) {
      setIsLogin(false);
      deleteAllCookies();
    } else {
      setIsLogin(true);
    }
  }, [loginUser]);

  useEffect(() => {
    setShowPrompt(appContext.showPrompt);
  }, [appContext]);

  return (
    <div id="mainAppContent">
      <Router basename={`/${appLang}`}>
        {locationPath.includes(`/${appLang}/`) &&
          !locationPath.includes('/change-password') &&
          !locationPath.includes('/request-email') &&
          !locationPath.includes('/forget-password') &&
          !isLogin && <Redirect to={`/login`} />}
        <Route exact path="/login" component={Login} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/login-as-company" component={LoginAsCompany} />
        <Route exact path="/request-email" component={RequestEmail} />
        <Route exact path="/forget-password" component={ChangeForgotPassword} />

        {isLogin && (
          <div className="wr-page">
            <div className={'side-page'}>
              <HomeRedirect />
            </div>
          </div>
        )}

        <NavigationPrompt when={showPrompt}>
          {({ onConfirm, onCancel }) => (
            <Modal
              dialogClassName={'unsavedModal'}
              size="lg"
              show={showPrompt}
              onHide={() => appContext.setPrompt(false)}
            >
              <Modal.Body>
                <div className="dialogMsg">{t('confirmModal.msgConfirmLost')}</div>
                <div className="dialogBtnGroup">
                  <button className="btnCloseModal" onClick={() => onCancel()}>
                    {t('confirmModal.no')}
                  </button>
                  <button
                    className="btnCloseModal black"
                    onClick={() => {
                      onConfirm();
                      appContext.setPrompt(false);
                    }}
                  >
                    {t('confirmModal.yes')}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </NavigationPrompt>
      </Router>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loginUser: state.loginUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect(withTranslation()(App)));
