import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import './style.scss';
import { connect } from 'react-redux';
import { callApiACT } from '../../redux/callApiRDC';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CSVReader from '../../components/csvReader';
import CSVDownloader from '../../components/CSVDownloader/CSVDownloader';
import { IoIosPlay } from 'react-icons/io';
import icnSuccess from './assets/icnSuccess.png';
import icnError from './assets/icnError.png';
import * as AppURL from '../../services/urlAPI';
import _ from 'lodash';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import * as Utils from '../../utils';
import loading from '../../assets/loadingx43px.svg';
import { useHistory, useLocation } from 'react-router';
import { addDays } from 'date-fns';
import moment from 'moment';
import { setCart } from '../../redux/dataCartCSV';
import { Trans, useTranslation, withTranslation } from 'react-i18next';

interface Props {
  callApiACT: (action) => void;
  showDialogImportCSV?: boolean;
  setShowDialogImportCSV: (params) => void;
  setCart: any;
}
function ImportCsvOrder({ callApiACT, showDialogImportCSV, setShowDialogImportCSV, setCart }: any) {
  const method = useForm();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const [disableBtnImportCSV, setDisableBtnImportCSV] = useState(true);

  const [validateHeaderCSV, setValidateHeaderCSV] = useState<string>('');
  const [validateFileSizeCSV, setValidateFileSizeCSV] = useState<string>('');
  const [validateFileFomatCSV, setValidateFileFomatCSV] = useState<string>('');

  const [hasResponsed, setHasResponsed] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMsgData, setErrorMsgData] = useState([]);
  const [msgSuccess, setMsgSuccess] = useState<string>('');

  const toLowerCaseReplace = (field: string, data) => {
    if (data) {
      let key = field.toLowerCase().replace(/\W/g, '_');
      return key in data;
    } else {
      return false;
    }
  };

  const isAgentLogin = Utils.getSafeValue(
    localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {}),
    'loginAsCompany',
    null
  );

  const handleForce = (data, fileInfo, csvData) => {
    let first_row_data = data[0];
    let errorHeaderCSV = '';
    let errorFileFomat = '';
    let errorFileSize = '';

    // console.log((/(\r\n|\n)/.test(csvData.meta.linebreak)));
    // console.log(csvData);

    if (/\.csv$/.test(fileInfo.name)) {
      errorFileFomat = '';
      setValidateFileFomatCSV(errorFileFomat);
    } else {
      errorFileFomat = 'The uploaded file must be CSV';
      setValidateFileFomatCSV(errorFileFomat);
      setDisableBtnImportCSV(true);
      return;
    }

    let maxSize = 1024 * 1024 * 5;
    if (fileInfo.size > maxSize) {
      errorFileSize = 'The maximum file upload size is 5MB';
      setValidateFileSizeCSV(errorFileSize);
      setDisableBtnImportCSV(true);
      return;
    } else {
      errorFileSize = '';
      setValidateFileSizeCSV(errorFileSize);
    }

    if (csvData.meta.delimiter != ',') {
      errorHeaderCSV = 'The uploaded file has incorrect format or invalid data';
      setValidateHeaderCSV(errorHeaderCSV);
      setDisableBtnImportCSV(true);
      return;
    } else {
      if (data.length > 0) {
        if (
          toLowerCaseReplace('SKU', first_row_data) &&
          toLowerCaseReplace('EAN', first_row_data) &&
          toLowerCaseReplace('Quantity', first_row_data) &&
          toLowerCaseReplace('Delivery date (dd/mm/yyyy)', first_row_data)
        ) {
          errorHeaderCSV = '';
          setValidateHeaderCSV(errorHeaderCSV);
        } else {
          errorHeaderCSV = 'The uploaded file has incorrect format or invalid data';
          setValidateHeaderCSV(errorHeaderCSV);
          setDisableBtnImportCSV(true);
          return;
        }
      } else {
        errorHeaderCSV = 'The uploaded file has incorrect format or invalid data';
        setValidateHeaderCSV(errorHeaderCSV);
        setDisableBtnImportCSV(true);
        return;
      }
    }

    if (!errorHeaderCSV && !errorFileFomat && !errorFileSize) {
      setDisableBtnImportCSV(false);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
  };

  const closeModalImportCSV = () => {
    setShowDialogImportCSV(false);
    setHasResponsed(false);
    setDisableBtnImportCSV(true);
    setValidateHeaderCSV('');
    setValidateFileSizeCSV('');
    setValidateFileFomatCSV('');
  };

  const headersSampleCSV = [
    { label: 'SKU', key: 'sku' },
    { label: 'EAN', key: 'ean' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Delivery date (dd/mm/yyyy)', key: 'delivery_date' },
  ];
  const dataSampleCSV = [
    {
      sku: '5001W-BLK01-8',
      ean: '5052316103970',
      quantity: 1,
      delivery_date: moment(addDays(new Date(), 5)).format('DD/MM/YYYY'),
    },
  ];

  const headersError = [{ label: 'Error:', key: 'error' }];

  const onFormSubmit = async data => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', data.file[0]);

    let baseUrl = localStorage.get(Constants.KeyAsyncStore.baseUrl, '');
    let headerLogin = Utils.safeParseJson(localStorage.getObject(Constants.KeyAsyncStore.headerDevLogin, {}));
    let appKey = localStorage.get(Constants.KeyAsyncStore.appKey, '');

    try {
      const res = await axios.post(baseUrl + AppURL.importBulkOrder, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          jm360_kp_ver: Utils.getSafeValue(headerLogin, 'jm360_kp_ver', ''),
          jm360_kp: Utils.getSafeValue(headerLogin, 'jm360_kp', ''),
        },
        params: {
          appKey: appKey,
        },
      });

      console.log(res);

      let dataRes = Utils.getSafeValue(res, 'data', {});

      if (!_.isEmpty(dataRes)) {
        if (!_.isEmpty(dataRes.cartId) && data.errors == null) {
          localStorage.set('cartId', dataRes.cartId);
          localStorage.setObject('shoppingCart', dataRes.cart);

          const payload = {
            shoppingCart: dataRes.cart,
          };
          setCart(payload);

          setMsgSuccess(dataRes.message);
          setResponseError(false);
        } else if (!_.isEmpty(dataRes.errors) && data.cartId == null) {
          let errorArr: any = [];

          _.map(dataRes.errors, (item, index) => {
            errorArr.push({ error: item.message });
          });

          setErrorMsgData(errorArr);
          setResponseError(true);
        }
        setHasResponsed(true);
      }
    } catch (err) {
      setErrorMessage(err);
      console.log(err);
      // if (err.response.status === 500) {
      //     console.log('There was a problem with the server');
      // } else {
      //     console.log(err.response.data.msg);
      // }
    }
    setIsLoading(false);
  };

  const importSuccessClick = () => {
    // setShowDialogImportCSV(false);
    // setHasResponsed(false);
    // history.push('/cart');

    if (location.pathname != '/cart') {
      setShowDialogImportCSV(false);
      setHasResponsed(false);
      history.push({
        pathname: '/cart',
        state: { isLoadCsvCart: true },
      });
    } else {
      setShowDialogImportCSV(false);
      setHasResponsed(false);
    }
  };

  const handleClickSwitch = () => {
    setShowDialogImportCSV(false);
    localStorage.removeItem(Constants.KeyAsyncStore.customerAssign);
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem('stepCheckout');
    localStorage.removeItem('selectedShippingMethod');
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedAddress');
    history.push({
      pathname: '/login-as-company',
    });
  };

  return (
    <>
      <Modal
        dialogClassName={`dialogImportCSV ${hasResponsed ? 'hasResponsed' : ''} ${
          !isAgentLogin ? 'confirmToSelectCompany confirmToRedirect' : ''
        }`}
        show={showDialogImportCSV}
        scrollable={false}
      >
        {isAgentLogin ? (
          <Modal.Body className="modalBody">
            {!hasResponsed ? (
              <Form onSubmit={method.handleSubmit(onFormSubmit)}>
                <div className="titleBlockModal">{t('importCSV.titleModal')}</div>
                <div className="modalBodyInner">
                  <div className="inputField">
                    <CSVReader
                      cssClass="react-csv-input"
                      onFileLoaded={handleForce}
                      parserOptions={papaparseOptions}
                      propRef={method.register({ required: true })}
                      name="file"
                      disabled={isLoading}
                    />
                  </div>
                  {(validateFileFomatCSV || validateFileSizeCSV || validateHeaderCSV) && (
                    <div className="msgError">
                      {validateFileFomatCSV ? (
                        validateFileFomatCSV
                      ) : (
                        <>{validateFileSizeCSV ? validateFileSizeCSV : validateHeaderCSV}</>
                      )}
                    </div>
                  )}
                  {errorMessage && <div className="msgError">{errorMessage}</div>}

                  <div className="msgDesc">
                    <ul>
                      <li>• {t('importCSV.descRule1')}</li>
                      <li>• {t('importCSV.descRule2')}</li>
                      <li>• {t('importCSV.descRule3')}</li>
                      <li>• {t('importCSV.descRule4')}</li>
                    </ul>
                    {t('importCSV.lblDownloadCSV1')}
                    <CSVDownloader data={dataSampleCSV} headers={headersSampleCSV} filename={'sample-csv-template.csv'}>
                      <span>{t('importCSV.lblDownloadCSV2')} </span>
                    </CSVDownloader>{' '}
                    {t('importCSV.lblDownloadCSV3')}
                  </div>
                  <div className="msgWarning">
                    <span className="semiBold">{t('importCSV.warning')}</span> {t('importCSV.warningClearCartCSV')}
                  </div>
                </div>
                <div className="btnGroups">
                  <button
                    type="button"
                    className="btnCancel"
                    disabled={isLoading}
                    onClick={() => closeModalImportCSV()}
                  >
                    {t('confirmModal.cancel')}
                  </button>
                  <button
                    type="submit"
                    className={`btnSubmit btnWithArrow ${isLoading ? 'loading' : ''}`}
                    disabled={disableBtnImportCSV}
                  >
                    {isLoading ? <img src={loading} width={18} /> : `${t('importCSV.lblBtnImportFile')}`}
                    <span className="icnArrow">
                      <IoIosPlay />
                    </span>
                  </button>
                </div>
              </Form>
            ) : (
              <>
                {responseError ? (
                  <div className="responseContent error">
                    <div className="responseContentInner">
                      <div className="img">
                        <img src={icnError} width={65} />
                      </div>
                      <div className="title">{t('importCSV.titleMsgImportFail')}</div>
                      <div className="desc">
                        {t('importCSV.descFail1')}
                        <br />
                        {t('importCSV.descFail2')}
                      </div>
                      <div className="btnGroups">
                        <button className="btnCancel" onClick={() => closeModalImportCSV()} type="button">
                          {t('confirmModal.cancel')}
                        </button>
                        <CSVDownloader data={errorMsgData} headers={headersError} filename={'error_log.txt'}>
                          <span className="btnDownloadError btnWithArrow">
                            {t('importCSV.lblBtnDownloadError')}
                            <span className="icnArrow">
                              <IoIosPlay />
                            </span>
                          </span>
                        </CSVDownloader>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="responseContent success">
                    <div className="responseContentInner">
                      <div className="img">
                        <img src={icnSuccess} width={65} />
                      </div>
                      <div className="title">{t('importCSV.titleMsgImportSuccess')}</div>
                      <div className="desc">{msgSuccess}</div>
                      <div className="btnGroups">
                        <button
                          className="btnSuccessOk btnWithArrow"
                          onClick={() => importSuccessClick()}
                          type="button"
                        >
                          {t('confirmModal.ok')}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body className="modalBody">
            <div className="text-message line1">
              {t('confirmModal.msgConfirmChooseCompany1')}
              <br />
              {t('confirmModal.msgConfirmChooseCompany2')}
            </div>
            <div className="btnGroup">
              <button type="button" onClick={() => closeModalImportCSV()} className="btnCancel">
                {t('confirmModal.no')}
              </button>
              <button type="button" onClick={() => handleClickSwitch()} className="btnLogout">
                {t('confirmModal.yes')}
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
    setCart: params => dispatch(setCart(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(withTranslation()(ImportCsvOrder));
