import React, { ReactElement } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { List, ListItem, ListItemText, Collapse, Divider } from '@material-ui/core';
import Icon, { ICONS } from 'src/components/Icon';
import _ from 'lodash';

interface Props {
  item?: any;
  itemKey?: any;
}

export default function PrivacyItem({ item, itemKey }: Props): ReactElement {
  const [open, setOpen] = React.useState(false);

  const handleClickCollapse = (): void => {
    setOpen(!open);
  };
  return (
    <div className={`termBoxLv1 boxNumber${itemKey}`}>
      <List component="div" aria-labelledby="nested-list-subheader">
        <div className="titleAccodion" onClick={handleClickCollapse}>
          <div className="titleBlock">
            <span className="numberKey">{item['key']}</span>
            <div className="title titleLv1">{item['title']}</div>
          </div>
          {open ? <Icon size={20} name={ICONS.MINUS} /> : <Icon size={20} name={ICONS.PLUS} />}
        </div>

        {item['child'] &&
          _.map(item['child'], (itemLv2: any, indexChild) => {
            return (
              <React.Fragment key={indexChild}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <div className="termBoxLv2">
                    <div className="titleBlock">
                      <span className="numberKey">{itemLv2.keyChild1}</span>
                      <div className="title titleLv2">{itemLv2.contenChild1}</div>
                    </div>
                    {itemLv2.child2 &&
                      _.map(itemLv2.child2, (itemLv3, indexLv3) => {
                        return (
                          <React.Fragment key={indexLv3}>
                            <div className="termBoxLv3">
                              <div className="titleBlock">
                                <span className="numberKey">{itemLv3.keyChild2}</span>
                                <div className="title titleLv3">{itemLv3.contenChild2}</div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </Collapse>
              </React.Fragment>
            );
          })}
      </List>
    </div>
  );
}
