import React, { useEffect, useState, useRef } from 'react';
import { callApiACT } from '../../../redux/callApiRDC';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DatePicker from 'react-datepicker';

import { TypeRequest } from '../../../constants';
import * as AppURL from '../../../services/urlAPI';
import * as Utils from '../../../utils';
import './style.scss';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import Skeleton from '@material-ui/lab/Skeleton';

import localStorage from '../../../utils/LocalStorage';
import moment from 'moment';
import * as Constants from '../../../constants';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { backTop } from '../../../utils';
import { useTranslation, withTranslation } from 'react-i18next';
import { ProductLineItemType } from 'src/types/cart';
import clsx from 'clsx';
import UserAddress from 'src/components/UserAddress';

interface Props {
  match: any;
  callApiACT: (action) => void;
}

function OrderDetail({ callApiACT, match }: any) {
  const { t } = useTranslation();

  useEffect(() => {
    getOrderDetails();
  }, []);

  const [loading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [productsOrder, setProductsOrder] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [isCustomAddress, setIsCustomAddress] = useState<boolean>();

  let appKey = localStorage.get('appKey', '');

  const [orderDateCreated, setOrderDateCreated] = useState('');
  const getOrderDetails = async () => {
    setIsLoading(true);
    let params = {
      appKey: appKey,
      deviceKey: '03670aa8423b6686237ece50f2de4947',
    };

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: Utils.replaceStrUrl(AppURL.getOrderDetails, match.params.id),
        params: params,
      });

      let order = Utils.getSafeValue(data, 'item', {});
      let billing = Utils.getSafeValue(order, 'billingAddress', {});
      let shipping = Utils.getSafeValue(order, 'shippingAddresses', []);
      let products = Utils.getSafeValue(order, 'products', []);

      setOrder(order);
      setBillingAddress(billing);
      setProductsOrder(products);
      setShippingAddress(shipping[0]);
      setIsCustomAddress(Utils.getSafeValue(order, 'customShippingAddress', false));

      // let dateCreatedText = Utils.getSafeValue(order, "dateCreated", "");
      // let dateCreated = dateCreatedText && dateCreatedText.length > 0 ? new Date(dateCreatedText).toDateString() : undefined;
      // setOrderDateCreated(dateCreated);
      // console.log(dateCreated);
    } catch (e) {
      console.log('error get order detail: ', e);
    }
    setIsLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className="loading">
          <div className="orderDetail">
            <Container>
              <Row>
                <div className="col-2"></div>
                <div className="col-8">
                  <div style={{ padding: '23px 60px 0 40px' }}>
                    <Skeleton variant={'rect'} width={260} height={40} />
                  </div>
                  <div style={{ padding: '0 40px' }}>
                    <div className="row">
                      {_.times(4, i => (
                        <div className="col-6" style={{ paddingTop: '40px' }}>
                          <Skeleton variant={'rect'} width={350} height={26} style={{ marginBottom: '14px' }} />
                          <Skeleton variant={'rect'} width={290} height={26} style={{ marginBottom: '10px' }} />
                          <Skeleton variant={'rect'} width={360} height={26} style={{ marginBottom: '10px' }} />
                          <Skeleton variant={'rect'} width={320} height={26} style={{ marginBottom: '10px' }} />
                          <Skeleton variant={'rect'} width={340} height={26} style={{ marginBottom: '10px' }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </Row>
            </Container>
          </div>
        </div>
      ) : (
        <div className="orderDetail">
          <div className="container">
            <Row>
              <div className="col-2"></div>
              <div className="col-8">
                <div className="backField">
                  <Link
                    className="backToOrderList"
                    to={{
                      pathname: '/my-account/order-history',
                    }}
                  >
                    {t('orderDetail.BackToOrderList')}
                  </Link>
                </div>
                <div className="contentOrder">
                  <div className="row">
                    <div className="col-12">
                      <div className="boxCol">
                        <div>
                          <span className="semiBold">{t('orderDetail.order')} #: </span>
                          {order['id']}
                        </div>
                        <div>
                          <span className="semiBold">{t('orderDetail.status')} </span>
                          {order['customStatus']}
                        </div>
                        <div>
                          <span className="semiBold">{t('orderDetail.orderDate')} </span>
                          {order['dateCreated'] &&
                            moment(order['dateCreated'], Constants.DateFormat.valueInput).format(
                              Constants.DateFormat.valueInput
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="boxCol">
                        <div className="titleBox">{t('orderDetail.billingDetails')}</div>
                        <div className="boxInfor">
                          <UserAddress item={billingAddress} />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="boxCol">
                        <div className="titleBox">{t('orderDetail.shippingDetails')}</div>
                        <div className="boxInfor">
                          <UserAddress item={shippingAddress} addedAddress={isCustomAddress} />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="boxCol">
                        <div className="titleBox">{t('orderDetail.shippingMethod')}</div>
                        <div className="boxInfor">
                          {shippingAddress['shippingMethod'] && <div>{shippingAddress['shippingMethod']}</div>}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="boxCol">
                        <div className="titleBox">{t('orderDetail.paymentMethod')}</div>
                        <div className="boxInfor">
                          <div>{order['paymentMethod'] && order['paymentMethod']}</div>
                        </div>
                      </div>
                    </div>

                    {order['customerReference'] && (
                      <div className="col-6">
                        <div className="boxCol">
                          <div className="titleBox">{t('orderDetail.customerReference')}</div>
                          <div className="boxInfor">
                            <div>{order['customerReference']}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="boxCol">
                        <div className="titleBox" style={{ marginBottom: '20px' }}>
                          {t('orderDetail.orderSummary')}
                        </div>
                        <div className="boxInfor">
                          <div className="tableOrderProduct">
                            <table>
                              <thead>
                                <tr>
                                  {/*<th className="clSKU">SKU</th>*/}
                                  <th className="clName">{t('tableColumnName.productName')}</th>
                                  <th className="clColor">{t('tableColumnName.color')}</th>
                                  <th className="clSize">{t('tableColumnName.size')}</th>
                                  <th className="clQty">{t('tableColumnName.qty')}</th>
                                  <th className="clSent">{t('tableColumnName.sent')}</th>
                                  <th className="clBalance">{t('tableColumnName.balance')}</th>
                                  <th className="clDlvDate">{t('tableColumnName.deliveryDate')}</th>
                                  <th className="clPrice">{t('tableColumnName.price')}</th>
                                  <th className="clSubTotal">{t('tableColumnName.subtotal')}</th>
                                </tr>
                              </thead>
                              {/*<tbody>*/}
                              {_.map(productsOrder, (item, index) => {
                                let options = item['productOptions'];
                                let color = _.find(options, { displayName: 'Color' });
                                let size = _.find(options, {
                                  displayName: 'Size',
                                });

                                const isBackOrder = Utils.getSafeValue(item, 'backOrder', false);

                                const itemType = Utils.getSafeValue(item, 'type', ProductLineItemType.NORMAL);

                                let groupItems = Utils.getSafeValue(item, 'groupItems', []);
                                if (isBackOrder && itemType === ProductLineItemType.NORMAL) {
                                  groupItems = [item];
                                }

                                const backOrderRow = isBackOrder || groupItems.length > 0;
                                return (
                                  <React.Fragment key={index}>
                                    <tbody>
                                      <tr className={`rowMain ${item['backOrderQuantity'] > 0 ? 'hasItemChild' : ''}`}>
                                        <td className="clName">
                                          <Link to={`/products/${item['productId']}`} onClick={() => backTop()}>
                                            {item['name']}
                                          </Link>
                                          <div className="itemSku">{item['sku']}</div>
                                        </td>
                                        <td className="clColor">{color ? color['displayValue'] : ''}</td>
                                        <td className="clSize">{size ? size['displayValue'] : ''}</td>
                                        <td className="clQty">{item['quantity']}</td>
                                        <td className="clSent">{item['quantityShipped']}</td>
                                        <td className="clBalance">{item['quantityBalance']}</td>
                                        <td className="clDlvDate">
                                          {!item['backOrder'] && item['deliveryDate']
                                            ? moment(
                                                item['deliveryDate'],
                                                Constants.DateFormat.serverDateFormat
                                              ).format(Constants.DateFormat.valueInput)
                                            : ''}
                                        </td>
                                        <td className="clPrice">
                                          {Utils.convertCurrency(item['basePrice'], order['currencyCode'])}
                                        </td>
                                        <td className="clSubTotal">
                                          {Utils.convertCurrency(item['baseTotal'], order['currencyCode'])}
                                        </td>
                                      </tr>
                                      {groupItems.map((lineItem, indexSubItem) => {
                                        const isBackOrderItem =
                                          Utils.getSafeValue(lineItem, 'backOrderQuantity', 0) > 0;
                                        const backorderType =
                                          (isBackOrderItem && t('checkout.backorder')) || t('checkout.standard');

                                        const lineQuantity =
                                          (isBackOrderItem && lineItem.backOrderQuantity) ||
                                          lineItem.actualQuantity ||
                                          0;

                                        let deliveryDate = '-';

                                        if (isBackOrderItem) {
                                          if (lineItem.backOrderDeliveryDate) {
                                            deliveryDate = moment(
                                              lineItem.backOrderDeliveryDate,
                                              Constants.DateFormat.serverDateFormat
                                            ).format(Constants.DateFormat.valueInput);
                                          }
                                        } else if (lineItem.actualDeliveryDate) {
                                          deliveryDate = moment(
                                            lineItem.actualDeliveryDate,
                                            Constants.DateFormat.serverDateFormat
                                          ).format(Constants.DateFormat.valueInput);
                                        }
                                        const classNames = clsx('rowSeparatorQty', {
                                          'first-subline': indexSubItem === 0,
                                        });
                                        return (
                                          <tr className={classNames}>
                                            {/* <td className="img backorder">
                                              <div className="backorder_sub-line flex-box backorder_sub-line__rectangle" />
                                            </td> */}
                                            <td className="pName backorder">
                                              <div className="backorder_sub-line flex-box backorder_sub-line__product">
                                                <div className="">{backorderType}</div>
                                              </div>
                                            </td>
                                            <td className="pOptionColor backorder">
                                              <div className="backorder_sub-line flex-box"> </div>
                                            </td>
                                            <td className="pOPtionSize backorder">
                                              <div className="backorder_sub-line flex-box"> </div>
                                            </td>
                                            <td className="pQty backorder">
                                              <div className="backorder_sub-line flex-box">{lineQuantity}</div>
                                            </td>
                                            <td className="sent backorder">
                                              <div className="backorder_sub-line flex-box" />
                                            </td>
                                            <td className="balance backorder">
                                              <div className="backorder_sub-line flex-box" />
                                            </td>
                                            <td className="pDeliveryDate backorder">
                                              <div className="backorder_sub-line flex-box">{deliveryDate}</div>
                                            </td>
                                            <td className="pSalePrice backorder">
                                              <div className="backorder_sub-line flex-box">
                                                {Utils.convertCurrency(
                                                  lineItem.basePrice,
                                                  Utils.getSafeValue(
                                                    order,
                                                    'currencyCode',
                                                    Constants.CurrencyKey.KEY_USD
                                                  )
                                                )}
                                              </div>
                                            </td>
                                            <td className="pSubTotal backorder end-TD">
                                              <div className="backorder_sub-line flex-box backorder_sub-line__subtotal">
                                                {Utils.convertCurrency(
                                                  lineQuantity * lineItem.basePrice,
                                                  Utils.getSafeValue(
                                                    order,
                                                    'currencyCode',
                                                    Constants.CurrencyKey.KEY_USD
                                                  )
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}

                                      {/*
                                      {item['backOrderQuantity'] > 0 && (
                                        <tr className="rowSperatorQty">
                                          <td colSpan={999}>
                                            <div className="speratorQty">
                                              {item['actualQuantity'] > 0 && (
                                                <div className="speratorQtyItem">
                                                  <div className="itemName">{t('orderDetail.standard')}</div>
                                                  <div className="itemQty">{item['actualQuantity']}</div>
                                                  <div className="itemSent"></div>
                                                  <div className="itemBalance"></div>
                                                  <div className="itemDeliveryDate">
                                                    {item['actualDeliveryDate'] != null &&
                                                    item['actualDeliveryDate'] != ''
                                                      ? moment(item['actualDeliveryDate']).format(
                                                          Constants.DateFormat.valueInput
                                                        )
                                                      : ''}
                                                  </div>

                                                  <div className="itemUnitPrice">
                                                    {Utils.convertCurrency(item['basePrice'], order['currencyCode'])}
                                                  </div>
                                                  <div className="itemTotalPrice">
                                                    {Utils.convertCurrency(
                                                      item['actualQuantity'] * item['basePrice'],
                                                      order['currencyCode']
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                              {item['backOrderQuantity'] > 0 && (
                                                <div className="speratorQtyItem">
                                                  <div className="itemName">{t('orderDetail.backorder')}</div>
                                                  <div className="itemQty">{item['backOrderQuantity']}</div>
                                                  <div className="itemSent"></div>
                                                  <div className="itemBalance"></div>
                                                  <div className="itemDeliveryDate">
                                                    {Utils.getSafeValue(item, 'backOrderDeliveryDate', '') !== ''
                                                      ? moment(
                                                          Utils.getSafeValue(item, 'backOrderDeliveryDate', '')
                                                        ).format(Constants.DateFormat.valueInput)
                                                      : ''}
                                                  </div>
                                                  <div className="itemUnitPrice">
                                                    {Utils.convertCurrency(item['basePrice'], order['currencyCode'])}
                                                  </div>
                                                  <div className="itemTotalPrice">
                                                    {Utils.convertCurrency(
                                                      item['backOrderQuantity'] * item['basePrice'],
                                                      order['currencyCode']
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                       */}
                                    </tbody>
                                  </React.Fragment>
                                );
                              })}
                              {/*</tbody>*/}
                            </table>
                          </div>
                          <div className="tableOrderTotal">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="fieldLbl">
                                    {t('orderDetail.subtotal')} ({order['itemsTotal']}):
                                  </td>
                                  <td>{Utils.convertCurrency(order['subtotalExTax'], order['currencyCode'])}</td>
                                </tr>
                                {order['couponDiscount'] !== 0 && (
                                  <tr>
                                    <td className="fieldLbl">{t('orderDetail.discount')}</td>
                                    <td>
                                      {Utils.convertCurrency(-1 * order['couponDiscount'], order['currencyCode'])}
                                    </td>
                                  </tr>
                                )}
                                {order['baseShippingCost'] !== 0 && (
                                  <tr>
                                    <td className="fieldLbl">{t('orderDetail.shipping')}</td>
                                    <td>{Utils.convertCurrency(order['baseShippingCost'], order['currencyCode'])}</td>
                                  </tr>
                                )}
                                {order['baseHandlingCost'] !== 0 && (
                                  <tr>
                                    <td className="fieldLbl">{t('orderDetail.handling')}</td>
                                    <td>{Utils.convertCurrency(order['baseHandlingCost'], order['currencyCode'])}</td>
                                  </tr>
                                )}

                                {order['totalTax'] !== 0 && (
                                  <tr>
                                    <td className="fieldLbl">{t('orderDetail.tax')}</td>
                                    <td>{Utils.convertCurrency(order['totalTax'], order['currencyCode'])}</td>
                                  </tr>
                                )}

                                <tr>
                                  <td colSpan={2} style={{ padding: '3px' }}></td>
                                </tr>
                                <tr className="fieldTotal">
                                  <td className="fieldLbl">{t('orderDetail.grandTotalInclTax')}</td>
                                  <td>{Utils.convertCurrency(order['totalIncTax'], order['currencyCode'])}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2"></div>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// export default compose(withConnect)(OrderDetail);
export default compose(withConnect(withTranslation()(OrderDetail)));
