import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import './style.scss';
import { Image, Button } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import Form from 'react-bootstrap/Form';
import { IoIosPlay } from 'react-icons/io';
import { useTranslation, withTranslation } from 'react-i18next';
import { getSafeValue } from 'src/utils';
import { callApiACT } from '../../redux/callApiRDC';
import * as AppURL from '../../services/urlAPI';
import { reducerNameHOR } from '../../redux';
import { TypeRequest } from '../../constants';
import localStorage from '../../utils/LocalStorage';
import loading from '../../assets/loadingx43px.svg';
import InputPassword from '../../components/Input/inputPassword';
import success_icn from './assets/icnSuccess.png';

interface Props {
  callApiACT: (action) => void;
}

function ChangeForgotPassword({ callApiACT }: any) {
  const methods = useForm();

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');
  const [msgValidate, setMsgValidate] = useState('');
  const [sendSuccess, setSendSuccess] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [validToken, setValidToken] = useState(true);

  useEffect(() => {
    let currentURL = window.location.href || '';
    let url = new URL(currentURL) || '';
    let searchParams = new URLSearchParams(url.search) || '';
    setToken(searchParams.get('token') || '');
    verifyToken(searchParams.get('token') || '');
  }, []);

  const onUserClicked = async data => {
    if (msgValidate != '') {
      return;
    }
    setIsLoading(true);

    let postData = _.assign(data, { token: token });

    let toastMessageSuccess = `Change Password Success`;

    try {
      let dataLogin = await callApiACT({
        typeRequest: TypeRequest.POST,
        name: reducerNameHOR.changePassword,
        url: AppURL.changeForgotPassword,
        params: postData,
        isToastSuccess: true,
        isToastErr: false,
        toastMessageSuccess: toastMessageSuccess,
      });
      setSendSuccess(true);
      localStorage.clear();
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setIsLoading(false);
  };

  const verifyToken = async e => {
    let params = {
      token: e,
    };

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: AppURL.verifyTokenForgotPassword,
        params: params,
      });
      const validateSuccess = !isEmpty(getSafeValue(data, 'item', {}));
      if (!validateSuccess) {
        setValidToken(false);
        setErrorMessage(`The given link in the Request Password email is invalid
        Please request another new password to receive a new link`);
      }
    } catch (e) {
      setValidToken(false);
      if (typeof e === 'string') {
        setErrorMessage(e);
      } else {
        setErrorMessage(`The given link in the Request Password email is invalid
          Please request another new password to receive a new link`);
      }
    }
  };

  const validateMatch = () => {
    if (methods.watch('password').length > 6 && methods.watch('confirmPassword').length > 6) {
      if (
        !methods.errors['password'] &&
        !methods.errors['confirmPassword'] &&
        methods.watch('password') != methods.watch('confirmPassword')
      ) {
        setMsgValidate(`Passwords do not match`);
      } else {
        setMsgValidate('');
      }
    } else {
      setMsgValidate('');
    }
    if (methods.watch('password').length > 0 && methods.watch('confirmPassword').length > 0) {
      setDisableBtn(false);
    }
  };

  return (
    <div className="loginPage forgotPassword">
      {/*{redirect && <Redirect to={`/login`}/>}*/}
      <div className="loginPageInner">
        {validToken && !sendSuccess && (
          <Form onSubmit={methods.handleSubmit(onUserClicked)}>
            <div className="loginForm">
              <h3 className="title">UPDATE YOUR PASSWORD</h3>
              <div className="desc">
                Passwords must be at least 7 characters and <br /> contain alphabetic, numeric, and special characters
              </div>
              {errorMessage != '' && (
                <div className="invalid-text" style={{ margin: '0 0 20px 0' }}>
                  {errorMessage}
                </div>
              )}
              <div className="formField">
                <div className="field">
                  <div className="fieldLabel">New Password</div>
                  <div className="fieldValue">
                    <InputPassword
                      onFocus={() => {
                        setErrorMessage('');
                        methods.clearErrors(['password']);
                      }}
                      onChange={() => validateMatch()}
                      style={methods.errors['password'] || errorMessage != '' ? { border: '1px solid #ff321f' } : {}}
                      name="password"
                      disabled={isLoading}
                      propRef={methods.register(
                        {
                          required: true,
                          minLength: 7,
                          pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/,
                        }
                        // {pattern: {value: /^[A-Za-z0-9_\.]{7,100}$/, message: 'sfsfsdfsf'}},
                      )}
                    />
                  </div>
                  {methods.errors && methods.errors['password'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {/*{methods.errors['password'] && `New Password is invalid`}*/}
                      {methods.errors['password'] === 'required' && 'New Password is invalid'}
                      {methods.errors['password'].type === 'minLength' &&
                        'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'}
                      {methods.errors['password'].type === 'pattern' &&
                        'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'}
                    </div>
                  )}
                </div>
                <div className="field">
                  <div className="fieldLabel">Confirm New Password</div>
                  <div className="fieldValue">
                    <InputPassword
                      onFocus={() => {
                        setErrorMessage('');
                        methods.clearErrors(['confirmPassword']);
                      }}
                      onChange={() => validateMatch()}
                      disabled={isLoading}
                      style={
                        methods.errors['confirmPassword'] || errorMessage != '' || msgValidate != ''
                          ? { border: '1px solid #ff321f' }
                          : {}
                      }
                      name="confirmPassword"
                      propRef={methods.register({
                        required: true,
                        minLength: 7,
                        pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/,
                      })}
                    />
                  </div>
                  {methods.errors && methods.errors['confirmPassword'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {/*{methods.errors['confirmPassword'] && `Confirm New Password is invalid`}*/}
                      {methods.errors['confirmPassword'].type === 'required' && 'Confirm New Password is invalid'}
                      {methods.errors['confirmPassword'].type === 'minLength' &&
                        'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'}
                      {methods.errors['confirmPassword'].type === 'pattern' &&
                        'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'}
                    </div>
                  )}
                  {msgValidate && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {msgValidate}
                    </div>
                  )}
                </div>
                <div className="field" style={{ textAlign: 'right' }}>
                  <button type="submit" disabled={disableBtn} className={`btnSubmit ${isLoading ? 'loading' : ''}`}>
                    {isLoading ? <img src={loading} width={18} /> : `Update Password`}
                    <span className="icnIcon">
                      <IoIosPlay />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
        {validToken && sendSuccess && (
          <div className="successBlock">
            <div className="imgSuccess">
              <img src={success_icn} width={65} />
            </div>
            <div className="msgSuccess">Your password has been updated</div>
          </div>
        )}
        {!validToken && (
          <div className="invalidToken">
            <div className="blockIcon">
              <div className="errorWarning">!</div>
            </div>
            <div className="blockMessage">{errorMessage}</div>

            <div className="back">
              <Link className="backToLogin" to="/login">
                Back to Login
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect(withTranslation()(ChangeForgotPassword)));
