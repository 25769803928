import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 14,
    height: 14,
    border: '1px solid  #000000',
    backgroundColor: '#ffffff',
  },
  checkedIcon: {
    width: '8px',
    height: '8px',
    backgroundColor: '#000000',
  },
});

// Inspired by blueprintjs
export default function StyledCheckbox(props: CheckboxProps): JSX.Element {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <div className={clsx(classes.icon, classes.center)}>
          <div className={classes.checkedIcon} />
        </div>
      }
      icon={<div className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}
