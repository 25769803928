import { create } from 'apisauce';
import get from 'lodash/get';

import { appTypeCode, devRegister, HeaderKey, JM360_ENV } from '../constants';
import { baseUrl } from './urlAPI';

const uuidv4 = require('uuid/v4');

const baseHeader = {
  [HeaderKey.APP_TYPE_CODE]: appTypeCode,
  [HeaderKey.JM360_ENV]: JM360_ENV,
  [HeaderKey.JM360_APP_TYPE]: '3',
};

export const initInstance = create({ baseURL: baseUrl, headers: baseHeader });

export const fingerPrint = () => {
  return {
    fingerprint: uuidv4(),
    model: '',
    version: '',
    os: 'Android OS',
    manufacturer: '',
  };
};
export const getBaseEndpointUrl = async () => {
  try {
    const result = await initInstance.get(devRegister);
    const endPoint: string = get(result.data, 'Endpoint', baseUrl);
    return endPoint;
  } catch (error) {
    return undefined;
  }
};
