import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { HighlightOff } from '@material-ui/icons';
import { List, makeStyles, Theme, createStyles, ListItem, ListItemText } from '@material-ui/core';

import { FilterItem, FilterOption, FilterOptionItem } from 'src/types/productList';
import { useTranslation } from 'react-i18next';
import useProductList from 'src/containers/business/hooks/useProductList';
import NestedList from './NestedList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '185px',
      backgroundColor: theme.palette.background.paper,
    },
    noPaddingTop: {
      paddingTop: 0,
    },
    alignMarginTop: {
      // marginTop: -10,
    },
    listItem: {
      width: '100%',
      maxWidth: '185px',
      backgroundColor: theme.palette.background.paper,
    },
    text: {
      fontFamily: 'FuturaPTBook',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '.5px',
      color: '#000000',
    },
    headerSearchText: {
      letterSpacing: '0.35px',
      fontWeight: 500,
      lineHeight: 1.05,
    },
    headerTitleText: {
      opacity: 0.5,
      letterSpacing: '0.48px',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    currentContainer: {
      paddingTop: '5px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    currentContentContainer: {
      paddingTop: 0,
      paddingBottom: 0,
      '& div': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    currentContent: {
      width: '100%',
      padding: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    clearAll: {
      width: '100%',
      maxWidth: '153px',
      height: '46px',
      border: 'solid 1px #b8b8b8',
      backgroundColor: '#ffffff',
      cursor: 'pointer',
      marginTop: '5px',
      marginBottom: '18px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      cursor: 'pointer',
    },
  })
);

type Filter = any;

interface MultiFilterProps {
  loading?: boolean;
  filters: FilterOption[];
  currentFilters: FilterOption[];
  error?: any;
  currencySymbol?: any;
  currencyCode: string;
  onChangeFilterCb: any;
  isChangePrice?: boolean;
  onClearAllFilterOption?: any;
}
const MultiFilter = ({ filters, currentFilters, currencyCode, onChangeFilterCb, isChangePrice }: MultiFilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { params, onChangePrice, onChangeFilter: onChangeFilterParams, onClearAllFilterOption } = useProductList();

  const handleClearAllFilter = () => {
    onClearAllFilterOption();
    onChangeFilterCb();
  };
  const handleChangeFilterOption = (filterOption: FilterOption, optionValue: FilterItem) => {
    if (filterOption.type !== 'PRICE_RANGE') {
      onChangeFilterParams(filterOption, optionValue);
    }
    onChangeFilterCb();
  };
  const onClickItemHOF = (filterOption: FilterOption, optionValue: FilterItem) => {
    return () => {
      handleChangeFilterOption(filterOption, optionValue);
    };
  };
  const onClickHeaderPriceHOF = () => {
    onChangePrice({});
    onChangeFilterCb();
  };
  const renderHeaderPriceItem = (priceOption: FilterOption) => {
    const { priceFilter } = params;
    const { maxPrice, minPrice } = priceFilter;
    if (_.isEmpty(priceFilter)) {
      return <div />;
    }
    const minPriceString = minPrice || '0';
    const maxPriceString = maxPrice || 'Max';
    const label = `${minPriceString} - ${maxPriceString} (${currencyCode})`;
    return (
      <ListItem key={priceOption.key} component="div" className={classes.currentContainer}>
        <ListItemText
          primary={`${priceOption.title}:`}
          primaryTypographyProps={{
            className: clsx(classes.text, classes.headerTitleText),
          }}
        />
        <List className={clsx(classes.listItem, classes.currentContentContainer)}>
          <ListItem component="div" className={classes.currentContent}>
            <ListItemText
              className={classes.listItem}
              // id={i}
              primary={label}
              primaryTypographyProps={{
                className: classes.text,
              }}
            />
            <HighlightOff className={classes.icon} onClick={onClickHeaderPriceHOF} />
          </ListItem>
        </List>
      </ListItem>
    );
  };
  const renderCurrentFilter = () => {
    // const currentOptions = {
    //   ...currentFilters.filters,
    //   Price:...currentFilters.priceFilter,
    // };

    if (currentFilters.length === 0) {
      return <div />;
    }

    // console.log({ currentFilters });
    return (
      <List
        component="div"
        aria-labelledby="nested-list-subheader"
        className={clsx(classes.root, classes.noPaddingTop)}
      >
        <ListItem className={clsx(classes.listItem, classes.noPaddingTop)}>
          <ListItemText
            primary={`${t('productList.currentlyShoppingBy')}`}
            primaryTypographyProps={{
              className: clsx(classes.text, classes.headerSearchText, classes.noPaddingTop),
            }}
          />
        </ListItem>
        {currentFilters.map(optionFilter => {
          if (optionFilter.type === 'PRICE_RANGE') {
            return renderHeaderPriceItem(optionFilter);
          }
          return (
            <ListItem key={optionFilter.key} component="div" className={classes.currentContainer}>
              <ListItemText
                primary={`${optionFilter.title}:`}
                primaryTypographyProps={{
                  className: clsx(classes.text, classes.headerTitleText),
                }}
              />
              <List className={clsx(classes.listItem, classes.currentContentContainer)}>
                {optionFilter.items.map((i, index) => {
                  return (
                    <ListItem component="div" className={classes.currentContent} key={index}>
                      <ListItemText
                        className={classes.listItem}
                        // id={i}
                        primary={`${i.label}`}
                        primaryTypographyProps={{
                          className: classes.text,
                        }}
                      />
                      <HighlightOff className={classes.icon} onClick={onClickItemHOF(optionFilter, i)} />
                    </ListItem>
                  );
                })}
              </List>
            </ListItem>
          );
        })}
        <ListItem className={classes.center}>
          <div className={clsx(classes.clearAll, classes.center)} onClick={handleClearAllFilter}>
            {t('productList.clearAll')}
          </div>
        </ListItem>
      </List>
    );
  };
  return (
    <List
      component="div"
      aria-labelledby="nested-list-subheader"
      className={clsx(classes.root, classes.noPaddingTop, classes.alignMarginTop)}
    >
      {renderCurrentFilter()}
      {_.map(filters, option => {
        return (
          <NestedList
            key={option.key}
            currentFilters={currentFilters}
            option={option}
            onChangeFilterCb={onChangeFilterCb}
            handleChangeFilterOption={handleChangeFilterOption}
            currencyCode={currencyCode}
            isChangePrice={isChangePrice}
          />
        );
      })}
    </List>
  );
};
MultiFilter.defaultProps = {
  currentFilters: [],
  error: '',
  currencySymbol: '',
};
export default MultiFilter;
