import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, NavLink } from 'react-router-dom';
import { callApiACT } from '../../redux/callApiRDC';
import _ from 'lodash';
import { getSafeValue } from 'src/utils';

interface SideMenuProps {
  menuData?: any;
  appConfig?: any;
  numberItem?: any;
  currentUrl?: any;
  setShowSideMenu?: any;
}

function SideMenu({ menuData, appConfig, numberItem, currentUrl }: SideMenuProps) {
  useEffect(() => {}, []);

  const getCatalogID = category => {
    const categoryMeta = JSON.parse(category.moduleMetaData.data);
    return categoryMeta.categoryId;
  };

  return (
    <ul className="side-menu">
      {appConfig &&
        !_.isEmpty(appConfig.response) &&
        menuData &&
        menuData.length > 0 &&
        menuData
          .filter(menu => !getSafeValue(menu, 'data.desktopTopBarDisabled', false))
          .map((menu, index) => {
            const menuAction = menu.data.action.replace('LOADMODULE ', '');
            const menuModule = _.find(appConfig.response.appData['modules'], { _id: menuAction });

            let menuHref = '';
            if (menuModule && menuModule.moduleType == 'ECOMMERCE_CATALOG') {
              menuHref = '/business/' + getCatalogID(menuModule);
            } else if (menuModule && menuModule.moduleType == 'HOME_SCREEN2') {
              menuHref = '/';
            } else if (menuModule && menuModule.moduleType == 'ABOUT_US') {
              menuHref = '/static/' + menuModule._id;
            }

            return (
              <Fragment key={index}>
                {numberItem ? (
                  <>
                    {index < numberItem && (
                      <Fragment>
                        {menuModule &&
                          (menuModule.moduleType == 'ECOMMERCE_CATALOG' ||
                            menuModule.moduleType == 'HOME_SCREEN2' ||
                            menuModule.moduleType == 'ABOUT_US') && (
                            <li className={`${currentUrl.pathname == menuHref ? 'active' : ''}`}>
                              <Link to={menuHref} target={menuModule.moduleType === 'ABOUT_US' ? '_blank' : '_self'}>
                                {menu.data.label}
                              </Link>
                            </li>
                          )}
                      </Fragment>
                    )}
                  </>
                ) : (
                  <Fragment>
                    {menuModule &&
                      (menuModule.moduleType == 'ECOMMERCE_CATALOG' ||
                        menuModule.moduleType == 'HOME_SCREEN2' ||
                        menuModule.moduleType == 'ABOUT_US') && (
                        <li className={`${currentUrl.pathname == menuHref ? 'active' : ''}`}>
                          <Link to={menuHref} target={menuModule.moduleType === 'ABOUT_US' ? '_blank' : '_self'}>
                            {menu.data.label}
                          </Link>
                        </li>
                      )}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
    </ul>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SideMenu);
