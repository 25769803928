import React, { useEffect, useState, useRef, useContext, ReactElement } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './style.scss';
import { callApiACT } from '../../redux/callApiRDC';
import { setParams } from '../../redux/indexRDC';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import { IoIosAdd, IoIosClose, IoIosPlay, IoIosRemove } from 'react-icons/io';
import _ from 'lodash';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { TypeRequest } from '../../constants';
import { reducerNameHOR } from '../../redux';
import * as AppURL from '../../services/urlAPI';
import * as Utils from '../../utils';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link, Route, useHistory } from 'react-router-dom';

import { AppContext } from '../../utils/appContext';
import moment from 'moment';
import loading from '../../assets/loadingx43px.svg';
import InputPassword from '../../components/Input/inputPassword';
import DatePickerInput from '../../components/datePicker';
import Select from '../../components/CustomSelect';
import { backTop } from '../../utils';
import ModalAlert from '../../components/modalAlert/modalAlert';
import { useLocation } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import CSVDownloader from 'src/components/CSVDownloader';
import MdDatePicker from 'src/components/MdDatePicker';
import { TextField } from '@material-ui/core';
import RadioButton from 'src/components/CustomRadioButton';
import { useTranslation, withTranslation } from 'react-i18next';
import UserAddress from 'src/components/UserAddress';

interface Props {
  callApiACT: (action) => void;
  setParams: (params) => void;
  match;
  location;
}

export interface QueryParams {
  pageNumber: number;
  pageSize?: number;
}
interface IDatePicker {
  inputValue?: string;
  //ParsableDate
  value?: object | string | number | Date | null | undefined;
}
function AccountInfo({ callApiACT }) {
  const [customer, setCustomer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const appContext: any = useContext(AppContext);
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [requiredPass, setRequiredPass] = useState<boolean>(false);
  const [errorSaveChange, setErrorSaveChange] = useState<string>('');

  const { t, i18n } = useTranslation();

  const { formState, ...methods } = useForm();

  useEffect(() => {
    getUserInfo();
  }, []);
  const getUserInfo = async () => {
    try {
      let data = await callApiACT({
        url: AppURL.getUserInfo,
        name: reducerNameHOR.getUserInfo,
        typeRequest: Constants.TypeRequest.GET,
      });
      localStorage.setObject(Constants.KeyAsyncStore.customer, data['customer']);
      setCustomer(data['customer']);
    } catch (e) {
      console.log('error message get user info: ', e);
      setErrorSaveChange(e);
    }
  };
  useEffect(() => {
    if (!_.isEmpty(customer)) {
      const data = customer;
      methods.reset({
        'first-name': data.firstName,
        'last-name': data.lastName,
        'company-name': data.company,
        'phone-number': data.phone,
      });
    }
  }, [customer]);

  useEffect(() => {
    if (!_.isEmpty(customer) && formState.isDirty && !formState.isSubmitted) {
      appContext.setPrompt(true);
    } else {
      appContext.setPrompt(false);
    }
  }, [formState]);

  const saveChangeEditUser = async data => {
    setShowSuccessMsg(false);
    setIsLoading(true);
    let paramsUser = {
      info: [
        {
          code: 'first-name',
          value: data['first-name'].trim(),
        },
        {
          code: 'last-name',
          value: data['last-name'].trim(),
        },
        {
          code: 'phone-number',
          value: data['phone-number'].trim(),
        },
        {
          code: 'current-password',
          value: data['current-password'] || '',
        },
        {
          code: 'password',
          value: data['password'] || '',
        },
        {
          code: 'confirm-password',
          value: data['confirm-password'] || '',
        },
      ],
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
    };

    try {
      let data = await callApiACT({
        url: AppURL.editAccount,
        typeRequest: Constants.TypeRequest.POST,
        params: paramsUser,
        isToastSuccess: true,
      });
      const { customer } = data;
      methods.reset({
        'first-name': customer.firstName,
        'last-name': customer.lastName,
        'company-name': customer.company,
        'phone-number': customer.phone,
      });
      if (data) {
        setShowSuccessMsg(true);
      }
      localStorage.setObject(Constants.KeyAsyncStore.customer, data['customer']);
    } catch (e) {
      console.log('error in edit user', e);
      setErrorSaveChange(e);
    }
    setIsLoading(false);
  };

  const clearFormError = () => {
    if (
      methods.watch('current-password') == '' &&
      methods.watch('password') == '' &&
      methods.watch('confirm-password') == ''
    ) {
      methods.clearErrors(['current-password', 'password', 'confirm-password']);
      setRequiredPass(false);
    } else {
      setRequiredPass(true);
    }
  };

  // bởi vì edit account ko dùng các trường custom field nên để form tĩnh cho nhanh, lưu lại chỗ này để sau nếu có thì sẽ làm form động
  // useEffect(() => {
  //     getSignUpForm();
  // }, []);
  const getSignUpForm = async () => {
    let params = [localStorage.get('appKey', '')];

    try {
      let data = await callApiACT({
        url: Utils.replaceStrUrl(AppURL.getSignUpForm, params),
        name: reducerNameHOR.getSignUpForm,
        typeRequest: Constants.TypeRequest.GET,
      });
    } catch (e) {
      console.log('error message get signup form in my account: ', e);
    }
  };

  const validateTrim = (e, field) => {
    let value = e.target.value;
    if (value.trim() == '') {
      methods.setError(field, { type: 'required' });
    } else {
      methods.clearErrors([field]);
    }
  };

  const onChangePhoneNumber = e => {
    let pattern = /[0-9'\.()*#+]/;
    console.log(e.key);
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const onCloseModal = () => {
    setErrorSaveChange('');
  };

  return (
    <>
      <div className="contentTab-accInfo">
        <div className="editAccountForm">
          <Form onSubmit={methods.handleSubmit(saveChangeEditUser)}>
            {showSuccessMsg && (
              <div className="msgSaveSuccess">
                {t('myAccount.msgProfileUpdatedSuccess')}
                <button type="button" onClick={() => setShowSuccessMsg(false)} className="btnCloseMsg">
                  <IoIosClose />
                </button>
              </div>
            )}

            <div className="labelRequired alignRight">{t('myAccount.requiredFields')}</div>
            <Row>
              <Col sm="12" md="6">
                <div className="formField">
                  <label>{t('myAccount.firstName')} *</label>
                  <input
                    className={`txt-text ${methods.errors && methods.errors['first-name'] ? 'invalid' : ''}`}
                    name="first-name"
                    disabled={isLoading}
                    onChange={e => {
                      validateTrim(e, 'first-name');
                    }}
                    ref={methods.register({ required: true })}
                    type="text"
                  />
                  {methods.errors && methods.errors['first-name'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {methods.errors['first-name'].type === 'required' && `${t('myAccount.msgFirstNameRequired')}`}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="formField">
                  <label>{t('myAccount.lastName')} *</label>
                  <input
                    className={`txt-text ${methods.errors && methods.errors['last-name'] ? 'invalid' : ''}`}
                    name="last-name"
                    disabled={isLoading}
                    onChange={e => {
                      validateTrim(e, 'last-name');
                    }}
                    ref={methods.register({ required: true })}
                    type="text"
                  />
                  {methods.errors && methods.errors['last-name'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {methods.errors['last-name'].type === 'required' && `${t('myAccount.msgLastNameRequired')}`}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="formField">
                  <label>{t('myAccount.phoneNumber')}</label>
                  <input
                    className="txt-text"
                    name="phone-number"
                    disabled={isLoading}
                    onKeyPress={e => onChangePhoneNumber(e)}
                    ref={methods.register}
                    type="tel"
                  />
                </div>
              </Col>
              <Col xs="12" style={{ height: '30px' }}></Col>
              <Col sm="12" md="6">
                <div className="formField">
                  <label>
                    {t('myAccount.currentPassword')} {requiredPass && '*'}
                  </label>
                  <InputPassword
                    className={`txt-text ${methods.errors && methods.errors['current-password'] ? 'invalid' : ''}`}
                    name="current-password"
                    disabled={isLoading}
                    onChange={() => clearFormError()}
                    propRef={methods.register({
                      required:
                        methods.watch('password') != '' || methods.watch('confirm-password') != '' ? true : false,
                      // minLength: 7,
                      pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/,
                    })}
                  />
                  {methods.errors && methods.errors['current-password'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {methods.errors['current-password'].type === 'required' &&
                        `${t('myAccount.currentPasswordRequired')}`}
                      {/*{methods.errors['current-password'].type === 'minLength' && "Password must be at least 7 characters"}*/}
                      {methods.errors['current-password'].type === 'pattern' &&
                        `${t('myAccount.patternPasswordValidate')}`}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs="12" />
              <Col sm="12" md="6">
                <div className="formField">
                  <label>
                    {t('myAccount.newPassword')} {requiredPass && '*'}
                  </label>
                  <InputPassword
                    className={`txt-text ${methods.errors && methods.errors['password'] ? 'invalid' : ''}`}
                    name="password"
                    disabled={isLoading}
                    onChange={() => clearFormError()}
                    propRef={methods.register({
                      required:
                        methods.watch('current-password') != '' || methods.watch('confirm-password') != ''
                          ? true
                          : false,
                      // minLength: 7,
                      // pattern: /(?=.*\d)(?=.*[a-zA-Z]).7*/,
                      pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/,
                    })}
                  />
                  {methods.errors && methods.errors['password'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {methods.errors['password'].type === 'required' && `${t('myAccount.newPasswordRequired')}`}
                      {/*{methods.errors['password'].type === 'minLength' && "Password must be at least 7 characters"}*/}
                      {methods.errors['password'].type === 'pattern' && `${t('myAccount.patternPasswordValidate')}`}
                    </div>
                  )}
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="formField">
                  <label>
                    {t('myAccount.confirmNewPassword')} {requiredPass && '*'}
                  </label>
                  <InputPassword
                    className={`txt-text ${methods.errors && methods.errors['confirm-password'] ? 'invalid' : ''}`}
                    name="confirm-password"
                    disabled={isLoading}
                    onChange={() => clearFormError()}
                    propRef={methods.register({
                      required:
                        methods.watch('current-password') != '' || methods.watch('password') != '' ? true : false,
                      validate: value => value === methods.watch('password'),
                      // minLength: 7,
                      // pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/
                    })}
                  />
                  {methods.errors && methods.errors['confirm-password'] && (
                    <div className="invalid-text" style={{ marginTop: '5px' }}>
                      {methods.errors['confirm-password'].type === 'required' &&
                        `${t('myAccount.confirmNewPasswordRequired')}`}
                      {methods.errors['confirm-password'].type === 'validate' && `${t('myAccount.passwordsNotMatch')}`}
                      {/*{methods.errors['confirm-password'].type === 'minLength' && "Password must be at least 7 characters"}*/}
                      {/*{methods.errors['confirm-password'].type === 'pattern' && "Password must be contain both alphabetic and numeric characters"}*/}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs="12" className="alignRight">
                <div className="formField">
                  <button className="btnWithArrow btnSaveChanges" type="submit">
                    {!isLoading ? (
                      `${t('myAccount.saveChanges')}`
                    ) : (
                      <>
                        <img src={loading} width={18} style={{ marginRight: '8px' }} /> {t('myAccount.saving')}
                      </>
                    )}
                    <span className="icnArrow">
                      <IoIosPlay />
                    </span>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <ModalAlert classCss={'myAccountError'} message={errorSaveChange} onCloseModal={() => onCloseModal()} />
    </>
  );
}

function AddressBook({ callApiACT }) {
  const { t } = useTranslation();

  const [isLoadingGetAddress, setIsLoadingGetAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);

  const [showConfirmRedirect, setShowConfirmRedirect] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const isAgentLogin = Utils.getSafeValue(
      localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {}),
      'loginAsCompany',
      null
    );
    if (isAgentLogin) {
      getAddressBook();
    } else {
      setShowConfirmRedirect(true);
    }
  }, []);

  const redirectToMyAccount = () => {
    setShowConfirmRedirect(false);
    history.push('/my-account/info');
  };

  const handleClickSwitch = () => {
    localStorage.removeItem(Constants.KeyAsyncStore.customerAssign);
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem('stepCheckout');
    localStorage.removeItem('selectedShippingMethod');
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedAddress');
    history.push({
      pathname: '/login-as-company',
      state: { linkHistory: location.pathname },
    });
  };

  const getAddressBook = async () => {
    setIsLoadingGetAddress(true);
    let appKey = localStorage.get('appKey', '');

    let pageNumher = -1;
    let appTypeCode = localStorage.get(Constants.KeyAsyncStore.appTypeCode, '');
    let deviceKey = localStorage.get(Constants.KeyAsyncStore.deviceKey, '');
    let params = {
      pageNumber: pageNumher,
      appKey: appKey,
      appTypeCode: appTypeCode,
      deviceKey: deviceKey,
    };

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: AppURL.getListAddress,
        name: reducerNameHOR.getUserAddress,
        params: params,
      });
      let billingAddress = Utils.getSafeValue(data, 'billingAddresses', []);
      let shippingAddress = Utils.getSafeValue(data, 'shippingAddresses', []);
      setBillingAddress(billingAddress);
      setShippingAddress(shippingAddress);
    } catch (e) {}
    setIsLoadingGetAddress(false);
  };

  function IsLoadingAddress() {
    return (
      <>
        <Skeleton variant={'rect'} width={220} height={24} style={{ marginBottom: '20px' }} />
        <Skeleton variant={'rect'} width={180} height={24} style={{ marginBottom: '10px' }} />
        <Skeleton variant={'rect'} width={250} height={24} style={{ marginBottom: '10px' }} />
        <Skeleton variant={'rect'} width={200} height={24} style={{ marginBottom: '10px' }} />
        <Skeleton variant={'rect'} width={250} height={24} style={{ marginBottom: '10px' }} />
        <Skeleton variant={'rect'} width={230} height={24} style={{ marginBottom: '10px' }} />
      </>
    );
  }

  return (
    <>
      <div className="contentTab-addBook">
        <Row>
          <Col md="6" sm="12">
            <div className="addBox">
              {isLoadingGetAddress ? (
                <div>
                  <IsLoadingAddress />
                </div>
              ) : (
                <>
                  <h3 className="title">{t('myAccount.invoiceAddress')}</h3>
                  <div className="addDetail">
                    {billingAddress.map(address => {
                      return <UserAddress item={address} showCompany={true} isMulti={billingAddress.length > 1} />;
                    })}
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col md="6" sm="12">
            <div className="addBox">
              {isLoadingGetAddress ? (
                <div>
                  <IsLoadingAddress />
                </div>
              ) : (
                <>
                  <h3 className="title">{t('myAccount.deliveryAddress')}</h3>
                  <div className="addDetail">
                    {shippingAddress.map(address => {
                      return <UserAddress item={address} showCompany={true} isMulti={shippingAddress.length > 1} />;
                    })}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        dialogClassName="confirmToSelectCompany confirmToRedirect"
        show={showConfirmRedirect}
        scrollable={false}
        onHide={() => setShowConfirmRedirect(false)}
      >
        <Modal.Body className="modalBody">
          <div className="text-message line1">
            {t('confirmModal.msgConfirmChooseCompany1')}
            <br />
            {t('confirmModal.msgConfirmChooseCompany2')}
          </div>
          <div className="btnGroup">
            <button type="button" onClick={() => redirectToMyAccount()} className="btnCancel">
              {t('confirmModal.no')}
            </button>
            <button type="button" onClick={() => handleClickSwitch()} className="btnLogout">
              {t('confirmModal.yes')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function IsLoading() {
  return (
    <>
      <div style={{ paddingTop: '40px' }}>
        {_.times(8, i => (
          <Skeleton variant={'rect'} width={'100%'} height={40} style={{ marginBottom: '20px' }} />
        ))}
      </div>
    </>
  );
}

function OrderHistory({ callApiACT }) {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [companyOrderList, setCompanyOrderList] = useState([]);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    pageSize: 30,
    pageNumber: 1,
  });
  const [hasMore, setHasMore] = useState(true);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [orderStatusList, setOrderStatusList] = useState();
  const [fromDate, setFromDate] = useState<IDatePicker>({});
  const [selectedStatus, setSelectedStatus] = useState<any>({});
  const [toDate, setToDate] = useState<IDatePicker>({});
  const [orderIDSearch, setOrderIDSearch] = useState('');

  const [isSearch, setIsSearch] = useState(false);
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allOrders, setAllOrders] = useState<any>([]);
  const [productCodeOption, setProductCodeOption] = useState('productSku');
  const [productCode, setProductCode] = useState('');
  const [customerReference, setCustomerReference] = useState('');
  const [fromDeliveryDate, setFromDeliveryDate] = useState<IDatePicker>({});
  const [toDeliveryDate, setToDeliveryDate] = useState<IDatePicker>({});
  const location = useLocation();
  const history = useHistory();

  let appKey = localStorage.get(Constants.KeyAsyncStore.appKey, '');

  const [showConfirmRedirect, setShowConfirmRedirect] = useState<boolean>(false);

  useEffect(() => {
    const isAgentLogin = Utils.getSafeValue(
      localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {}),
      'loginAsCompany',
      null
    );
    if (isAgentLogin) {
      getOrderStatusList();
    } else {
      setShowConfirmRedirect(true);
    }
  }, []);

  const redirectToMyAccount = () => {
    setShowConfirmRedirect(false);
    history.push('/my-account/info');
  };

  const handleClickSwitch = () => {
    localStorage.removeItem(Constants.KeyAsyncStore.customerAssign);
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem('stepCheckout');
    localStorage.removeItem('selectedShippingMethod');
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedAddress');
    history.push({
      pathname: '/login-as-company',
      state: { linkHistory: location.pathname },
    });
  };

  useEffect(() => {
    const isAgentLogin = Utils.getSafeValue(
      localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {}),
      'loginAsCompany',
      null
    );
    if (isAgentLogin) {
      getOrderList();
      getAllOrders();
    } else {
      setShowConfirmRedirect(true);
    }
  }, [queryParams]);

  const getOrderStatusList = async () => {
    try {
      setIsLoading(true);
      let data = await callApiACT({
        typeRequest: TypeRequest.GET,
        name: reducerNameHOR.getCompanyOrderStatusList,
        url: Utils.replaceStrUrl(AppURL.getCompanyOrderStatusList, [appKey]),
        isToastErr: false,
        isToastSuccess: false,
      });
      let list = await Utils.getSafeValue(data, 'list', []);
      let orderStatusList: any = _.map(list, (item, index) => {
        return {
          label: item.name,
          value: item.name,
          id: item.id,
          customLabel: item.customLabel,
        };
      });
      setOrderStatusList(orderStatusList);
    } catch (errorMessage) {
      // setErrorMessage(errorMessage)
      console.log('error in get company order status in my account: ' + errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  const onChangeOrderID = e => {
    setOrderIDSearch(e.target.value.trim());
  };

  const onChangeProductCode = e => {
    const value = Utils.getSafeValue(e, 'target.value', '');
    setProductCode(value);
  };
  const onChangeCustomerReference = e => {
    const value = Utils.getSafeValue(e, 'target.value', '');
    setCustomerReference(value);
  };

  const onKeyPressOrderID = e => {
    // let pattern = /^(0|[1-9][0-9]*)$/;
    // setOrderIDSearch((e.target.value).trim());

    let pattern = /[0-9]/;
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  };
  const onChangeFromDate = value => {
    setFromDate(value.value);
  };
  const onChangeToDate = value => {
    setToDate(value.value);
  };

  const onChangeMdFromDate = date => {
    const input = Utils.getSafeValue(date, '_i', '');
    setFromDate({ ...fromDate, inputValue: input });
    if (date != null && date.isValid && date.isValid()) {
      // const toDateMoment = moment(toDate.value);
      if (toDate.value && moment(toDate.value).isBefore(date)) {
        setFromDate({ ...toDate });
      } else {
        setFromDate({ inputValue: input, value: date.toDate() });
      }
    }
    if (date == null) {
      setFromDate({ inputValue: input, value: null });
    }
  };
  const onChangeMdToDate = date => {
    const input = Utils.getSafeValue(date, '_i', '');
    setToDate({ ...toDate, inputValue: input });
    if (date != null && date.isValid && date.isValid()) {
      // const fromDateMoment = moment(fromDate.value);
      if (fromDate.value && moment(fromDate.value).isAfter(date)) {
        setToDate({ ...fromDate });
      } else {
        setToDate({ inputValue: input, value: date.toDate() });
      }
    }
    if (date == null) {
      setToDate({ inputValue: input, value: null });
    }
  };

  const onChangeFromDeliveryDate = date => {
    const input = Utils.getSafeValue(date, '_i', '');
    setFromDeliveryDate({ ...fromDeliveryDate, inputValue: input });
    if (date != null && date.isValid && date.isValid()) {
      if (toDeliveryDate.value && moment(toDeliveryDate.value).isBefore(date)) {
        setFromDeliveryDate({ ...toDeliveryDate });
      } else {
        setFromDeliveryDate({ inputValue: input, value: date.toDate() });
      }
    }
    if (date == null) {
      setFromDeliveryDate({ inputValue: input, value: null });
    }
  };
  const onChangeToDeliveryDate = date => {
    const input = Utils.getSafeValue(date, '_i', '');
    setToDeliveryDate({ ...toDeliveryDate, inputValue: input });
    if (date != null && date.isValid && date.isValid()) {
      if (fromDeliveryDate.value && moment(fromDeliveryDate.value).isAfter(date)) {
        setToDeliveryDate({ ...fromDeliveryDate });
      } else {
        setToDeliveryDate({ inputValue: input, value: date.toDate() });
      }
    }
    if (date == null) {
      setToDeliveryDate({ inputValue: input, value: null });
    }
  };

  const onAcceptDate = (date, setStateCallback) => {
    const inputValue = moment(date).format('DD-MM-YYYY');
    setStateCallback({ inputValue, value: date });
  };
  const onChangeProductCodeOption = event => {
    const value = Utils.getSafeValue(event, 'target.value', '');
    setProductCodeOption(productCodeOption => value);
  };
  const onSelectStatus = value => {
    setSelectedStatus(value);
  };

  // const getOrderList = async () => {
  //
  //     let appKey = localStorage.get('appKey', '');
  //     let deviceKey = localStorage.get('deviceKey', '');
  //
  //     let params = {
  //         "appKey": appKey,
  //         "deviceKey": deviceKey,
  //         "pageNumber": queryParams.pageNumber,
  //         "pageSize": queryParams.pageSize,
  //     };
  //
  //     try {
  //         setIsLoading(true);
  //         let dataOrder = await callApiACT({
  //             typeRequest: TypeRequest.POST,
  //             name: reducerNameHOR.getCompanyOrderList,
  //             url: AppURL.getCompanyOrderList,
  //             params: params,
  //             isToastErr: false,
  //             isToastSuccess: false,
  //         });
  //
  //         console.log(dataOrder);
  //         let now = moment();
  //         console.log(now);
  //         console.log(new Date());
  //
  //         getDataOrderList(dataOrder);
  //
  //         setIsLoading(false);
  //
  //     } catch (errorMessage) {
  //         // setErrorMessage(errorMessage)
  //         console.log('error in get company order in my account: ' + errorMessage);
  //     }
  // };

  const onGetSearch = () => {
    setOrderIDSearch(orderIDSearch.trim());
    setProductCode(productCode.trim());
    setCustomerReference(customerReference.trim());
    updateQueryParams('pageNumber', 1);
    setHasMore(true);
    setIsSearch(true);
    setIsLoading(true);
  };

  const getOrderList = async () => {
    let appKey = localStorage.get('appKey', '');
    let deviceKey = localStorage.get('deviceKey', '');
    let pageNumber = queryParams.pageNumber;
    let pageSize = queryParams.pageSize;

    let statusId = !_.isEmpty(selectedStatus) ? selectedStatus['id'] : '';
    let orderId = orderIDSearch ? parseInt(orderIDSearch) : '';

    let fromDate2 = '';
    if (fromDate.value) {
      fromDate2 = moment(fromDate.value).format('YYYY-MM-DD');
    }
    let toDate2 = '';
    if (toDate.value) {
      toDate2 = moment(toDate.value).format('YYYY-MM-DD');
    }
    let fromDeliveryDate2 = '';
    if (fromDeliveryDate.value) {
      fromDeliveryDate2 = moment(fromDeliveryDate.value).format('YYYY-MM-DD');
    }
    let toDeliveryDate2 = '';
    if (toDeliveryDate.value) {
      toDeliveryDate2 = moment(toDeliveryDate.value).format('YYYY-MM-DD');
    }
    const productSku = productCodeOption === 'productSku' ? productCode.trim() : '';
    const productEan = productCodeOption === 'productEan' ? productCode.trim() : '';
    setIsLoadingOrder(true);
    try {
      let dataOrder = await callApiACT({
        typeRequest: TypeRequest.GET,
        url: Utils.replaceStrUrl(AppURL.getCompanyOrderListWithParams, {
          appKey,
          deviceKey,
          pageNumber,
          pageSize,
          statusId,
          orderId,
          fromDate: fromDate2,
          toDate: toDate2,
          customerReference,
          productSku,
          productEan,
          fromDeliveryDate: fromDeliveryDate2,
          toDeliveryDate: toDeliveryDate2,
        }),
        name: reducerNameHOR.getCompanyOrderList,
        isToastErr: false,
        isToastSuccess: false,
      });

      getDataOrderList(dataOrder);
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
      console.log('error in get company order in my account: ' + errorMessage);
    }
    setIsLoading(false);
    setIsLoadingOrder(false);
    setIsClearSearch(false);
  };

  const getDataOrderList = dataOrderList => {
    if (dataOrderList['list'].length > 0) {
      // if ((dataOrderList['list'].length < 20)&&(queryParams.pageNumber == 1)){
      //     setCompanyOrderList(Utils.getSafeValue(dataOrderList, 'list', []));
      //     setHasMore(false);
      // } else {
      let _curList = queryParams.pageNumber == 1 ? [] : [...companyOrderList];
      setCompanyOrderList(_curList.concat(Utils.getSafeValue(dataOrderList, 'list', [])));
      // }
    } else {
      if (queryParams.pageNumber == 1) {
        setCompanyOrderList([]);
      }
      setHasMore(false);
    }
  };
  const updateQueryParams = (field, value) => {
    let _queryParams = { ...queryParams };
    _queryParams[field] = value;
    setQueryParams(_queryParams);
  };
  const loadMore = () => {
    updateQueryParams('pageNumber', queryParams.pageNumber + 1);
  };

  const onCloseModal = () => {
    setErrorMessage('');
  };

  const clearSearch = () => {
    const emptyDatePicker: IDatePicker = { inputValue: '', value: null };
    setOrderIDSearch('');
    setSelectedStatus({});
    setFromDate(emptyDatePicker);
    setToDate(emptyDatePicker);
    setFromDeliveryDate(emptyDatePicker);
    setToDeliveryDate(emptyDatePicker);
    setProductCode('');
    setCustomerReference('');

    setHasMore(true);
    setIsSearch(true);
    setIsLoading(true);
    setIsClearSearch(true);
    updateQueryParams('pageNumber', 1);
  };
  const getAllOrders = async () => {
    //!!!
    // quick dirty code to get all orders, this logic should be move to sever-side

    const appKey = localStorage.get('appKey', '');
    const deviceKey = localStorage.get('deviceKey', '');
    let pageNumber = 0;
    const pageSize = 250;
    let hasMore = true;

    const statusId = !_.isEmpty(selectedStatus) ? selectedStatus['id'] : '';
    const orderId = orderIDSearch ? parseInt(orderIDSearch) : '';

    let fromDate2 = '';
    if (fromDate.value) {
      fromDate2 = moment(fromDate.value).format('YYYY-MM-DD');
    }
    let toDate2 = '';
    if (toDate.value) {
      toDate2 = moment(toDate.value).format('YYYY-MM-DD');
    }
    let fromDeliveryDate2 = '';
    if (fromDeliveryDate.value) {
      fromDeliveryDate2 = moment(fromDeliveryDate.value).format('YYYY-MM-DD');
    }
    let toDeliveryDate2 = '';
    if (toDeliveryDate.value) {
      toDeliveryDate2 = moment(toDeliveryDate.value).format('YYYY-MM-DD');
    }
    const productSku = productCodeOption === 'productSku' ? productCode.trim() : '';
    const productEan = productCodeOption === 'productEan' ? productCode.trim() : '';
    let orders: any = [];
    try {
      while (hasMore) {
        pageNumber = pageNumber + 1;
        const dataOrder = await callApiACT({
          typeRequest: TypeRequest.GET,
          url: Utils.replaceStrUrl(AppURL.getCompanyOrderListWithParams, {
            appKey,
            deviceKey,
            pageNumber,
            pageSize,
            statusId,
            orderId,
            fromDate: fromDate2,
            toDate: toDate2,
            customerReference,
            productSku,
            productEan,
            fromDeliveryDate: fromDeliveryDate2,
            toDeliveryDate: toDeliveryDate2,
          }),
          name: reducerNameHOR.getCompanyOrderList,
          isToastErr: false,
          isToastSuccess: false,
        });
        orders = orders.concat(Utils.getSafeValue(dataOrder, 'list', []));
        // current api remove incomplete order
        // loop until don't have any order
        if (Utils.getSafeValue(dataOrder, 'list.length', 0) === 0) {
          hasMore = false;
          break;
        }
      }
      // console.log(orders.length);

      setAllOrders(orders);
      return orders;
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
      console.log('error in get company order in my account: ' + errorMessage);
      setAllOrders([]);
      return [];
    }
  };
  const renderTableOrderList = (): ReactElement => {
    const headers = [
      { label: 'Order Date', key: 'orderDate' },
      { label: 'Status', key: 'status' },
      { label: 'Order ID', key: 'orderId' },
      { label: 'Order Total', key: 'orderTotal' },
    ];
    const customerAssign = localStorage.getObject(Constants.KeyAsyncStore.customerAssign, {});
    const companyName =
      Utils.getSafeValue(customerAssign, 'assignCompany.name', '') ||
      Utils.getSafeValue(customerAssign, 'linkToCompany', '');
    const filename = `${companyName} - Order history.csv`;

    interface ExportData {
      orderDate: string;
      status: string;
      orderId: string;
      orderTotal: string;
    }
    const data = allOrders.map(order => {
      const { dateCreated: orderDate, status, id: orderId, totalIncTax, defaultCurrencyCode } = order;

      const orderObj: ExportData = {
        orderDate: `${moment(orderDate, Constants.DateFormat.valueInput).format(Constants.DateFormat.valueInput)}`,
        status: `${status}`,
        orderId: `${orderId}`,
        orderTotal: `${Utils.convertCurrency(totalIncTax, defaultCurrencyCode)}`,
      };

      return orderObj;
    });

    return (
      <div className="tableOrderContainer">
        <div className="download-csv">
          <CSVDownloader data={data} headers={headers} filename={filename}>
            <div className="btnExport">
              <div className="labelExportContainer">
                <div className="labelExport">{t('myAccount.exportCSV')}</div>
              </div>
              <div className="iconExportContainer">
                <div className="iconExport">
                  <IoIosPlay size="1.5em" />
                </div>
              </div>
            </div>
          </CSVDownloader>
        </div>

        <table className="tableOrder">
          <thead>
            <tr>
              <th className="colDate">{t('myAccount.date')}</th>
              <th className="colStatus">{t('myAccount.status')}</th>
              <th className="colID">{t('myAccount.order')} #</th>
              <th className="colTotal">{t('myAccount.orderTotal')}</th>
              <th className="colAction">{t('myAccount.action')}</th>
            </tr>
          </thead>
          <tbody id="tbodyList">
            {_.map(companyOrderList, (item, index) => {
              return (
                <tr key={index}>
                  <td className="colDate">
                    {item['dateCreated'] &&
                      moment(item['dateCreated'], Constants.DateFormat.valueInput).format(
                        Constants.DateFormat.valueInput
                      )}
                  </td>
                  <td className="colStatus">{item['status']}</td>
                  <td className="colID">{item['id']}</td>
                  <td className="colTotal">
                    {Utils.convertCurrency(item['totalIncTax'], item['defaultCurrencyCode'])}
                  </td>
                  <td className="colAction">
                    <Link
                      onClick={() => {
                        backTop();
                      }}
                      to={`/order/${item['id']}`}
                    >
                      {t('myAccount.viewDetails')}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <div className="contentTab-companyOrder">
        {isLoading && !isSearch ? (
          <div>
            <Skeleton variant={'rect'} width={'100%'} height={100} style={{ marginBottom: '20px' }} />
          </div>
        ) : (
          <div className="searchOrder">
            <div className="titleSearchBox">
              <h3 className="title">{t('myAccount.searchOrder')}</h3>
              <button className="toggleCollapse" onClick={() => setShowSearch(!showSearch)} type="button">
                {showSearch ? <IoIosRemove /> : <IoIosAdd />}
              </button>
            </div>
            {showSearch && (
              <div className="searchField">
                <Row>
                  <div className="col-6" style={{ marginBottom: '35px' }}>
                    <div className="fieldItem">
                      <div className="fieldLabel">{t('myAccount.orderID')}</div>
                      <div className="fieldvalue">
                        <input
                          type="number"
                          className="txt_text"
                          value={orderIDSearch}
                          name="orderID"
                          disabled={isSearch && isLoading}
                          onBlur={e => {
                            onChangeOrderID(e);
                          }}
                          onChange={e => onChangeOrderID(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6" style={{ marginBottom: '35px' }}>
                    <div className="fieldItem">
                      <div className="fieldLabel">{t('myAccount.orderStatus')}</div>
                      <div className="fieldvalue">
                        <Select
                          isSearchable={false}
                          placeholder={`${t('myAccount.pleaseSelect')}`}
                          name="orderStatus"
                          options={orderStatusList}
                          disabled={isSearch && isLoading}
                          value={!_.isEmpty(selectedStatus) ? selectedStatus : ''}
                          className={`txt_select_react ${isSearch && isLoading ? 'disabled' : ''}`}
                          classNamePrefix={'txt_select_react'}
                          onChange={value => {
                            onSelectStatus(value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-6" style={{ marginBottom: '35px' }}>
                    <div className="fieldLabel">{t('myAccount.productCode')}</div>
                    <div className="fieldItem radio-group-container">
                      <div className="radio-group">
                        <div
                          style={{
                            display: 'flex',
                            flex: '1',
                          }}
                        >
                          <RadioButton
                            checked={productCodeOption === 'productSku'}
                            name="productCodeOption"
                            value="productSku"
                            label="SKU"
                            disabled={isSearch && isLoading}
                            onChange={e => onChangeProductCodeOption(e)}
                          />
                          <RadioButton
                            checked={productCodeOption === 'productEan'}
                            name="productCodeOption"
                            value="productEan"
                            label="EAN"
                            disabled={isSearch && isLoading}
                            onChange={e => onChangeProductCodeOption(e)}
                          />
                        </div>
                      </div>
                      <div className="fieldvalue" style={{ flex: 2 }}>
                        <input
                          type="text"
                          className="txt_text"
                          value={productCode}
                          name="productCode"
                          disabled={isSearch && isLoading}
                          onChange={e => onChangeProductCode(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6" style={{ marginBottom: '35px' }}>
                    <div className="fieldItem">
                      <div className="fieldLabel">{t('myAccount.customerReference')}</div>
                      <div className="fieldvalue">
                        <input
                          type="text"
                          className="txt_text"
                          value={customerReference}
                          name="customerReference"
                          disabled={isSearch && isLoading}
                          onChange={e => onChangeCustomerReference(e)}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-6">
                    <Row>
                      <div className="fieldLabel">{t('myAccount.orderDate')}</div>
                    </Row>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <MdDatePicker
                        fullWidth
                        name="fromDate"
                        onChangeCb={value => onChangeMdFromDate(value)}
                        onChange={value => {
                          /*due to required props*/
                        }}
                        onAccept={date => onAcceptDate(date, setFromDate)}
                        value={fromDate.value || null}
                        maxDate={toDate.value || undefined}
                        inputValue={fromDate.inputValue || ''}
                      />

                      <div style={{ margin: '10px 20px' }}>
                        <div>{t('myAccount.to')}</div>
                      </div>

                      <MdDatePicker
                        fullWidth
                        name="toDate"
                        value={toDate.value || undefined}
                        minDate={fromDate.value || undefined}
                        inputValue={toDate.inputValue || ''}
                        onChangeCb={date => onChangeMdToDate(date)}
                        onChange={value => {
                          /*due to required props*/
                        }}
                        onAccept={date => onAcceptDate(date, setToDate)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <Row>
                      <div className="fieldLabel">{t('myAccount.deliveryDate')}</div>
                    </Row>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <MdDatePicker
                        fullWidth
                        name="fromDeliveryDate"
                        onChangeCb={value => onChangeFromDeliveryDate(value)}
                        onChange={value => {
                          /*due to required props*/
                        }}
                        onAccept={date => onAcceptDate(date, setFromDeliveryDate)}
                        value={fromDeliveryDate.value || null}
                        maxDate={toDeliveryDate.value || undefined}
                        inputValue={fromDeliveryDate.inputValue || ''}
                      />

                      <div style={{ margin: '10px 20px' }}>
                        <div>{t('myAccount.to')}</div>
                      </div>

                      <MdDatePicker
                        fullWidth
                        name="toDeliveryDate"
                        onChangeCb={value => onChangeToDeliveryDate(value)}
                        onChange={value => {
                          /*due to required props*/
                        }}
                        onAccept={date => onAcceptDate(date, setToDeliveryDate)}
                        value={toDeliveryDate.value || null}
                        minDate={fromDeliveryDate.value || undefined}
                        inputValue={toDeliveryDate.inputValue || ''}
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12" style={{ marginBottom: 30 }} />
                  <div className="col-6" />
                  <div className="col-6">
                    <div className="fieldItem fieldSubmit">
                      <div className="fieldItemInner">
                        <button
                          className="btnClearSearch"
                          onClick={() => clearSearch()}
                          disabled={[
                            orderIDSearch,
                            fromDate.inputValue,
                            toDate.inputValue,
                            toDeliveryDate.inputValue,
                            fromDeliveryDate.inputValue,
                            productCode,
                            customerReference,
                            !_.isEmpty(selectedStatus),
                          ].every(item => !item)}
                        >
                          {t('myAccount.clearAll')}
                        </button>
                        <button
                          type="submit"
                          onClick={() => onGetSearch()}
                          disabled={[
                            orderIDSearch,
                            fromDate.inputValue,
                            toDate.inputValue,
                            toDeliveryDate.inputValue,
                            fromDeliveryDate.inputValue,
                            productCode,
                            customerReference,
                            !_.isEmpty(selectedStatus),
                          ].every(item => !item)}
                          className="btnSearch btnWithArrow"
                        >
                          {!isLoading || isClearSearch ? (
                            `${t('myAccount.search')}`
                          ) : (
                            <>
                              <img src={loading} width={18} style={{ marginRight: '8px' }} /> {t('myAccount.searching')}
                            </>
                          )}
                          <span className="icnArrow">
                            <IoIosPlay />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            )}
          </div>
        )}
        {isLoadingOrder && queryParams.pageNumber == 1 ? (
          <IsLoading />
        ) : (
          <>
            {companyOrderList.length > 0 ? (
              <>
                {/*<InfiniteScroll*/}
                {/*dataLength={companyOrderList.length}*/}
                {/*next={loadMore}*/}
                {/*hasMore={hasMore}*/}
                {/*scrollThreshold={0.5}*/}
                {/*loader={<IsLoading/>}*/}
                {/*>*/}
                {renderTableOrderList()}

                {/*{isLoading && queryParams.pageNumber >1 &&*/}
                {/*<IsLoading/>*/}
                {/*}*/}
                {hasMore && (
                  <div className="btnFieldLoadmore">
                    <button
                      className={`btnLoadmore btnWithArrow ${isLoadingOrder ? 'loading' : ''}`}
                      type="button"
                      onClick={() => {
                        loadMore();
                      }}
                    >
                      {!isLoadingOrder ? (
                        `${t('myAccount.loadMore')}`
                      ) : (
                        <>
                          <img src={loading} width={18} style={{ marginRight: '8px' }} /> {t('myAccount.loading')}
                        </>
                      )}
                      <span className="icnArrow">
                        <IoIosPlay />
                      </span>
                    </button>
                  </div>
                )}

                {/*// </InfiniteScroll>*/}
              </>
            ) : (
              <div className="noOrder">{isSearch ? `${t('myAccount.noOrderFound')}` : `${t('myAccount.noOrder')}`}</div>
            )}
          </>
        )}
      </div>
      <ModalAlert message={errorMessage} onCloseModal={() => onCloseModal()} />

      <Modal
        dialogClassName="confirmToSelectCompany confirmToRedirect"
        show={showConfirmRedirect}
        scrollable={false}
        onHide={() => setShowConfirmRedirect(false)}
      >
        <Modal.Body className="modalBody">
          <div className="text-message line1">
            {t('confirmModal.msgConfirmChooseCompany1')}
            <br />
            {t('confirmModal.msgConfirmChooseCompany2')}
          </div>
          <div className="btnGroup">
            <button type="button" onClick={() => redirectToMyAccount()} className="btnCancel">
              {t('confirmModal.no')}
            </button>
            <button type="button" onClick={() => handleClickSwitch()} className="btnLogout">
              {t('confirmModal.yes')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function MyAccount({ callApiACT, setParams, match, location }: any) {
  const [activeTab, setActiveTab] = useState();

  const { t } = useTranslation();

  const tabs = {
    '/my-account/info': 1,
    '/my-account/order-history': 2,
    '/my-account/address-book': 3,
  };

  useEffect(() => {
    setActiveTab(tabs[location.pathname]);
  }, [location]);

  return (
    <>
      <div className="myAccountPage">
        <div className="myAccountPageInner">
          <Container>
            <Row>
              <div className="col-2"></div>
              <div className="col-8">
                {/*<div className='titlePage'>*/}
                {/*<h3 className='title'>My account</h3>*/}
                {/*</div>*/}
                <div className="tabContainer">
                  <div className="tab-buttons nav">
                    <Link to={`/my-account/info`} className={`${activeTab == 1 ? 'active' : ''}`}>
                      {t('myAccount.tabAccountInformation')}
                    </Link>
                    <Link to={`/my-account/order-history`} className={`${activeTab == 2 ? 'active' : ''}`}>
                      {t('myAccount.tabCompanyOrderHistory')}
                    </Link>
                    <Link to={`/my-account/address-book`} className={`${activeTab == 3 ? 'active' : ''}`}>
                      {t('myAccount.tabCompanyAddressBook')}
                    </Link>
                  </div>
                  <div className="tab-contents tab-content">
                    <div className="tab-content-item tab-pane active show">
                      {activeTab == 1 && (
                        <Route
                          exact
                          path="/my-account/info"
                          render={props => <AccountInfo callApiACT={callApiACT} />}
                        />
                      )}
                      {activeTab == 2 && (
                        <Route
                          exact
                          path="/my-account/order-history"
                          render={props => <OrderHistory callApiACT={callApiACT} />}
                        />
                      )}
                      {activeTab == 3 && (
                        <Route
                          exact
                          path="/my-account/address-book"
                          render={props => <AddressBook callApiACT={callApiACT} />}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2"></div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
    setParams: params => dispatch(setParams(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect(withTranslation()(MyAccount)));
