import { API_CALL_REQUEST, API_CALL_SUCCESS, API_CALL_FAILURE } from '../redux/callApiRDC';
import { SHOW_ALERT } from '../redux/showAlert';
import { put, takeEvery, call, all, fork, takeLatest } from 'redux-saga/effects';
import { ShowAlert } from '../redux/showAlert';
import { getDataAPI, postDataAPI, putDataAPI, deleteDataAPI } from '../services/callDataAPI';
import Lodash from 'lodash';
import * as Constants from '../constants';
import * as Utils from '../utils';

function* requestAPI(action) {
  let response;
  let errMessage;

  /**
   * you can create a function to mock data here. ex: const responseAPI = yield getProductList(isTestSuccess: true/false)
   */
  if (action.typeRequest === Constants.TypeRequest.GET) {
    const responseAPI = yield getDataAPI(action.url);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  } else if (action.typeRequest === Constants.TypeRequest.POST) {
    const responseAPI = yield postDataAPI(action.url, action.params);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  } else if (action.typeRequest === Constants.TypeRequest.PUT) {
    const responseAPI = yield putDataAPI(action.url, action.params);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  } else if (action.typeRequest === Constants.TypeRequest.DELETE) {
    const responseAPI = yield deleteDataAPI(action.url, action.params);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  }

  if (response) {
    let errorObject = Utils.getSafeValue(response, 'error', {});
    let errorCode = Utils.getSafeValue(errorObject, 'code', 0);

    if (Lodash.isEmpty(errorObject) || errorCode === 0) {
      yield put({
        ...action,
        type: API_CALL_SUCCESS,
        response,
      });
      action.resolve(response);
      if (action.isToastSuccess === true) {
        yield put(ShowAlert({ type: 'success', messages: action.toastMessageSuccess }));
      }
    } else {
      let errorMessage = Utils.getSafeValue(errorObject, 'message', Constants.ErrMessage.systemError);

      yield put({
        ...action,
        type: API_CALL_FAILURE,
        errorMessage,
      });
      if (action.isToastErr === true) {
        yield put(
          ShowAlert({ type: 'error', messages: errorMessage, autoClose: action.autoCLoseErrorMessage || 600000 })
        );
      }
      action.reject(errorMessage);
    }
  } else {
    yield put({
      ...action,
      type: API_CALL_FAILURE,
      errMessage,
    });

    action.reject(errMessage);
  }
}

function* watcherSaga() {
  yield takeEvery(API_CALL_REQUEST, requestAPI);
}

export default watcherSaga;
