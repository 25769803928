import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

interface Props {
  number?: number;
}

export const ListProductSkeleton = ({ number }: Props) => {
  let num = number ? number : 10;

  return (
    <tbody>
      {_.times(num, i => (
        <tr key={i}>
          <td colSpan={1000}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="rect" width={70} height={70} />
              <div style={{ marginLeft: '10px', width: '100%' }}>
                <Skeleton variant="text" width="100%" height={40} />
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
