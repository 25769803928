import React, { useEffect, useState, useReducer } from 'react';
import { Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import './style.scss';
import * as AppURL from '../../services/urlAPI';
import * as Utils from '../../utils';
import * as Constants from '../../constants';
import localStorage from '../../utils/LocalStorage';
import { callApiACT } from '../../redux/callApiRDC';
import { reducerNameHOR } from '../../redux';
import TableProductList from '../tableListProduct';
import PopupOrder from '../popupOrder';
import { IoIosArrowBack, IoIosSearch } from 'react-icons/io';
import { LoadingScreen } from '../../components/loadingScreen';

interface Sort {
  value?: string;
  label?: string;
  direction?: string;
  sortBy?: string;
  selected?: boolean;
}

export interface QueryParams {
  pageSize: number;
  pageNumber: number;
  category?: any;
}

function SearchPage({ callApiACT, match, appConfig }) {
  const [recentSearch, setRecentSearch] = useState<any>([]);
  const [showResult, setShowResult] = useState<boolean>(false);

  const [query, setQuery] = useState('');
  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [popupOrderModal, setPopupOrderModal] = useState(false);

  const [selectSort, setSelectSort] = useState<Sort>({});
  const [selectSorts, setSelectSorts] = useState([{}]);
  const [sortList, setSortList] = useState([]);
  const [pagination, setPagination] = useState({ null: true });
  const [queryParams, setQueryParams] = useState<QueryParams>({
    pageSize: 20,
    pageNumber: 1,
  });
  const [showRecentSearch, setShowRecentSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [listSuggestions, setListSuggestions] = useState([]);
  const [matchingCategories, setMatchingCategories] = useState<any>([]);

  useEffect(() => {
    const _recentSearch = localStorage.getObject('recentSearch', []);
    setRecentSearch(_recentSearch);
  }, []);

  const onChangeSort = value => {
    setSelectSort(value);
    updateQueryParams('pageNumber', 1);
  };

  useEffect(() => {
    if (queryParams.pageNumber == 1) {
      setProducts([]);
    }
    searchProduct(query);
  }, [queryParams]);

  useEffect(() => {
    if (sortList) {
      let _selectSorts = _.map(sortList, (item, index) => {
        return {
          value: `${item['sortBy']}_${item['direction']}`,
          label: item['display'],
          direction: item['direction'],
          sortBy: item['sortBy'],
          selected: item['selected'],
        };
      });
      setSelectSorts(_selectSorts);
      //set default sort
      let sortByBestSelling = _.find(_selectSorts, s => s.selected == true);
      sortByBestSelling && setSelectSort(sortByBestSelling);
    }
  }, [sortList]);

  const columns = [
    {
      name: '',
      selector: '',
      cell: row => (
        <div className="image-tiny">
          {_.map(row.images, (item, index) => {
            if (item.isThumbnail === true) {
              return <Image key={index} src={item.urlThumbnail} />;
            }
          })}
        </div>
      ),
      className: 'colImage',
      width: '100px',
    },
    {
      name: 'Code',
      selector: 'id',
      className: 'colID',
      width: '100px',
    },
    {
      name: 'Product Name',
      selector: 'name',
      className: 'colProductName',
      cell: row => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setProduct(row);
            setPopupOrderModal(true);
          }}
        >
          {row['name']}
        </div>
      ),
      width: '43%',
    },
    {
      name: 'Colour',
      selector: 'colour',
      cell: row => (
        <div>
          {_.map(row.optionDisplay['Color'], (item, index) => {
            return (
              <div key={index} className="item-color" style={{ marginRight: '5px' }}>
                {_.map(item['colors'], (itemColor, indexColor) => {
                  return (
                    <span
                      key={indexColor}
                      style={{
                        backgroundColor: itemColor,
                      }}
                    ></span>
                  );
                })}
              </div>
            );
          })}
        </div>
      ),
      width: '19%',
      className: 'colColor',
    },
    {
      name: 'Size',
      selector: '',
      cell: row => {
        let listSize = _.map(row.optionDisplay['Size'], (item, index) => {
          return item['label'];
        }).join(', ');
        return <div style={{ maxWidth: '100px', minWidth: '100px' }}>{listSize}</div>;
      },
      className: 'colSize',
      width: '19%',
    },
  ];

  const onKeyUp = _.debounce((key, query) => {
    if (query !== '') {
      setShowRecentSearch(false);
      if (key !== 'Enter') {
        if (query.length >= 2) {
          getSearchSuggestion(query);
        }
      } else {
        searchQuery(query);
      }
    } else {
      setListSuggestions([]);
      setMatchingCategories([]);
      setShowRecentSearch(true);
    }
  }, 200);

  const searchQuery = query => {
    setShowResult(true);
    setQuery(query);
    setProducts([]);
    searchProduct(query);
  };

  const clearQuery = () => {
    setRecentSearch([]);
    localStorage.setObject('recentSearch', []);
  };

  const updateQueryParams = (field, value) => {
    setQueryParams({
      ...queryParams,
      [field]: value,
    });
  };

  const loadMore = () => {
    updateQueryParams('pageNumber', queryParams.pageNumber + 1);
  };

  const findMatchingCategory = query => {
    if (appConfig.response && appConfig.response.appData) {
      const catalogModules = _.filter(appConfig.response.appData.modules, { moduleType: 'ECOMMERCE_CATALOG' });
      const categories = catalogModules.map(m => ({
        ...m,
        moduleMetaData: {
          ...m.moduleMetaData,
          data: JSON.parse(m.moduleMetaData.data || null),
        },
      }));
      const _matchingCategories = _.filter(
        categories,
        cat => cat.moduleName && cat.moduleName.toLowerCase().includes(query.toLowerCase())
      );
      setMatchingCategories(_matchingCategories);
    }
  };

  const getSearchSuggestion = async query => {
    findMatchingCategory(query);
    let appKey = localStorage.get('appKey', '');
    let deviceKey = localStorage.get('deviceKey', '');
    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.POST,
        name: reducerNameHOR.searchSuggestion,
        url: AppURL.searchSuggestion,
        params: { appKey, deviceKey, query },
        isToastErr: false,
        isToastSuccess: false,
      });
      data.keywords && setListSuggestions(data.keywords);
    } catch (error) {}
  };

  const searchProduct = async query => {
    setIsLoading(true);
    let appKey = localStorage.get('appKey', '');
    let deviceKey = localStorage.get('deviceKey', '');
    let params = {
      pageNumber: queryParams.pageNumber,
      pageSize: queryParams.pageSize,
      appTypeCode: 'bcm',
      sortBy: selectSort.sortBy,
      sortDirection: selectSort.direction,
    };
    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.POST,
        name: reducerNameHOR.listProductSearch,
        url: Utils.replaceStrUrl(AppURL.searchProduct, appKey),
        params: { appKey, deviceKey, query, ...params },
        isToastErr: false,
        isToastSuccess: false,
      });
      if (data) {
        getDataProduct(data);
        if (query.length >= 2) {
          let _recentSearch = _.uniq([query, ...recentSearch]);
          setRecentSearch(_recentSearch);
          localStorage.setObject('recentSearch', _recentSearch);
        }
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const getDataProduct = dataProducts => {
    let _currProduct = queryParams.pageNumber == 1 ? [] : [...products];
    setProducts(_currProduct.concat(Utils.getSafeValue(dataProducts, 'products', [])));
    let meta = Utils.getSafeValue(dataProducts, 'meta', {});
    if (!sortList.length) {
      setSortList(Utils.getSafeValue(meta, 'sortList', []));
    }
    setPagination(Utils.getSafeValue(meta, 'pagination', {}));
  };

  const saveRecentSearchMatchCategory = item => {
    let objectLink = {
      linkItem: '/business/' + item.moduleMetaData.data.categoryId,
      nameItem: item.moduleName,
    };
    let matchCategory = _.uniq([objectLink, ...recentSearch]);
    localStorage.setObject('recentSearch', matchCategory);
  };

  return (
    <>
      <div className="searchPage">
        <div className="headSearchPage">
          {!showResult && (
            <div className="headerSearchInput">
              <button className="btnCancelSearch" onClick={() => window.history.back()}>
                Cancel
              </button>
              {/*<Link className="btnCancelSearch" to={`/`}>Cancel</Link>*/}
              <input
                className="txt_input"
                placeholder="Search Product"
                onKeyUp={(e: any) => onKeyUp(e.key, e.target.value)}
                defaultValue={query}
              />
            </div>
          )}
          {showResult && (
            <div className="headerSearchResult">
              <a
                className="btnBack"
                onClick={() => {
                  setShowResult(false);
                  setListSuggestions([]);
                  setMatchingCategories([]);
                }}
              >
                <IoIosArrowBack />
                Back
              </a>
              <div className="textResult truncate-text">
                <span>Search result for "{query}"</span>
              </div>
            </div>
          )}
        </div>
        <div className={`bodySearchPage ${showResult && 'showResult'}`}>
          {!showResult && (
            <>
              <div className="queryBox">
                <ul className="query-list">
                  {listSuggestions.map((q, index) => (
                    <li key={index}>
                      <a className="truncate-text" onClick={() => searchQuery(q)}>
                        {q}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {/*MATCHING CATEGORIES*/}
              {matchingCategories && matchingCategories.length > 0 && (
                <div className="queryBox">
                  <div className="title">Matching Categories</div>
                  <ul className="query-list">
                    {matchingCategories.map((c, index) => (
                      <li key={index}>
                        <Link
                          className="cursor-pointer truncate-text"
                          onClick={() => saveRecentSearchMatchCategory(c)}
                          to={`/business/${c.moduleMetaData.data.categoryId}`}
                        >
                          {c.moduleName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/*RECENT SEARCH*/}
              {showRecentSearch && recentSearch && recentSearch.length > 0 ? (
                <div className="queryBox">
                  <div className="title">
                    Search History
                    <button className="btnClearSearch" type="button" onClick={clearQuery}>
                      Clear All
                    </button>
                  </div>
                  <ul className="query-list">
                    {recentSearch.map((q, index) => (
                      <>
                        {q.linkItem ? (
                          <li key={index}>
                            <a className="truncate-text" href={q.linkItem}>
                              {q.nameItem}
                            </a>
                          </li>
                        ) : (
                          <li key={index}>
                            <a className="truncate-text" onClick={() => searchQuery(q)}>
                              {q}
                            </a>
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                </div>
              ) : (
                <>
                  <div className="noRecentSearch">
                    <div className="icnSearch">
                      <IoIosSearch />
                    </div>
                    <div>No Recent Search</div>
                  </div>
                </>
              )}
            </>
          )}
          {showResult && (
            <>
              {isLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  <div className="wr-sort">
                    <span className="labelSort">Sort by:</span>
                    <Select
                      className="selectSort"
                      classNamePrefix={'selectSort'}
                      inputProps={{ readOnly: true }}
                      name="display"
                      options={selectSorts}
                      isSearchable={false}
                      value={selectSort}
                      onChange={value => onChangeSort(value)}
                    />
                  </div>
                  {/*{products.length > 0 ? (*/}
                  {/*<TableProductList*/}
                  {/*columns={columns}*/}
                  {/*data={products}*/}
                  {/*expand={false}*/}
                  {/*isLoading={isLoading}*/}
                  {/*pagination={pagination}*/}
                  {/*match={match}*/}
                  {/*loadMore={loadMore}*/}
                  {/*infiniteScroll={true}*/}
                  {/*/>*/}
                  {/*) : (*/}
                  {/*<div className="noSearchResult">No Item Found</div>*/}
                  {/*)}*/}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {product != null && (
        <PopupOrder
          product={product}
          setProduct={setProduct}
          popupOrderModal={popupOrderModal}
          setPopupOrderModal={setPopupOrderModal}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SearchPage);
