import { reducerNameHOR } from '.';

export const CHANGE_LOADING_ADDRESS = 'CHANGE_LOADING_ADDRESS';

const initialState = {
  detectChange: false,
  detectError: false,
};
export function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOADING_ADDRESS:
      return {
        detectChange: action.isSuccess ? !state.detectChange : state.detectChange,
        detectError: action.isSuccess ? state.detectError : !state.detectError,
      };
    default:
      return state;
  }
}

export function changeLoadingAddress(isSuccess) {
  return {
    type: CHANGE_LOADING_ADDRESS,
    isSuccess,
  };
}

export default reducer;
