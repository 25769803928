import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './style.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as Utils from '../../utils';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { backTop } from '../../utils';

function StaticPage({ appConfig, match }) {
  const [appModules, setAppModules] = useState();
  const [module, setModule] = useState<any>({});
  const [matchModule, setMatchModule] = useState<any>({});

  useEffect(() => {
    backTop();
  }, []);

  useEffect(() => {
    if (appConfig) {
      let modules = Utils.getSafeValue(appConfig.response['appData'], 'modules', []);
      const modulesData = modules.map(m => ({
        id: m._id,
        moduleMetaData: {
          ...m.moduleMetaData,
          data: JSON.parse(m.moduleMetaData.data || null),
        },
      }));
      setAppModules(modulesData);
    }
  }, [appConfig]);

  useEffect(() => {
    let modules = Utils.getSafeValue(appConfig.response['appData'], 'modules', []);
    let matchModule = _.find(modules, (m: any) => m._id == match.params.name);
    setMatchModule(matchModule);

    if (appModules) {
      const category = _.find(appModules, (m: any) => m.id == match.params.name);
      setModule(category);
    }
  }, [match, appModules]);

  return (
    <div className="infoPage">
      <Container>
        <Row>
          <div className="col-2"></div>
          <div className="col-8">
            <div className="titlePage">
              {matchModule && <div className="title">{matchModule.moduleName}</div>}
              {module && module.moduleMetaData && module.moduleMetaData.data && (
                <div className="socialLink">
                  {module.moduleMetaData.data.instagram && module.moduleMetaData.data.instagram.url && (
                    <a
                      className="iconSocial iconInstagram"
                      target="_blank"
                      href={module.moduleMetaData.data.instagram.url}
                    >
                      &nbsp;
                    </a>
                  )}
                  {module.moduleMetaData.data.twitter && (
                    <a className="iconSocial iconTwitter" target="_blank" href={module.moduleMetaData.data.twitter}>
                      &nbsp;
                    </a>
                  )}
                  {module.moduleMetaData.data.facebook && (
                    <a className="iconSocial iconFacebook" target="_blank" href={module.moduleMetaData.data.facebook}>
                      &nbsp;
                    </a>
                  )}
                  {module.moduleMetaData.data.website && (
                    <a className="iconSocial iconWebsite" target="_blank" href={module.moduleMetaData.data.website}>
                      &nbsp;
                    </a>
                  )}
                  {module.moduleMetaData.data.email && (
                    <a className="iconSocial iconEmail" href={`mailto: ${module.moduleMetaData.data.email}`}>
                      &nbsp;
                    </a>
                  )}
                  {module.moduleMetaData.data.phone && (
                    <a className="iconSocial iconPhone" href={`tel: ${module.moduleMetaData.data.phone}`}>
                      &nbsp;
                    </a>
                  )}
                </div>
              )}
            </div>

            <div
              className="contentInfoPage"
              dangerouslySetInnerHTML={{
                __html: module && module.moduleMetaData ? module.moduleMetaData.data.content : '',
              }}
            ></div>
          </div>
          <div className="col-2"></div>
        </Row>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
  };
}

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(StaticPage);
