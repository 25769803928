import { reducerNameHOR } from '.';
import * as Utils from '../utils';
import * as CartConvert from '../utils/CartConvert';
import { CHANGE_CART_COUNT } from './getCartCountRDC';
import { Product } from '../constants/productConstant';
export const API_CALL_REQUEST_CART = 'API_CALL_REQUEST_CART';
export const API_CALL_SUCCESS_CART = 'API_CALL_SUCCESS_CART';
export const API_CALL_FAILURE_CART = 'API_CALL_FAILURE_CART';
export const SET_LOADING = 'SET_LOADING';
export const SET_COUPOUN_DATA_ERROR = 'SET_COUPOUN_DATA_ERROR';
export const CLEAR_CART = 'CLEAR_CART';

const initialState = {
  cart: { items: [] },
  couponData: null,
  isFetching: true,
  refreshing: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_REQUEST_CART:
      return action.isFirstTime && !action.refreshing
        ? { ...state, isFetching: true, refreshing: action.refreshing }
        : { ...state, refreshing: action.refreshing };
    case SET_LOADING: {
      let { id, loading } = action;
      let items = state.cart.items.map(
        item =>
          // item.id === id ? { ...item, skeleton: loading } :
          item
      );
      let cart = { ...state.cart, items };
      return {
        ...state,
        cart,
      };
    }
    case SET_COUPOUN_DATA_ERROR:
      return { ...state, couponData: { gotError: true } };
    case API_CALL_SUCCESS_CART: {
      let cart = Utils.getSafeValue(action.response, 'cart', {});
      let cartPrices = Utils.getSafeValue(cart, 'cartPrices', []);
      let couponCode = Utils.getSafeValue(cart, 'couponCode', '');
      let currencyCode = Utils.getSafeValue(cart, 'currencyCode', '');
      let items = Utils.getSafeValue(cart, 'items', []);

      return {
        cart: converCart(cart, currencyCode),
        couponData: getCouponCodeInfomation(cartPrices, couponCode, currencyCode),
        isFetching: action.isFirstTime ? false : state.isFetching,
        isError: checkErrorCart(items),
        refreshing: false,
      };
    }
    case API_CALL_FAILURE_CART:
      return {
        ...state,
        isFetching: false,
        refreshing: false,
      };

    case CLEAR_CART:
      return { ...initialState, isFetching: false };
    default:
      return state;
  }
}

export function clearCart() {
  return dispatch => {
    dispatch({
      type: CHANGE_CART_COUNT,
      count: 0,
      name: reducerNameHOR.getCartCount,
    });
    dispatch({
      type: CLEAR_CART,
      name: reducerNameHOR.getCartList,
    });
  };
}

export function converCart(cart, currencyCode) {
  let currency = Utils.getSafeValue(cart, 'currencyCode', '');
  if (!currencyCode) {
    currencyCode = currency;
  }
  let cartItems = Utils.getSafeValue(cart, 'items', []);
  let items = cartItems.map(item => {
    let product = Utils.getSafeValue(item, 'product', {});
    let type = Utils.getSafeValue(product, 'type', '');
    let explandView = [];
    switch (type) {
      case Product.ProductType.PRODUCT_TYPE_CONFIGURABLE:
        return CartConvert.convertToConfigurable(item, currencyCode);
      case Product.ProductType.PRODUCT_TYPE_BUNDLE:
        return CartConvert.convertToBundel(item, currencyCode);
      case Product.ProductType.PRODUCT_TYPE_SIMPLE:
        return CartConvert.convertToSimple(item, currencyCode);
      case Product.ProductType.PRODUCT_TYPE_CONFIGURABLE_SCP:
        return CartConvert.convertToConfigurable(item, currencyCode);
      default:
        return CartConvert.convertToSimple(item, currencyCode);
    }

    return items;
  });
  let cartPricesConvert = CartConvert.convertCartPrices(cart);
  let orderTotal = CartConvert.getOrderTotal(cart);
  return { ...cart, items, orderTotal, cartPricesConvert };
}

function getCouponCodeInfomation(cartPrices, couponCode, currencyCode) {
  let couponData: any = null;
  if (cartPrices && cartPrices.length > 0) {
    cartPrices.forEach(element => {
      if (element.code === 'discount') {
        couponData = {
          code: couponCode,
          price: Utils.convertCurrency(element.price, currencyCode),
          gotError: false,
        };
      }
    });
  }
  return couponData;
}

function checkErrorCart(items) {
  let error = false;
  items.forEach(item => {
    if (item.hasError) {
      let list = Utils.getSafeValue(item, 'hasMessages', []);
      for (const el of list) {
        if (el.type === 2) {
          error = true;
        }
      }
    }
  });
  return error;
}
export function callApiListCart(action) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        ...action,
        type: API_CALL_REQUEST_CART,
        notRequestCrossSell: action.notRequestCrossSell || false,
        url: action.url || '',
        isFirstTime: action.isFirstTime || false,
        isFetching: action.isFetching || true,
        isToastErr: action.isToastErr || true,
        refreshing: action.refreshing || false,
        name: reducerNameHOR.getCartList,
        params: action.params || {},
        typeRequest: action.typeRequest || 'GET',
        analyticObj: action.analyticObj || {},
        toastMessageSuccess: action.toastMessageSuccess || '',
        isToastSuccess: action.isToastSuccess || false,
        resolve,
        reject,
      });
    });
  };
}
export function setLoading(id, loading = true) {
  return {
    type: SET_LOADING,
    id,
    loading,
    name: reducerNameHOR.getCartList,
  };
}
export function setCouponData() {
  return {
    type: SET_COUPOUN_DATA_ERROR,
    name: reducerNameHOR.getCartList,
  };
}
export default reducer;
