import React, { Children, useState, useEffect } from 'react';

const defaultState = {
  showPrompt: null,
  setPrompt: any => {},
};

const AppContext = React.createContext(defaultState);

function AppProvider(props) {
  const [showPrompt, setShowPrompt] = useState(defaultState.showPrompt);

  const setPrompt = value => {
    setShowPrompt(value);
  };

  return (
    <AppContext.Provider value={{ showPrompt, setPrompt }}>{Children.toArray(props.children)}</AppContext.Provider>
  );
}

export { AppContext, AppProvider };
