import { reducerNameHOR } from '.';
import produce from 'immer';

export const SHOW_ALERT = 'SHOW_ALERT';

export const initialState = {
  messages: null,
};
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SHOW_ALERT:
        draft.messages = action.params;
        break;
    }
  });

export function ShowAlert(params) {
  // console.log(params);
  return {
    type: SHOW_ALERT,
    params,
    name: reducerNameHOR.showAlert,
  };
}

export default reducer;
