import { reducerNameHOR } from '.';
// import { RoutesName } from "../Config/Routes";

export const CHANGE_FOCUS_EDIT_PROFILE = 'CHANGE_FOCUS_EDIT_PROFILE';
export const OPEN_MODAL_EDIT_PROFILE = 'OPEN_MODAL_EDIT_PROFILE';
export const CLOSE_MODAL_EDIT_PROFILE = 'CLOSE_MODAL_EDIT_PROFILE';
const initialState = {
  isFocusEditProfile: false,
  visibleModal: false,
  isBackPress: false,
  route: 'HomeStack',
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL_EDIT_PROFILE:
      return {
        ...state,
        isBackPress: action.isBackPress,
        // route: action.route || state.route,
        visibleModal: true,
      };
    case CLOSE_MODAL_EDIT_PROFILE:
      return {
        ...state,
        visibleModal: false,
      };
    case CHANGE_FOCUS_EDIT_PROFILE:
      return {
        ...state,
        isFocusEditProfile: action.isFocusEditProfile,
      };
    default:
      return state;
  }
}

export function closeModalEditProfile() {
  return {
    type: CLOSE_MODAL_EDIT_PROFILE,
  };
}
export function openModalEditProfile(action) {
  return {
    type: OPEN_MODAL_EDIT_PROFILE,
    isBackPress: action.isBackPress,
    // route: action.route
  };
}
export function changeFocusEditProfile(isFocusEditProfile) {
  return {
    type: CHANGE_FOCUS_EDIT_PROFILE,
    isFocusEditProfile,
  };
}

export default reducer;
