import React, { useEffect, useState, memo } from 'react';
import './../style.scss';
import { useForm } from 'react-hook-form';
import { Button, Container, Form, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from '../../../components/CustomSelect';
import localStorage from '../../../utils/LocalStorage';
import { compose } from 'redux';
import { callApiACT } from '../../../redux/callApiRDC';

import { TypeRequest } from '../../../constants';
import * as AppURL from '../../../services/urlAPI';
import * as Utils from '../../../utils';
import * as Constants from '../../../constants';
import { reducerNameHOR } from '../../../redux';
import _ from 'lodash';
import Modal from 'react-bootstrap/esm/Modal';
import InputWithCounter from '../../../components/InputWithCounter';
import { mapFieldCodes } from './BillingInformation';
import { useTranslation, withTranslation } from 'react-i18next';

interface Props {
  isCreateAddress: boolean;
  setIsCreateAddress: (action) => void;
  onCreateAddress: (action) => void;
  selectedAddressEdit: any;
  onCancel: (action) => void;
  callApiACT: (action) => void;
  addressFields: any;
}

function AddressForm({
  isCreateAddress,
  onCreateAddress,
  selectedAddressEdit,
  onCancel,
  setIsCreateAddress,
  callApiACT,
  addressFields,
}: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [CAcountries, CAsetCountries] = useState([]);
  const [CAcountry, CAsetCountry] = useState();
  const [CAstates, CAsetStates] = useState([]);
  const [CAstate, CAsetState] = useState();
  const methodsCreateAddress = useForm();
  //methodsCreateAddress.register({name: 'country'}, {required: 'Country is required'});
  //methodsCreateAddress.register({name: 'state'}, {});
  methodsCreateAddress.register({ name: 'id' }, {});

  const { t } = useTranslation();

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    const countryField = _.find(addressFields, { type: 'country-list' });
    const stateField = _.find(addressFields, { type: 'state-list' });
    countryField &&
      methodsCreateAddress.register(
        { name: countryField.code },
        { ...(countryField.required ? { required: `${countryField.displayName} is required` } : {}) }
      );
    stateField &&
      methodsCreateAddress.register(
        { name: stateField.code },
        { ...(stateField.required ? { required: `${stateField.displayName} is required` } : {}) }
      );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedAddressEdit)) {
      const data = {};
      _.forEach(mapFieldCodes, (v, k) => {
        data[v] = selectedAddressEdit[k];
      });
      const countryField = _.find(addressFields, { type: 'country-list' });
      const country = {
        value: selectedAddressEdit.countryId,
        label: selectedAddressEdit.country,
        code: selectedAddressEdit.countryIso2,
      };
      const state = _.isObject(selectedAddressEdit.state)
        ? { value: selectedAddressEdit.state, label: selectedAddressEdit.state }
        : selectedAddressEdit.state;
      methodsCreateAddress.reset(data);
      methodsCreateAddress.setValue(countryField.code, country);
      methodsCreateAddress.setValue('id', selectedAddressEdit.id);
      CAsetCountry(country);
      getDefaultState(country);
    } else {
      if (addressFields) {
        addressFields.map((field, index) => {
          if (field.type == 'text-field') {
            methodsCreateAddress.setValue(field.code, field.value);
          }
        });
      }
    }
  }, [selectedAddressEdit]);

  const getDefaultState = async country => {
    if (country) {
      const stateOptions = await getState([selectedAddressEdit.countryId]);
      const stateField = _.find(addressFields, { type: 'state-list' });
      let state;
      if (stateOptions.length > 0) {
        state = { value: selectedAddressEdit.state, label: selectedAddressEdit.state };
        methodsCreateAddress.setValue(stateField.code, state['label']);
      } else {
        state = selectedAddressEdit.state;
        methodsCreateAddress.setValue(stateField.code, state);
      }
      CAsetState(state);
    }
  };

  const getCountry = async () => {
    let appKey = localStorage.get(Constants.KeyAsyncStore.appKey, '');
    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.GET,
        url: Utils.replaceStrUrl(AppURL.getCountry, appKey),
      });
      let countries = Utils.getSafeValue(data, 'countries', []);
      let countryOptions: any = _.map(countries, (item, index) => {
        return {
          label: item.country,
          value: item.id,
          code: item.countryIso2,
        };
      });

      CAsetCountries(countryOptions);
    } catch (e) {
      console.log(e);
    }
  };

  const getState = async params => {
    try {
      let appKey = localStorage.get(Constants.KeyAsyncStore.appKey, '');
      let deviceKey = localStorage.get(Constants.KeyAsyncStore.deviceKey, '');
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: Utils.replaceStrUrl(AppURL.getState, params),
        name: reducerNameHOR.getRegionList,
        params: {
          deviceKey: deviceKey,
          appKey: appKey,
        },
      });

      let states = Utils.getSafeValue(data, 'states', []);
      let stateOptions: any = _.map(states, (item, index) => {
        return {
          label: item.state,
          value: item.id,
        };
      });
      const stateField = _.find(addressFields, { type: 'state-list' });
      methodsCreateAddress.unregister(stateField.code);
      let inputStateOptions = stateOptions.length > 0 ? { required: `${stateField.displayName} is required` } : {};
      if (stateOptions.length > 0) {
        methodsCreateAddress.register({ name: stateField.code }, inputStateOptions);
      }

      CAsetStates(stateOptions);
      return stateOptions;
    } catch (e) {}
  };

  const onChangeCountry = async value => {
    setIsLoading(true);
    CAsetCountry(value);
    const countryField = _.find(addressFields, { type: 'country-list' });
    methodsCreateAddress.setValue(countryField.code, value, { shouldValidate: true });
    CAsetState({});

    let params = [value.value];
    let states = await getState(params);
    setIsLoading(false);
  };

  const hideModal = () => {
    setIsCreateAddress(false);
  };

  return (
    <>
      <Modal dialogClassName="dialogEditAddress" size="lg" show={isCreateAddress}>
        <Modal.Body className="modalBody">
          <div className="form-create-address">
            <Form onSubmit={methodsCreateAddress.handleSubmit(onCreateAddress)}>
              <div className="formTitleBlock">
                <h3 className="formTitle">{t('checkout.shippingAddress')}</h3>
                <div className="noteRequired">{t('checkout.requiredFields')}</div>
              </div>

              <div className="formFields">
                <div className="fields">
                  <div className="row">
                    {addressFields &&
                      addressFields.map((field, index) => {
                        const inputOptions: any = {};
                        field.required && (inputOptions.required = `${field.displayName} is required`);
                        field.maxLength && (inputOptions.maxLength = field.maxLength);
                        const showAsterisk = field => {
                          if (field.type == 'state-list' && !_.isEmpty(CAcountry) && !CAstates.length) return false;
                          return field.required;
                        };
                        // methodsCreateAddress.setValue(field.code, field.value);
                        return (
                          <div className="col-6" key={index}>
                            <div className="field">
                              <div className="fieldLbl">
                                {field.displayName} {showAsterisk(field) && <>*</>}
                              </div>
                              <div className="fieldValue">
                                {field.type == 'text-field' && (
                                  <>
                                    <InputWithCounter
                                      {...(field.maxLength ? { maxLength: field.maxLength } : {})}
                                      className={
                                        'txt_text ' + (methodsCreateAddress.errors[field.code] ? 'invalid' : '')
                                      }
                                      type={`${field.code === 'phone-number' ? 'tel' : 'text'}`}
                                      name={field.code}
                                      onFocus={() => methodsCreateAddress.clearErrors(field.code)}
                                      methods={methodsCreateAddress}
                                      inputOptions={inputOptions}
                                    />
                                    {/*
                                                                    // @ts-ignore */}
                                    {methodsCreateAddress.errors && methodsCreateAddress.errors[field.code] && (
                                      <div className="text-danger">
                                        {Utils.getSafeValue(methodsCreateAddress, `errors[${field.code}].message`, '')}
                                      </div>
                                    )}
                                  </>
                                )}
                                {field.type == 'country-list' && (
                                  <>
                                    <Select
                                      isSearchable
                                      options={CAcountries}
                                      name={field.code}
                                      className={
                                        'txt_select ' +
                                        (methodsCreateAddress.errors && methodsCreateAddress.errors[field.code]
                                          ? 'invalid'
                                          : '')
                                      }
                                      classNamePrefix="selectAddress"
                                      value={CAcountry}
                                      onChange={value => {
                                        onChangeCountry(value);
                                      }}
                                    />
                                    {/*
                                                                    // @ts-ignore */}
                                    {methodsCreateAddress.errors && methodsCreateAddress.errors[field.code] && (
                                      <div className="text-danger">
                                        {Utils.getSafeValue(methodsCreateAddress, `errors[${field.code}].message`, '')}
                                      </div>
                                    )}
                                  </>
                                )}
                                {field.type == 'state-list' && CAstates.length > 0 && (
                                  <>
                                    <Select
                                      isSearchable
                                      className={`txt_select ${
                                        methodsCreateAddress.errors && methodsCreateAddress.errors[field.code]
                                          ? 'invalid'
                                          : ''
                                      }`}
                                      classNamePrefix="selectAddress"
                                      noOptionsMessage={() => {
                                        if (CAstates.length === 0) {
                                          return 'No option';
                                        }
                                        return null;
                                      }}
                                      options={CAstates}
                                      name={field.code}
                                      value={CAstate}
                                      onChange={value => {
                                        CAsetState(value);
                                        methodsCreateAddress.setValue(
                                          field.code,
                                          Utils.getSafeValue(value, 'label', ''),
                                          { shouldValidate: true }
                                        );
                                      }}
                                    />
                                    {/*
                                                                    // @ts-ignore */}
                                    {methodsCreateAddress.errors && methodsCreateAddress.errors[field.code] && (
                                      <div className="text-danger">
                                        {Utils.getSafeValue(methodsCreateAddress, `errors[${field.code}].message`, '')}
                                      </div>
                                    )}
                                  </>
                                )}
                                {field.type == 'state-list' && !CAstates.length && (
                                  <>
                                    <InputWithCounter
                                      {...(field.maxLength ? { maxLength: field.maxLength } : {})}
                                      className={`txt_text ${methodsCreateAddress.errors[field.code] ? 'invalid' : ''}`}
                                      type="text"
                                      name={field.code}
                                      onFocus={() => methodsCreateAddress.clearErrors(field.code)}
                                      methods={methodsCreateAddress}
                                      inputOptions={{ ...inputOptions.maxLength }}
                                    />
                                    {/*
                                                                    // @ts-ignore */}
                                    {methodsCreateAddress.errors && methodsCreateAddress.errors[field.code] && (
                                      <div className="text-danger">
                                        {Utils.getSafeValue(methodsCreateAddress, `errors[${field.code}].message`, '')}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="col-12">
                      <div className="field">
                        <div className="fieldValue">
                          <div className="fieldGroupBtn">
                            <button className="btnCancel" onClick={() => hideModal()}>
                              {t('checkout.discard')}
                            </button>
                            <button type="submit" className="btnSave">
                              {typeof selectedAddressEdit['id'] === 'undefined'
                                ? `${t('checkout.done')}`
                                : `${t('checkout.saveChanges')}`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
// export default compose(withConnect)(AddressForm);
export default compose(withConnect(withTranslation()(AddressForm)));
