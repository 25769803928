import { KeyAsyncStore } from 'src/constants';

// export const baseUrl = "https://b2b-business.jmango.vn";
export const baseUrl = localStorage.getItem(KeyAsyncStore.baseUrl) || 'https://b2b-business.kuber.jmango.vn';
export const DEV_REGISTER_SERVER = process.env.REACT_APP_DEV_REGISTER || 'https://dev-register.jmango360.com/endpoints';

export const loginUser = '/ecom/bcm/customer/login';
export const getProductsList = '/ecom/bcm/products';
export const getProductsDetails = '/ecom/bcm/products/details';
export const getOrderDetails = '/ecom/bcm/order/get/%s';
export const getCategoriesTree = '/ecom/bcm/categories';
export const getAppConfig = '/common/bcm/settings?appKey=%s';
export const createCart = '/ecom/bcm/carts/create';
export const addProductToExistCart = '/ecom/bcm/carts/%s/items';
export const getCart = '/ecom/bcm/carts?appKey=%s&deviceKey=%s&cartId=%s';
export const getCartGroupItem = '/ecom/bcm/carts/groupItems?appKey=%s&deviceKey=%s&cartId=%s';
export const editQuantity = '/ecom/bcm/carts/%s/items/%s/qty';
export const deleteCartItem = '/ecom/bcm/carts/%s/items/%s';
export const deleteCartItems = '/ecom/bcm/carts/%s/items';
export const editCart = '/ecom/bcm/carts/%s/items/update';
export const searchProduct = '/ecom/bcm/products/search';
export const changeForgotPassword = '/b2b-user/users/change/password';
export const requestEmailForgotPassword = '/b2b-user/users/forgot/password';
export const verifyTokenForgotPassword = '/b2b-user/users/forgot/password/validate';
export const getFilter = '/ecom/bcm/products/getCount';
export const importBulkOrder = '/ecom/bcm/import/bulk-orders';

export const loginAsCompany = '/b2b-user/users/loginAsCompany';
export const loginAsGuest = '/b2b-user/users/loginAgentOnly';
// export const getCompaniesAssignedToSale = "/b2b-user/users/saleCompanies?appKey=%s";
export const getCompaniesAssignedToSale = '/b2b-user/users/saleCompanies';
export const getCompaniesAgentStore = '/ecom/bcm/b2b-user/stores?pageNumber=%s&pageSize=%s';
export const getSaleToCompany = '/b2b-user/company/assignSale';
export const assignCustomerToCompany = '/b2b-user/company/assignAgent';
export const userLogin = '/b2b-user/users/login';
export const forceChangePassword = '/b2b-user/users/update/password';
export const getSignUpForm = '/ecom/bcm/customer/signup-form?appKey=%s';
export const editAccount = '/ecom/bcm/customer/edit';
export const getUserInfo = '/b2b-user/users/getCurrent';

export const getListAddress = '/ecom/bcm/address/list';
export const getListAddressFields = '/ecom/bcm/address/form/custom?appKey=%s&deviceKey=%s';
export const addBillingAddressToCart = '/ecom/bcm/carts/%s/address';
export const addShippingAddressToCart = '/ecom/bcm/carts/%s/address';
export const addShippingMethodToCart = '/ecom/bcm/carts/%s/consignments/%s';
export const getCartCount = '/ecom/bcm/carts/count?appKey=%s&deviceKey=%s&cartId=%s';
export const createNewOrder = '/ecom/bcm/payment/mobile-place-order/%s/%s';
export const getOrderReview = '/ecom/bcm/payment/order-review/%s/%s/%s?paymentMethod=%s';
export const addReference = '/ecom/bcm/carts/%s/customers/reference';
export const deleteReference = '/ecom/bcm/carts/%s/customers/reference';
export const addCoupon = '/ecom/bcm/carts/%s/coupon';
export const deleteCoupon = '/ecom/bcm/carts/%s/coupon/%s';
export const getState = '/ecom/bcm/customer/states/%s';
export const getCountry = '/ecom/bcm/carts/countries?appKey=%s';
export const searchSuggestion = '/ecom/bcm/searchsuggestion';

export const validatePayment = 'ecom/%s/carts/validatePayment';
export const getCompanyOrderList =
  'ecom/bcm/order/search?appKey=%s&deviceKey=%s&pageNumber=%s&pageSize=%s&statusId=%s&orderId=%s&fromDate=%s&toDate=%s&customerReference=%s&productCode=%s&fromDeliveryDate=%s&toDeliveryDate=%s';
export const getCompanyOrderListWithParams =
  'ecom/bcm/order/search?appKey=%(appKey)s&deviceKey=%(deviceKey)s&pageNumber=%(pageNumber)s&pageSize=%(pageSize)s&statusId=%(statusId)s&orderId=%(orderId)s&fromDate=%(fromDate)s&toDate=%(toDate)s&customerReference=%(customerReference)s&fromDeliveryDate=%(fromDeliveryDate)s&toDeliveryDate=%(toDeliveryDate)s&productSku=%(productSku)s&productEan=%(productEan)s';

export const getCompanyOrderStatusList = 'ecom/bcm/order/status?appKey=%s';
// API Detail product.
export const detailProduct = '/ecom/%s/products/details';
export const reviewDetailProduct = '/ecom/%s/products/%s/reviews?appKey=%s&deviceKey=%s';
export const configReviewDetailProduct = '/ecom/%s/products/%s/reviews/config?appKey=%s&deviceKey=%s';
export const relatedProduct = '/ecom/%s/products/%s/related?appKey=%s&deviceKey=%s&ticket=%s';
export const upSellProduct = '/ecom/%s/products/%s/up_sell?appKey=%s&deviceKey=%s&ticket=%s';
export const addReviewProduct = '/ecom/%s/products/%s/reviews';
export const addProductToWatchList = '/ecom/%s/accounts/wishlist/add';
export const removeProductFromWatchList = '/ecom/%s/accounts/wishlist/remove';
// get price sale of config_scp product after finish picking all option
export const getPriceSaleProduct = '/ecom/%s/products/%s/reload';

//API Push message
