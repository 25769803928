import React from 'react';
import { Image } from 'react-bootstrap';
import loading from '../../assets/loadingx43px.svg';
import './style.scss';

export function LoadingScreen() {
  return (
    <div className="loading-screen">
      <Image src={loading} />
    </div>
  );
}
