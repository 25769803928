import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { backTop } from '../../../utils';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Utils from '../../../utils';

function TermConditionPage() {
  const [termList, setTermList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    backTop();

    let dataContent = i18next.t('termConditionSale', { returnObjects: true });
    let dataContent2 = Utils.getSafeValue(dataContent, 'tableList', []);

    setTermList(dataContent2);
  }, []);

  const termCondition2 = [
    {
      key: '1.',
      title: 'INTERPRETATION',
      child: [
        {
          keyChild1: '“Conditions”',
          contenChild1: 'means these terms and conditions of sale;',
        },
        {
          keyChild1: '“Contract”',
          contenChild1:
            'means the contract for the sale and purchase of the goods and includes International Contract unless the context or these Conditions otherwise provide;',
        },
        {
          keyChild1: '“Excluded Customer Groups”',
          contenChild1:
            'means customers of a type which have been reserved exclusively to the Seller, or in respect of which the Seller has appointed an exclusive distributor, as informed to the buyer by the Seller from time to time;',
        },
        {
          keyChild1: '“International Contract”',
          contenChild1:
            'means the contract for the sale and purchase of the goods where the principal place of business of the buyer is in a country other than Great Britain notwithstanding the fact that the goods may be dispatched and delivered in different countries or in the same country; and',
        },
        {
          keyChild1: '“Reserved Territories”',
          contenChild1:
            'any countries and/or areas in respect of which the Seller informs the buyer that it has appointed or will appoint an exclusive distributor or has reserved exclusively to itself.',
        },
      ],
    },
    {
      key: '2.',
      title: 'BASIS OF SALE',
      child: [
        {
          keyChild1: '2.1',
          contenChild1:
            'These Conditions shall apply to the sale by the Seller of all goods purchased by the buyer and these Conditions shall govern the contract to the exclusion of any other terms and conditions introduced or submitted by the buyer.',
        },
        {
          keyChild1: '2.2',
          contenChild1:
            'No variation to these Conditions shall be binding on the Seller unless agreed in writing by an authorised representative of the Seller.',
        },
        {
          keyChild1: '2.3',
          contenChild1:
            'Where the goods are delivered to a buyer located outside of the European Economic Area (“EEA”), Switzerland and the UK (in the event that the UK leaves the EEA), and to the extent that this provision is enforceable in accordance with applicable laws, the buyer shall not supply the goods to any person outside of the country in which the buyer has its principal place of business.',
        },
        {
          keyChild1: '2.4',
          contenChild1: 'Where the goods are delivered to a buyer located in the EEA, Switzerland or the UK:',
          child2: [
            {
              keyChild2: '(a)',
              contenChild2:
                'the buyer shall not supply the goods to any person outside of the EEA, Switzerland and the UK (in the event that the UK leaves the EEA) without Seller’s prior written consent; and',
            },
            {
              keyChild2: '(b)',
              contenChild2:
                'the buyer shall refrain from making active sales of the goods to customers in the Reserved Territories and/or to the Excluded Customer Groups.',
            },
          ],
        },
        {
          keyChild1: '2.5',
          contenChild1:
            'For the purposes of clause 2.4(b) active sales of the goods shall be understood to mean actively approaching or soliciting customers, including, but not limited to, the following actions:',
          child2: [
            {
              keyChild2: '(a)',
              contenChild2: 'visits;',
            },
            {
              keyChild2: '(b)',
              contenChild2: 'direct mail, including the sending of unsolicited emails;',
            },
            {
              keyChild2: '(c)',
              contenChild2:
                'advertising in media, on the internet or other promotions, where such advertising or promotion is specifically targeted at customers in Reserved Territories or at Excluded Customer Groups;',
            },
            {
              keyChild2: '(d)',
              contenChild2:
                'online advertisements addressed to customers in Reserved Territories or to Excluded Customer Groups and other efforts to be found specifically by users in Reserved Territories or belonging to Excluded Customer Groups, including the use of territory based banners on third party websites and paying a search engine or online advertisement provider to have advertisements or higher search rankings displayed specifically to users in Reserved Territories or belonging to Excluded Customer Groups; and',
            },
            {
              keyChild2: '(e)',
              contenChild2:
                "advertising or promotion in any form, or translation of the buyer's website into a language other than an official language of any country forming part of the Territory, that the buyer would not reasonably carry out but for the likelihood that it will reach customers in Reserved Territories or Excluded Customer Groups.",
            },
          ],
        },
        {
          keyChild1: '2.6',
          contenChild1:
            'The buyer shall ensure that the goods are shown and described in a professional and accurate manner that reflects their quality image and that a good level of consumer service is provided.',
        },
        {
          keyChild1: '2.7',
          contenChild1:
            'If the Seller reasonably believes that the buyer is not complying with the obligations in clause 2.3 – 2.6 then the Seller shall be entitled to cancel the Contract, suspend any further deliveries under the Contract without any liability to the buyer and refuse any future orders from the buyer.',
        },
      ],
    },
    {
      key: '3.',
      title: 'ORDERS AND SPECIFICATIONS',
      child: [
        {
          keyChild1: '3.1',
          contenChild1:
            'Where the Seller confirms the details of the contract in writing, the buyer shall be under a duty to bring any discrepancies to the Seller’s notice within 3 days of receipt of the confirmation or prior to acceptance of delivery whichever is the earlier, and if the buyer fails to do so, the buyer shall be bound by the details therein.',
        },
        {
          keyChild1: '3.2',
          contenChild1:
            'All descriptions of the goods, including but not limited to descriptions as to quantity, size, specification, design, print, materials and colour are approximate descriptions only and the buyer shall not rely on them as being precise and accurate.',
        },
        {
          keyChild1: '3.3',
          contenChild1:
            'Where the goods are to be manufactured or any process is to be applied to the goods by the Seller in accordance with a specification requested by the buyer, the buyer shall indemnify the Seller against all loss, damages, costs and expenses awarded against or incurred by the Seller in connection with or paid or agreed to be paid by the Seller in settlement of any claim for infringement of any intellectual property rights of any person which results from the Seller’s use of the buyer’s specification.',
        },
        {
          keyChild1: '3.4',
          contenChild1:
            'Where any goods are supplied to the buyer on the Seller’s specification or design any intellectual property rights in such goods shall belong solely to the Seller unless agreed otherwise in writing and the Seller may reproduce and use and sell any such specification or design in the course of his business or in any dealings with any third parties whatsoever.',
        },
        {
          keyChild1: '3.5',
          contenChild1: 'The Seller reserves the right to make any changes to the specification of the goods: -',
          child2: [
            {
              keyChild2: '(a)',
              contenChild2:
                'which are required so that the goods conform with any applicable statutory or European Community requirement; or',
            },
            {
              keyChild2: '(b)',
              contenChild2:
                'where the goods are to be supplied to the Seller’s specification, and such changes in the specification do not materially affect the quality appearance or performance of the goods.',
            },
          ],
        },
      ],
    },
    {
      key: '4.',
      title: 'DELIVERY',
      child: [
        {
          keyChild1: '4.1',
          contenChild1:
            'The Seller shall reasonably endeavour to deliver the goods to the buyer’s premises or such other address as may be agreed between the parties on the date indicated by the Seller or the date agreed between the parties, but the time of delivery shall not be of the essence.',
        },
        {
          keyChild1: '4.2',
          contenChild1: 'The Seller shall have the right to deliver the goods in instalments.',
        },
        {
          keyChild1: '4.3',
          contenChild1:
            'Failure by the Seller to deliver any one or more of the instalments in accordance with these Conditions or any claim by the buyer in respect of the goods delivered in any one or more instalments shall not entitle the buyer to either treat the contract as a whole as repudiated, or to reject or refuse to take delivery of any of the goods delivered in any other instalment.',
        },
        {
          keyChild1: '4.4',
          contenChild1:
            'If the buyer fails to take delivery of the goods or any instalment thereof (otherwise than by reason of the Seller’s fault) or fails to give the Seller adequate delivery instructions for delivery then, without prejudice to any other right or remedy available to the Seller, the Seller may:-',
          child2: [
            {
              keyChild2: '4.4.1',
              contenChild2:
                'store the goods until actual delivery and charge the buyer for the reasonable costs (including insurance) of storage; or',
            },
            {
              keyChild2: '4.4.2',
              contenChild2:
                'sell the goods at the best price readily obtainable and (after deducting all reasonable storage and selling expenses) charge the buyer for any shortfall below the Price under the contract. ',
            },
          ],
        },
        {
          keyChild1: '4.5',
          contenChild1:
            'On delivery of the goods or any instalment thereof, the Seller or independent carrier shall tender a receipt to the buyer or person taking delivery of the goods for signature and such signature shall be conclusive evidence of receipt of the quantity of the goods stated thereon and that the goods received appear to be undamaged and in good condition.',
        },
        {
          keyChild1: '4.6',
          contenChild1:
            'In the event of a discrepancy as between the quantity or condition of the goods as referred to in clause 4.5 above, the buyer or representative thereof shall bring any such discrepancy to the Seller’s notice immediately on receipt of the goods.',
        },
        {
          keyChild1: '4.7',
          contenChild1:
            'Should the buyer be unable to accept an agreed delivery they will be responsible for any additional carriage cost.',
        },
      ],
    },
    {
      key: '5.',
      title: 'RISK',
      child: [
        {
          keyChild1: '',
          contenChild1: 'Risk of damage to or loss of the goods shall pass to the buyer at the time of delivery.',
        },
      ],
    },
    {
      key: '6.',
      title: 'PRICE',
      child: [
        {
          keyChild1: '6.1',
          contenChild1:
            'The Price for the goods shall be the price specified on the price list published by the seller and current at the date that the order is made and shall be:-',
          child2: [
            {
              keyChild2: '6.1.1',
              contenChild2:
                'exclusive of Value Added Tax (which the buyer shall be additionally liable to pay to the Seller); and ',
            },
            {
              keyChild2: '6.1.2',
              contenChild2: 'calculated on an Ex-works basis.',
            },
          ],
        },
        {
          keyChild1: '6.2',
          contenChild1:
            'The Seller reserves the right to charge a reasonable fee to the buyer for the cost of any carriage, freight, insurance, import duties and taxes charged or incurred by the Seller in relation to the delivery of the goods (“Additional Charges”).',
        },
        {
          keyChild1: '6.3',
          contenChild1:
            'The Supplier reserves the right to amend prices to those ruling at the date of despatch and prices are in any event subject to increase to the extent of any variation after the date of quotation in costs resulting from alterations in exchange rates, new or increased taxes, duties or other imposts or in the costs of or relating to the manufacture or delivery of the Goods.',
        },
        {
          keyChild1: '6.4',
          contenChild1:
            'The Seller reserves the right, by giving notice to the buyer at any time before delivery, to increase the Price of the goods to reflect any increase in the costs to the Seller which are due to any factor beyond the control of the Seller.',
        },
      ],
    },
    {
      key: '7.',
      title: 'PAYMENT',
      child: [
        {
          keyChild1: '7.1',
          contenChild1:
            'The Seller shall be entitled to invoice the buyer for the Price of the goods on or at any time after despatch of the goods or any instalment thereof.',
        },
        {
          keyChild1: '7.2',
          contenChild1:
            'The buyer shall make payment for the Price of the goods and any Additional Charges relating thereto (as defined in clause 6.2 above) in the currency in which the Seller shall invoice the buyer. Any payment received by the Seller in any other currency will not be deemed to be payment for the goods in question.',
        },
        {
          keyChild1: '7.3',
          contenChild1:
            'Unless otherwise agreed between the parties, the buyer shall pay the Price of the goods within 30 days of the date of the Seller’s invoice and the time of payment of the Price shall be of the essence of the contract.',
        },
        {
          keyChild1: '7.4',
          contenChild1:
            'Where prices are quoted in currencies other than sterling, Buyer shall compensate Seller for any currency losses suffered by Seller as a result of the Buyer’s failure to pay for the products on the date specified in Clause 7.3.',
        },
        {
          keyChild1: '7.5',
          contenChild1:
            'If the buyer fails to make payment on the due date then, without prejudice to any other right or remedy available to the Seller the Seller shall be entitled: -',
          child2: [
            {
              keyChild2: '7.5.1',
              contenChild2:
                'to charge the buyer interest (both before and after any judgement) on the amount unpaid, at the rate of 4% per annum above the Seller’s bankers base rate current from time to time; and',
            },
            {
              keyChild2: '7.5.2',
              contenChild2:
                'withhold delivery of any other goods (being the subject of the contract or any other contract) agreed to be sold by the Seller to the buyer and any instalment thereof until payment in full is made.',
            },
          ],
        },
      ],
    },
    {
      key: '8.',
      title: 'TITLE',
      child: [
        {
          keyChild1: '8.1',
          contenChild1:
            'Notwithstanding delivery and the passing of risk in the goods, or any other provision of these Conditions, title in the goods shall not pass to the buyer until the Seller has received in cash or cleared funds payment in full of the price of the goods and all other goods agreed to be sold by the Seller to the buyer for which payment is then due.',
        },
        {
          keyChild1: '8.2',
          contenChild1:
            'Until such time as title in the goods passes to the buyer, the buyer shall hold the goods as the Seller’s fiduciary agent and bailee, and shall keep the goods separate from those of the buyer and third parties and properly stored, protected and insured and identified as the Seller’s property, but shall be entitled to resell or use the goods in the ordinary course of its business.',
        },
        {
          keyChild1: '8.3',
          contenChild1:
            'Until such time as the title in the goods passes to the buyer, the Seller shall be entitled at any time to require the buyer to deliver up the goods to the Seller and if the buyer fails to do so forthwith, to enter upon any premises of the buyer or said party where the goods are stored and mark identify and repossess the goods.',
        },
        {
          keyChild1: '8.4',
          contenChild1:
            'The buyer shall not be entitled to pledge or in any way charge by way of security for any indebtedness any of the goods which remain the property of the Seller, but if the buyer does so all monies owing by the buyer to the Seller shall (without prejudice to any other right or remedy of the Seller) forthwith become due and payable.',
        },
      ],
    },
    {
      key: '9.',
      title: 'INTERNATIONAL CONTRACTS DISTINGUISHED',
      child: [
        {
          keyChild1: '9.1',
          contenChild1:
            'For the avoidance of doubt, the time that delivery shall take place and the time that risk passes from the Seller to the buyer shall be determined, in the case of an International Contract, by the Incoterm agreed between the parties and confirmed by the Seller in writing. The time of delivery and risk in respect of all other contracts shall be determined by clauses 4 and 5 of these Conditions.',
        },
        {
          keyChild1: '9.2',
          contenChild1:
            'Any provisions contained in these Conditions relating to delivery (save those provisions (or part thereof) which state when or where delivery shall take place) shall apply to both International Contracts and contracts.',
        },
        {
          keyChild1: '9.3',
          contenChild1:
            'For the avoidance of doubt and notwithstanding any presumptions raised by the use of any Incoterm, in the case of an International Contract, title in the Goods shall pass in accordance with the clause 8 of these Conditions.',
        },
        {
          keyChild1: '9.4',
          contenChild1:
            'Unless otherwise agreed in writing between the buyer and the Seller, the Incoterm governing the International Contract shall be Ex-works.',
        },
        {
          keyChild1: '9.5',
          contenChild1:
            'Unless otherwise agreed between the parties, where the contract is an International Contract, the Seller shall be entitled to invoice the buyer for the goods at any time after the Seller has despatched the goods to the buyer, whether or not delivery of the goods has been made.',
        },
      ],
    },
    {
      key: '10.',
      title: 'RETURN OF THE GOODS',
      child: [
        {
          keyChild1: '10.1',
          contenChild1:
            'The goods are not sold to the buyer on a “sale or return” basis and the buyer shall not be entitled to return any goods to the Seller unless the buyer has a lawful right to reject the goods.',
        },
        {
          keyChild1: '10.2',
          contenChild1:
            'If permission is given to return goods the seller can charge the buyer a 20% handling charge for any goods that are returned by the buyer because of over ordering, not being able to pay for the goods or any reason.',
        },
        {
          keyChild1: '10.3',
          contenChild1:
            'All permitted returns must be in a condition that allows the business resell, this includes swing tickets attached, without price stickers, in correct packaging and in original condition.',
        },
        {
          keyChild1: '10.4',
          contenChild1: 'Items sold at clearance prices are not returnable unless the warranty provision applies.',
        },
        {
          keyChild1: '10.5',
          contenChild1:
            'Items that have been personalised or branded are not returnable unless the warranty provision applies.',
        },
      ],
    },
    {
      key: '11.',
      title: 'WARRANTIES AND LIABILITY',
      child: [
        {
          keyChild1: '11.1',
          contenChild1:
            'The Seller warrants that the goods will be free from defects in materials, components and workmanship save that this warranty will not extend to defects arising through fair wear and tear or extended use or defects caused by inappropriate use, misuse, accidents or incorrect care.',
        },
        {
          keyChild1: '11.2',
          contenChild1:
            'Subject as expressly provided in these Conditions, all warranties conditions or other terms implied by statute or common law are excluded to the fullest extent permitted by law. ',
        },
        {
          keyChild1: '11.3',
          contenChild1:
            'Any claim by the buyer which is based on any defect in the goods or their failure to correspond with the specification shall be notified to the Seller immediately upon discovery of the defect or failure and where any such valid claim is made the Seller shall be entitled to replace the goods (or the part in question) free of charge or, at the Seller’s sole discretion, refund to the buyer the price of the goods (or a proportionate part of the price), and the Seller shall have no further liability to the buyer. ',
        },
        {
          keyChild1: '11.4',
          contenChild1:
            'Except in respect of death or personal injury caused by the Seller’s negligence, the Seller shall not be liable to the buyer for any indirect, special, consequential loss or damage (whether for loss of profit or otherwise), costs, expenses or other claims whatsoever which arise out of or in connection with the sale of the goods to the buyer, and the entire liability of the Seller shall not exceed the price of the goods, except as expressly provided in these Conditions.',
        },
        {
          keyChild1: '11.5',
          contenChild1:
            'The Seller shall not be liable to the buyer nor be deemed to be in breach of contract by reason of any delay in performing, or any failure to perform, any of the Seller’s obligations in relation to the contract, if the delay or failure was due to any cause beyond the Seller’s reasonable control such as (but without limitation) any strike, lock-out or other form of industrial action.',
        },
      ],
    },
    {
      key: '12.',
      title: 'INSOLVENCY',
      child: [
        {
          keyChild1: '12.1',
          contenChild1: 'This clause applies if:-',
          child2: [
            {
              keyChild2: '12.1.1',
              contenChild2:
                'the buyer makes any voluntary arrangement with its creditors or (being an individual or firm) becomes bankrupt or (being a company) becomes subject to an administration order or goes into liquidation (otherwise than for the purposes of amalgamation or reconstruction);  or ',
            },
            {
              keyChild2: '12.1.2',
              contenChild2:
                'an encumbrancer takes possession or a receiver is appointed, of any of the property or assets of the buyer; or',
            },
            {
              keyChild2: '12.1.3',
              contenChild2: 'the buyer ceases, or threatens to cease, to carry on business; or',
            },
            {
              keyChild2: '12.1.4',
              contenChild2:
                'the Seller reasonably apprehends that any of the events mentioned above is about to occur in relation to the buyer and notifies the buyer accordingly.',
            },
          ],
        },
        {
          keyChild1: '12.2',
          contenChild1:
            'If this clause applies then, without prejudice to any other right or remedy available to the Seller, the Seller shall be entitled to cancel the contract and suspend any further deliveries under the contract without any liability to the buyer, and if the goods have been delivered but not paid for, the price shall become immediately due and payable notwithstanding any previous agreement or arrangement to the contrary.',
        },
      ],
    },
    {
      key: '13.',
      title: 'GENERAL',
      child: [
        {
          keyChild1: '13.1',
          contenChild1:
            'If any provision of these Conditions is held by any competent authority to be invalid or unenforceable in whole or in part the validity of the other provisions of these Conditions shall remain in full force and effect.',
        },
        {
          keyChild1: '13.2',
          contenChild1:
            'No waiver by the Seller of any breach of the contract by the buyer shall be considered as a waiver of any subsequent breach of the same or any other provision.',
        },
        {
          keyChild1: '13.3',
          contenChild1:
            'The contract shall be governed by the laws of England, and the buyer agrees to submit to the non-exclusive jurisdiction of the English courts.',
        },
        {
          keyChild1: '13.4',
          contenChild1:
            'References to any English legal term for any action remedy method of judicial proceedings legal documents legal status court official or any other legal concept or thing shall in respect of any jurisdiction other than England be deemed to include what most nearly approximates in that jurisdiction to the English legal term.',
        },
      ],
    },
  ];

  return (
    <>
      <div className="termConditionPage">
        <Container>
          <Row>
            <div className="col-1"></div>
            <div className="col-10">
              <div className="titlePage">{t('termConditionSale.titlePage')}</div>
              {_.map(termList, (item, index) => {
                return (
                  <div className={`termBoxLv1 boxNumber${index}`} key={index}>
                    <div className="titleBlock">
                      <span className="numberKey">{item['key']}</span>
                      <div className="title titleLv1">{item['title']}</div>
                    </div>
                    {item['child'] &&
                      _.map(item['child'], (itemLv2: any, indexChild) => {
                        return (
                          <React.Fragment key={indexChild}>
                            <div className="termBoxLv2">
                              <div className="titleBlock">
                                <span className="numberKey">{itemLv2.keyChild1}</span>
                                <div className="title titleLv2">{itemLv2.contenChild1}</div>
                              </div>
                              {itemLv2.child2 &&
                                _.map(itemLv2.child2, (itemLv3, indexLv3) => {
                                  return (
                                    <React.Fragment key={indexLv3}>
                                      <div className="termBoxLv3">
                                        <div className="titleBlock">
                                          <span className="numberKey">{itemLv3.keyChild2}</span>
                                          <div className="title titleLv3">{itemLv3.contenChild2}</div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                );
              })}
            </div>

            <div className="col-1"></div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default withTranslation()(TermConditionPage);
