import React, { useEffect, useState } from 'react';
import './style.scss';
import { callApiACT } from '../../redux/callApiRDC';
import { compose } from 'redux';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as AppURL from '../../services/urlAPI';
import { getBaseUrl, setGlobalAPI, getHeader } from '../../services/callDataAPI';
import * as Utils from '../../utils';
import { reducerNameHOR } from '../../redux';
import { HeaderKey, TypeRequest } from '../../constants';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import * as Services from '../../services';
import _ from 'lodash';
import { Redirect } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import { LoadingScreen } from '../../components/loadingScreen';
import { Form } from 'react-bootstrap';
import CheckLogin from '../../components/checkLogin';

interface Props {}

function PageNotFound({}: Props) {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h2>Page Not Found</h2>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PageNotFound);
