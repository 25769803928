import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import './style.scss';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { callApiACT } from '../../redux/callApiRDC';
import * as AppURL from '../../services/urlAPI';
import * as Utils from '../../utils';
import { reducerNameHOR } from '../../redux';
import { TypeRequest } from '../../constants';
import * as Constants from '../../constants';
import Form from 'react-bootstrap/Form';
import loading from '../../assets/loadingx43px.svg';
import success_icn from './assets/icnSuccess.png';
import logo from '../../assets/logo.svg';
import { IoIosPlay } from 'react-icons/io';
import { useTranslation, withTranslation } from 'react-i18next';

interface Props {
  callApiACT: (action) => void;
}

function RequestEmail({ callApiACT }: Props) {
  const methods = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [sendSuccess, setSendSuccess] = useState(false);

  const onUserClicked = async data => {
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    const params = {
      appKey: Constants.appKey,
      email: data.email,
    };

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        name: reducerNameHOR.forgetPass,
        url: AppURL.requestEmailForgotPassword,
        params: params,
        isToastSuccess: false,
        isToastErr: false,
      });
      let success = Utils.getSafeValue(data, 'success', {});
      setSuccessMessage(success['message']);
      setSendSuccess(true);
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setIsLoading(false);
  };

  const [valueUsername, setValueUsername] = useState('');
  const trimSpaceEmail = e => {
    let value = e.target.value;
    setValueUsername(value);
    if (value !== '') {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  const onKeyPress = e => {
    if (e.which == 32) {
      e.preventDefault();
    }
  };

  return (
    <div className="loginPage forgotPassword">
      <div className="loginPageInner">
        <div className="headerLogin">
          <Image src={logo} />
          <div className="paragraph">Online ordering and stock information portal</div>
        </div>
        {!sendSuccess && (
          <Form onSubmit={methods.handleSubmit(onUserClicked)}>
            <div className="loginForm">
              <h3 className="title" style={{ textTransform: 'uppercase' }}>
                Forgot your password?
              </h3>
              <div className="desc">
                Please enter your email address
                <br />
                You will receive a link to reset your password.
              </div>

              <div className="formField">
                <div className="field">
                  <div className="fieldLabel">Email Address:</div>
                  <div className="fieldValue">
                    <input
                      onFocus={() => {
                        setErrorMessage('');
                      }}
                      style={methods.errors.email || errorMessage != '' ? { border: '1px solid #ff321f' } : {}}
                      type="text"
                      name="email"
                      disabled={isLoading}
                      onKeyPress={e => onKeyPress(e)}
                      onChange={e => trimSpaceEmail(e)}
                      className="txt_email"
                      ref={methods.register({
                        required: true,
                        pattern: /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                      })}
                    />
                  </div>
                  {errorMessage !== '' && successMessage === '' && (
                    <div className="invalid-text d-flex-row">
                      <div className="msgError">{errorMessage}</div>
                    </div>
                  )}
                  {methods.errors && methods.errors['email'] && (
                    <div className="invalid-text d-flex-row">
                      <div className="msgError">Please enter a valid email address</div>
                    </div>
                  )}
                </div>
                <div className="field">
                  <div className="fieldBtn">
                    <Link className={`backLogin ${isLoading ? 'loading' : ''}`} to={`/login`}>
                      Back to Login
                    </Link>
                    <button
                      type="submit"
                      disabled={buttonDisable}
                      className={`btnSubmit ${isLoading ? 'loading' : ''}`}
                    >
                      {isLoading ? <img src={loading} width={18} /> : `Submit`}
                      <span className="icnIcon">
                        <IoIosPlay />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
        {sendSuccess && (
          <div className="successBlock">
            <div className="imgSuccess">
              <img src={success_icn} width={65} />
            </div>
            <div className="msgSuccess">
              An email with a link to reset your password has been sent to
              <div className="emailSent">{valueUsername}</div>
            </div>
            <div className="back">
              <Link className="forgotPassword" to={`/login`}>
                Back to Login
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(RequestEmail);
