import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { backTop } from '../../../utils';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Utils from '../../../utils';

function TermConditionSite() {
  const [termList, setTermList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    backTop();

    let dataContent = i18next.t('termConditionSite', { returnObjects: true });
    let dataContent2 = Utils.getSafeValue(dataContent, 'tableList', []);

    setTermList(dataContent2);
  }, []);

  const termConditionSite = [
    {
      title: '',
      desc: [
        'Please read the following terms and conditions of use before using this web site. In using this website you agree to these terms and conditions of use.',
        'This web site is operated by Douglas Gill International Ltd, Manor House Road, Long Eaton, Nottingham, NG10 1LR. If you have any queries about this web site, please contact us, at the above address or email sales@gilluk.com',
      ],
    },
    {
      title: 'USE OF THIS WEBSITE',
      desc: [
        'This website contains material which is owned by or licensed to Douglas Gill International Ltd. This material includes, but is not limited to, the design, layout, look, appearance and graphics. It is protected by intellectual property laws including, but not limited to, copyright.',
        'You may download, view, copy and print information and documentation held at this website provided that:',
        "Materials are used solely for your own personal use or for product / supplier research in connection with your business or your employer's business. Commercial use is not permitted.",
        'Materials may not be altered or modified in any way without prior written permission from Douglas Gill International Ltd.',
        'Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.',
      ],
    },
    {
      title: 'PROFESSIONAL ADVICE',
      desc: [
        'Information provided on pages held at this site and in any documents downloaded from this site is provided for general information only. It is subject to change without notice. Douglas Gill International Ltd is not responsible for any inaccuracies, makes no representation and gives no warranty as to its accuracy. Since the information was not prepared for you personally, it is not intended to form recommendations or advice. It is your sole responsibility to satisfy yourself that the information is suitable for your purposes.',
        'Nothing on this website is intended to be nor should be construed as an offer to enter into a contractual relationship.',
      ],
    },
    {
      title: 'TRADEMARKS',
      desc: [
        'Your use of this site and any materials downloaded, viewed, copied or printed does not authorise you to use any names or trademarks of Douglas Gill International Ltd, its trading partners or associates and no links without consent.',
      ],
    },
    {
      title: 'LINKS TO OTHER SITES',
      desc: [
        'Douglas Gill International Ltd cannot be held liable for any material or representations contained in other websites, nor does Douglas Gill International Ltd endorse or make representations in respect to such websites. All links are used at your own risk. In evoking links to other sites, you agree to accept these conditions of use.',
        "You may not create a link to this website from another website or document without Douglas Gill International Ltd's prior written consent.",
      ],
    },
  ];
  return (
    <>
      <div className="termConditionSite">
        <Container>
          <Row>
            <div className="col-2"></div>
            <div className="col-8">
              <div className="titlePage">{t('termConditionSite.titlePage')}</div>
              {_.map(termList, (item, index) => {
                return (
                  <div className="blockLayerGuide" key={index}>
                    {item['title'] && <div className="titleBlock">{item['title']}</div>}
                    {!_.isEmpty(item['desc']) && (
                      <div className="desc">
                        {_.map(item['desc'], (item2: any, index2) => {
                          return (
                            <div className="descItem" key={index2}>
                              <span dangerouslySetInnerHTML={{ __html: item2 }} />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="col-2"></div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(TermConditionSite);
