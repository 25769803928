import APISauce from 'apisauce';
import Lodash from 'lodash';

import * as Constants from '../constants';
import { HeaderKey } from '../constants';
import * as Utils from '../utils';
import { baseUrl } from './urlAPI';
import localStorage from '../utils/LocalStorage';
import { getBaseEndpointUrl } from '.';

const TIMEOUT_ERROR = 'TIMEOUT_ERROR';
const NETWORK_ERROR = 'NETWORK_ERROR';

let header: string = localStorage.getObject('headerDevLogin', '');
let lang: string = !Lodash.isEmpty(localStorage.get('appLang', 'en')) ? localStorage.get('appLang', 'en') : 'en';
let headerBase = Utils.safeParseJson(header);

// var appId = DeviceInfo.getBundleId();
// var osVersion = DeviceInfo.getSystemVersion();
// const version = DeviceInfo.getVersion();
// const buildNumber = DeviceInfo.getBuildNumber();
// var typeOS = Platform.OS === "ios" ? "iPhone OS" : "Android OS";
// var modelDevice = Platform.OS === "ios" ? "iPhone" : "Android";
// const manufacturer = DeviceInfo.getManufacturer();
// import AsyncStore from "../utils/AsyncStore";
// import I18n from "react-native-i18n";

const baseHeader = {
  // Accept: "application/json",
  'Content-Type': 'application/json; charset=utf-8',
  'Accept-Language': lang,
  'X-Powered-By': 'JM360-Mobile',
  // accept: "application/json, text/plain, */*",
  [HeaderKey.JM360_VERSION]: '1.17.0',
  [HeaderKey.JM360_APP_VERSION]: '2.60.3',
  'x-powered-by': 'JM360-Mobile',

  [HeaderKey.JM360_APP_TYPE]: '7',
  [HeaderKey.JM360_APP_ID]: ' com.jmango360.app.dev',
  [HeaderKey.JM360_KP_VER]: Utils.getSafeValue(headerBase, 'jm360_kp_ver', ''),
  [HeaderKey.JM360_KP]: Utils.getSafeValue(headerBase, 'jm360_kp', ''),
  [HeaderKey.JM360_APP_TYPE]: 3,
  // [HeaderKey.JM360_APP_KEY]: null,
  // [HeaderKey.JM360_BUILD]: `Version ${version} - ${buildNumber}`,
  // [HeaderKey.JM360_OS_TYPE]: typeOS,
  // [HeaderKey.JM360_OS_VERSION]: osVersion,
  // [HeaderKey.JM360_DEVICE_MODEL]: modelDevice
};

export const apiGlobal = APISauce.create({
  baseURL: baseUrl,
  timeout: 50000,
  headers: baseHeader,
});

export const getBaseUrl = () => {
  return apiGlobal.getBaseURL();
};

export const getHeader = () => {
  return apiGlobal.headers;
};

export const setGlobalAPI = async (baseURL, headers) => {
  if (!Lodash.isEmpty(baseURL)) {
    apiGlobal.setBaseURL(baseURL);
  }
  if (!Lodash.isEmpty(headers)) {
    apiGlobal.setHeaders(headers);

    await localStorage.setObject(Constants.KeyAsyncStore.headerDevLogin, JSON.stringify(headers));
  }
};

export const postDataAPI = (tailURL = '', body) => {
  return apiGlobal.post(tailURL, body).then(response => {
    // console.log(getBaseUrl())
    // console.log(JSON.stringify(body))
    // console.log(JSON.stringify(getHeader()))
    // console.log(tailURL)
    // console.log(response)
    if (response.ok && response.data) {
      return { response: response.data };
    } else {
      // handle error of Server, network, timeout...
      let errMessage = '';

      switch (response.problem) {
        case TIMEOUT_ERROR:
          errMessage = Constants.ErrMessage.timeOutRequest;
          break;
        case NETWORK_ERROR:
          errMessage = Constants.ErrMessage.networkError;
          break;
        default:
          errMessage = Utils.getSafeValue(response.data, 'message', Constants.ErrMessage.systemError);
      }

      return { errMessage };
    }
  });
};

export const deleteDataAPI = (tailURL = '', body) => {
  let requestData = {
    data: body,
  };
  return apiGlobal.delete(tailURL, {}, requestData).then(response => {
    if (response.ok && response.data) {
      return { response: response.data };
    } else {
      // handle error of Server, network, timeout...
      let errMessage = '';

      switch (response.problem) {
        case TIMEOUT_ERROR:
          errMessage = Constants.ErrMessage.timeOutRequest;
          break;
        case NETWORK_ERROR:
          errMessage = Constants.ErrMessage.networkError;
          break;
        default:
          errMessage = Utils.getSafeValue(response.data, 'message', Constants.ErrMessage.systemError);
      }

      return { errMessage };
    }
  });
};

export const putDataAPI = (tailURL = '', body) => {
  return apiGlobal.put(tailURL, body).then(response => {
    //   console.log(getBaseUrl())
    // console.log(JSON.stringify(body))
    // console.log(JSON.stringify(getHeader()))
    // console.log(tailURL)
    if (response.ok && response.data) {
      // console.log(response);
      return { response: response.data };
    } else {
      // handle error of Server, network, timeout...
      let errMessage = '';

      switch (response.problem) {
        case TIMEOUT_ERROR:
          errMessage = Constants.ErrMessage.timeOutRequest;
          break;
        case NETWORK_ERROR:
          errMessage = Constants.ErrMessage.networkError;
          break;
        default:
          errMessage = Utils.getSafeValue(response.data, 'message', Constants.ErrMessage.systemError);
      }

      return { errMessage };
    }
  });
};

export const getDataAPI = (tailURL = '') => {
  return apiGlobal.get(tailURL).then(response => {
    // console.log(getBaseUrl())
    // console.log(JSON.stringify(getHeader()))
    // console.log(tailURL)
    // console.log(response)
    if (response.ok && response.data) {
      return { response: response.data };
    } else {
      // handle error of Server, network, timeout...
      let errMessage = '';

      switch (response.problem) {
        case TIMEOUT_ERROR:
          errMessage = Constants.ErrMessage.timeOutRequest;
          break;
        case NETWORK_ERROR:
          errMessage = Constants.ErrMessage.networkError;
          break;
        default:
          errMessage = Utils.getSafeValue(response.data, 'message', Constants.ErrMessage.systemError);
      }

      return { errMessage };
    }
  });
};

export const mergeHeader = (object = {}) => {
  let headerOld = getHeader();
  let mergeHeaderObject = { ...headerOld, ...object };
  return mergeHeaderObject;
};

export const resetHeader = () => {
  return baseHeader;
};

apiGlobal.addAsyncRequestTransform(request => async () => {
  const localBaseURL = localStorage.get(Constants.KeyAsyncStore.baseUrl, '');

  if (localBaseURL) {
    request.baseURL = localBaseURL;
  } else {
    const resBaseUrl = await getBaseEndpointUrl();
    if (resBaseUrl) {
      localStorage.set(Constants.KeyAsyncStore.baseUrl, resBaseUrl);
      request.baseURL = resBaseUrl;
    }
  }
});
