import React, { useEffect, useState } from 'react';
import './../style.scss';
import { Controller, useForm } from 'react-hook-form';
import { Button, Container, Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _, { get } from 'lodash';
import { compose } from 'redux';
import CustomSelect from 'src/components/CustomSelect';
import CustomOption from 'src/components/CustomSelect/components/CustomOption';
import { callApiACT } from '../../../redux/callApiRDC';
import * as AppURL from '../../../services/urlAPI';
import * as Utils from '../../../utils';
import { reducerNameHOR } from '../../../redux';
import { TypeRequest } from '../../../constants';
import localStorage from '../../../utils/LocalStorage';
import * as Constants from '../../../constants';
import { connect } from 'react-redux';
import { LoadingScreen } from '../../../components/loadingScreen';
import Select, { components } from 'react-select';
import AddressForm from './AddressForm';
import ShippingAddress from './ShippingAddress';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useTranslation, withTranslation } from 'react-i18next';
import RadioButton from 'src/components/CustomRadioButton';

const AddressCart = ({ address, onOpenEditAddress, setSelectedAddressEdit }) => {
  const { t } = useTranslation();

  const {
    firstName = '',
    lastName = '',
    company = '',
    street1 = '',
    street2 = '',
    city = '',
    zip = '',
    state = '',
    country = '',
    phone = '',
    email = '',
  } = address;
  const fullName = `${firstName} ${lastName}`.trim();
  const fullAddress = [company, street1, state, country].filter(str => !!str).join(', ');
  return (
    <div style={{ padding: '17px 20px' }}>
      <div>{fullName}</div>
      <div>{phone}</div>
      <div>{fullAddress}</div>
      {Utils.getSafeValue(address, 'type', '') === 'newAddress' && (
        <div
          className="btnEditAddress"
          onClick={() => {
            onOpenEditAddress(address);
            setSelectedAddressEdit(address);
          }}
        >
          {t('checkout.editAddressDetails')}
        </div>
      )}
    </div>
  );
};

interface Props {
  callApiACT: (action) => void;
  activeStep: number;
  setActiveStep: (param) => void;
  handleNext: (param) => void;
  steps: any[];
  setLoadingState: (param) => void;
  loadingState?: boolean;
  setCurrentStep: (param) => void;
  currentStep?: number;
}

export interface PropsItemBilling {
  item: any;
}

export const InvoiceAddress = ({ item }: PropsItemBilling) => {
  let fullName = item.firstName + ' ' + item.lastName;
  let company = item.company;
  let street1 = item.street1;
  let street2 = item.street2 ? item.street2 : '';
  let city = item.city;
  let zip = item.zip ? item.zip : '';
  let state = item.state ? item.state : '';
  let country = item.country;
  let phone = item.phone ? item.phone : '';
  let email = item.email ? item.email : '';

  return (
    <div className="boxItem">
      <div className="boxItemInner">
        <div className="boxInfo">
          {Utils.getAddressDisplayType(item) === 0 && (
            <>
              {/*<div className="itemName">{fullName}</div>*/}
              <div className="itemName">{company}</div>
              {email && (
                <div className="addEmail">
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
              <div className="addStreet1">{street1}</div>
              {street2 && <div className="addStreet2">{street2}</div>}
              <div className="addCity">
                {zip && zip + ' '}
                {city}
              </div>
              {state && <div className="addState">{state}</div>}
              <div>{country}</div>
              {phone && (
                <div className="addPhone">
                  <a className="colorBlue" href={`tel:${phone}`}>
                    {phone}
                  </a>
                </div>
              )}
            </>
          )}
          {Utils.getAddressDisplayType(item) === 1 && (
            <>
              {/*<div className="itemName">{fullName}</div>*/}
              <div className="itemName">{company}</div>
              {email && (
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
              <div className="">{street1}</div>
              {street2 && <div>{street2}</div>}
              <div className="">
                {city}
                <br />
                {zip && zip} {state && state}
              </div>
              <div>{country}</div>
              {phone && (
                <div>
                  <a className="colorBlue" href={`tel:${phone}`}>
                    {phone}
                  </a>
                </div>
              )}
            </>
          )}
          {Utils.getAddressDisplayType(item) === 2 && (
            <>
              {/*<div className="itemName">{fullName}</div>*/}
              <div className="itemName">{company}</div>
              {email && (
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
              <div className="">{street1}</div>
              {street2 && <div>{street2}</div>}
              <div className="">
                {city} {state && state} {zip && zip}
              </div>
              <div>{country}</div>
              {phone && (
                <div>
                  <a href={`tel:${phone}`}>{phone}</a>
                </div>
              )}
            </>
          )}
          {Utils.getAddressDisplayType(item) === 3 && (
            <>
              {/*<div className="itemName">{fullName}</div>*/}
              <div className="itemName">{company}</div>
              {email && (
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
              <div className="">{street1}</div>
              {street2 && <div>{street2}</div>}
              <div className="">
                {zip && zip + ' '}
                {city} {state && state}
              </div>
              <div>{country}</div>
              {phone && (
                <div>
                  <a href={`tel:${phone}`}>{phone}</a>
                </div>
              )}
            </>
          )}
          {Utils.getAddressDisplayType(item) === 4 && (
            <>
              {/*<div className="itemName">{fullName}</div>*/}
              <div className="itemName">{company}</div>
              {email && (
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
              <div className="">{street1}</div>
              {street2 && <div>{street2}</div>}
              <div>{city}</div>
              {zip + state != '' && (
                <div className="">
                  {zip && zip + ' '}
                  {state && state}
                </div>
              )}
              <div>{country}</div>
              {phone && (
                <div>
                  <a href={`tel:${phone}`}>{phone}</a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const mapFieldCodes = {
  firstName: 'first-name',
  lastName: 'last-name',
  company: 'company-name',
  phone: 'phone-number',
  street1: 'address-1',
  street2: 'address-2',
  city: 'suburb-city',
  state: 'state-province',
  country: 'country',
  zip: 'zip-postcode',
};

function BillingInformation({
  callApiACT,
  activeStep,
  handleNext,
  setLoadingState,
  setCurrentStep,
  loadingState,
}: any) {
  const [listAddress, setListAddress] = useState([]);
  const [invoiceAddress, setInvoiceAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState<any>([]);
  const [isCreateAddress, setIsCreateAddress] = useState(false);
  const [selectedNewAddress, setSelectedNewAddress] = useState({});
  const [selectedOption, setSelectedOption] = useState<any>('');
  const [selectedAddressEdit, setSelectedAddressEdit] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [addressConfigFields, setAddressConfigFields] = useState();
  const [isEditAddress, setIsEditAddress] = useState<boolean>(false);

  const [selectedShippingAddress, setSelectedShippingAddress] = useState({});

  const methods = useForm();

  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedShippingMethod');
    getListAddress();
    getListFormField();
  }, []);

  useEffect(() => {
    // const selectedAddress = localStorage.getObject(Constants.KeyAsyncStore.selectedAddress, {});
    // if (!_.isEmpty(selectedAddress)){
    //     setSelectedOption(selectedAddress.id);
    // } else if(_.isEmpty(selectedAddress)){
    //     deliveryAddress.length && setSelectedOption(deliveryAddress[0].id);
    // }
    // GM-646: not keep address when f5

    if (!_.isEmpty(deliveryAddress)) {
      // setSelectedOption(deliveryAddress[0].id);
      setSelectedAddress(deliveryAddress[0]);
    }
  }, [deliveryAddress]);

  useEffect(() => {
    setDisableButton(selectedOption == '');
  }, [selectedOption]);

  const setSelectedAddress = address => {
    setSelectedOption(address.id);
    setSelectedShippingAddress(address);
  };

  useEffect(() => {
    if ((!_.isEmpty(invoiceAddress) && selectedOption && !_.isEmpty(deliveryAddress)) || isEditAddress) {
      setTimeout(e => {
        onSubmit(e);
      }, 0);
      if (isEditAddress) {
        setIsEditAddress(false);
      }
    }
    // console.log(selectedOption);
  }, [selectedOption, invoiceAddress, deliveryAddress, isEditAddress]);

  const onChangeAddress = _.debounce(e => {
    if (e.id !== selectedOption) {
      // setSelectedOption(e.id);
      setSelectedAddress(e);
      setCurrentStep(1);
    }
  }, 100);

  const onSubmit = async e => {
    setLoadingState(true);
    let selectedAddressInvoice = invoiceAddress[0];
    let paramsInvoice = {
      fields: [
        {
          code: 'country',
          value: selectedAddressInvoice['countryIso2'] || '',
        },
        {
          code: 'first-name',
          value: selectedAddressInvoice['firstName'] || '',
        },
        {
          code: 'last-name',
          value: selectedAddressInvoice['lastName'] || '',
        },
        {
          code: 'email-address',
          value: selectedAddressInvoice['emailAddress'] || '',
        },
        {
          code: 'company-name',
          value: selectedAddressInvoice['company'] || '',
        },
        {
          code: 'address-1',
          value: selectedAddressInvoice['street1'] || '',
        },
        {
          code: 'address-2',
          value: selectedAddressInvoice['street2'] || '',
        },
        {
          code: 'suburb-city',
          value: selectedAddressInvoice['city'] || '',
        },
        {
          code: 'state-province',
          value: selectedAddressInvoice['state'] || '',
        },
        {
          code: 'zip-postcode',
          value: selectedAddressInvoice['zip'] || '',
        },
        {
          code: 'phone-number',
          value: selectedAddressInvoice['phone'] || '',
        },
      ],
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
      mode: 'billing',
    };
    let cartId = [localStorage.get('cartId', '')];
    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.PUT,
        url: Utils.replaceStrUrl(AppURL.addBillingAddressToCart, cartId),
        name: reducerNameHOR.setCartAddress,
        params: paramsInvoice,
      });
    } catch (e) {}
    let selectedAddressDelivery =
      _.find(listAddress, { id: selectedOption }) || _.find(deliveryAddress, { id: parseInt(selectedOption) });
    localStorage.setObject(Constants.KeyAsyncStore.selectedAddress, selectedAddressDelivery);
    // console.log({ listAddress, selectedOption, deliveryAddress, selectedAddressDelivery });
    // console.log({
    //   value: [...listAddress, ...deliveryAddress].find(opt => opt.id === selectedOption),
    // });
    // console.log(listAddress);
    // console.log(selectedOption);
    // console.log(selectedAddressDelivery);

    let customAddress = Utils.getSafeValue(selectedAddressDelivery, 'type', '') === 'newAddress';

    let paramsDelivery = {
      fields: [
        {
          code: 'country',
          value: selectedAddressDelivery['countryIso2'] || '',
        },
        {
          code: 'first-name',
          value: selectedAddressDelivery['firstName'] || '',
        },
        {
          code: 'last-name',
          value: selectedAddressDelivery['lastName'] || '',
        },
        {
          code: 'email-address',
          value: selectedAddressDelivery['emailAddress'] || '',
        },
        {
          code: 'company-name',
          value: selectedAddressDelivery['company'] || '',
        },
        {
          code: 'address-1',
          value: selectedAddressDelivery['street1'] || '',
        },
        {
          code: 'address-2',
          value: selectedAddressDelivery['street2'] || '',
        },
        {
          code: 'suburb-city',
          value: selectedAddressDelivery['city'] || '',
        },
        {
          code: 'state-province',
          value: selectedAddressDelivery['state'] || '',
        },
        {
          code: 'zip-postcode',
          value: selectedAddressDelivery['zip'] || '',
        },
        {
          code: 'phone-number',
          value: selectedAddressDelivery['phone'] || '',
        },
      ],
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
      mode: 'shipping',
      customAddress: customAddress,
    };
    // console.log(paramsDelivery);
    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.PUT,
        url: Utils.replaceStrUrl(AppURL.addShippingAddressToCart, cartId),
        name: reducerNameHOR.setCartAddress,
        params: paramsDelivery,
      });

      setCurrentStep(2);
      handleNext(activeStep);
    } catch (e) {}
    setLoadingState(false);
  };

  const getListFormField = async () => {
    let appKey = localStorage.get('appKey', '');
    let deviceKey = localStorage.get(Constants.KeyAsyncStore.deviceKey, '');

    try {
      let data: any = await callApiACT({
        typeRequest: TypeRequest.GET,
        name: reducerNameHOR.getListAddressFields,
        url: Utils.replaceStrUrl(AppURL.getListAddressFields, [appKey, deviceKey]),
      });
      setAddressConfigFields(data.fields);
    } catch (e) {}
  };

  const getListAddress = async () => {
    let appKey = localStorage.get('appKey', '');

    let pageNumher = 1;
    let appTypeCode = localStorage.get(Constants.KeyAsyncStore.appTypeCode, '');
    let deviceKey = localStorage.get(Constants.KeyAsyncStore.deviceKey, '');
    let params = {
      pageNumber: pageNumher,
      appKey: appKey,
      appTypeCode: appTypeCode,
      deviceKey: deviceKey,
    };

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: AppURL.getListAddress,
        name: reducerNameHOR.getUserAddress,
        params: params,
      });
      let invoiceAddress = Utils.getSafeValue(data, 'billingAddresses', []);
      let deliveryAddress = Utils.getSafeValue(data, 'shippingAddresses', []);
      const selectInvoiceOptions = invoiceAddress.map(option => {
        return { ...option, value: option.id, label: `${option.city} ${option.country}` };
      });

      const selectDeliveryOption = deliveryAddress.map(option => {
        // return { ...option, value: option.id, label: `${option.city} ${option.country}` };
        return { ...option, value: option.id, label: `${option?.firstName} ${option?.lastName}` };
      });

      setInvoiceAddress(selectInvoiceOptions);
      setDeliveryAddress(selectDeliveryOption);

      // console.log(data);
    } catch (e) {}
  };

  const onCreateAddress = async data => {
    // let listAddressLocal: never[] = localStorage.getObject(Constants.KeyAsyncStore.newAddresses, []);
    // GM-646: not keep address when f5
    let listAddressLocal: never[] = [];
    if (typeof selectedAddressEdit['id'] === 'undefined') {
      let address = {
        id: 'custom' + listAddress.length,
        firstName: data[mapFieldCodes.firstName] || '',
        lastName: data[mapFieldCodes.lastName] || '',
        company: data[mapFieldCodes.company] || '',
        phone: data[mapFieldCodes.phone] || '',
        street1: data[mapFieldCodes.street1] || '',
        street2: data[mapFieldCodes.street2] || '',
        city: data[mapFieldCodes.city] || '',
        state: data[mapFieldCodes.state] || '',
        countryId: Utils.getSafeValue(data[mapFieldCodes.country], 'value', null),
        countryIso2: Utils.getSafeValue(data[mapFieldCodes.country], 'code', null),
        country: Utils.getSafeValue(data[mapFieldCodes.country], 'label', null),
        zip: data[mapFieldCodes.zip] || '',
        type: 'newAddress',
        value: `custom${listAddress.length}`,
        label: `${Utils.getSafeValue(data, `[${mapFieldCodes.city}]`, '')} ${Utils.getSafeValue(
          data,
          `[${mapFieldCodes.country}].label`,
          ''
        )}`,
      };

      let list: any = [];
      list.push(address);

      // setListAddress(listAddressLocal.concat(list));
      setListAddress(list.concat(listAddress));
      // localStorage.setObject(Constants.KeyAsyncStore.newAddresses, listAddressLocal.concat(list));
      // localStorage.setObject(Constants.KeyAsyncStore.newAddresses, list.concat(listAddressLocal)); // //GM-646: not keep address when f5
      setSelectedOption('custom' + listAddress.length);
      setSelectedNewAddress(address);
      setIsCreateAddress(false);
    } else {
      let address: any = {
        id: data.id,
        firstName: data[mapFieldCodes.firstName] || '',
        lastName: data[mapFieldCodes.lastName] || '',
        company: data[mapFieldCodes.company] || '',
        phone: data[mapFieldCodes.phone] || '',
        street1: data[mapFieldCodes.street1] || '',
        street2: data[mapFieldCodes.street2] || '',
        city: data[mapFieldCodes.city] || '',
        state: data[mapFieldCodes.state] || '',
        countryId: Utils.getSafeValue(data[mapFieldCodes.country], 'value', null),
        countryIso2: Utils.getSafeValue(data[mapFieldCodes.country], 'code', null),
        country: Utils.getSafeValue(data[mapFieldCodes.country], 'label', null),
        zip: data[mapFieldCodes.zip] || '',
        type: 'newAddress',
      };
      let list: any = [];
      list.push(address);
      let newListAddress1 = _.differenceBy(listAddress, [selectedAddressEdit], 'id');
      let newListAddress2 = list.concat(newListAddress1);

      setListAddress(newListAddress2);
      // localStorage.setObject(Constants.KeyAsyncStore.newAddresses, newListAddress2); //GM-646: not keep address when f5
      setSelectedOption(data.id);
      setSelectedNewAddress(address);
      setSelectedAddressEdit({});
      setIsCreateAddress(false);
      setIsEditAddress(true);
    }
  };

  const onOpenEditAddress = async data => {
    setIsCreateAddress(true);
  };

  return (
    <div className="step">
      {/*<div onClick={(e)=>onSubmit(e)}>submit test</div>*/}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="row">
                <div className="col-6">
                  <div className="boxCol">
                    <div className="title">1. {t('checkout.billingAddress')}</div>
                    {_.map(invoiceAddress, (item, index) => {
                      return <InvoiceAddress key={index} item={item} />;
                    })}
                  </div>
                </div>
                <div className="col-6">
                  <div className="boxCol">
                    <div className="title">
                      2. {t('checkout.shippingAddress')}
                      <div className="fieldNewAddres">
                        <div className="inner-item">
                          {/*<div*/}
                          {/*onClick={() => {*/}
                          {/*setSelectedAddressEdit({});*/}
                          {/*setIsCreateAddress(true);*/}
                          {/*}}*/}
                          {/*className="cursor-pointer"*/}
                          {/*>*/}
                          {/*{t('checkout.shipToDifferentAddress')}*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>

                    <CustomSelect
                      className="select-address addressShipping"
                      classNamePrefix="select-address"
                      value={[...listAddress, ...deliveryAddress].find(opt => opt.id === selectedOption)}
                      options={[...listAddress, ...deliveryAddress]}
                      components={{
                        Option: optionProps => (
                          <components.Option {...optionProps}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                color: '#000',
                              }}
                            >
                              <div className="options-content">
                                <AddressCart
                                  address={optionProps.data}
                                  onOpenEditAddress={onOpenEditAddress}
                                  setSelectedAddressEdit={setSelectedAddressEdit}
                                />
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <RadioButton defaultChecked={get(optionProps, 'data.id', '') === selectedOption} />
                              </div>
                            </div>
                          </components.Option>
                        ),
                        DropdownIndicator: dropdownProps => (
                          <components.DropdownIndicator {...dropdownProps}>
                            <span className="arrow-dropdown" />
                          </components.DropdownIndicator>
                        ),
                      }}
                      styles={{
                        option: base => ({ ...base, padding: 0 }),
                      }}
                      onChange={onChangeAddress}
                    />
                    {selectedShippingAddress && (
                      <div className="deliverySelected">
                        <InvoiceAddress item={selectedShippingAddress} />
                      </div>
                    )}

                    {/*
                    {_.map(listAddress, (item, index) => {
                      return (
                        <ShippingAddress
                          onOpenEditAddress={onOpenEditAddress}
                          setSelectedAddressEdit={setSelectedAddressEdit}
                          onChangeAddress={onChangeAddress}
                          selectedOption={selectedOption}
                          key={index}
                          item={item}
                          addedAddress={true}
                        />
                      );
                    })}
                    {_.map(deliveryAddress, (item, index) => {
                      return (
                        <ShippingAddress
                          onOpenEditAddress={onOpenEditAddress}
                          setSelectedAddressEdit={setSelectedAddressEdit}
                          onChangeAddress={onChangeAddress}
                          selectedOption={selectedOption}
                          key={index}
                          item={item}
                        />
                      );
                    })}
                     */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </Container>
      </Form>
      {isCreateAddress && (
        <AddressForm
          isCreateAddress={isCreateAddress}
          onCreateAddress={onCreateAddress}
          selectedAddressEdit={selectedAddressEdit}
          onCancel={() => setIsCreateAddress(false)}
          setIsCreateAddress={setIsCreateAddress}
          addressFields={addressConfigFields}
        />
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
// export default compose(withConnect)(BillingInformation);
export default compose(withConnect(withTranslation()(BillingInformation)));
