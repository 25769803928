import i18n from 'src/locales/i18n';

export const test = '';
export const validateQuantity = (
  quantity: number,
  constraints: { min: number; max: number; inStock: number; productName?: string }
) => {
  const { min, max, inStock, productName = '' } = constraints;
  // case 1
  if (inStock === 0) {
    return {
      quantity: 0,
      error: {
        isError: true,
        message: i18n.t('tableOrderScreen.outStock'),
      },
    };
  }
  if (min > 0 || max > 0) {
    // case 2
    if (inStock < min) {
      return {
        quantity: 0,
        error: {
          isError: true,
          message: i18n.t('tableOrderScreen.messageCheckStockError', {
            productName,
          }),
        },
      };
    }
    // case 3
    if (inStock < quantity) {
      const message = i18n.t('tableOrderScreen.messageCheckStockError', {
        productName,
      });
      // case 3a
      if (inStock === min) {
        return { quantity: min, error: { isError: true, message } };
      }
      // case 3b
      if (inStock < max && max > 0) {
        return { quantity: inStock, error: { isError: true, message } };
      }
      // case 3c
      if (max <= inStock && max > 0) {
        return { quantity: max, error: { isError: true, message } };
      }
    }
    // case 4
    if (quantity < min) {
      const message = i18n.t('tableOrderScreen.messageMinQuantityError', { min });
      return { quantity: min, error: { isError: true, message } };
    }
    // case 4
    if (max < quantity && max !== 0) {
      const message = i18n.t('tableOrderScreen.messageMaxQuantityError', { max });
      return { quantity: max, error: { isError: true, message } };
    }
  }
  // case 3d
  if (inStock < quantity) {
    const message = i18n.t('tableOrderScreen.messageCheckStockError', {
      productName,
    });
    return { quantity: inStock, error: { isError: true, message } };
  }
  return { quantity, error: { isError: false, message: '' } };
};
