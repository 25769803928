import React, { useEffect, useState } from 'react';
import MainHeader from '../mainHeader';
import { Route, Switch, useRouteMatch } from 'react-router';
import HomePage from '../homePage';
import Business from '../business/ProductList';
import Products from '../products';
import ShoppingCart from '../shoppingCart';
import ProductDetail from '../productDetail';
import OrderDetail from '../order/orderDetail';
import Checkout from '../Checkout';
import Thankyou from '../Checkout/steppers/Thankyou';
import StaticPage from '../staticPage';
import SearchPage from '../SearchPage';
import PageNotFound from '../../containers/pageNotFound';

import MyAccount from '../myAccount';
import MainFooter from '../mainFooter';
import SizeGuide from '../htmlStaticPage/sizeGuide';
import LayeringGuide from '../htmlStaticPage/layeringGuide';
import FabricSystem from '../htmlStaticPage/fabricSystem';
import HelpPage from '../htmlStaticPage/HelpPage';
import TermConditionPage from '../htmlStaticPage/termConditionPage';
import TermConditionSite from '../htmlStaticPage/termConditionSite';
import CookiesAndPrivacy from '../htmlStaticPage/cookiesAndPrivacy';
import ProductCare from '../htmlStaticPage/productCare/productCare';

function HomeRedirect() {
  let match = useRouteMatch('/checkout');
  let matchSearch = useRouteMatch('/search');

  return (
    <>
      {/*{!match && <MainHeader className={matchSearch? 'isSearchPage' : ''}/>}*/}
      <MainHeader className={`${matchSearch ? 'isSearchPage' : ''} ${match ? 'isCheckoutPage' : ''}`} />

      <div className="mainSiteContent">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/" component={HomePage} />
          <Route exact path={`/business/:category`} component={Business} />
          <Route path={`/business/search/keyword=:key`} component={Business} />
          <Route exact path={`/search`} component={SearchPage} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/cart" component={ShoppingCart} />
          <Route exact path={`/products/:id`} component={ProductDetail} />
          <Route exact path={`/order/:id`} component={OrderDetail} />
          <Route exact path={`/checkout`} component={Checkout} />
          <Route exact path={`/thankyou`} component={Thankyou} />
          <Route path={`/my-account`} component={MyAccount} />
          <Route exact path={`/size-guide`} component={SizeGuide} />
          <Route exact path={`/layering-guide`} component={LayeringGuide} />
          <Route exact path={`/fabric-system`} component={FabricSystem} />
          <Route exact path={`/term-condition-sale`} component={TermConditionPage} />
          <Route exact path={`/term-condition`} component={TermConditionSite} />
          <Route exact path={`/cookies-policy`} component={CookiesAndPrivacy} />
          <Route exact path={`/product-care`} component={ProductCare} />
          <Route exact path={`/static/:name`} component={StaticPage} />
          <Route exact path={`/help`} component={HelpPage} />
          <Route exact path="" component={PageNotFound} />{' '}
          {/* this page not fount alway at bottom, it mean that if there is a new page, it MUST PUT ABOVE this page not found*/}
        </Switch>
      </div>
      <MainFooter />
    </>
  );
}

export default HomeRedirect;
