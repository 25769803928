import React, { useEffect, useState, useReducer } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Image, Button, Table, Modal, Form } from 'react-bootstrap';
import { addDays, format } from 'date-fns';
import { connect, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import _ from 'lodash';
import { compose } from 'redux';
import { IoIosAdd, IoIosArrowBack, IoIosClose, IoIosCloseCircleOutline, IoIosPlay, IoIosRemove } from 'react-icons/io';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactTooltip from 'react-tooltip';
import { useHistory, useLocation } from 'react-router';
import { useTranslation, withTranslation } from 'react-i18next';

import * as Constants from '../../constants';
import './style.scss';
import TableProductList from '../tableListProduct';
import DatePickerInput from '../../components/datePicker';
import { callApiListCart } from '../../redux/getListCartRDC';
import * as AppURL from '../../services/urlAPI';
import * as Utils from '../../utils';
import { reducerNameHOR, RootState } from '../../redux';
import { TypeRequest } from '../../constants';
import localStorage from '../../utils/LocalStorage';
import { callApiACT } from '../../redux/callApiRDC';
import loading from '../../assets/loadingx43px.svg';

import ModalAlert from '../../components/modalAlert/modalAlert';
import ImportCsvOrder from '../../containers/importCSV';
import dataCartCSV from '../../redux/dataCartCSV';
import { getEnableBackOrder } from 'src/redux/selector/appConfig.selector';
import { validateQuantity } from 'src/utils/product.helpers';

interface ErrorQuantity {
  type?: 'default' | 'error' | 'warning';
  message?: string;
}

export interface Props {
  callApiListCart: (action) => void;
  callApiACT: (action) => void;
  getCartList: any;
  shoppingCart: any;
}

interface PropRowDeleteItem {
  row: any;
  callApiListCart: (action) => void;
  deleteItem: (a) => void;
}

interface PropsRowQty {
  row: any;
  step?: number;
  index: string | number;
  onChangeQtt?: any;
  value: any;
  disabled?: boolean;
}

function RowQty({ row, step, index, onChangeQtt, value, disabled }: PropsRowQty) {
  const { t } = useTranslation();
  const enableBackOrder = useSelector(getEnableBackOrder);

  const [rowValue, setRowValue] = useState(value);
  const [qtyErrorMsg, setQtyErrorMsg] = useState<string>('');
  const [errorQuantity, setErrorQuantity] = useState<ErrorQuantity>({});

  const onChangeQuantity = e => {
    let value = e.target.value;
    if (!value || value == 0) {
      onChangeQtt(1);
    } else {
      onChangeQtt(rowValue);
    }
  };

  const validateQuantityInput = (inputQuantity: number) => {
    const { orderQuantityMinimum: min, orderQuantityMaximum: max, inventoryLevel, backOrderActualStock } = row;

    const inStock = enableBackOrder ? backOrderActualStock : inventoryLevel;

    const productName = Utils.getSafeValue(row, 'name', '');
    const estDate = Utils.getSafeValue(row, 'estimateDeliveryDate', '');
    const estDeliveryDate =
      (estDate &&
        Utils.convertDateFormat(estDate, Constants.DateFormat.serverDateFormat, Constants.DateFormat.valueShow)) ||
      '';
    const backOrderWaring = t('tableOrderScreen.messageBackOrderWarning', {
      estDeliveryDate,
    });
    const stockWaring = t('tableOrderScreen.messageCheckStockWarning', {
      productName,
    });
    const { quantity, error } = validateQuantity(inputQuantity, { min, max, inStock, productName });

    if (error.isError) {
      setErrorQuantity({
        type: 'error' as const,
        message: error.message,
      });
    } else if (quantity > inventoryLevel) {
      setErrorQuantity({
        type: 'warning' as const,
        message: `${stockWaring} <br/> ${backOrderWaring}`,
      });
      if (inputQuantity === 0) {
        onChangeQtt(1);
      } else {
        onChangeQtt(inputQuantity);
      }
    } else {
      setErrorQuantity({
        type: 'default' as const,
        message: '',
      });
      setRowValue(quantity);
      if (inputQuantity === 0) {
        onChangeQtt(1);
      } else {
        onChangeQtt(inputQuantity);
      }
    }
  };
  const onBlurQuantity = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value: valueInput } = event.target;
    validateQuantityInput(Utils.safeParseInt(valueInput));
  };

  return (
    <div className="qtyBox">
      <div className="inputQty">
        {/*<span onClick={minusQuantity}><IoIosRemove/></span>*/}
        <input
          className={`input-qty ${errorQuantity.type}`}
          min={0}
          name={`quantity[${index}]`}
          type="number"
          pattern="\d*"
          onFocus={e => {
            e.target.select();
          }}
          onMouseUp={e => e.preventDefault()}
          // onBlur={e => {onChangeQuantity(e);}}
          onBlur={onBlurQuantity}
          onChange={e => setRowValue(e.target.value)}
          disabled={disabled}
          value={rowValue}
        />
        {['error', 'warning'].includes(Utils.getSafeValue(errorQuantity, 'type', '')) && (
          <span
            className={`input_error ${errorQuantity.type === 'warning' ? 'backorder-waring' : ''}`}
            data-tip={Utils.getSafeValue(errorQuantity, 'message', '')}
            data-for="quantityError"
          >
            <ReactTooltip clickable effect="solid" id="quantityError" multiline className="errorTooltip" />
          </span>
        )}
      </div>
    </div>
  );
}

function ConfirmDeleteModal({ show, setShow, onDelete, isMultiDelete }) {
  const { t } = useTranslation();

  return (
    <Modal dialogClassName="confirmDelete" show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        {/*<div className="msg">Do you want to remove {isMultiDelete ? 'selected items' : 'this item'} from your shopping cart?</div>*/}
        <div className="msg">{t('confirmModal.areYouSure')}</div>
        <div className="btnGroup">
          <Button className="btnCancel" onClick={() => setShow(false)}>
            {t('confirmModal.no')}
          </Button>
          <Button className="btnYes" onClick={onDelete}>
            {t('confirmModal.yes')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function RowDeleteItem({ callApiListCart, row, deleteItem }: PropRowDeleteItem) {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const onClick = () => {
    setShow(!show);
  };

  const onDelete = () => {
    setIsLoading(true);
    deleteItem(row);
    setIsLoading(false);
    setShow(false);
  };

  return (
    <div>
      <ConfirmDeleteModal show={show} setShow={value => setShow(value)} onDelete={onDelete} isMultiDelete={false} />
      <button className="btnSingleDelete" type="button" onClick={onClick}>
        <IoIosCloseCircleOutline />
      </button>
    </div>
  );
}

export function SelectToDelete({ toggleCheckItem, checked }) {
  return (
    <>
      <label className="lblCheckbox">
        <input checked={checked} type="checkbox" onChange={e => toggleCheckItem(e.target.checked)} />
        <span className="icnCheckbox"></span>
      </label>
    </>
  );
}

function ShoppingCart({ callApiListCart, callApiACT, getCartList, shoppingCart, appConfig }: any) {
  const methodsReference = useForm();
  const methodsDiscount = useForm();
  const appKey = localStorage.get('appKey', '');
  const deviceKey = localStorage.get('deviceKey', '');

  const { t } = useTranslation();

  const isCart = localStorage.get('cartId', '');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddReference, setIsAddReference] = useState(false);
  const [isAddDiscount, setIsAddDiscount] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [cart, setCart] = useState({ id: null });
  const [newCart, setNewCart] = useState({ null: true });
  const [lineItems, setLineItems] = useState<any>({});
  const [lineItemsOrigin, setLineItemsOrigin] = useState<any>({});
  const [errorMessageDiscount, setErrorMessageDiscount] = useState('');

  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isEnableDeleteButton, setIsEnableDeleteButton] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [refCustomer, setRefCustomer] = useState(0);

  const [currency, setCurrency] = useState({});
  const [cartSubTotal, setCartSubTotal] = useState('');
  const [orderTotal, setOrderTotal] = useState('');
  const [totalTax, setTotalTax] = useState('');
  const [coupon, setCoupon] = useState({});

  const [showCouponForm, setShowCouponForm] = useState(false);
  const [showAddCustomRefForm, setShowAddCustomRefForm] = useState(false);
  const [numberItemDelete, setNumberItemDelete] = useState<number>(0);
  const [showDeleteSuccessMsg, setShowDeleteSuccessMsg] = useState<boolean>(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errorUpdateCart, setErrorUpdateCart] = useState<string>('');

  const [showDialogImportCSV, setShowDialogImportCSV] = useState(false);
  const [showTaxLine, setShowTaxLine] = useState(false);

  const cartHead = useSelector((state: RootState) => state.getCartList);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (appConfig && !_.isEmpty(appConfig.response)) {
      let appMetadata = Utils.getSafeValue(appConfig.response['appData']['appMetadata'], 'data', '');
      let appMetadataJson = Utils.safeParseJson(appMetadata);
      setShowTaxLine(appMetadataJson['showTaxInCart']);
    }
  }, [appConfig]);

  useEffect(() => {
    if (_.isEmpty(location.state)) {
      getCart();
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(cartHead) && !_.isEmpty(cartHead.cart)) {
      let cart = Utils.getSafeValue(cartHead, 'cart', {});
      getCountCart(cart.id);
    }
  }, [cartHead]);

  useEffect(() => {
    if (shoppingCart) {
      setCart(shoppingCart);
      let _lineItems = Utils.getSafeValue(shoppingCart, 'lineItems', {});
      setLineItems(_lineItems);
      setLineItemsOrigin(_lineItems);
      getCartField(shoppingCart);

      // getCountCart(shoppingCart.id);

      history.replace({
        pathname: '/cart',
        state: {},
      });
    }
  }, [shoppingCart]);

  useEffect(() => {
    if (!newCart['null']) {
      let _lineItems = Utils.getSafeValue(newCart, 'lineItems', {});
      setLineItems(_lineItems);
      setLineItemsOrigin(_lineItems);
      let physicalItems: any[] = Utils.getSafeValue(_lineItems, 'physicalItems', []);
      if (physicalItems.length === 0) {
        localStorage.removeItem(Constants.KeyAsyncStore.cartId);
      }
    }
  }, [newCart]);

  useEffect(() => {
    if (!lineItems.physicalItems || (lineItems.physicalItems && !lineItems.physicalItems.length)) return;
    let checkedItems = _.filter(lineItems.physicalItems, s => s.checked);
    setSelectAll(checkedItems.length == lineItems.physicalItems.length);
    setIsEnableDeleteButton(checkedItems.length > 0);
  }, [lineItems]);

  const onChangeQtt = (value, index) => {
    //dispatchUserSelection({type: 'changeQtt', data: {value, index}});
    setLineItems({
      ...lineItems,
      physicalItems: lineItems.physicalItems.map((item, itemIndex) =>
        index == itemIndex ? { ...item, quantity: value } : item
      ),
    });
  };
  const onChangeDeliveryDate = (index: number) => {
    return (date: Date) => {
      setLineItems({
        ...lineItems,
        physicalItems: lineItems.physicalItems.map((item, itemIndex) =>
          index === itemIndex
            ? { ...item, deliveryDate: (date && moment(date).format(Constants.DateFormat.serverDateFormat)) || '' }
            : item
        ),
      });
    };
  };

  const toggleCheckItem = (value: boolean, index) => {
    setLineItems({
      ...lineItems,
      physicalItems: lineItems.physicalItems.map((item, itemIndex) =>
        index == itemIndex ? { ...item, checked: value } : item
      ),
    });
  };

  const getCart = async () => {
    setIsLoading(true);
    let params = [localStorage.get('appKey', ''), localStorage.get('deviceKey', ''), isCart];

    try {
      let data = await callApiListCart({
        url: Utils.replaceStrUrl(AppURL.getCartGroupItem, params),
        name: reducerNameHOR.getCartList,
        typeRequest: Constants.TypeRequest.GET,
      });

      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      localStorage.set('cartId', cart['id']);
      setCart(cart);
      let _lineItems = Utils.getSafeValue(cart, 'lineItems', {});
      setLineItems(_lineItems);
      setLineItemsOrigin(_lineItems);
      getCartField(cart);
    } catch (e) {
      console.log('error message get cart: ', e);
    }
    setIsLoading(false);
  };

  const deleteItem = async row => {
    setIsLoadingDelete(true);
    let cartId = localStorage.get('cartId', '');
    let productId = row['id'];
    let params = {
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
      appTypeCode: 'bcm',
    };
    let toastMessageSuccess = 'Delete product from cart success';
    try {
      let data = await callApiListCart({
        typeRequest: TypeRequest.DELETE,
        url: Utils.replaceStrUrl(AppURL.deleteCartItem, [cartId, productId]),
        name: reducerNameHOR.deleteCartItem,
        isToastSuccess: true,
        toastMessageSuccess: toastMessageSuccess,
        params: params,
      });
      let newCart = await Utils.getSafeValue(data, 'cart', { id: null });
      setNewCart(newCart);
      getCartField(newCart);
    } catch (e) {}
    setIsLoadingDelete(false);
  };

  const deleteItems = async () => {
    setIsLoadingDelete(true);
    setShowConfirm(false);
    let cartId = localStorage.get('cartId', '');
    let itemsToDelete = _.filter(lineItems.physicalItems, { checked: true }).map(s => s.id);
    let params = {
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
      appTypeCode: 'bcm',
      itemIds: itemsToDelete,
    };
    let toastMessageSuccess = 'Delete products from cart success';
    try {
      let data = await callApiListCart({
        typeRequest: TypeRequest.DELETE,
        url: Utils.replaceStrUrl(AppURL.deleteCartItems, [cartId]),
        name: reducerNameHOR.deleteCartItems,
        isToastSuccess: true,
        toastMessageSuccess: toastMessageSuccess,
        params: params,
      });
      let newCart = await Utils.getSafeValue(data, 'cart', { id: null });
      setNewCart(newCart);

      setDeleteMode(false);
      getCartField(newCart);

      setShowDeleteSuccessMsg(true);
      setNumberItemDelete(itemsToDelete.length);

      setTimeout(function () {
        setShowDeleteSuccessMsg(false);
      }, 3000);
    } catch (e) {}
    setIsLoadingDelete(false);
    setIsEnableDeleteButton(false);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setLineItems({
      ...lineItems,
      physicalItems: lineItems.physicalItems.map((item, itemIndex) => ({
        ...item,
        checked: !selectAll,
      })),
    });
  };

  const onAddReference = async data => {
    setIsAddReference(true);
    let params = {
      appKey: localStorage.get(Constants.KeyAsyncStore.appKey, ''),
      appTypeCode: localStorage.get(Constants.KeyAsyncStore.appTypeCode, ''),
      deviceKey: localStorage.get(Constants.KeyAsyncStore.deviceKey, ''),
      customerReference: data.reference,
    };

    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.PUT,
        url: Utils.replaceStrUrl(AppURL.addReference, [localStorage.get(Constants.KeyAsyncStore.cartId, '')]),
        params: params,
        isToastErr: false,
      });
      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      setCart(cart);
    } catch (e) {}
    setIsAddReference(false);
  };

  const deleteReference = async () => {
    setIsAddReference(true);
    let params = {
      appKey: localStorage.get(Constants.KeyAsyncStore.appKey, ''),
      appTypeCode: localStorage.get(Constants.KeyAsyncStore.appTypeCode, ''),
      deviceKey: localStorage.get(Constants.KeyAsyncStore.deviceKey, ''),
      customerReference: cart['customerReference'],
    };

    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.DELETE,
        url: Utils.replaceStrUrl(AppURL.deleteReference, [localStorage.get(Constants.KeyAsyncStore.cartId, '')]),
        params: params,
        isToastErr: false,
      });
      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      setCart(cart);
      getCartField(cart);
    } catch (e) {}
    setIsAddReference(false);
    setValueCustomRef('');
    setShowAddCustomRefForm(false);
  };

  const getCartField = cart => {
    let currency = Utils.getSafeValue(cart, 'currency', {});
    // let showTaxInCart = localStorage.get(Constants.KeyAsyncStore.showTaxInCart, false);
    setCurrency(currency);

    let subtotal = Utils.convertCurrency(cart['subTotal'], currency['code']);
    let orderTotal = Utils.convertCurrency(cart['cartAmount'], currency['code']);
    let totalTax = Utils.convertCurrency(cart['taxTotal'], currency['code']);
    setCartSubTotal(subtotal);
    setOrderTotal(orderTotal);
    setTotalTax(totalTax);

    let coupon = Utils.getSafeValue(cart, 'coupons', []);
    setCoupon(coupon[0]);
    if (cart.id === null) {
      setEditMode(false);
    }
  };

  const onApplyDiscountCode = async data => {
    setIsAddDiscount(true);
    methodsDiscount.clearErrors('discount');
    setErrorMessageDiscount('');
    let params = {
      appKey: localStorage.get(Constants.KeyAsyncStore.appKey, ''),
      deviceKey: localStorage.get(Constants.KeyAsyncStore.deviceKey, ''),
      couponCode: data.discount,
    };

    try {
      let data = await callApiListCart({
        typeRequest: Constants.TypeRequest.PUT,
        url: Utils.replaceStrUrl(AppURL.addCoupon, [localStorage.get(Constants.KeyAsyncStore.cartId, '')]),
        params: params,
        isToastMessage: false,
        isToastErr: false,
      });
      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      setCart(cart);
      getCartField(cart);
    } catch (e) {
      setErrorMessageDiscount(e);
    }
    setIsAddDiscount(false);
  };

  const deleteCoupon = async e => {
    setIsAddDiscount(true);
    let params = {
      appKey: localStorage.get(Constants.KeyAsyncStore.appKey, ''),
      deviceKey: localStorage.get(Constants.KeyAsyncStore.deviceKey, ''),
    };

    try {
      let data = await callApiListCart({
        typeRequest: Constants.TypeRequest.DELETE,
        url: Utils.replaceStrUrl(AppURL.deleteCoupon, [localStorage.get(Constants.KeyAsyncStore.cartId, ''), e.code]),
        params: params,
        isToastMessage: false,
        isToastErr: false,
      });
      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      setCart(cart);
      getCartField(cart);
    } catch (e) {
      setErrorMessageDiscount(e);
    }
    setIsAddDiscount(false);
    setShowCouponForm(false);
  };

  const updateCart = async () => {
    setLoadingUpdate(true);
    try {
      let _lineItems: any = lineItems.physicalItems.map((i, index) => {
        let date =
          i.deliveryDate != '' && i.deliveryDate != null
            ? moment(i.deliveryDate).format(Constants.DateFormat.serverDateFormat)
            : '';
        return {
          id: i.id,
          modifierSelections: [],
          optionSelections: i.options.map(o => ({
            optionId: o.nameId,
            optionValue: o.valueId,
          })),
          productId: i.productId,
          quantity: i.quantity,
          // deliveryDate: i.deliveryDate,
          deliveryDate: date,
        };
      });
      let params: any = {
        appKey: appKey,
        deviceKey: deviceKey,
        lineItems: _lineItems,
      };

      let data: any = await callApiListCart({
        url: Utils.replaceStrUrl(AppURL.editCart, [localStorage.get('cartId', '')]),
        name: reducerNameHOR.editCart,
        typeRequest: Constants.TypeRequest.PUT,
        params: params,
        isToastSuccess: true,
      });

      if (data && data.cart) {
        let newCart = await Utils.getSafeValue(data, 'cart', { id: null });

        setNewCart(newCart);
        localStorage.set('cartId', data.cart['id']);
        setEditMode(false);
        getCartField(newCart);
      }
      setLoadingUpdate(false);
    } catch (e) {
      //setQty(row.quantity);
      console.log('error message edit cart item quantity', e);
      setErrorUpdateCart(e);
      //force re-render
      setEditMode(false);
      setEditMode(true);
      setLoadingUpdate(false);
    }

    //setEditMode(false);
  };

  const [cartCount, setCartCouut] = useState(0);

  const getCountCart = async cartID => {
    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.GET,
        url: Utils.replaceStrUrl(AppURL.getCartCount, [appKey, deviceKey, cartID]),
        name: reducerNameHOR.getCartCount,
      });

      let count = Utils.getSafeValue(data, 'count', 0);
      setCartCouut(count);
    } catch (e) {}
  };

  const columns = [
    editMode && {
      name: `${t('tableColumnName.delete')}`,
      nameAction: (row, index) => (
        <button className="btnMode btnSelectAll" onClick={toggleSelectAll}>
          {selectAll ? 'Deselect' : 'Select'}
        </button>
      ),
      selector: '',
      cell: (row, index) => (
        <div className="">
          <SelectToDelete checked={row.checked} toggleCheckItem={checked => toggleCheckItem(checked, index)} />
        </div>
      ),
      backOrderCell: () => {
        return <div />;
      },
      className: 'colDelete',
    },
    {
      name: '',
      selector: 'image',
      cell: row => (
        <div className="thumb">
          <Link to={`/products/${row.productId}`}>
            <Image src={row.imageUrl} />
          </Link>
        </div>
      ),
      backOrderCell: () => {
        return <div className="backorder-sub-line backorder-sub-line__rectangle" />;
      },
      className: 'colImage',
    },
    {
      name: `${t('tableColumnName.productName')}`,
      selector: 'product-name',
      cell: row => (
        <>
          <div className="productName">
            <Link to={`/products/${row.productId}`}>{row.name}</Link>
          </div>
          <div className="productSKU">
            <Link to={`/products/${row.productId}`}>{row.sku}</Link>
          </div>
        </>
      ),
      backOrderCell: row => {
        const isBackOrder = Utils.getSafeValue(row, 'backOrderQuantity', 0) > 0;
        const backorderType = (isBackOrder && t('checkout.backorder')) || t('checkout.standard');
        return (
          <div className="flex-box backorder-sub-line backorder-sub-line__product">
            <div className="">{backorderType}</div>
          </div>
        );
      },
      className: 'colName',
    },
    // {
    !editMode && {
      name: `${t('tableColumnName.deliveryDate')}`,
      selector: '',
      cell: (row, index) => {
        const isBackOrder = Utils.getSafeValue(row, 'backOrder', false);

        const groupItems = Utils.getSafeValue(row, 'groupItems', []);

        const isBackOrderItem = isBackOrder || groupItems.length > 0;
        const deliveryDate =
          (row.deliveryDate && moment(row.deliveryDate).format(Constants.DateFormat.valueInput)) || '';

        return (
          <div className="">
            {(isBackOrderItem && <div>-</div>) || <Link to={`/products/${row.productId}`}>{deliveryDate}</Link>}
          </div>
        );
      },
      backOrderCell: row => {
        const isBackOrderType = Utils.getSafeValue(row, 'backOrderQuantity', 0) > 0;

        let deliveryDate = '-';

        if (isBackOrderType) {
          if (row.backOrderDeliveryDate) {
            deliveryDate = moment(row.backOrderDeliveryDate, Constants.DateFormat.serverDateFormat).format(
              Constants.DateFormat.valueInput
            );
          }
        } else if (row.actualDeliveryDate) {
          deliveryDate = moment(row.actualDeliveryDate, Constants.DateFormat.serverDateFormat).format(
            Constants.DateFormat.valueInput
          );
        }

        return (
          <div className="backorder-sub-line flex-box">
            <div className="itemDeliveryDate">{deliveryDate}</div>
          </div>
        );
      },
      className: 'colDdateView',
    },
    // },
    {
      name: `${t('tableColumnName.color')}`,
      selector: '',
      cell: (row, index) => {
        let options = Utils.getSafeValue(row, 'options', []);
        let color = _.find(options, { name: 'Color' }) || { undefined: true };
        if (!color['undefined']) {
          return (
            <div className="colorWrap">
              {_.map(color['colors'], (item, indexItem) => {
                return (
                  <>
                    <div
                      key={indexItem}
                      className="item-color"
                      style={{ backgroundColor: item }}
                      data-tip={color['value']}
                      data-for={`itemTooltipColorCart${index}`}
                    ></div>
                    <ReactTooltip effect={'solid'} clickable={true} id={`itemTooltipColorCart${index}`}></ReactTooltip>
                  </>
                );
              })}
            </div>
          );
        }
      },
      backOrderCell: () => {
        return (
          <div className="backorder-sub-line flex-box">
            <div />
          </div>
        );
      },
      className: 'colColor',
    },
    {
      name: `${t('tableColumnName.size')}`,
      selector: '',
      cell: row => (
        <Link to={`/products/${row.productId}`}>
          {Utils.getSafeValue(_.find(row.options, { name: 'Size' }), ['value'], '')}
        </Link>
      ),
      backOrderCell: () => {
        return (
          <div className="backorder-sub-line flex-box">
            <div />
          </div>
        );
      },
      className: 'colSize',
    },
    {
      name: `${t('tableColumnName.itemPrice')}`,
      selector: '',
      cell: row => (
        <div>
          <Link to={`/products/${row.productId}`}>{Utils.convertCurrency(row['listPrice'], currency['code'])}</Link>
        </div>
      ),
      backOrderCell: row => {
        return (
          <div className="backorder-sub-line flex-box">
            <div className="itemUnitPrice">
              {Utils.convertCurrency(
                row.listPrice,
                Utils.getSafeValue(currency, 'code', Constants.CurrencyKey.KEY_USD)
              )}
            </div>
          </div>
        );
      },
      className: 'colPrice',
    },
    {
      name: `${t('tableColumnName.qty')}`,
      selector: '',
      cell: (row, index) => (
        <div className="">
          {editMode ? (
            <RowQty
              index={index}
              value={row.quantity}
              onChangeQtt={value => onChangeQtt(value, index)}
              row={row}
              disabled={loadingUpdate || isLoadingDelete}
            />
          ) : (
            <Link to={`/products/${row.productId}`}>{row['quantity']}</Link>
          )}
        </div>
      ),
      backOrderCell: row => {
        const isBackOrder = Utils.getSafeValue(row, 'backOrderQuantity', 0) > 0;

        const lineQuantity = (isBackOrder && row.backOrderQuantity) || row.actualQuantity || 0;
        return (
          <div className="backorder-sub-line flex-box">
            <div className="itemQty">{lineQuantity}</div>
          </div>
        );
      },
      className: 'colQty',
    },
    !editMode && {
      name: `${t('tableColumnName.subtotal')}`,
      selector: '',
      // cell: row => <div>{row['salePrice'] * row['quantity']}</div>,
      cell: row => (
        <div>
          <Link to={`/products/${row.productId}`}>
            {Utils.convertCurrency(row['listPrice'] * row['quantity'], currency['code'])}
          </Link>
        </div>
      ),
      backOrderCell: row => {
        const isBackOrder = Utils.getSafeValue(row, 'backOrder', true);

        const lineQuantity = (isBackOrder && row.backOrderQuantity) || row.actualQuantity || 0;
        return (
          <div className="backorder-sub-line flex-box backorder-sub-line__subtotal">
            <div className="itemTotalPrice">
              {Utils.convertCurrency(
                lineQuantity * row.listPrice,
                Utils.getSafeValue(currency, 'code', Constants.CurrencyKey.KEY_USD)
              )}
            </div>
          </div>
        );
      },
      className: 'colSubtotal',
    },
    editMode && {
      name: `${t('tableColumnName.estDeliveryDate')}`,
      selector: '',
      cell: (row, index) => (
        <div className="">
          {row.estimateDeliveryDate != null
            ? moment(row.estimateDeliveryDate).format(Constants.DateFormat.valueInput)
            : ''}
        </div>
      ),
      backOrderCell: () => {
        return (
          <div className="backorder-sub-line flex-box">
            <div>-</div>
          </div>
        );
      },
      className: 'colEstDate',
    },
    editMode && {
      name: `${t('tableColumnName.deliveryDate')}`,
      selector: '',
      cell: (row, index) => {
        const estDelivery = Utils.safeParseDate(row.estimateDeliveryDate);

        const deliveryDate = Utils.safeParseDate(row.deliveryDate);

        return (
          <div className="">
            <DatePickerInput
              onChange={onChangeDeliveryDate(index)}
              name="deliveryDate"
              // minDate={new Date()}
              // selected={row.deliveryDate}
              className="datePicker"
              selected={deliveryDate || estDelivery}
              minDate={row.estimateDeliveryDate ? new Date(row.estimateDeliveryDate) : new Date()}
              // openToDate={!row.estimateDeliveryDate && addDays(new Date(), 1)}
              disabled={loadingUpdate || isLoadingDelete}
            />
          </div>
        );
      },
      backOrderCell: row => {
        const isBackOrder = Utils.getSafeValue(row, 'backOrder', true);
        const dateString =
          (isBackOrder && Utils.getSafeValue(row, 'backOrderDeliveryDate', '')) ||
          Utils.getSafeValue(row, 'actualDeliveryDate', '');
        return (
          <div className="backorder-sub-line flex-box">
            {(dateString && moment(dateString).format(Constants.DateFormat.valueInput)) || ''}
          </div>
        );
      },
      className: 'colDdateEdit',
    },
  ];

  const BtnToggle = () => {
    return (
      <button className={`btnMode btnSelectAll ${selectAll ? 'checkedAll' : ''}`} onClick={toggleSelectAll}>
        &nbsp;
      </button>
    );
  };
  const TableProduct = () => {
    return (
      <TableProductList
        columns={columns}
        data={lineItems['physicalItems']}
        isLoading={isLoading}
        onPageChange={e => console.log(e)}
        btnToggle={<BtnToggle />}
        className={!editMode ? 'modeNormal' : 'editMode'}
        currency={currency}
      />
    );
  };

  const [valueCustomRef, setValueCustomRef] = useState('');
  const onChangeRefCustomer = e => {
    let valueLength = e.target.value.length;
    let value = e.target.value;
    if (valueLength > 30) {
      e.preventDefault();
      valueLength = 30;
      value = value.substring(0, 30);
    }
    setValueCustomRef(value);
    setRefCustomer(valueLength);
  };

  const onCloseModal = () => {
    setErrorUpdateCart('');
  };

  return (
    <>
      <div className={`wr-shopping-cart ${editMode ? 'editMode' : ''} ${deleteMode ? 'deleteMode' : ''}`}>
        <ConfirmDeleteModal
          show={showConfirm}
          setShow={value => setShowConfirm(value)}
          onDelete={deleteItems}
          isMultiDelete={true}
        />
        <div className="shoppingCart">
          <div className="blockTitlePage">
            <Container>
              <Row>
                <div className="col-2"></div>
                <div className="col-8">
                  {isLoading ? (
                    <div
                      style={{
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Skeleton variant="rect" width={'100%'} height={40} />
                    </div>
                  ) : (
                    <div className="blockTitlePageInner">
                      <Row>
                        <div className="col-3">
                          <div className="titlePage">
                            <h3>
                              {!editMode ? (
                                <>
                                  {t('cart.myCart')} ({cartCount}{' '}
                                  {cartCount > 1 ? `${t('cart.items')}` : `${t('cart.item')}`})
                                </>
                              ) : (
                                `${t('cart.editCart')}`
                              )}
                            </h3>
                            <div className="orderTotalTop">
                              {t('cart.orderTotal')} {orderTotal}
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="modeAction">
                            {!editMode ? (
                              <>
                                <button
                                  type="button"
                                  className="btnMode importCsv"
                                  onClick={() => setShowDialogImportCSV(true)}
                                >
                                  {t('cart.bulkOrderCSV')}
                                </button>
                                <button
                                  className="btnSetEditMode"
                                  disabled={_.isEmpty(isCart)}
                                  onClick={() => {
                                    setEditMode(!editMode);
                                  }}
                                >
                                  {t('cart.editCart')}
                                </button>
                                <Link
                                  to={'/checkout'}
                                  className={`btnToCheckout btnWithArrow ${_.isEmpty(isCart) ? 'disabled' : ''}`}
                                >
                                  {t('cart.checkout')}
                                  <span className="icnArrow">
                                    <IoIosPlay />
                                  </span>
                                </Link>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btnMode btnCancelEdit"
                                  disabled={loadingUpdate || isLoadingDelete || _.isEmpty(isCart)}
                                  onClick={() => {
                                    setLineItems({
                                      ...lineItemsOrigin,
                                    });
                                    setEditMode(false);
                                  }}
                                >
                                  {t('cart.cancel')}
                                </button>
                                <button
                                  className={`btnMode btnUpdateCart ${loadingUpdate ? 'loading' : ''}`}
                                  disabled={isLoadingDelete || _.isEmpty(isCart)}
                                  onClick={() => updateCart()}
                                >
                                  <span>
                                    {loadingUpdate ? <img src={loading} width={18} /> : `${t('cart.update')}`}
                                  </span>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        {isEnableDeleteButton && !_.isEmpty(isCart) && (
                          <div className="col-12">
                            <div className="modeDelete">
                              <button
                                className={`btnDeleteItem ${isLoadingDelete ? 'loading' : ''}`}
                                disabled={loadingUpdate}
                                onClick={() => {
                                  setShowConfirm(true);
                                }}
                              >
                                {isLoadingDelete ? (
                                  <>
                                    <img src={loading} width={18} /> {t('cart.deleting')}
                                  </>
                                ) : (
                                  `${t('cart.deleteSelected')}`
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Row>
                      {showDeleteSuccessMsg && (
                        <div>
                          <div className="msgDeleteSuccess">
                            {numberItemDelete}{' '}
                            {numberItemDelete > 1
                              ? `${t('cart.itemsRemovedFromCart')}`
                              : `${t('cart.itemRemovedFromCart')}`}
                            <button
                              type="button"
                              onClick={() => setShowDeleteSuccessMsg(false)}
                              className="btnCloseMsg"
                            >
                              <IoIosClose />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-2"></div>
              </Row>
            </Container>
          </div>
          {_.isEmpty(isCart) ? (
            <div className="msgCartEmpty">
              <div className="page-title">{t('cart.msgCartEmpty')}</div>
            </div>
          ) : (
            <>
              <Container>
                <Row>
                  <div className="col-2"></div>
                  <div className="col-8">
                    <div className="shoppingCartInner">
                      <TableProduct />
                    </div>
                  </div>
                  <div className="col-2"></div>
                </Row>
              </Container>
              {!isLoading && !editMode && (
                <Container>
                  <Row>
                    <div className="col-2"></div>
                    <div className="col-8">
                      <div className="shoppingCartInner">
                        <div className="totalCart">
                          <div className="totalCartInner alignLeft">
                            <Table className="tb-cart-totals">
                              <tbody>
                                <tr>
                                  <td className="tdLbl">{t('cart.customerReference')}</td>
                                  <td className="tdValue"></td>
                                </tr>
                                <tr>
                                  <td colSpan={2} className="tdLbl">
                                    {_.isEmpty(cart['customerReference']) && (
                                      <>
                                        <Form onSubmit={methodsReference.handleSubmit(onAddReference)}>
                                          <div className="formField">
                                            <div className="field-wrapper">
                                              <input
                                                className="txt_text"
                                                type="text"
                                                placeholder={`${t('cart.enterCustomerReference')}`}
                                                onChange={e => {
                                                  onChangeRefCustomer(e);
                                                }}
                                                disabled={isAddReference}
                                                value={valueCustomRef}
                                                name="reference"
                                                ref={methodsReference.register}
                                              />
                                              <button
                                                type="submit"
                                                className={`btn-apply ${isAddReference ? 'loading' : ''}`}
                                              >
                                                {isAddReference ? <img src={loading} width={18} /> : `${t('cart.add')}`}
                                              </button>
                                              {/*<br/><span className="valueCount">{refCustomer}/30</span>*/}
                                              {/* GM-701 not display counter*/}
                                            </div>
                                          </div>
                                        </Form>
                                      </>
                                    )}
                                    {!_.isEmpty(cart['customerReference']) && (
                                      <div className="valueAddedWrap">
                                        <div className="valueAdded">{cart['customerReference']}</div>
                                        <div className="removeField">
                                          <button className="btnRemoveRef" onClick={() => deleteReference()}>
                                            {t('cart.remove')}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className="totalCartInner">
                            <Table className="tb-cart-totals">
                              <tbody>
                                <tr>
                                  <td className="tdLbl">
                                    <div className="">
                                      {t('cart.subtotal')} ({cartCount}):
                                    </div>
                                  </td>
                                  <td className="tdValue">
                                    <div className="">{cartSubTotal}</div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="tdLbl">{t('cart.couponCode')}</td>
                                  <td className="tdValue">
                                    {_.isEmpty(cart['coupons']) && (
                                      <button
                                        type="button"
                                        className="showHideCoupon"
                                        disabled={isAddDiscount}
                                        onClick={e => {
                                          setShowCouponForm(!showCouponForm);
                                        }}
                                      >
                                        {showCouponForm ? `${t('cart.cancel')}` : `${t('cart.addCoupon')}`}
                                      </button>
                                    )}
                                    {coupon &&
                                      _.map(cart['coupons'], (item, index) => {
                                        return (
                                          <div className="item" key={index}>
                                            <button
                                              type="button"
                                              className="btnRemoveCoupon"
                                              onClick={() => deleteCoupon(item)}
                                            >
                                              {t('cart.remove')}
                                            </button>
                                          </div>
                                        );
                                      })}
                                  </td>
                                </tr>
                                {coupon && (
                                  <tr>
                                    <td className="tdLbl">
                                      <div className="valueAdded">
                                        {_.map(cart['coupons'], (item, index) => {
                                          return (
                                            <div className="item" key={index}>
                                              <span className="">{item['code']}</span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </td>
                                    <td className="tdValue">
                                      <div className="valueAdded">
                                        {Utils.convertCurrency(-1 * coupon['discountedAmount'], currency['code'])}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {_.isEmpty(cart['coupons']) && showCouponForm && (
                                  <tr>
                                    <td colSpan={2}>
                                      <Form onSubmit={methodsDiscount.handleSubmit(onApplyDiscountCode)}>
                                        <div
                                          className="formField"
                                          style={{
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div className="field-wrapper">
                                            <input
                                              className={`txt_text ${errorMessageDiscount != '' ? 'invalid' : ''}`}
                                              type="text"
                                              onFocus={() => setErrorMessageDiscount('')}
                                              name="discount"
                                              placeholder={`${t('cart.enterCouponCode')}`}
                                              disabled={isAddDiscount}
                                              ref={methodsDiscount.register}
                                            />
                                            <button
                                              type="submit"
                                              className={`btn-apply ${isAddDiscount ? 'loading' : ''}`}
                                            >
                                              {isAddDiscount ? <img src={loading} width={18} /> : `${t('cart.apply')}`}
                                            </button>
                                          </div>
                                          {errorMessageDiscount !== '' && (
                                            <div className="errorMessageDiscount">
                                              <div className="msg">{errorMessageDiscount}</div>
                                            </div>
                                          )}
                                        </div>
                                      </Form>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td colSpan={2} style={{ height: '4px' }}></td>
                                </tr>
                                {showTaxLine && (
                                  <tr>
                                    <td className="tdLbl">
                                      <div className="">{t('checkout.tax')}</div>
                                    </td>
                                    <td className="tdValue">
                                      <div className="">{totalTax}</div>
                                    </td>
                                  </tr>
                                )}

                                <tr className="rowTotal">
                                  <td className="tdLbl">
                                    <div className="">{t('cart.totalAmountPayable')}</div>
                                  </td>
                                  <td className="tdValue">
                                    <div className="">{orderTotal}</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="" colSpan={2}>
                                    <Link to={'/checkout'} className="btnCheckout btnWithArrow">
                                      {t('cart.checkout')}
                                      <span className="icnArrow">
                                        <IoIosPlay />
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </Row>
                </Container>
              )}
            </>
          )}
        </div>
        <ModalAlert message={errorUpdateCart} onCloseModal={() => onCloseModal()} />
      </div>
      <ImportCsvOrder showDialogImportCSV={showDialogImportCSV} setShowDialogImportCSV={setShowDialogImportCSV} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    listCategory: state.getCategoriesTree,
    getCartList: state.getCartList,
    shoppingCart: state.dataCartCSV.shoppingCart,
    appConfig: state.appConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiListCart: params => dispatch(callApiListCart(params)),
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
// export default compose(withConnect)(ShoppingCart);
export default compose(withConnect(withTranslation()(ShoppingCart)));
