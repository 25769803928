import React, { ReactElement, MouseEventHandler } from 'react';
import { CSVLink, CSVDownload } from 'react-csv';
// missing defined in @types/react-csv
export interface LabelKeyObject {
  label: string;
  key: string;
}

export type Data = object[];
export type Headers = LabelKeyObject[] | string[];
export type SyncClickHandler = (event: MouseEventHandler<HTMLAnchorElement>) => boolean | void;
export type AsyncClickHandler = (
  event: MouseEventHandler<HTMLAnchorElement>,
  done: (proceed?: boolean) => void
) => void;

export interface CommonPropTypes {
  data: string | Data;
  headers?: Headers;
  enclosingCharacter?: string;
  separator?: string;
  filename?: string;
  uFEFF?: boolean;
  onClick?: SyncClickHandler | AsyncClickHandler;
  asyncOnClick?: boolean;
}
// tslint:disable-next-line strict-export-declare-modifiers
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface LinkProps
  extends CommonPropTypes,
    Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'onClick'> {}
export interface DownloadPropTypes extends CommonPropTypes {
  target?: string;
}
//end @types/react-csv

interface Props extends LinkProps, DownloadPropTypes {
  children: ReactElement;
  className?: string;
}
function CSVDownloader(props: Props): ReactElement {
  //CSVDownload triggers downloading ONLY on mounting the component.
  //so , be careful to render this component whenever it is needed. (docs)

  const { data, headers, separator = ',', enclosingCharacter = '', filename, children } = props;
  return (
    <CSVLink
      data={data}
      headers={headers}
      separator={separator}
      enclosingCharacter={enclosingCharacter}
      filename={filename}
    >
      {children}
    </CSVLink>
  );
}

export default CSVDownloader;
