import React from 'react';
import * as Utils from '../../utils';
import './styles.scss';

export interface PropsItemAddress {
  item: any;
  showCompany?: boolean;
  addedAddress?: boolean;
  isMulti?: boolean;
}

export const UserAddress = ({ item, showCompany, addedAddress, isMulti }: PropsItemAddress) => {
  let fullName = item.firstName + ' ' + item.lastName;
  let company = item.company;
  let street1 = item.street1;
  let street2 = item.street2 ? item.street2 : '';
  let city = item.city;
  let zip = item.zip ? item.zip : '';
  let state = item.state ? item.state : '';
  let country = item.country;
  let phone = item.phone ? item.phone : '';
  let email = item.email ? item.email : '';

  return (
    <>
      <div className="boxInfo">
        {Utils.getAddressDisplayType(item) === 0 && (
          <>
            {addedAddress && <div className="fullName">{fullName}</div>}
            {company && <div className="fullName">{company}</div>}
            <div className="">{street1}</div>
            {street2 && <div>{street2}</div>}
            <div className="">
              {zip && zip + ' '}
              {city}
            </div>
            {state && <div>{state}</div>}
            <div>{country}</div>
            {phone && (
              <div>
                <a className="colorBlue" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            )}
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
          </>
        )}
        {Utils.getAddressDisplayType(item) === 1 && (
          <>
            {addedAddress && <div className="fullName">{fullName}</div>}
            {company && <div className="fullName">{company}</div>}
            <div className="">{street1}</div>
            {street2 && <div>{street2}</div>}
            <div className="">
              {city}
              <br />
              {zip && zip} {state && state}
            </div>
            <div>{country}</div>
            {phone && (
              <div>
                <a className="colorBlue" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            )}
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
          </>
        )}
        {Utils.getAddressDisplayType(item) === 2 && (
          <>
            {addedAddress && <div className="fullName">{fullName}</div>}
            {company && <div className="fullName">{company}</div>}
            <div className="">{street1}</div>
            {street2 && <div>{street2}</div>}
            <div className="">
              {city} {state && state} {zip && zip}
            </div>
            <div>{country}</div>
            {phone && (
              <div>
                <a className="colorBlue" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            )}
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
          </>
        )}
        {Utils.getAddressDisplayType(item) === 3 && (
          <>
            {addedAddress && <div className="fullName">{fullName}</div>}
            {company && <div className="fullName">{company}</div>}
            <div className="">{street1}</div>
            {street2 && <div>{street2}</div>}
            <div className="">
              {zip && zip + ' '}
              {city} {state && state}
            </div>
            <div>{country}</div>
            {phone && (
              <div>
                <a className="colorBlue" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            )}
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
          </>
        )}
        {Utils.getAddressDisplayType(item) === 4 && (
          <>
            {addedAddress && <div className="fullName">{fullName}</div>}
            {company && <div className="fullName">{company}</div>}
            <div className="">{street1}</div>
            {street2 && <div>{street2}</div>}
            <div>{city}</div>
            {zip + state != '' && (
              <div className="">
                {zip && zip + ' '}
                {state && state}
              </div>
            )}
            <div>{country}</div>
            {phone && (
              <div>
                <a className="colorBlue" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            )}
            {email && (
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
          </>
        )}
        {isMulti && <div className="divider" />}
        {/*<div className="semiBold" style={{marginBottom: '12px'}}>{item.firstName} {item.lastName}</div>*/}
        {/*<div className="">{item.phone}</div>*/}
        {/*<div className="">{item.street1 + ' '}</div>*/}
        {/*{item.street2 && <div>{item.street2 + ' '}</div>}*/}
        {/*<div className="">{item.zip && item.zip + ' '} {item.city}</div>*/}
        {/*<div className="">{item.state ? item.state + ' ' : ''} {item.country}</div>*/}

        {/*{item.email && <div style={{marginTop: '7px'}}>{item.email}</div>}*/}
      </div>
    </>
  );
};
export default UserAddress;
