import React, { createRef, useEffect, useState } from 'react';
import './../style.scss';
import { useForm } from 'react-hook-form';
import { Button, Container, Form, Image } from 'react-bootstrap';
import _ from 'lodash';
import { compose } from 'redux';
import { ProductLineItemType } from 'src/types/cart';
import { callApiACT } from '../../../redux/callApiRDC';
import * as AppURL from '../../../services/urlAPI';
import * as Utils from '../../../utils';
import { reducerNameHOR } from '../../../redux';
import { TypeRequest } from '../../../constants';
import localStorage from '../../../utils/LocalStorage';
import * as Constants from '../../../constants';
import { connect, useDispatch } from 'react-redux';
import { callApiListCart, clearCart } from '../../../redux/getListCartRDC';
import DatePicker from 'react-datepicker';
import { IoIosArrowBack, IoIosPlay } from 'react-icons/io';
import Row from 'react-bootstrap/Row';
import loading from '../../../assets/loadingx43px.svg';
import loading60 from '../../../assets/loading60Black.svg';
import { useHistory } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next';
import clsx from 'clsx';

interface Props {
  callApiACT: (action) => void;
  activeStep: number;
  setActiveStep: (param) => void;
  steps: any[];
  callApiListCart: (action) => void;
  setStepData: (number, any) => void;
  appConfig: any;
  setCurrentStep: (param) => void;
  currentStep?: number;
  setLoadingState: (param) => void;
  loadingState?: boolean;
}

function OrderReview({
  callApiACT,
  callApiListCart,
  setActiveStep,
  activeStep,
  setStepData,
  setCurrentStep,
  currentStep,
  appConfig,
  setLoadingState,
  loadingState,
}: any) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [checkedConfirm, setCheckedConfirm] = useState(false);
  const [cartCount, setCartCount] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState({});
  const [selectedShippingAddress, setSelectedShippingAddress] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<any>({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({});
  const [cart, setCart] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [currency, setCurrency] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState([]);
  const [handlingCost, setHandlingCost] = useState(0);
  const [orderInfo, setOrderInfo] = useState();
  const [termSettings, setTermSettings] = useState({});
  const [noteOrder, setNoteOrder] = useState<string>('');

  const methods = useForm();

  const [valueCustomRef, setValueCustomRef] = useState(cart['customerReference']);
  const [isAddReference, setIsAddReference] = useState(false);
  const [showBtnCustomReference, setShowBtnCustomReference] = useState(false);

  let deviceKey = localStorage.get('deviceKey', '');
  let appKey = localStorage.get('appKey', '');
  let cartId = localStorage.get('cartId', '');

  useEffect(() => {
    if (currentStep == 3) {
      getCountCart();
      getCart();
    }
  }, [activeStep]);

  useEffect(() => {
    setValueCustomRef(cart['customerReference']);
  }, [cart]);

  useEffect(() => {
    let termSettings = localStorage.getObject(Constants.KeyAsyncStore.termSettings, {});
    if (!termSettings['isEnabled']) {
      setCheckedConfirm(true);
    }
    setTermSettings(termSettings);
  }, []);

  const getCountCart = async () => {
    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.GET,
        url: Utils.replaceStrUrl(AppURL.getCartCount, [appKey, deviceKey, cartId]),
        name: reducerNameHOR.getCartCount,
      });
      let count = Utils.getSafeValue(data, 'count', 0);
      setCartCount(count);
    } catch (e) {}
  };

  const getCart = async () => {
    setLoadingState(true);
    let params = [appKey, deviceKey, cartId];

    try {
      let data = await callApiListCart({
        url: Utils.replaceStrUrl(AppURL.getCartGroupItem, params),
        name: reducerNameHOR.getCartList,
        typeRequest: Constants.TypeRequest.GET,
      });

      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      localStorage.set('cartId', cart['id']);
      setCart(cart);

      setSelectedBillingAddress(Utils.getSafeValue(cart, 'billingAddress', {}));

      let consignments = Utils.getSafeValue(cart, 'consignments', []);
      let _shippingMethod = localStorage.getObject('selectedShippingMethod', '');
      setSelectedShippingAddress(consignments['0']['shippingAddress']);
      setSelectedShippingMethod(_.find(consignments['0']['shippingOptions'], { id: _shippingMethod }));

      let paymentMethods = Utils.getSafeValue(cart, 'paymentMethods', []);
      let _paymentMethod = localStorage.getObject('selectedPaymentMethod', '');
      setSelectedPaymentMethod(_.find(paymentMethods, { code: _paymentMethod }));

      let lineItems = Utils.getSafeValue(cart, 'lineItems', {});
      setLineItems(lineItems);

      let currency = Utils.getSafeValue(cart, 'currency', {});
      setCurrency(currency);

      let coupon = Utils.getSafeValue(cart, 'coupons', []);
      setCoupon(coupon[0]);

      let handlingCost = consignments[0]['handlingCostExTax'];
      setHandlingCost(handlingCost);
    } catch (e) {
      console.log('error message get cart: ', e);
    }
    setLoadingState(false);
  };

  const createNewOrder = async e => {
    setIsLoading(true);

    let appKey = localStorage.get(Constants.KeyAsyncStore.appKey, '');
    let deviceKey = localStorage.get(Constants.KeyAsyncStore.deviceKey, '');
    let cartId = localStorage.get(Constants.KeyAsyncStore.cartId, '');
    let method = localStorage.getObject('selectedPaymentMethod', '');
    let note = e.note;
    let toastMessageSuccess = 'Place Order Success. Thank you!';

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: Utils.replaceStrUrl(AppURL.createNewOrder, [appKey, deviceKey]),
        name: reducerNameHOR.createNewOrder,
        params: {
          cartId: cartId,
          paymentMethod: method,
          note: note,
        },
        isToastSuccess: false,
        toastMessageSuccess: toastMessageSuccess,
      });

      onComleteOrder(data);
      dispatch(clearCart());
      setCurrentStep(4);
    } catch (e) {}
    setIsLoading(false);
  };

  let history = useHistory();
  const onComleteOrder = data => {
    setOrderInfo(data);
    history.push({
      pathname: '/thankyou',
      state: data,
    });
    localStorage.removeItem(Constants.KeyAsyncStore.cartId);
    localStorage.removeItem(Constants.KeyAsyncStore.newAddresses);
    localStorage.removeItem(Constants.KeyAsyncStore.stepCheckout);
    localStorage.removeItem('selectedPaymentMethod');
    localStorage.removeItem('selectedShippingMethod');
  };

  const onBlurNote = e => {
    let value = e.target.value;
    setNoteOrder(value.trim());
  };

  const onChangeRefCustomer = e => {
    let valueLength = e.target.value.length;
    let value = e.target.value;
    if (valueLength > 30) {
      e.preventDefault();
      valueLength = 30;
      value = value.substring(0, 30);
    }
    if (value != cart['customerReference']) {
      setShowBtnCustomReference(true);
    } else {
      setShowBtnCustomReference(false);
    }
    setValueCustomRef(value);
  };

  const onCancelChangeReference = () => {
    setValueCustomRef(cart['customerReference']);
    setShowBtnCustomReference(false);
  };

  const onAddReference = async () => {
    setIsAddReference(true);
    let params = {
      appKey: localStorage.get(Constants.KeyAsyncStore.appKey, ''),
      appTypeCode: localStorage.get(Constants.KeyAsyncStore.appTypeCode, ''),
      deviceKey: localStorage.get(Constants.KeyAsyncStore.deviceKey, ''),
      // customerReference: data.reference,
      customerReference: valueCustomRef,
    };

    try {
      let data = await callApiACT({
        typeRequest: Constants.TypeRequest.PUT,
        url: Utils.replaceStrUrl(AppURL.addReference, [localStorage.get(Constants.KeyAsyncStore.cartId, '')]),
        params: params,
        isToastErr: false,
      });
      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      setCart(cart);
      setIsAddReference(false);
      setShowBtnCustomReference(false);
    } catch (e) {}
  };

  return (
    <>
      {currentStep && currentStep >= 3 && (
        <div className="orderReview">
          <Form onSubmit={methods.handleSubmit(createNewOrder)}>
            <Container>
              <Row>
                <div className="col-2"></div>
                <div className="col-8">
                  <Row>
                    <div className="col-6">
                      <div className="boxCol">
                        <div className="title">5. {t('checkout.customerReference')}</div>
                        {/*<div className="">{cart['customerReference'] || '---'}</div>*/}

                        <div className="">
                          {/*<Form onSubmit={methodsReference.handleSubmit(onAddReference)}>*/}
                          <div className="formField">
                            <div className="field-wrapper">
                              <textarea
                                className="txt_area"
                                placeholder={`${t('cart.enterCustomerReference')}`}
                                onChange={e => {
                                  onChangeRefCustomer(e);
                                }}
                                disabled={isAddReference}
                                value={valueCustomRef}
                                // name="reference"
                                // ref={methodsReference.register}
                              />
                              {showBtnCustomReference && (
                                <div className="checkoutCustomReference">
                                  <button
                                    type="button"
                                    onClick={() => onCancelChangeReference()}
                                    className="btn-cancel"
                                  >
                                    {t('cart.cancel')}
                                  </button>
                                  <button
                                    onClick={() => onAddReference()}
                                    type="button"
                                    className={`btn-apply ${isAddReference ? 'loading' : ''}`}
                                  >
                                    {isAddReference ? <img src={loading} width={18} /> : `${t('cart.update')}`}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          {/*</Form>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="boxCol">
                        <div className="title">6. {t('checkout.orderNote')}</div>

                        <textarea
                          name="note"
                          placeholder={`${t('checkout.placeHolderOrderNote')}`}
                          className="txt_area"
                          onBlur={e => {
                            onBlurNote(e);
                          }}
                          onChange={e => {
                            setNoteOrder(e.target.value);
                          }}
                          ref={methods.register}
                          value={noteOrder}
                        />
                      </div>
                    </div>
                  </Row>
                  <div className="boxCol">
                    <div className="order-summary">
                      <div className="title">7. {t('checkout.orderSummary')}</div>
                      <div className="orderReviewProduct">
                        <table>
                          <thead>
                            <tr>
                              <th className="img"></th>
                              <th className="pName">{t('tableColumnName.name')}</th>
                              <th className="pOptionColor">{t('tableColumnName.color')}</th>
                              <th className="pOPtionSize">{t('tableColumnName.size')}</th>
                              <th className="pQty">{t('tableColumnName.qty')}</th>
                              <th className="pDeliveryDate">{t('tableColumnName.deliveryDate')}</th>
                              <th className="pSalePrice">{t('tableColumnName.price')}</th>
                              <th className="pSubTotal">{t('tableColumnName.total')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(lineItems['physicalItems'], (item, index) => {
                              let options = item['options'];
                              let color = _.find(options, {
                                name: 'Color',
                              });
                              let size = _.find(options, {
                                name: 'Size',
                              });

                              const isBackOrder = Utils.getSafeValue(item, 'backOrder', false);

                              const itemType = Utils.getSafeValue(item, 'type', ProductLineItemType.NORMAL);

                              let groupItems = Utils.getSafeValue(item, 'groupItems', []);
                              if (isBackOrder && itemType === ProductLineItemType.NORMAL) {
                                groupItems = [item];
                              }

                              const backOrderRow = isBackOrder || groupItems.length > 0;

                              return (
                                <React.Fragment key={index}>
                                  <tr className={`product ${item['backOrderQuantity'] > 0 ? 'hasItemChild' : ''}`}>
                                    <td className={clsx('img', { 'product-item__backorder': backOrderRow })}>
                                      <Image src={item['imageUrl']} />
                                    </td>
                                    <td className={clsx('pName', { 'product-item__backorder': backOrderRow })}>
                                      <div className="pNameItem">{item['name']}</div>
                                      <div className="pSku">{item['sku']}</div>
                                    </td>
                                    <td className={clsx('pOptionColor', { 'product-item__backorder': backOrderRow })}>
                                      {color ? color['value'] : ''}
                                    </td>
                                    <td className={clsx('pOPtionSize', { 'product-item__backorder': backOrderRow })}>
                                      {size ? size['value'] : ''}
                                    </td>
                                    <td className={clsx('pQty', { 'product-item__backorder': backOrderRow })}>
                                      {item['quantity']}
                                    </td>
                                    <td className={clsx('pDeliveryDate', { 'product-item__backorder': backOrderRow })}>
                                      {!item['backOrder'] && item['deliveryDate']
                                        ? moment(item['deliveryDate'], Constants.DateFormat.serverDateFormat).format(
                                            Constants.DateFormat.valueInput
                                          )
                                        : ''}
                                    </td>
                                    <td className={clsx('pSalePrice', { 'product-item__backorder': backOrderRow })}>
                                      {Utils.convertCurrency(item['listPrice'], currency['code'])}
                                    </td>
                                    <td className={clsx('pSubTotal', { 'product-item__backorder': backOrderRow })}>
                                      {Utils.convertCurrency(item['quantity'] * item['listPrice'], currency['code'])}
                                    </td>
                                  </tr>
                                  {groupItems.map((lineItem, indexSubItem) => {
                                    const isBackOrderItem = Utils.getSafeValue(lineItem, 'backOrderQuantity', 0) > 0;
                                    const backorderType =
                                      (isBackOrderItem && t('checkout.backorder')) || t('checkout.standard');

                                    const lineQuantity =
                                      (isBackOrderItem && lineItem.backOrderQuantity) || lineItem.actualQuantity || 0;

                                    let deliveryDate = '-';

                                    if (isBackOrderItem) {
                                      if (lineItem.backOrderDeliveryDate) {
                                        deliveryDate = moment(
                                          lineItem.backOrderDeliveryDate,
                                          Constants.DateFormat.serverDateFormat
                                        ).format(Constants.DateFormat.valueInput);
                                      }
                                    } else if (lineItem.actualDeliveryDate) {
                                      deliveryDate = moment(
                                        lineItem.actualDeliveryDate,
                                        Constants.DateFormat.serverDateFormat
                                      ).format(Constants.DateFormat.valueInput);
                                    }
                                    const classNames = clsx('rowSeparatorQty', { 'first-subline': indexSubItem === 0 });
                                    return (
                                      <tr className={classNames}>
                                        <td className="img backorder">
                                          <div className="backorder_sub-line flex-box backorder_sub-line__rectangle" />
                                        </td>
                                        <td className="pName backorder">
                                          <div className="backorder_sub-line flex-box backorder_sub-line__product">
                                            <div className="">{backorderType}</div>
                                          </div>
                                        </td>
                                        <td className="pOptionColor backorder">
                                          <div className="backorder_sub-line flex-box"> </div>
                                        </td>
                                        <td className="pOPtionSize backorder">
                                          <div className="backorder_sub-line flex-box"> </div>
                                        </td>
                                        <td className="pQty backorder">
                                          <div className="backorder_sub-line flex-box">{lineQuantity}</div>
                                        </td>
                                        <td className="pDeliveryDate backorder">
                                          <div className="backorder_sub-line flex-box">{deliveryDate}</div>
                                        </td>
                                        <td className="pSalePrice backorder">
                                          <div className="backorder_sub-line flex-box">
                                            {Utils.convertCurrency(
                                              lineItem.listPrice,
                                              Utils.getSafeValue(currency, 'code', Constants.CurrencyKey.KEY_USD)
                                            )}
                                          </div>
                                        </td>
                                        <td className="pSubTotal backorder end-TD">
                                          <div className="backorder_sub-line flex-box backorder_sub-line__subtotal">
                                            {Utils.convertCurrency(
                                              lineQuantity * lineItem.listPrice,
                                              Utils.getSafeValue(currency, 'code', Constants.CurrencyKey.KEY_USD)
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="orderReviewTotal">
                        <table>
                          <tbody>
                            <tr>
                              <td className="fieldLbl">
                                {t('checkout.subtotal')} ({cartCount}):
                              </td>
                              <td>{Utils.convertCurrency(cart['baseAmount'], currency['code'])}</td>
                            </tr>
                            {coupon && (
                              <tr>
                                <td className="fieldLbl">{t('checkout.discount')}</td>
                                <td>{Utils.convertCurrency(-1 * coupon['discountedAmount'], currency['code'])}</td>
                              </tr>
                            )}

                            <tr>
                              <td className="fieldLbl">{t('checkout.shipping')}</td>
                              <td>
                                <div>
                                  {selectedShippingMethod && selectedShippingMethod['cost']
                                    ? Utils.convertCurrency(selectedShippingMethod['cost'], currency['code'])
                                    : Utils.convertCurrency(0, currency['code'])}
                                </div>
                              </td>
                            </tr>
                            {handlingCost !== 0 && (
                              <tr>
                                <td className="fieldLbl">{t('checkout.handling')}</td>
                                <td>{Utils.convertCurrency(handlingCost, currency['code'])}</td>
                              </tr>
                            )}

                            <tr>
                              <td className="fieldLbl">{t('checkout.tax')}</td>
                              <td>{Utils.convertCurrency(cart['taxTotal'], currency['code'])}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ height: '4px' }}></td>
                            </tr>
                            <tr className="fieldTotal">
                              <td className="fieldLbl">{t('checkout.totalAmountPayable')}</td>
                              <td>{Utils.convertCurrency(cart['grandTotal'], currency['code'])}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="fieldPlaceOrder">
                      <div className="fieldInner">
                        {termSettings['isEnabled'] && (
                          <label className="lblCheckbox">
                            <input
                              id="confirm"
                              name="confirm"
                              type="checkbox"
                              checked={checkedConfirm}
                              onChange={() => setCheckedConfirm(!checkedConfirm)}
                            />
                            <span className="icnCheckbox"></span>
                            {termSettings['type'] == 'text' ? (
                              <span dangerouslySetInnerHTML={{ __html: termSettings['textContent'] }} />
                            ) : (
                              <span className="lblText">
                                {t('checkout.msgTermLine1')}{' '}
                                <a className="cursor-pointer" href={termSettings['linkContent']} target="_blank">
                                  {' '}
                                  {t('checkout.msgTermLine2')}
                                </a>
                              </span>
                            )}
                          </label>
                        )}

                        <div className="fieldBtnPlaceOrder">
                          <Button
                            type="submit"
                            className="btnPlaceOrder"
                            disabled={currentStep != 3 || !checkedConfirm}
                          >
                            {isLoading ? <img src={loading} width={18} /> : `${t('checkout.placeOrder')}`}
                            <span className="icnIcon">
                              <IoIosPlay />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </Row>
            </Container>
            <div className={`placeOrderTop ${termSettings['isEnabled'] ? '' : 'termDisable'}`}>
              <Container>
                <Row>
                  <div className="col-2"></div>
                  <div className="col-8">
                    <div className="placeOrderTopInner">
                      <div className="fieldInner">
                        <div className="row">
                          <div className="col-7">
                            <div className="titlePage">{t('checkout.checkout')}</div>
                            <div className="fieldTotalOrderTop">
                              {t('checkout.grandTotal')}{' '}
                              <span className="semiBold">
                                {Utils.convertCurrency(cart['grandTotal'], currency['code'])}
                              </span>
                            </div>
                            {/*{termSettings['isEnabled'] && (*/}
                            {/*<label className="lblCheckbox">*/}
                            {/*<input*/}
                            {/*id="confirm"*/}
                            {/*name="confirm"*/}
                            {/*type="checkbox"*/}
                            {/*checked={checkedConfirm}*/}
                            {/*onChange={() => setCheckedConfirm(!checkedConfirm)}*/}
                            {/*/>*/}
                            {/*<span className="icnCheckbox"></span>*/}
                            {/*{termSettings['type'] == 'text' ? (*/}
                            {/*<span dangerouslySetInnerHTML={{ __html: termSettings['textContent'] }} />*/}
                            {/*) : (*/}
                            {/*<span className="lblText">*/}
                            {/*{t('checkout.msgTermLine1')}{' '}*/}
                            {/*<a className="cursor-pointer" href={termSettings['linkContent']} target="_blank">*/}
                            {/*{' '}*/}
                            {/*{t('checkout.msgTermLine2')}*/}
                            {/*</a>*/}
                            {/*</span>*/}
                            {/*)}*/}
                            {/*</label>*/}
                            {/*)}*/}
                          </div>
                          {/*<div className="col-5">*/}
                          {/*<div className="fieldBtnPlaceOrder">*/}
                          {/*<Button*/}
                          {/*type="submit"*/}
                          {/*className="btnPlaceOrder"*/}
                          {/*disabled={currentStep != 3 || !checkedConfirm}*/}
                          {/*>*/}
                          {/*{isLoading ? <img src={loading} width={18} /> : `${t('checkout.placeOrder')}`}*/}
                          {/*<span className="icnIcon">*/}
                          {/*<IoIosPlay />*/}
                          {/*</span>*/}
                          {/*</Button>*/}
                          {/*</div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2"></div>
                </Row>
              </Container>
            </div>
          </Form>

          <Modal
            dialogClassName={'modalWaiting'}
            size="lg"
            // show={true}
            show={isLoading}
          >
            <Modal.Body>
              <div className="modalBody">
                <div className="dialogMsg" style={{ paddingBottom: '30px' }}>
                  {t('checkout.placingOrder')}
                </div>
                <div className="iconWaiting">
                  <img src={loading60} />
                </div>
                <div className="" style={{ paddingTop: '30px' }}>
                  {t('checkout.doNotCloseBrowser')}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
    callApiListCart: params => dispatch(callApiListCart(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// export default compose(withConnect)(OrderReview);
export default compose(withConnect(withTranslation()(OrderReview)));
