import { API_CALL_REQUEST_CART, API_CALL_SUCCESS_CART, API_CALL_FAILURE_CART } from '../redux/getListCartRDC';
import { changeCartCount } from '../redux/getCartCountRDC';
import { ShowAlert } from '../redux/showAlert';

import * as AppURL from '../services/urlAPI';
// import * as AnalyticHelper from "../utils/AnalyticHelper";
// import GlobalSettingApp from "../config/GlobalSettingApp";
// import * as i18Service from "../utils/I18nService";

import { put, takeEvery, call, all, fork, takeLatest } from 'redux-saga/effects';
// import { delay } from "redux-saga";
import { getDataAPI, postDataAPI, putDataAPI, deleteDataAPI } from '../services/callDataAPI';
import Lodash from 'lodash';
import * as Constants from '../constants';
import * as Utils from '../utils';
// import { showError } from "../utils/MessageApp";
import { callApiACT } from '../redux/callApiRDC';
import { reducerNameHOR } from '../redux';
import store from '../redux';
import localStorage from '../utils/LocalStorage';

function* requestAPI(action) {
  let response;
  let errMessage;

  if (action.typeRequest === Constants.TypeRequest.GET) {
    const responseAPI = yield getDataAPI(action.url);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  } else if (action.typeRequest === Constants.TypeRequest.POST) {
    const responseAPI = yield postDataAPI(action.url, action.params);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  } else if (action.typeRequest === Constants.TypeRequest.PUT) {
    const responseAPI = yield putDataAPI(action.url, action.params);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  } else if (action.typeRequest === Constants.TypeRequest.DELETE) {
    const responseAPI = yield deleteDataAPI(action.url, action.params);
    response = responseAPI.response;
    errMessage = responseAPI.errMessage;
  }

  if (response) {
    let errorObject = Utils.getSafeValue(response, 'error', {});
    let errorCode = Utils.getSafeValue(errorObject, 'code', 0);

    if (Lodash.isEmpty(errorObject) || errorCode === 0) {
      let cart = Utils.getSafeValue(response, 'cart', {});
      let itemCount = Utils.getSafeValue(cart, 'itemCount', 0);
      let appKey = localStorage.get('appKey', '');
      let deviceKey = localStorage.get('deviceKey', '');

      // Send event add to cart here.
      sendAnalyticEvent(response, action);
      yield all([
        put({
          ...action,
          type: API_CALL_SUCCESS_CART,
          response,
        }),
        //put(changeCartCount(itemCount))
      ]);
      if (action.isToastSuccess === true) {
        yield put(ShowAlert({ type: 'success', messages: action.toastMessageSuccess }));
      }
      // if (!action.notRequestCrossSell) {
      //   yield put(
      //     callApiACT({
      //       typeRequest: Constants.TypeRequest.GET,
      //       name: reducerNameHOR.getListCartCrossSell,
      //       url: Utils.replaceStrUrl(AppURL.getCrossSell, [
      //         'bcm',
      //         "0",
      //         appKey,
      //         deviceKey,
      //         ""
      //       ])
      //     })
      //   );
      // }
      action.resolve(response);
    } else {
      let errorMessage = Utils.getSafeValue(errorObject, 'message', Constants.ErrMessage.systemError);

      const isShowErr = Utils.getSafeValue(action, 'isShowErr', true);

      yield put({
        ...action,
        type: API_CALL_FAILURE_CART,
        errorMessage,
      });
      //
      // if (isShowErr) {
      //   showError(`${i18Service.strings("ErrMsg.titleErr")}`, errorMessage);
      // }
      if (action.isToastErr === true) {
        yield put(
          ShowAlert({ type: 'error', messages: errorMessage, autoClose: action.autoCLoseErrorMessage || 600000 })
        );
      }
      action.reject(errorMessage);
    }
  } else {
    yield put({
      ...action,
      type: API_CALL_FAILURE_CART,
      errMessage,
    });

    const isShowErr = Utils.getSafeValue(action, 'isShowErr', true);
    // if (isShowErr) {
    //   showError(`${i18Service.strings("ErrMsg.titleErr")}`, errorMessage);
    // }

    action.reject(errMessage);
  }
}

function* watcherSaga() {
  yield takeEvery(API_CALL_REQUEST_CART, requestAPI);
}

function sendAnalyticEvent(response, action) {
  const analyticObj = Utils.getSafeValue(action, 'analyticObj', {});

  if (Lodash.isEmpty(analyticObj)) {
    return;
  }

  const productId = Utils.getSafeValue(analyticObj, 'productId', null);
  const nameEventAnalytic = Utils.getSafeValue(analyticObj, 'nameEventAnalytic', '');

  var listItemCart = Utils.getSafeValue(response, 'cart.items', []);
  // if (nameEventAnalytic === Constants.AnalyticEventName.REMOVE_FROM_CART) {
  //   const objListCart = store.getState().getCartList;
  //   listItemCart = Utils.getSafeValue(objListCart, "cart.items", []);
  // }
  const productInCart = Lodash.find(listItemCart, obj => {
    const idItem = Utils.getSafeValue(obj, 'product.id', null);

    return idItem === productId;
  });

  if (Lodash.isEmpty(productInCart)) {
    return;
  }

  const productCartObj = Utils.getSafeValue(productInCart, 'product', {});
  const idProductCart = Utils.getSafeValue(productCartObj, 'id', 0);
  const titleProductCart = Utils.getSafeValue(productCartObj, 'title', '');
  const productSelectionObj = Utils.getSafeValue(productInCart, 'itemSelection', {});
  const qtySelection = Utils.getSafeValue(productSelectionObj, 'qty', 0);
  const priceSelection = Utils.getSafeValue(productSelectionObj, 'price', 0);

  const { CurrencyKey } = Constants;
  const currencyCode = CurrencyKey.KEY_USD;

  const validTitle = Utils.truncateTextAnalytic(titleProductCart);
  const validIdProduct = Utils.truncateTextAnalytic(`${idProductCart}`);
  const params = {
    [Constants.AnalyticParamName.itemId]: validIdProduct,
    [Constants.AnalyticParamName.itemName]: validTitle,
    [Constants.AnalyticParamName.quantity]: qtySelection,
    [Constants.AnalyticParamName.price]: priceSelection,
    [Constants.AnalyticParamName.currency]: currencyCode,
  };

  // if (nameEventAnalytic === Constants.AnalyticEventName.ADD_TO_CART) {
  //   AnalyticHelper.sendEvent(Constants.AnalyticEventName.ADD_TO_CART, params);
  // } else if (nameEventAnalytic === Constants.AnalyticEventName.UPDATE_TO_CART) {
  //   AnalyticHelper.sendEvent(
  //     Constants.AnalyticEventName.UPDATE_TO_CART,
  //     params
  //   );
  // } else if (
  //   nameEventAnalytic === Constants.AnalyticEventName.REMOVE_FROM_CART
  // ) {
  //   AnalyticHelper.sendEvent(
  //     Constants.AnalyticEventName.REMOVE_FROM_CART,
  //     params
  //   );
  // }
}

export default watcherSaga;
