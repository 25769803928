import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import * as PapaParse from 'papaparse';

export interface IFileInfo {
  name: string;
  size: number;
  type: string;
}

export interface CSVReaderProps {
  accept?: string;
  cssClass?: string;
  cssInputClass?: string;
  fileEncoding?: string;
  inputId?: string;
  inputStyle?: object;
  label?: string | React.ReactNode;
  onError?: (error: Error) => void;
  onFileLoaded: (data: Array<any>, fileInfo: IFileInfo, files: any) => any;
  parserOptions?: PapaParse.ParseConfig;
  disabled?: boolean;
  propRef?: any;
  name?: string;
}

const CSVReader: React.FC<CSVReaderProps> = ({
  accept = '.csv, text/csv',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  fileEncoding = 'UTF-8',
  inputId = 'react-csv-reader-input',
  inputStyle = {},
  label,
  onError,
  onFileLoaded,
  propRef,
  name,
  disabled,
  parserOptions = {} as PapaParse.ParseConfig,
}) => {
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader: FileReader = new FileReader();
    const files: FileList = e.target.files!;
    if (files.length > 0) {
      const fileInfo: IFileInfo = {
        name: files[0].name,
        size: files[0].size,
        type: files[0].type,
      };

      reader.onload = (_event: Event) => {
        const csvData = PapaParse.parse(
          reader.result as string,
          Object.assign(parserOptions, {
            error: onError,
            encoding: fileEncoding,
          })
        );

        onFileLoaded(csvData.data.length > 0 ? csvData.data : [], fileInfo, csvData);
      };

      reader.readAsText(files[0], fileEncoding);
    }
  };

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        ref={propRef}
        name={name}
        onChange={e => handleChangeFile(e)}
        disabled={disabled}
        onClick={e => {
          e.currentTarget.value = '';
        }}
      />
    </div>
  );
};

CSVReader.propTypes = {
  accept: PropTypes.string,
  cssClass: PropTypes.string,
  cssInputClass: PropTypes.string,
  fileEncoding: PropTypes.string,
  inputId: PropTypes.string,
  inputStyle: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onError: PropTypes.func,
  onFileLoaded: PropTypes.func.isRequired,
  parserOptions: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CSVReader;
