import { omit } from 'lodash';
import React, { createContext, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { AddToCartStatus } from 'src/components/Button/ActionButton';
import { FilterItem, FilterOption, PriceFilterParams, ProductListQueryParams } from 'src/types/productList';
import { FilterListActionType, filterListReducer, initFilterListState } from './filterList.reducer';
import {
  initProductsParams,
  ProductListDispatch,
  ProductsParamsActionType,
  productsParamsReducer,
} from './params.reducer';
import { ProductListDispatchContext, ProductListStateContext } from './productList.provider';
import { initProductListState, productListReducer, ProductsListActionType } from './productList.reducer';
import { ErrorQuantity, SimpleProductAction, simpleProductReducer } from './simpleProduct.reducer';

const useProductListContext = () => {
  const state = useContext(ProductListStateContext);

  if (state === undefined) {
    throw new Error('must be used within a Provider');
  }

  return state;
};

const useProductListDispatchContext = () => {
  const dispatch = useContext(ProductListDispatchContext);

  if (dispatch === undefined) {
    throw new Error('must be used within a Provider');
  }

  return dispatch;
};

export const useProductList = () => {
  const params = useProductListContext();
  const dispatchParams = useProductListDispatchContext();
  const changeKeyword = (keyword: string) => {
    dispatchParams({ type: ProductsParamsActionType.CHANGE_KEYWORD, payload: keyword });
  };
  const changeCategory = (catId: number | string) => {
    dispatchParams({ type: ProductsParamsActionType.CHANGE_CATEGORY_ID, payload: catId });
  };
  const changeSortOrder = (sortBy: string, sortOrder = 'desc' as const) => {
    dispatchParams({ type: ProductsParamsActionType.CHANGE_SORTING, payload: sortBy });
    dispatchParams({ type: ProductsParamsActionType.CHANGE_SORTING_DIRECTION, payload: sortOrder });
  };
  const changePagination = (page: number) => {
    dispatchParams({
      type: ProductsParamsActionType.CHANGE_PAGINATION,
      payload: page,
    });
  };
  const changePerpage = (itemPerPage: number) => {
    dispatchParams({
      type: ProductsParamsActionType.CHANGE_PERPAGE,
      payload: itemPerPage,
    });
  };

  const onChangeFilter = (filterOption: FilterOption, optionValue: FilterItem) => {
    changePagination(1);
    const { filters } = params;
    // console.log({ filters, filterOption, optionValue });
    if (Object.keys(filters).includes(filterOption.key)) {
      if (filters[filterOption.key].includes(optionValue.id)) {
        filters[filterOption.key] = filters[filterOption.key].filter(value => value !== optionValue.id);

        const newFilters = filters[filterOption.key].length === 0 ? omit(filters, filterOption.key) : filters;

        dispatchParams({
          type: ProductsParamsActionType.CHANGE_FILTER_OPTION,
          payload: newFilters,
        });
      } else {
        const { [filterOption.key]: optionKeys, ...restFilters } = filters;
        const newOptionKeys = optionKeys.concat(optionValue.id);
        const newFilters = {
          ...restFilters,
          [filterOption.key]: newOptionKeys,
        };
        dispatchParams({
          type: ProductsParamsActionType.CHANGE_FILTER_OPTION,
          payload: newFilters,
        });
      }
    } else {
      const newFilter = { ...filters, [filterOption.key]: [optionValue.id] };
      dispatchParams({
        type: ProductsParamsActionType.CHANGE_FILTER_OPTION,
        payload: newFilter,
      });
    }
  };
  const onChangePrice = (price: PriceFilterParams) => {
    changePagination(1);

    dispatchParams({ type: ProductsParamsActionType.CHANGE_FILTER_PRICE, payload: price });
  };
  const onClearAllFilterOption = () => {
    dispatchParams({ type: ProductsParamsActionType.CLEAR_FILTER });
  };

  return {
    params,
    changeKeyword,
    changeCategory,
    changeSortOrder,
    changePagination,
    changePerpage,
    onChangeFilter,
    onChangePrice,
    onClearAllFilterOption,
  };
};
export const useProducts = () => {
  const [productList, dispatchProductList] = useReducer(productListReducer, initProductListState);
  const setLoadingProducts = () => {
    dispatchProductList({ type: ProductsListActionType.LOADING });
  };
  const setSuccessProducts = (productData: any) => {
    dispatchProductList({
      type: ProductsListActionType.FETCH_SUCCESS,
      payload: productData,
    });
  };
  const setErrorProducts = (error: any) => {
    dispatchProductList({
      type: ProductsListActionType.FETCH_SUCCESS,
      payload: error,
    });
  };
  return { productList, setLoadingProducts, setSuccessProducts, setErrorProducts };
};
export const useMultiFilters = () => {
  const params = useProductListContext();

  const [filterList, dispatchFilterList] = useReducer(filterListReducer, initFilterListState);
  const setLoadingFilterList = () => {
    dispatchFilterList({ type: FilterListActionType.LOADING });
  };
  const setSuccessFilterList = (productData: any) => {
    dispatchFilterList({
      type: FilterListActionType.FETCH_SUCCESS,
      payload: productData,
      params,
    });
  };
  const setErrorFilterList = (error: any) => {
    dispatchFilterList({
      type: FilterListActionType.FETCH_SUCCESS,
      payload: error,
      params,
    });
  };
  return { filterList, setLoadingFilterList, setSuccessFilterList, setErrorFilterList };
};
export const useSimpleProduct = () => {
  const [selectedProducts, dispatchSelectProduct] = useReducer(simpleProductReducer, []);
  const changeSelectedDeliveryDate = (index: number, date: Date) => {
    dispatchSelectProduct({ type: SimpleProductAction.CHANGE_DELIVERY_DATE, index, payload: date });
  };
  const changeSelectedQuantity = (index: number, value: number) => {
    dispatchSelectProduct({ type: SimpleProductAction.CHANGE_QUANTITY, index, payload: value });
  };
  const setDefaultStatus = (productsLength: number) => {
    dispatchSelectProduct({ type: SimpleProductAction.INIT_DEFAULT_STATUS, length: productsLength });
  };
  const changeErrorQuantity = (index: number, error: ErrorQuantity) => {
    dispatchSelectProduct({ type: SimpleProductAction.CHANGE_ERROR_QUANTITY, index, payload: error });
  };
  const changeButtonStatus = (index: number, status: AddToCartStatus) => {
    dispatchSelectProduct({ type: SimpleProductAction.CHANGE_BUTTON_CART_STATUS, index, payload: status });
  };

  return {
    selectedProducts,
    changeSelectedDeliveryDate,
    changeSelectedQuantity,
    setDefaultStatus,
    changeErrorQuantity,
    changeButtonStatus,
  };
};
export default useProductList;
