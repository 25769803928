import React from 'react';
import plus from '../../assets/icon/plus-icn.svg';
import minus from '../../assets/icon/minus-icn.svg';

export const ICONS = {
  PLUS: plus,
  MINUS: minus,
};
interface Props {
  name?: any;
  size?: number;
}
const Icon = ({ name, size }: Props): React.ReactElement => {
  return <img width={`${size ? size : ''}`} className="" src={name} />;
};

export default Icon;
