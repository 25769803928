import React, { useEffect, useState } from 'react';
import './../style.scss';
import { useForm, Controller } from 'react-hook-form';
import { Button, Container, Form } from 'react-bootstrap';
import _, { method } from 'lodash';
import { compose } from 'redux';
import { callApiACT } from '../../../redux/callApiRDC';
import * as AppURL from '../../../services/urlAPI';
import * as Utils from '../../../utils';
import { reducerNameHOR } from '../../../redux';
import { HeaderKey, TypeRequest } from '../../../constants';
import localStorage from '../../../utils/LocalStorage';
import * as Constants from '../../../constants';
import { connect } from 'react-redux';
import { callApiListCart } from '../../../redux/getListCartRDC';
import { useTranslation, withTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect';
import CustomOption from 'src/components/CustomSelect/components/CustomOption';
import { components } from 'react-select';
import RadioButton from 'src/components/CustomRadioButton';

interface Props {
  callApiACT: (action) => void;
  activeStep: number;
  setActiveStep: (param) => void;
  handleNext: (param) => void;
  steps: any[];
  callApiListCart: (action) => void;
  setLoadingState: (param) => void;
  loadingState?: boolean;
  setCurrentStep: (param) => void;
  currentStep?: number;
}

interface PropsItemShippingMethod {
  item: any;
  cart: any;
  methods?: any;
  onChange?: (action) => void;
  defaultChecked: boolean;
  selectedShippingMethod?: string;
}

export const ItemShippingMethod = ({
  item,
  cart,
  methods,
  onChange,
  defaultChecked,
  selectedShippingMethod,
}: PropsItemShippingMethod) => {
  let currency = Utils.getSafeValue(cart, 'currency', {});

  return (
    <div className={`boxItem hasBorder ${item.id == selectedShippingMethod ? 'itemSelected' : ''}`}>
      <div className="boxItemInner">
        <div className="boxInfo">
          <div>
            <div className="itemName">{item.description}</div>
            <div className="itemCost">{Utils.convertCurrency(item.cost, currency['code'])}</div>
          </div>
          <label className="radio-btn">
            <input
              type="radio"
              name="shippingMethod"
              value={item.id}
              className="form-radio ml-auto"
              onChange={onChange}
              ref={methods.register}
              defaultChecked={defaultChecked}
            />
            <span className="icnChecked"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

interface PropsItemPaymentMethod {
  item: any;
  methods?: any;
  selectedOption?: string;
  onChange?: (action) => void;
  defaultChecked: boolean;
}

export const ItemPaymentMethod = ({
  item,
  methods,
  onChange,
  selectedOption,
  defaultChecked,
}: PropsItemPaymentMethod) => {
  return (
    <div className={`boxItem hasBorder ${selectedOption == item.code ? 'itemSelected' : ''}`}>
      <div className="boxItemInner">
        <div className={`boxInfo`}>
          <div>
            <div className="itemName">{item.name}</div>
          </div>
          <label className="radio-btn">
            <input
              type="radio"
              name="paymentMethod"
              ref={methods.register}
              onChange={onChange}
              value={item.code}
              className="form-radio ml-auto"
              defaultChecked={defaultChecked}
            />
            <span className="icnChecked"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

function ShippingMethod({
  callApiACT,
  activeStep,
  handleNext,
  callApiListCart,
  loadingState,
  setLoadingState,
  setCurrentStep,
  currentStep,
  steps,
}: any) {
  const { t } = useTranslation();

  const methods = useForm();
  const [selectedOption, setSelectedOption] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [shippingMethodOptions, setShippingMethodOptions] = useState<any[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [cart, setCart] = useState({});
  const [defaultShippingMethod, setDefaultShippingMethod] = useState();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState();

  const [selectedShippingMethod, setSelectedShippingMethod] = useState('');

  const [selectedParams, setSelectedParams] = useState({});

  let deviceKey = localStorage.get('deviceKey', '');
  let appKey = localStorage.get('appKey', '');
  let cartId = localStorage.get('cartId', '');

  useEffect(() => {
    if (currentStep == 2) {
      getCart();
    }
  }, [activeStep]);

  const getCart = async () => {
    setLoadingState(true);
    let params = [appKey, deviceKey, cartId];

    try {
      let data = await callApiListCart({
        url: Utils.replaceStrUrl(AppURL.getCartGroupItem, params),
        name: reducerNameHOR.getCartList,
        typeRequest: Constants.TypeRequest.GET,
      });

      let cart = Utils.getSafeValue(data, 'cart', { id: null });
      localStorage.set('cartId', cart['id']);
      setCart(cart);

      let shippingMethodList: any[] = _.map(cart['consignments'], (item, index) => {
        return item['shippingOptions'];
      });
      let selectShippingOptions: any[] = [];
      if (shippingMethodList.length === 1) {
        selectShippingOptions = shippingMethodList[0].map(method => {
          return {
            ...method,
            value: method,
            label: method.description,
          };
        });
        setShippingMethodOptions(selectShippingOptions);
      }
      let paymentMethods = Utils.getSafeValue(cart, 'paymentMethods', []);

      const selectPaymentOptions = paymentMethods.map(method => {
        return {
          ...method,
          label: Utils.getSafeValue(method, 'name', ''),
          value: Utils.getSafeValue(method, 'code', ''),
        };
      });

      setPaymentMethodOptions(selectPaymentOptions);

      let _shippingMethod = localStorage.getObject('selectedShippingMethod', '');
      let _paymentMethod = localStorage.getObject('selectedPaymentMethod', '');
      let _defaultShippingMethod = !_.isEmpty(shippingMethodList[0]) ? shippingMethodList['0'][0].id : '';
      let _defaultPaymentMethod = _paymentMethod ? _paymentMethod : paymentMethods.length ? paymentMethods[0].code : '';
      let paramSelected = {};
      if (!_.isEmpty(_defaultShippingMethod)) {
        setDefaultShippingMethod(_defaultShippingMethod);
        setSelectedShippingMethod(_defaultShippingMethod);

        const defaultShipping = selectShippingOptions.find(
          option => Utils.getSafeValue(option, 'id', '') === _defaultShippingMethod
        );
        methods.setValue('shippingMethod', defaultShipping);
        paramSelected = {
          shippingMethod: _defaultShippingMethod,
        };
      } else {
        methods.reset(['shippingMethod']);
      }
      if (_defaultPaymentMethod) {
        setDefaultPaymentMethod(_defaultPaymentMethod);
        setSelectedOption(_defaultPaymentMethod);
        const defaultPayment = selectPaymentOptions.find(
          option => Utils.getSafeValue(option, 'code', '') === _defaultPaymentMethod
        );

        methods.setValue('paymentMethod', defaultPayment);

        paramSelected = {
          paymentMethod: _defaultPaymentMethod,
          ...paramSelected,
        };
      }
      setSelectedParams(paramSelected);

      if (!_.isEmpty(_defaultShippingMethod) && !_.isEmpty(_defaultPaymentMethod)) {
        setDisableButton(false);
      }
    } catch (e) {
      console.log('error message get cart: ', e);
    }
    setLoadingState(false);
  };

  const onChange = _.debounce(e => {
    const selectedValue = methods.getValues();
    setSelectedOption(selectedValue.paymentMethod);

    let paramSelected = {};
    if (selectedValue.shippingMethod) {
      setSelectedShippingMethod(selectedValue.shippingMethod);
      paramSelected = {
        shippingMethod: selectedValue.shippingMethod,
      };
    }
    if (selectedValue.paymentMethod) {
      paramSelected = {
        paymentMethod: selectedValue.paymentMethod,
        ...paramSelected,
      };
    }
    setSelectedParams(paramSelected);
  }, 100);

  useEffect(() => {
    let selectedOption = methods.getValues();
    let shippingMethod = selectedOption['shippingMethod'];
    let paymentMethod = selectedOption['paymentMethod'];

    if (!_.isEmpty(shippingMethod) && !_.isEmpty(paymentMethod)) {
      onSubmit(selectedOption);
    }
  }, [selectedParams]);

  const onSubmit = async (formData: any) => {
    setLoadingState(true);

    let shippingAddressId = cart['consignments'][0]['id'];
    //let shippingMethodId = Utils.getSafeValue(shippingMethodOptions[0], 'id', '');
    let cartId = [localStorage.get('cartId', '')];
    let params = {
      appKey: localStorage.get('appKey', ''),
      deviceKey: localStorage.get('deviceKey', ''),
      shippingOptionId: Utils.getSafeValue(formData, 'shippingMethod.id', ''),
    };

    try {
      let data = await callApiACT({
        typeRequest: TypeRequest.PUT,
        url: Utils.replaceStrUrl(AppURL.addShippingMethodToCart, [cartId, shippingAddressId]),
        name: reducerNameHOR.setCartShippingMethod,
        params: params,
      });
      // if (paymentMethodOptions.length === 1) {
      //     localStorage.setObject('selectedPaymentMethod', paymentMethodOptions[0]);
      // }
      localStorage.setObject('selectedPaymentMethod', Utils.getSafeValue(formData, 'paymentMethod.code', ''));
      // localStorage.setObject('selectedShippingMethod', formData.shippingMethod);
      setCurrentStep(3);
      handleNext(activeStep);
    } catch (e) {}
    setLoadingState(false);
  };

  return (
    <>
      {currentStep && currentStep >= 2 && (
        <div className="step">
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <div className="row">
                    <div className="col-6">
                      <div className="boxCol boxShippingMethod">
                        <div className="title">3. {t('checkout.shippingMethod')}</div>

                        <Controller
                          as={<CustomSelect />}
                          name="shippingMethod"
                          control={methods.control}
                          // defaultMenuIsOpen
                          // menuIsOpen
                          className="select-address"
                          classNamePrefix="select-address"
                          options={shippingMethodOptions}
                          components={{
                            Option: optionProps => (
                              <components.Option {...optionProps}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: '#fff',
                                    color: '#000',
                                  }}
                                >
                                  <div className="options-content">
                                    <div style={{ padding: '25px 22px' }}>{optionProps.label}</div>
                                  </div>
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <RadioButton
                                      defaultChecked={
                                        Utils.getSafeValue(optionProps, 'data.id', '') ===
                                        Utils.getSafeValue(methods.getValues('shippingMethod'), 'id', '')
                                      }
                                    />
                                  </div>
                                </div>
                              </components.Option>
                            ),
                            DropdownIndicator: dropdownProps => (
                              <components.DropdownIndicator {...dropdownProps}>
                                <span className="arrow-dropdown" />
                              </components.DropdownIndicator>
                            ),
                          }}
                          styles={{
                            option: base => ({ ...base, padding: 0 }),
                          }}
                          onChange={onChange}
                        />
                        {/*
                        {_.map(shippingMethodOptions, (item, index) => {
                          return (
                            <ItemShippingMethod
                              cart={cart}
                              key={index}
                              item={item}
                              onChange={onChange}
                              methods={methods}
                              defaultChecked={item['id'] == defaultShippingMethod}
                              selectedShippingMethod={selectedShippingMethod}
                            />
                          );
                        })}
                         */}
                        {_.isEmpty(shippingMethodOptions) && `${t('checkout.msgNoShippingMethod')}`}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="boxCol boxPaymentMethod">
                        <div className="title">4. {t('checkout.paymentMethod')}</div>
                        <div>
                          <Controller
                            control={methods.control}
                            name="paymentMethod"
                            as={<CustomSelect />}
                            className="select-address"
                            classNamePrefix="select-address"
                            options={paymentMethodOptions}
                            components={{
                              Option: optionProps => (
                                <components.Option {...optionProps}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      backgroundColor: '#fff',
                                      color: '#000',
                                    }}
                                  >
                                    <div className="options-content">
                                      <div style={{ padding: '25px 22px' }}>{optionProps.label}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <RadioButton
                                        defaultChecked={
                                          Utils.getSafeValue(optionProps, 'data.code', '') ===
                                          Utils.getSafeValue(methods.getValues('paymentMethod'), 'code', '')
                                        }
                                      />
                                    </div>
                                  </div>
                                </components.Option>
                              ),
                              DropdownIndicator: dropdownProps => (
                                <components.DropdownIndicator {...dropdownProps}>
                                  <span className="arrow-dropdown" />
                                </components.DropdownIndicator>
                              ),
                            }}
                            styles={{
                              option: base => ({ ...base, padding: 0 }),
                            }}
                          />

                          {/*
                          {_.map(paymentMethodOptions, (item, index) => {
                            return (
                              <ItemPaymentMethod
                                key={index}
                                item={item}
                                methods={methods}
                                selectedOption={selectedOption}
                                onChange={onChange}
                                defaultChecked={item['code'] == defaultPaymentMethod}
                              />
                            );
                          })}
                          */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </Container>
          </Form>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
    callApiListCart: params => dispatch(callApiListCart(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect(withTranslation()(ShippingMethod)));
