import React, { useEffect } from 'react';
import './style.scss';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { backTop } from '../../../utils';

import Image1 from './assets/Image1.png';
import imgLayer2 from './assets/fabric-2layer.png';
import imgLayer3 from './assets/fabric-3layer.png';
import imgLayer4 from './assets/fabric-4layer2.png';
import imgLayer5 from './assets/fabric-firecell-notitle.png';
import { useTranslation, withTranslation } from 'react-i18next';

function FabricSystem() {
  const { t } = useTranslation();

  useEffect(() => {
    backTop();
  }, []);

  const FabricSystem2 = {
    desc: `${t('fabricSystemPage.descMain')}`,
    image: Image1,
    block1: [
      {
        title: `${t('fabricSystemPage.block1.item1.title')}`,
        desc: `${t('fabricSystemPage.block1.item1.desc')}`,
      },
      {
        title: `${t('fabricSystemPage.block1.item2.title')}`,
        desc: `${t('fabricSystemPage.block1.item2.desc')}`,
      },
      {
        title: `${t('fabricSystemPage.block1.item3.title')}`,
        desc: `${t('fabricSystemPage.block1.item3.desc')}`,
      },
    ],
    block3: [
      {
        title: `${t('fabricSystemPage.block3.item1.title')}`,
        desc1: `${t('fabricSystemPage.block3.item1.desc1')}`,
        desc2: `${t('fabricSystemPage.block3.item1.desc2')}`,
        image: imgLayer2,
      },
      {
        title: `${t('fabricSystemPage.block3.item2.title')}`,
        desc1: `${t('fabricSystemPage.block3.item2.desc1')}`,
        desc2: `${t('fabricSystemPage.block3.item2.desc2')}`,
        image: imgLayer3,
      },
      {
        title: `${t('fabricSystemPage.block3.item3.title')}`,
        desc1: `${t('fabricSystemPage.block3.item3.desc1')}`,
        desc2: `${t('fabricSystemPage.block3.item3.desc2')}`,
        image: imgLayer4,
      },
      {
        title: `${t('fabricSystemPage.block3.item4.title')}`,
        desc1: `${t('fabricSystemPage.block3.item4.desc1')}`,
        desc2: `${t('fabricSystemPage.block3.item4.desc2')}`,
        image: imgLayer5,
      },
    ],
  };

  return (
    <>
      <div className="fabricSystemPage">
        <Container>
          <Row>
            <div className="col-2"></div>
            <div className="col-8">
              <div className="titlePage">{t('fabricSystemPage.titlePage')}</div>
              <div className="blockTop">
                <div className="descBlock">
                  {FabricSystem2.desc && (
                    <div className="desc">
                      <span dangerouslySetInnerHTML={{ __html: FabricSystem2.desc }} />
                    </div>
                  )}
                </div>
                <div className="imageBlock">
                  <img src={FabricSystem2.image} />
                </div>
              </div>

              <div className="blockContent block1">
                {_.map(FabricSystem2.block1, (item2, index2) => {
                  return (
                    <div className="blockItem block1Item" key={index2}>
                      <div className="title">{item2.title}</div>
                      {item2.desc && (
                        <div className="desc">
                          <span dangerouslySetInnerHTML={{ __html: item2.desc }} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="blockMidder">
                <div className="title">{t('fabricSystemPage.block2.title')}</div>
                <div className="title2">{t('fabricSystemPage.block2.title2')}</div>
                <div className="desc">{t('fabricSystemPage.block2.desc')}</div>
              </div>

              <div className="blockContent block2">
                {_.map(FabricSystem2.block3, (item3, index3) => {
                  return (
                    <div className="blockItem block2Item" key={index3}>
                      <div className="imageBox">
                        <img src={item3.image} />
                      </div>
                      <div className="boxContent">
                        <div className="title">{item3.title}</div>
                        {item3.desc1 && (
                          <div className="desc">
                            <span dangerouslySetInnerHTML={{ __html: item3.desc1 }} />
                          </div>
                        )}
                        {item3.desc2 && (
                          <div className="desc2">
                            <span dangerouslySetInnerHTML={{ __html: item3.desc2 }} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-2"></div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default withTranslation()(FabricSystem);
