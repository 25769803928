import React, { ReactElement, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import HelpItem from './HelpItem';
import './style.scss';
import { backTop } from 'src/utils';
const helpData = [
  {
    key: 'id1',
    title: 'How do I search for products?',
    info: [
      `There are several ways in which you can view stock.`,

      `1. Using the left hand side navigation, this allows you to search for products by categories - browse by gender, type of sailing activity, and specific product groups. This search function uses a filter system so each selection you make will filter the results accordingly and will narrow/widen your search depending on your selections. If you decide you want to change your search you would need to deselect the categories you have selected in order to start a new search otherwise it will continue to filter based on your existing selections. The yellow dot highlights which categories have been selected.`,

      `2. Using the Products tab along the top navigation bar displays thumbnails highlighting key product and activity categories.`,

      `3. Using the ‘Search’ function (displayed under the country selection/account details) which is particularly useful when you know the product code/name you are looking for.`,
    ],
  },
  {
    key: 'id2',

    title: 'How do I view stock?',
    info: [
      `You can quickly view stock on the ‘Search Results’ page with the ‘Select’ function displayed to the right of the product listing.`,

      `Stock levels are shown by a traffic light system.`,

      `Red = out of stock (in this case an estimated delivery date will be shown)`,

      `Amber = low stock`,

      ` Green = good stock levels`,
    ],
  },
  {
    key: 'id3',

    title: 'How do I view product details?',
    info: [
      `To view product details select ‘View’ on a specific product on the ‘Search Results’ page. This takes you to the main product page which details key features, technology and sizing information.`,
    ],
  },
  {
    key: 'id4',

    title: 'How do I place an order?',
    info: [
      `You can place an order on the ‘Search Results’ page or on the ‘Product’ page.`,

      `Simply input the quantity required and ‘Update’ the order to add your selection to the basket.`,

      `You can also place a forward order if you do not want it despatching immediately. To do this select the calendar and choose your preferred delivery date.`,

      `You can then edit the basket, continue shopping or checkout.`,

      `Once at the checkout you are still able to amend the delivery date and quantities. You can also remove items from the basket.`,

      `A customer reference is required at this stage so please input this in to the ‘Customer Reference’ box. This will appear on your despatch note.`,

      `Confirm order to progress to the ‘Checkout’ page. You will then have the opportunity to change the delivery address if you wish it to be different to the invoice address.`,
    ],
  },
  {
    title: 'How do I view my order history',
    info: [
      `To view your order history, click the ‘Account Details’ tab at the top of the page and go to ‘My Account’. On the left hand side of the page click ‘MY ORDERS’ and you will see your previous orders.`,
    ],
  },
];

interface Props {
  data?: any;
}

export default function Help({ data = helpData }: Props): ReactElement {
  useEffect(() => {
    backTop();
  }, []);
  return (
    <div className="help-container">
      <div className="content">
        <div className="page-title">HELP</div>
        {data.map((item, index) => {
          return <HelpItem key={item.key} item={item} />;
        })}
      </div>
    </div>
  );
}
