import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { backTop } from '../../../utils';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Utils from '../../../utils';
import PrivacyItem from './privacyItem';

import './style.scss';

import { Collapse, Divider } from '@material-ui/core';
import Icon, { ICONS } from 'src/components/Icon';

function CookiesAndPrivacy() {
  const [termList, setTermList] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState({});
  const [cookiesPolicy, setCookiesPolicy] = useState({});
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    backTop();

    let dataContent = i18next.t('privacyPolicy', { returnObjects: true });
    let dataContent2 = Utils.getSafeValue(dataContent, 'tableList', []);
    setTermList(dataContent2);
    setPrivacyPolicy(dataContent);

    let dataCookies = i18next.t('cookiesPolicy', { returnObjects: true });
    setCookiesPolicy(dataCookies);
  }, []);

  const handleClickCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="termConditionPage cookiesAndPrivacy">
        <Container>
          <Row>
            <div className="col-2"></div>
            <div className="col-8">
              <div className="cookiesPolicy">
                <div className="titleAccodion" onClick={handleClickCollapse}>
                  <div className="titleBlock">
                    <div className="title">{cookiesPolicy['titlePage']}</div>
                  </div>
                  {open ? <Icon name={ICONS.MINUS} /> : <Icon name={ICONS.PLUS} />}
                </div>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <div className="">
                    {cookiesPolicy['desc1'] && (
                      <div className="desc">
                        {_.map(cookiesPolicy['desc1'], (item, index) => {
                          return <p key={index} dangerouslySetInnerHTML={{ __html: item }} />;
                        })}
                      </div>
                    )}

                    <div className="tableCookiesPolicy">
                      {_.map(cookiesPolicy['tableList'], (item, index) => {
                        return (
                          <div className="rowItem" key={index}>
                            <div className="colItem colItem1" dangerouslySetInnerHTML={{ __html: item['cookie'] }} />
                            <div className="colItem colItem2" dangerouslySetInnerHTML={{ __html: item['name'] }} />
                            <div className="colItem colItem3" dangerouslySetInnerHTML={{ __html: item['purpose'] }} />
                          </div>
                        );
                      })}
                    </div>

                    {cookiesPolicy['desc2'] && (
                      <div className="desc">
                        {_.map(cookiesPolicy['desc2'], (item, index) => {
                          return <p key={index} dangerouslySetInnerHTML={{ __html: item }} />;
                        })}
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>

              <div className="privacyPolicy">
                <div className="titlePageBlock">
                  <div className="titlePage">{privacyPolicy['titlePage']}</div>
                  {privacyPolicy['descPage'] && (
                    <div className="desc">
                      {_.map(privacyPolicy['descPage'], (item, index) => {
                        return <p key={index} dangerouslySetInnerHTML={{ __html: item }} />;
                      })}
                    </div>
                  )}
                </div>

                {_.map(privacyPolicy['tableList'], (item, index) => {
                  return <PrivacyItem item={item} itemKey={index}></PrivacyItem>;
                })}
              </div>
            </div>

            <div className="col-2"></div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default withTranslation()(CookiesAndPrivacy);
