import * as Utils from '..';
import { Product } from '../../constants/productConstant';

export function getDataAddToCart(item) {
  let bundleOption = {};
  let bundleOptionQty = {};
  let discount = 0;
  let groupedProducts = {};
  let options = {};
  let price = 0;
  let qty = 1;
  let superAttribute = {};
  let totalPrice = 0;
  return {
    bundleOption,
    bundleOptionQty,
    discount,
    groupedProducts,
    options,
    price,
    qty,
    superAttribute,
    totalPrice,
  };
}

export function convertToConfigurable(item, currencyCode) {
  let explandViewConfig = [];
  let itemSelection = Utils.getSafeValue(item, 'itemSelection', {});
  let superAttribute = Utils.getSafeValue(itemSelection, 'superAttribute', {});
  let product = Utils.getSafeValue(item, 'product', {});
  let configurableAttributes = Utils.getSafeValue(product, 'configurableAttributes', []);
  configurableAttributes.forEach(element => {
    let id = Utils.getSafeValue(element, 'id', 0);
    let idValue = superAttribute[id];
    if (idValue) {
      let options = Utils.getSafeValue(element, 'options', []);
      let configurableValue = options.find(e => e.id.toString() === idValue.toString());
      let idSelect = '';
      let value = '';
      let displayPrice = '';
      if (configurableValue) {
        idSelect = Utils.getSafeValue(configurableValue, 'id', 0).toString();
        value = configurableValue.label;
        let price = Utils.getSafeValue(configurableValue, 'price', 0);
        if (price > 0) {
          displayPrice = Utils.convertCurrency(price, currencyCode);
        }
      }
      let label = element.label;
      explandViewConfig.push({
        label,
        value,
        id: id.toString(),
        idSelect,
        displayPrice,
      });
    }
  });
  let explandViewSimple = commonSimple(item, currencyCode);
  let explandView = [...explandViewConfig, ...explandViewSimple];
  return { ...item, explandView, isLoading: false };
}

export function convertToSCP(item, currencyCode) {
  let explandView = [];
  return { ...item, explandView, isLoading: false };
}
export function convertToSimple(item, currencyCode) {
  let explandView = commonSimple(item, currencyCode);
  return { ...item, explandView, isLoading: false };
}

export function convertToBundel(item, currencyCode) {
  let explandViewBundle = [];
  let itemSelection = Utils.getSafeValue(item, 'itemSelection', {});
  let bundleOption = Utils.getSafeValue(itemSelection, 'bundleOption', {});
  let options = Utils.getSafeValue(itemSelection, 'options', {});
  let bundleOptionQty = Utils.getSafeValue(itemSelection, 'bundleOptionQty', {});
  let product = Utils.getSafeValue(item, 'product', {});
  let bundleOptions = Utils.getSafeValue(product, 'bundleOptions', []);
  let productOptions = Utils.getSafeValue(product, 'options', []);

  for (const bundleOptionKey in bundleOption) {
    let bundleOptionTitle = '';
    let indexOptionInProduct = bundleOptions.findIndex(bundleOptionModel => {
      return bundleOptionModel.optionId == bundleOptionKey;
    });

    let selectedBundleOptionModel = bundleOptions[indexOptionInProduct];

    let bundleOptionValues = bundleOption[bundleOptionKey];
    let displaySelectedValues = [];

    bundleOptionValues.forEach(bOptionValueId => {
      if (selectedBundleOptionModel) {
        bundleOptionTitle = selectedBundleOptionModel.title;

        // get bundle selection object
        let selections = Utils.getSafeValue(selectedBundleOptionModel, 'selections', []);
        let bundleSelectionModel = selections.find(aBundleSelectionModel => {
          return aBundleSelectionModel.selectionId == bOptionValueId;
        });

        if (bundleSelectionModel) {
          bundleSelectionModel.selected = true;
          let displayPrice = Utils.convertCurrency(bundleSelectionModel.price, currencyCode);
          let quantityDisplayString = '';
          let bundleSelectionTitle = bundleSelectionModel.name;

          //get selected quantity for bundle selection
          let optionQuantityList = Utils.getSafeValue(bundleOptionQty, bundleOptionKey, []);
          if (optionQuantityList) {
            let quantityString = optionQuantityList.find(item => {
              return item.includes(bundleSelectionModel.selectionId);
            });

            if (quantityString) {
              quantityDisplayString = cutBundleQuantity(quantityString);
              bundleSelectionModel.inputQuantity = quantityDisplayString;
            }
          }

          let obj = {
            label: quantityDisplayString + ' x ' + bundleSelectionTitle,
            value: displayPrice,
            id: bOptionValueId,
            defaultQty: quantityDisplayString,
          };

          displaySelectedValues.push(obj);
        }
      }
    });
    let displayTextForItems = {
      id: bundleOptionKey,
      optionTitle: bundleOptionTitle,
      selectedOptionTitles: displaySelectedValues,
      indexOptionInProduct,
    };
    explandViewBundle.push(displayTextForItems);
  }
  explandViewBundle.sort((o1, o2) => {
    return o1.indexOptionInProduct - o2.indexOptionInProduct;
  });
  let explandViewSimple = commonSimple(item, currencyCode);
  let explandView = [...explandViewBundle, ...explandViewSimple];
  return { ...item, explandView, isLoading: false };
}
export function commonSimple(item, currencyCode) {
  let explandView = [];
  let itemSelection = Utils.getSafeValue(item, 'itemSelection', {});
  let options = Utils.getSafeValue(itemSelection, 'options', {});
  let product = Utils.getSafeValue(item, 'product', {});
  let productOptions = Utils.getSafeValue(product, 'options', []);

  for (const key in options) {
    let optionTitle = '';
    let optionKey = key;

    let option = productOptions.find(aOption => {
      return aOption.optionId == key;
    });

    if (option) {
      optionTitle = option.title;
      let selectedOptionValues = options[key];
      if (selectedOptionValues) {
        let prices = [];
        let selectedTitles = [];

        selectedOptionValues.forEach(selectedOptionId => {
          let selections = Utils.getSafeValue(option, 'selections', []);
          let selectedOption = selections.find(element => {
            return element.valueId === selectedOptionId;
          });

          if (selectedOption) {
            let tempPrice = '';
            if (selectedOption.price > 0) {
              tempPrice = Utils.convertCurrency(selectedOption.price, currencyCode);
            }
            selectedOption.selected = true;
            let temp = {
              title: selectedOption.name,
              price: tempPrice,
              id: selectedOptionId,
            };
            selectedTitles.push(temp);
          } else {
            let tempPrice = '';
            let tempArr = Array.from(selectedOptionValues);
            let optionTitle = tempArr.pop();
            option.selections.forEach(element => {
              element.inputValue = optionTitle;
              if (element.price > 0) {
                tempPrice = Utils.convertCurrency(element.price, currencyCode);
              }
            });
            let temp = {
              title: optionTitle,
              price: tempPrice,
              id: selectedOptionId,
            };
            selectedTitles.push(temp);
          }
        });

        let displayTextForItems = {
          optionId: optionKey,
          optionTitle: optionTitle,
          selectedOptionTitles: selectedTitles,
          prices: prices,
        };
        explandView.push(displayTextForItems);
      }
    }
  }
  return explandView;
}
function cutBundleQuantity(str) {
  let quantity = '';
  let listString = str.split(':');
  if (listString && listString.length > 0) {
    quantity = listString.pop();
  }
  return quantity;
}

export function convertCartToProduct(explandView, type) {
  switch (type) {
    case Product.ProductType.PRODUCT_TYPE_CONFIGURABLE:
      return convertCartConfigurableToProduct(explandView);
    case Product.ProductType.PRODUCT_TYPE_BUNDLE:
      return convertCartBundleToProduct(explandView);
    case Product.ProductType.PRODUCT_TYPE_SIMPLE:
      return convertCartSimpleToProduct(explandView);
    case Product.ProductType.PRODUCT_TYPE_GROUP:
      return convertCartGroupToProduct(explandView);
    case Product.ProductType.PRODUCT_TYPE_CONFIGURABLE_SCP:
      return convertCartConfigurableToProduct(explandView);
    default:
      return convertCartSimpleToProduct(explandView);
  }
}

export function convertCartSCPToProduct(optionList) {
  return [];
}
export function convertCartBundleToProduct(optionList) {
  let options = [];
  let bundleOptions = [];
  optionList.forEach(option => {
    if (option.optionId) {
      let id = option.optionId;
      let listSelectedOption = [];
      option.selectedOptionTitles.forEach(element => {
        let idValue = element.id;
        let textInput = '';
        let qtyOption = 0;
        listSelectedOption.push({ idValue, textInput, qtyOption });
      });
      options.push({ id, listSelectedOption });
    } else {
      let id = option.id;
      let listSelectedOption = [];
      option.selectedOptionTitles.forEach(element => {
        let idValue = element.id;
        let textInput = '';
        let qtyOption = element.defaultQty;
        listSelectedOption.push({ idValue, textInput, qtyOption });
      });
      bundleOptions.push({ id, listSelectedOption });
    }
  });
  return { options, bundleOptions };
}

export function convertCartSimpleToProduct(optionList) {
  let options = [];

  optionList.forEach(option => {
    let id = option.optionId;
    let listSelectedOption = [];
    option.selectedOptionTitles.forEach(element => {
      let idValue = element.id;
      let textInput = '';
      let qtyOption = 0;
      listSelectedOption.push({ idValue, textInput, qtyOption });
    });
    options.push({ id, listSelectedOption });
  });
  return { options };
}

export function convertCartConfigurableToProduct(optionList) {
  let configurableAttributes = [];
  let options = [];
  optionList.forEach(option => {
    if (option.optionId) {
      let id = option.optionId;
      let listSelectedOption = [];
      option.selectedOptionTitles.forEach(element => {
        let idValue = element.id;
        let textInput = '';
        let qtyOption = 0;
        listSelectedOption.push({ idValue, textInput, qtyOption });
      });
      options.push({ id, listSelectedOption });
    } else {
      let id = option.id;
      let listSelectedOption = [];
      listSelectedOption.push({
        idValue: option.idSelect,
        textInput: '',
        qtyOption: 0,
      });
      configurableAttributes.push({ id, listSelectedOption });
    }
  });
  return { options, configurableAttributes };
}

export function convertCartGroupToProduct(optionList) {
  let groupedItems = [];
  optionList.forEach(option => {
    let id = option.id;
    let qtyOption = option.defaultQty;
    groupedItems.push({ id, qtyOption });
  });
  return { groupedItems };
}

export function getOrderTotal(cart) {
  let cartPrices = Utils.getSafeValue(cart, 'cartPrices', []);
  let orderTotal = 0;
  cartPrices.forEach(item => {
    if (item.title === 'Grand Total') {
      orderTotal = Utils.getSafeValue(item, 'price', 0);
    }
  });
  return orderTotal;
}
export function convertCartPrices(cart) {
  let cartPrices = Utils.getSafeValue(cart, 'cartPrices', []);
  let tax = null;
  let grandTotal = null;
  let shipping = null;
  let subtotal = null;
  cartPrices.forEach(item => {
    if (item.code === 'grand_total') {
      grandTotal = item;
    } else if (item.code === 'subtotal') {
      subtotal = item;
    } else if (item.code === 'tax') {
      tax = item;
    } else if (item.code === 'shipping') {
      shipping = item;
    }
  });
  return { tax, grandTotal, shipping, subtotal };
}
// {
//   idProduct, qtyProduct, [], [], [];
// }

export function deleteAllCookies() {
  document.cookie.split(';').forEach(c => {
    if (c.includes('KL_FORMS_MODAL') || c.includes('__kla_id')) {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    }
  });
  const Script = document.getElementById('klaviyo');
  if (Script) {
    document.body.removeChild(Script);
  }
}
