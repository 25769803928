export const ProductDetail = {
  charLeft: 'char left',
  newText: 'NEW',
  defaultOptionTitle: 'Choose a selection…',
  donePicker: 'Done',
  cancelPicker: 'Cancel',
  placeHolderText: 'Enter',
  textQuantity: 'Qty',
  tierBuy: 'Buy {{quantity}} for ',
  tierEach: ' each and ',
  tierPercent: ' save {{savePercentage}}%',
  outOfStock: 'OUT OF STOCK',
  addToCartText: 'ADD TO CART',
  addingText: 'ADDING',
  addedText: 'ADDED',
  updateCartText: 'Update Cart',
  outOfStockText: 'Sorry, this item is out of stock',
  additionInfoText: 'Additional Information',
  reviewText: 'Review',
  seeAllText: 'See All',
  addReviewText: 'Add Your Review',
  beFirstReviewText: 'Be the first to review this product',
  writeReview: 'Write Review',
  relatedProduct: 'Related Products',
  upSellProduct: 'You might also like',
  titleError: 'Please fill in all required fields',
  headerDetail: 'Details',
  showPriceText: 'Show Price',
  loginToWriteReview: 'Login to Write Review',
  titleAlertATC: 'Before adding this product to the cart it needs to be configured.',
  contentAlertATC: 'Please click on Yes to view product details.',
  priceText: 'Price',
  requireFieldErr: 'is a required field.',
  groupProductErr: 'Please specific the quantity for at least one option to add product to cart',
  errorTitle: 'ERROR',
  itemUpdatedWatchList: 'Item is updated into your wishlist',
  itemAddedToWatchList: 'Item is added into your wishlist',
  textRequireField: '*Required Field',
  overviewCustomerReview: 'Overview Customer Reviews',
  byText: 'by',
  ofText: 'of',
  deliveryText: 'Delivery',
  reviewSingle: ' review',
  reviewMultiple: ' reviews',
  fixShippingCost: 'Fixed shipping cost',
  freeShipping: 'Free Shipping',
  calculateCheckout: 'Calculated at checkout',
};
