/* eslint-disable import/prefer-default-export */
export const getTabIndex = (baseNumber: number, term: number) => {
  const radix = 10;
  if (term >= 0) {
    // tra ve tang dan, vd base = 2 thi tab tu 100->999
    return radix ** baseNumber + term;
  }
  // tra ve giam dan, vd base = 2 thi tab tu 999->100
  return radix ** (baseNumber + 1) + term;
};
