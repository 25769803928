import { reducerNameHOR } from '.';
import * as Utils from '../utils';

export const API_CALL_REQUEST = 'API_CALL_REQUEST';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';
export const API_CALL_FAILURE = 'API_CALL_FAILURE';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';

const initialState = {
  isFetching: false,
  userInfo: null,
  errMessage: '',
};
export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_REQUEST:
      return {
        ...state,
        isFetching: true,
        errMessage: '',
      };
    case API_CALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case API_CALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        errMessage: action.errMessage,
      };
    case CHANGE_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };

    default:
      return state;
  }
}

export function changeUserInfo(userInfo) {
  return {
    type: CHANGE_USER_INFO,
    userInfo,
    name: reducerNameHOR.loginMagento,
  };
}
export function callApiUserInfo(action) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: API_CALL_REQUEST,
        url: action.url || '',
        isLoading: action.isLoading || true,
        isToastErr: action.isToastErr || true,
        name: reducerNameHOR.loginMagento,
        params: action.params || {},
        typeRequest: action.typeRequest || 'POST',
        resolve,
        reject,
      });
    });
  };
}

export default reducer;
