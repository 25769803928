import React, { useEffect, useState } from 'react';
import './../style.scss';
import { connect } from 'react-redux';
import localStorage from '../../../utils/LocalStorage';
import { compose } from 'redux';
import { callApiACT } from '../../../redux/callApiRDC';
import { Image } from 'react-bootstrap';
import * as Constants from '../../../constants';
import { Link } from 'react-router-dom';
import checkedImage from '../assets/icnSuccess.png';
import * as Utils from '../../../utils';
import _ from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';

interface Props {
  location;
}
function Thankyou({ location }: any) {
  let orderSuccessLogo = localStorage.get(Constants.KeyAsyncStore.orderSuccessLogo, '');

  const { t } = useTranslation();

  let orderObj = Utils.getSafeValue(location.state, 'item', {});
  useEffect(() => {
    Utils.backTop();
  }, []);
  return (
    <div className="thankYouPage">
      <div className="thankYouPageInner">
        <div className="img">
          {!_.isEmpty(orderSuccessLogo) ? <Image src={orderSuccessLogo} /> : <Image src={checkedImage} width={65} />}
        </div>

        <div className="orderInfoID">
          {t('checkout.yourOrderNumberIs')} #<span className="semiBold">{orderObj['id']}</span>
        </div>
        <div className="emailOrderMsg">{t('checkout.msgThankYouOrder')}</div>
        <div className="fieldGroupBtn">
          <Link className="backToOrderList" to={{ pathname: '/my-account/order-history' }}>
            {t('checkout.viewOrderDetails')}
          </Link>
          <br />
          <Link className="backToOrderHome" to={{ pathname: '/' }}>
            {t('checkout.backToHome')}
          </Link>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
// export default compose(withConnect)(Thankyou);
export default compose(withConnect(withTranslation()(Thankyou)));
