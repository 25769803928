import { reducerNameHOR } from '.';
import produce from 'immer';

export const SET_CART = 'SET_CART';

export const initialState = {
  shoppingCart: {},
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return { ...state, shoppingCart: action.payload.shoppingCart };
    default:
      return state;
  }
};

export function setCart(payload) {
  return {
    type: SET_CART,
    payload,
  };
}

export default reducer;
