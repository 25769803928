import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './style.scss';

interface Props {
  message: string;
  onCloseModal: () => void;
  classCss?: string;
}

function ModalAlert({ message, onCloseModal, classCss }: Props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message != '') {
      setOpen(true);
    }
  }, [message]);

  const closeModal = () => {
    setOpen(false);
    onCloseModal();
  };

  return (
    <>
      <Modal dialogClassName={`modalAlert-fail ${classCss ? classCss : ''}`} show={open} onHide={closeModal}>
        <Modal.Body className="modalAlert-body">
          <div className="text-message">{message}</div>
          <Button onClick={closeModal} className="btn-ok">
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ModalAlert;
