import React, { useEffect, useState } from 'react';
import { find, uniqBy, forEach } from 'lodash';
import './stockLevelStyle.scss';
import ReactTooltip from 'react-tooltip';
import * as Utils from '../../utils';
import { useTranslation, withTranslation } from 'react-i18next';

interface Product {
  product: any;
  // isAgent?: boolean,
}

const COLOR_PROP_LABEL = ['Choose a color', 'Color'];
const SIZE_PROP_LABEL = 'Size';

function StockLevel({
  product,
}: // isAgent
any) {
  const { t } = useTranslation();

  const [stockTableData, setStockTableData] = useState<any>([]);
  let stockTypeDisplay = Utils.getSafeValue(product, 'stockTypeDisplay', '');
  useEffect(() => {
    if (product.variantMatrix && product.variantMatrix.length) {
      const listOfColors = uniqBy(
        product.variantMatrix.map(v => {
          return find(v.optionValues, o => COLOR_PROP_LABEL.includes(o.optionDisplayName));
        }),
        'id'
      );
      const listOfSizes = uniqBy(
        product.variantMatrix.map(v => {
          return find(v.optionValues, o => o.optionDisplayName == SIZE_PROP_LABEL);
        }),
        'id'
      );

      if (listOfColors.length && listOfSizes.length) {
        let stockMatrix: any = [];
        forEach(listOfColors, (c: any) => {
          stockMatrix.push({
            color: c,
            sizes: listOfSizes.map((s: any) => {
              let matchedOption = find(product.variantMatrix, v => {
                let sizeMatch = find(v.optionValues, o => o.id == s.id);
                let colorMatch = find(v.optionValues, o => o.id == c.id);
                return sizeMatch && colorMatch;
              });
              return {
                size: s,
                stockLevelStatus: matchedOption ? matchedOption.stockLevelStatus : -1,
                inventoryLevel: matchedOption ? matchedOption.inventoryLevel : -1,
              };
            }),
          });
        });
        setStockTableData(stockMatrix);
      }
    }
  }, [product]);

  return (
    <>
      {stockTableData && stockTableData.length > 0 && (
        <>
          <div className="tableStockCheck">
            <div className="tableColor">
              <table className="tableStockLevel">
                <thead>
                  <tr>
                    <th className="clColorOption">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {stockTableData.map((c: any, i) => (
                    <tr key={i}>
                      <td className="clColorOption">
                        {c.color.colors.map((color: any, i) => {
                          return <span className="lblColor" style={{ background: color }} key={i}></span>;
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="tableSize">
              <table className="tableStockLevel">
                <thead>
                  <tr>
                    {stockTableData[0].sizes.map((s: any, i) => (
                      <th className="item" key={i}>
                        {s.size.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {stockTableData.map((c: any, i) => (
                    <tr key={i}>
                      {c.sizes.map((s: any, i2) => {
                        let msg = '';
                        if (s.stockLevelStatus == 2) {
                          msg = `${t('productDetail.inStock')}`;
                        } else if (s.stockLevelStatus == 1) {
                          msg = `${t('productDetail.lowStock')}`;
                        } else if (s.stockLevelStatus == 0) {
                          msg = `${t('productDetail.outOfStock')}`;
                        }
                        return (
                          <td key={i2}>
                            {stockTypeDisplay == 'color' && (
                              <>
                                <span
                                  className={`itemStatus itemStatus-${s.stockLevelStatus}`}
                                  data-tip={msg}
                                  data-for={`itemTooltip${i}${i2}`}
                                ></span>
                                <ReactTooltip
                                  effect={'solid'}
                                  clickable={true}
                                  id={`itemTooltip${i}${i2}`}
                                ></ReactTooltip>
                              </>
                            )}
                            {stockTypeDisplay == 'number' && (
                              <>
                                <span>{s.inventoryLevel > 9999 ? '9999+' : s.inventoryLevel}</span>
                                {/*<span>{s.inventoryLevel}</span>*/}
                              </>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default withTranslation()(StockLevel);
