import React, { ReactElement } from 'react';
import { Image } from 'react-bootstrap';
import './style.scss';

interface Props {
  imageSrc: string;
  message: string;
  styles?: React.CSSProperties;
}

export default function CustomLoading({ imageSrc, message, styles }: Props): ReactElement {
  return (
    <div className="loading-screen">
      <div className="modal-loading">
        <div className="modal-message">{message}</div>
        <Image src={imageSrc} style={styles ? styles : {}} />
      </div>
    </div>
  );
}
