import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { FilterList, FilterOption, ProductListQueryParams } from 'src/types/productList';
import { getSafeValue } from 'src/utils';

export const initFilterList: FilterList = {
  currencySymbol: '',
  error: null,
  filters: [],
  item: null,
  list: null,
  numberOfProducts: 10,
  success: null,
};
interface FilterListState {
  loading: boolean;
  data: FilterList;
  currentFilters: FilterOption[];
}
export const initFilterListState: FilterListState = {
  loading: false,
  data: initFilterList,
  currentFilters: [],
};
export enum FilterListActionType {
  LOADING = 'LOADING',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_ERROR = 'FETCH_ERROR',
}

type FilterListAction =
  | { type: FilterListActionType.LOADING }
  | { type: FilterListActionType.FETCH_SUCCESS; payload: FilterList; params: ProductListQueryParams }
  | { type: FilterListActionType.FETCH_ERROR; payload?: any };

export const filterListReducer = (state: FilterListState, action: FilterListAction) => {
  switch (action.type) {
    case FilterListActionType.LOADING: {
      const newState: FilterListState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case FilterListActionType.FETCH_SUCCESS: {
      const { params, payload } = action;

      const priceOptionKey = isEmpty(params.priceFilter) ? '' : 'Price';
      const filtersOptionKey = Object.keys(params.filters);
      const listKey = (priceOptionKey && filtersOptionKey.concat(priceOptionKey)) || filtersOptionKey;

      const matchFilters = getSafeValue(payload, 'filters', []).filter(filterOption =>
        listKey.includes(filterOption.key)
      );

      // currentFilter can be filtered from set of OptionFilter by params (params currently works properly)
      // TODO:  confirm logic to calculate set of OptionFiler below  = [prevCurrentFilter, prevFilters, newFilters] ?

      const mergedFilterOptions = [...state.currentFilters, ...state.data.filters, ...matchFilters].reduce(
        (listFilter, filterOption) => {
          const matchKeyPosition = listFilter.findIndex(option => option.key === filterOption.key);

          if (matchKeyPosition === -1) {
            return listFilter.concat(filterOption);
          }

          const { items: filterOptionItems } = filterOption;
          const { items: currentItems, ...restProperty } = listFilter[matchKeyPosition];

          const mergedItems = uniqBy([...filterOptionItems, ...currentItems], item => item.id);
          return Object.assign([], listFilter, { [matchKeyPosition]: { items: mergedItems, ...restProperty } });
        },
        [] as FilterOption[]
      );

      const newCurrentFilters = mergedFilterOptions
        .filter(filterOption => listKey.includes(filterOption.key))
        .map(filterOption => {
          if (filterOption.key === 'Price') {
            return filterOption;
          }
          const { items, ...cloneOption } = filterOption;
          const matchParamsItems = items.filter(item => {
            return params.filters[filterOption.key].includes(item.id);
          });
          return { ...cloneOption, items: matchParamsItems };
        });

      const newState: FilterListState = {
        ...state,
        loading: false,
        data: payload,
        currentFilters: newCurrentFilters,
      };
      return newState;
    }
    case FilterListActionType.FETCH_ERROR: {
      const newState: FilterListState = {
        ...state,
        loading: false,
        data: action.payload,
      };
      return newState;
    }

    default: {
      return state;
    }
  }
};
