import Lodash, { uniqBy, forEach, isEmpty } from 'lodash';
import { sprintf, vsprintf } from 'sprintf-js';
import htmlTags from 'html-tags';
import { NumberFormat } from 'intl';
import * as Constants from '../constants';
import moment from 'moment';
import 'intl/locale-data/jsonp/en.js';
// import { uniqBy, forEach, isEmpty } from 'lodash';

const { CurrencyKey } = Constants;

export const convertTimeStamp = unixTimeStr => {
  const timeSent = safeParseFloat(unixTimeStr);
  let dateStr = moment.unix(timeSent / 1000).format('dddd, MMMM Do, YYYY - h:mm A');

  return dateStr;
};

export const parseDate = dateInput => {
  const dateFull = moment(dateInput).format();
  let dateNum = Date.parse(dateFull);

  return dateNum;
};

export const removeHtmlTag = content => {
  const regex = /(<([^>]+)>)/gi;
  return content.replace(regex, '');
};

export const convertNumberWithDot = num => {
  let parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
};

export const getDistanceMeter = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = Lodash.round(R * c * 1000); // Distance in meter
  return d;
};

export const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

export const truncateTextAnalytic = text => {
  let validText = '';

  validText = Lodash.truncate(text, {
    length: 100,
    separator: '',
  });

  return validText;
};

// export const getSizeImg = uriImg => {
//   return new Promise((resolve, reject) => {
//     Image.getSize(
//       uriImg,
//       (width, height) => {
//         resolve({ widthImg: width, heightImg: height });
//       },
//       err => resolve({ widthImg: 0, heightImg: 0 })
//     );
//   });
// };

// export const getAllEcomCategory = (
//   array,
//   keyGet,
//   idModuleParent,
//   resultArray = []
// ) => {
//   Lodash.forEach(array, function(obj) {
//     let listChild = getSafeValue(obj, keyGet, []);
//     const name = getSafeValue(obj, "name", "");
//     // console.log("object child", obj);
//     const newObj = { name, idModuleParent, objBrowseList: obj };
//     resultArray.push(newObj);
//     if (listChild.length > 0) {
//       getAllEcomCategory(listChild, keyGet, idModuleParent, resultArray);
//     }
//   });
//   return resultArray;
// };

export const isHtmlStr = str => {
  const basic = /\s?<!doctype html>|(<html\b[^>]*>|<body\b[^>]*>|<x-[^>]+>)+/i;
  const full = new RegExp(htmlTags.map(tag => `<${tag}\\b[^>]*>`).join('|'), 'i');
  return basic.test(str) || full.test(str);
};

export const isValidColor = strColor => {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(strColor);
};

export const safeParseInt = strNumber => {
  let numParse = 0;
  if (strNumber === '' || strNumber === null || strNumber === undefined) {
    return numParse;
  }

  numParse = Lodash.parseInt(strNumber);
  if (numParse === null || Lodash.isNaN(numParse)) {
    numParse = 0;
  }

  return numParse;
};

export const safeParseFloat = strNumber => {
  let numParse = 0;
  if (strNumber === '' || strNumber === null || strNumber === undefined) {
    return numParse;
  }

  numParse = parseFloat(strNumber);
  if (numParse === null) {
    numParse = 0;
  }

  return numParse;
};

export const getDeviceLocale = currencyCode => {
  if (currencyCode.toUpperCase() === CurrencyKey.KEY_EUR.toUpperCase()) {
    return 'nl-NL';
  } else if (currencyCode.toUpperCase() === CurrencyKey.KEY_SAR.toUpperCase()) {
    return 'en-US';
  } else if (currencyCode.toUpperCase() === CurrencyKey.KEY_SEK.toUpperCase()) {
    return 'sv-SE';
  } else if (currencyCode.toUpperCase() === CurrencyKey.KEY_USD.toUpperCase()) {
    return 'en-US';
  } else {
    return 'en-US';
  }
};

export const backTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

// export const convertCurrency = (value, currencyCode) => {
//   const val = safeParseFloat(value);
//   const valueRound = Math.round(val * 100) / 100;
//   const currencyDefault = getSafeValue(
//     GlobalSettingApp.settingApp,
//     "currency",
//     CurrencyKey.KEY_USD
//   );

// const locale = getDeviceLocale(currencyCode || currencyDefault);
// // const testVal = new NumberFormat("en-AU", {
// //   style: "currency",
// //   currency: "AUD"
// // }).format(valueRound);
// // console.log("testVal", testVal);
//
//   return new NumberFormat(locale, {
//     style: "currency",
//     currency: currencyCode || currencyDefault,
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 2
//   }).format(valueRound);
// };

export const safeParseJson = jsonValue => {
  let objParsed = {};
  if (jsonValue && jsonValue !== '' && typeof jsonValue === 'string' && jsonValue !== null) {
    objParsed = JSON.parse(jsonValue);
  }
  return Lodash.isObject(objParsed) ? objParsed : {};
};

export const safeParseListJson = jsonValue => {
  let objParsed = [];
  if (jsonValue && jsonValue !== '' && typeof jsonValue === 'string' && jsonValue !== null) {
    objParsed = JSON.parse(jsonValue);
  }

  return Lodash.isArray(objParsed) ? objParsed : [];
};

export const replaceStrUrl = (baseUrl, arrStr) => {
  let path = vsprintf(baseUrl, arrStr);
  return path;
};

export const getSafeValue = (object, keyItem, defaultValue) => {
  let safeValue = Lodash.get(object, keyItem, defaultValue);
  if (safeValue === null) {
    safeValue = defaultValue;
  }

  if (safeValue === '') {
    safeValue = defaultValue;
  }

  if (
    safeValue !== null &&
    defaultValue !== null &&
    (typeof safeValue !== typeof defaultValue || safeValue.constructor !== defaultValue.constructor)
  ) {
    safeValue = defaultValue;
  }

  // console.log("safeValue", safeValue);

  return safeValue;
};

export const convertCurrency = (value, currencyCode) => {
  const val = safeParseFloat(value);
  const valueRound = Math.round(val * 100) / 100;
  const currencyDefault = CurrencyKey.KEY_USD;

  const locale = getDeviceLocale(currencyCode || currencyDefault);
  // const testVal = new NumberFormat("en-AU", {
  //   style: "currency",
  //   currency: "AUD"
  // }).format(valueRound);
  // console.log("testVal", testVal);

  return new NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode || currencyDefault,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(valueRound);
};

export const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }, offsetList) => {
  let paddingToBottom = 10;
  let isScrollDown = true;
  const currentOffset = contentOffset.y;
  const dif = currentOffset - (offsetList || 0);
  const isLongeContent = contentSize.height > layoutMeasurement.height;

  if (Math.abs(dif) < 3) {
    // console.log("unclear");
    isScrollDown = false;
  } else if (dif < 0) {
    // console.log("up");
    isScrollDown = false;
  } else {
    // console.log("down");
    isScrollDown = true;
  }

  offsetList = currentOffset;
  let isCloseToBot = layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  return isCloseToBot && isScrollDown && isLongeContent;
};

export const buildFullTree = (tree, id, data: any = []) => {
  const findPath = (node, id) => {
    //If current node matches search node, return tail of path result
    if (node) {
      if (node.id === id) {
        return [node];
      } else {
        //If current node not search node match, examine children. For first
        //child that returns an array (path), prepend current node to that
        //path result
        for (const child of node.children) {
          const childPath = findPath(child, id);
          if (Array.isArray(childPath)) {
            childPath.unshift(child);
            return childPath;
          }
        }
      }
    }
  };

  const foundPath = findPath(tree, id);

  // If search from root returns a path, prepend root node to path in
  // data result
  if (Array.isArray(foundPath)) {
    data.push(tree);
    data.push(...foundPath);
  }

  return uniqBy(data, 'id');
};

export const findCategoryTree = (categories, id) => {
  let treeData: any = [];
  forEach(categories, cat => {
    const tree = buildFullTree(cat, id);
    !isEmpty(tree) && (treeData = tree);
  });
  return treeData;
};

export const getAllEcomCategory = (array, keyGet, idModuleParent, resultArray: any[] = []) => {
  forEach(array, function (obj) {
    let listChild = getSafeValue(obj, keyGet, []);
    let name = getSafeValue(obj, 'name', '');
    let id = obj['id'];
    // console.log("object child", obj);
    let newObj = { name, id, idModuleParent, objBrowseList: obj };
    resultArray.push(newObj);
    if (listChild.length > 0) {
      getAllEcomCategory(listChild, keyGet, idModuleParent, resultArray);
    }
  });
  return resultArray;
};

export const getAddressDisplayType = address => {
  if (Constants.ADDRESS_TYPE_0.includes(address.countryIso2)) {
    return 0;
  } else if (Constants.ADDRESS_TYPE_1.includes(address.countryIso2)) {
    return 1;
  } else if (Constants.ADDRESS_TYPE_2.includes(address.countryIso2)) {
    return 2;
  } else if (Constants.ADDRESS_TYPE_3.includes(address.countryIso2)) {
    return 3;
  } else {
    return 4;
  }
};
export const convertEstDeliveryToDate = timeString => {
  const date = moment(timeString, Constants.DateFormat.serverDateFormat).toDate();
  const isValid = moment(date).isValid();
  return (isValid && date) || undefined;
};
export const convertDateFormat = (value: string, fromFormat: string, toFormat: string): string => {
  const date = moment(value, fromFormat);
  return date.isValid() ? date.format(toFormat) : '';
};
export const safeParseDate = (value: string, format = 'YYYY-MM-DD') => {
  const date = moment(value, format).toDate();
  const isValid = moment(date).isValid();
  return (isValid && date) || undefined;
};
export const getPathCategoryTree = (categoryTree: any[], childrenKey: string, key: string, value: number | string) => {
  const res: any = [];
  const comparedValue = safeParseInt(value);

  function hasPath(category) {
    res.push(category);

    if (category[key] === comparedValue) {
      return true;
    }
    let childMatch = false;
    category[childrenKey].forEach(child => {
      if (hasPath(child)) {
        childMatch = true;
        return true;
      }
    });
    if (childMatch) {
      return true;
    }
    res.pop(category);
    return false;
  }
  categoryTree.forEach(cat => {
    if (cat && hasPath(cat)) {
      return true;
    }
  });
  return res;
};
