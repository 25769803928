import React, { useEffect, useState } from 'react';
import './style.scss';
import _ from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import glovesImage from './assets/Gloves-Men.jpg';
import { backTop } from '../../../utils';
import i18next from 'i18next';
import * as Utils from '../../../utils';
import { useTranslation, withTranslation } from 'react-i18next';

function SizeGuide() {
  const [sizeGuideList, setSizeGuideList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    backTop();
    let dataContent = i18next.t('sizeChart', { returnObjects: true, glovesImage: glovesImage });
    let dataContent2 = Utils.getSafeValue(dataContent, 'tableList', []);

    setSizeGuideList(dataContent2);
  }, []);

  return (
    <>
      <div className="sizeGuidePage">
        <Container>
          <Row>
            <div className="col-2"></div>
            <div className="col-8">
              <div className="titlePage">{t('sizeChart.titlePage')}</div>
              {_.map(sizeGuideList, (item, index) => {
                return (
                  <div className="blockSizeGuide" key={index}>
                    <div className="titleBlock">{item['titleSizeBlock']}</div>
                    {/*<div className="titleDesc">{item['topDesc']}</div>*/}
                    <div className={`tableSize ${item['classBlock'] ? item['classBlock'] : ''}`}>
                      <div className="tableSizeInner">
                        <div className="colLabel">
                          <div className="colItem">
                            {_.map(item['listLabelOption'], (item2: any, index2) => {
                              return (
                                <div
                                  className={`rowValue ${item2['rowspan'] ? 'rowspan' + item2['rowspan'] : ''} ${
                                    index2 == 0 ? 'semiBold' : ''
                                  }`}
                                  key={index2}
                                >
                                  {item2['item'] ? item2['item'] : <>&nbsp;</>}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="colValue">
                          <div className="colTable">
                            {_.map(item['listSize'], (item1: any, index1) => {
                              return (
                                <div className="colItem" key={index1}>
                                  <div className="rowValue semiBold">{item1.sizeName}</div>
                                  {_.map(item1.optionSize, (itemOption, indexOption) => {
                                    return (
                                      <div className="rowValue" key={indexOption}>
                                        {itemOption.option ? itemOption.option : '-'}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {!item['bottomImage'] ? (
                      <>
                        {item['bottomDesc'] && (
                          <div className="bottomDesc">
                            <span dangerouslySetInnerHTML={{ __html: item['bottomDesc'] }} />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="blockBottom">
                        <div className="imageBlock">
                          <img src={item['bottomImage']} />
                        </div>
                        {item['bottomDesc'] && (
                          <div className="bottomDesc">
                            <span dangerouslySetInnerHTML={{ __html: item['bottomDesc'] }} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="col-2"></div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default withTranslation()(SizeGuide);
