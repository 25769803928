import React, { useEffect, useState, useRef, useContext } from 'react';
import './style.scss';
import TableOrder from '../tableListProduct/tableOrder';
import SizeGuide from '../htmlStaticPage/sizeGuide';
import { callApiACT } from '../../redux/callApiRDC';
import { connect } from 'react-redux';
import * as AppURL from '../../services/urlAPI';
import * as Utils from '../../utils';
import { TypeRequest } from '../../constants';
import _ from 'lodash';
import { compose } from 'redux';
import ImageGallery from 'react-image-gallery';
import Skeleton from '@material-ui/lab/Skeleton';
import StockLevel from './StockLevel';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import * as ProductHelpers from './productDetailHelpers';
import { useTranslation, withTranslation } from 'react-i18next';
import { getTabIndex } from '../tableListProduct/helpers';

interface Props {
  match: any;
  callApiACT: (action) => void;
}

function ProductDetail({ callApiACT, match }: any) {
  const { t } = useTranslation();

  const [images, setImages] = useState<any[]>([]);
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [listColor, setListColor] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [checkedOption, setCheckedOption] = useState(null);

  const [showDesc, setShowDesc] = useState(true);
  const [showStockTable, setShowStockTable] = useState(false);
  const [showBulkOrder, setShowBulkOrder] = useState(true);
  const [showAdditionInfo, setShowAdditionInfo] = useState(false);
  const [arrayRender, setArrayRender] = useState<any>([]);
  // const [isAgent, setIsAgent] = useState<boolean>(false);

  // let permissions = localStorage.getObject(Constants.KeyAsyncStore.permissions, {});

  useEffect(() => {
    getProductDetails();
    // setIsAgent(Utils.getSafeValue(permissions, 'loginAsCustomer', false));
  }, []);

  let appKey = localStorage.get(Constants.KeyAsyncStore.appKey, '');
  let deviceKey = localStorage.get(Constants.KeyAsyncStore.deviceKey, '');

  const getProductDetails = async () => {
    setIsLoading(true);
    let params = {
      productId: match.params.id || '',
      appKey: appKey,
      appTypeCode: 'bcm',
      deviceKey: deviceKey,
    };
    try {
      let productDetails = await callApiACT({
        typeRequest: TypeRequest.POST,
        url: AppURL.getProductsDetails,
        params: params,
      });
      let product = await Utils.getSafeValue(productDetails, 'product', { s: 1 });
      setProduct(product);

      let gallerySet = Utils.getSafeValue(product, 'gallerySet', []);

      let galleryColor = _.find(gallerySet, { displayName: 'Color' });
      let listColor = Utils.getSafeValue(galleryColor, 'optionValues', []);
      setListColor(listColor);

      let imageArr = listColor[0]['imageUrls'];
      let images = _.map(imageArr, (item, index) => {
        return {
          original: item,
          thumbnail: item,
        };
      });
      setImages(images);
      setCheckedOption(listColor[0]['id']);
      setSelectedOption(listColor[0]);

      const pricingRules = Utils.getSafeValue(productDetails, 'pricingRules', []);
      const calculatedOption = ProductHelpers.calculatedOptionWithPrice(product, gallerySet, pricingRules);
      const listAttributes = ProductHelpers.getAttributeToDisplay(product, calculatedOption);
      const customFields = Utils.getSafeValue(product, 'customFields', []);

      const _arrayRender = [...listAttributes, ...customFields];
      setArrayRender(_arrayRender);

      //set images product gallery = parent product images
      // let images = _.map(product['images'], (value, index) => {
      //     return {
      //         original: value.urlStandard,
      //         thumbnail: value.urlStandard,
      //     };
      // });
      // setImages(images);
    } catch (e) {
      console.log('error get product: ', e);
    }
    setIsLoading(false);
  };

  // const [classScroll, setClassScroll] = useState('');
  // const onScroll = () => {
  //     const scrollY = window.scrollY;
  //     if (scrollY>0){
  //         setClassScroll('onScroll');
  //     } else {
  //         setClassScroll('');
  //     }
  // };
  // window.addEventListener('scroll', ()=> {onScroll()});

  const renderAttributeAndCustomField = (arrayRender): JSX.Element => {
    return arrayRender.map((item, index: number) => {
      return (
        <div className="attribute" key={index}>
          <span className="att-name">{`${item.name}: `} </span>
          <span className="att-value">{item.value}</span>
        </div>
      );
    });
  };

  const showHideDesc = () => {
    setShowDesc(!showDesc);
    setShowAdditionInfo(false);
    setShowStockTable(false);
    setShowBulkOrder(false);
  };
  const showHideAdditionInfo = () => {
    setShowDesc(false);
    setShowAdditionInfo(!showAdditionInfo);
    setShowStockTable(false);
    setShowBulkOrder(false);
  };
  const showHideStockTable = () => {
    setShowDesc(false);
    setShowAdditionInfo(false);
    setShowStockTable(!showStockTable);
    setShowBulkOrder(false);
  };
  const showHideBulkOrder = () => {
    setShowDesc(false);
    setShowAdditionInfo(false);
    setShowStockTable(false);
    setShowBulkOrder(!showBulkOrder);
  };

  return (
    <div>
      <div className={`wr-product-detail`}>
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="details">
                <div className="row">
                  <div className="col-6">
                    <div className="wr-slideShow">
                      {isLoading ? (
                        <div className="d-flex-row">
                          <div>
                            {_.times(4, i => (
                              <Skeleton variant={'rect'} width={81} height={81} style={{ marginBottom: '10px' }} />
                            ))}
                          </div>
                          <div className="m-auto" style={{ paddingLeft: '15px' }}>
                            <Skeleton variant={'rect'} width={360} height={360} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="productDetailGallery">
                            {/*<WrapCarousel />*/}
                            <ImageGallery
                              items={images}
                              showPlayButton={false}
                              showFullscreenButton={false}
                              thumbnailPosition="left"
                              showNav={images.length > 4 ? true : false}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="wr-detail">
                      <div className="product-shop">
                        <div className="product-name">
                          <h1>{product['name']}</h1>
                        </div>
                        <div className="add-to-cart-wrapper">
                          {!_.isEmpty(product['sku']) && <div className="productSku">{product['sku']}</div>}
                          <div className="product-options">
                            {!_.isEmpty(selectedOption) && (
                              <div className="label-option">
                                <span className="">{t('productDetail.color')} </span>
                                {_.isEmpty(selectedOption) ? '...' : selectedOption['label']}
                              </div>
                            )}
                            <div className="attr">
                              <ul>
                                {_.map(listColor, (item, index) => {
                                  let colors = item['valueData']['colors'] || [];
                                  let imgUrls = _.map(item['imageUrls'], (imgUrl, indexUrl) => {
                                    return {
                                      original: imgUrl,
                                      thumbnail: imgUrl,
                                    };
                                  });
                                  return (
                                    <li key={index}>
                                      <label
                                        className="value"
                                        onClick={() => {
                                          setImages(imgUrls);
                                          setSelectedOption(item);
                                          setCheckedOption(item['id']);
                                        }}
                                      >
                                        <input type="radio" name="color" checked={item['id'] == checkedOption} />
                                        {colors.map((color: any, i) => (
                                          <span
                                            key={i}
                                            className="span-color checkmark"
                                            style={{ backgroundColor: color }}
                                          ></span>
                                        ))}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="detailDescription">
                <div className="toggleShow" onClick={() => showHideDesc()}>
                  {t('productDetail.productDescription')}
                  <span className="icon">{showDesc ? <IoIosRemove /> : <IoIosAdd />}</span>
                </div>
                {showDesc && !isLoading && (
                  <>
                    {!_.isEmpty(product['description']) ? (
                      <div className="product-desc" dangerouslySetInnerHTML={{ __html: product['description'] }} />
                    ) : (
                      <div className="product-desc">{t('productDetail.noInformationAvailable')}</div>
                    )}
                  </>
                )}
              </div>

              <div className="additioInfo">
                <div className="toggleShow" onClick={() => showHideAdditionInfo()}>
                  {t('productDetail.additionalInformation')}
                  <span className="icon">{showAdditionInfo ? <IoIosRemove /> : <IoIosAdd />}</span>
                </div>
                {showAdditionInfo && (
                  <div className="additioInfoContent">
                    {arrayRender.length > 0
                      ? renderAttributeAndCustomField(arrayRender)
                      : `${t('productDetail.noInformationAvailable')}`}
                  </div>
                )}
              </div>

              <div className="stockCheckBox">
                <div className="toggleShow" onClick={() => showHideStockTable()}>
                  {t('productDetail.stockCheck')}
                  <span className="icon">{showStockTable ? <IoIosRemove /> : <IoIosAdd />}</span>
                </div>
                {showStockTable && (
                  <div className="contentBox">
                    <StockLevel
                      product={product}
                      // isAgent={isAgent}
                    />
                  </div>
                )}
              </div>

              <div className="wr-order">
                <div className="toggleShow" onClick={() => showHideBulkOrder()}>
                  {t('productDetail.bulkOrder')}
                  <span className="icon">{showBulkOrder ? <IoIosRemove /> : <IoIosAdd />}</span>
                </div>
                {showBulkOrder && (
                  <div className="info not-inner">
                    <TableOrder
                      data={product}
                      id={match.params.id}
                      getTabIndex={num => getTabIndex(2, num)}

                      // isAgent={isAgent}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      {/*<SizeGuide/>*/}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
// export default compose(withConnect)(ProductDetail);
export default compose(withConnect(withTranslation()(ProductDetail)));
