import React from 'react';
import './styles.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode | undefined;
}

const RadioButton = ({ className: classNames, tabIndex, label, ...rest }: Props) => {
  return (
    <span style={{ marginLeft: 10 }}>
      <label className="radio" tabIndex={tabIndex || 0}>
        <input className={`radio__input ${classNames}`} type="radio" {...rest} />
        <span className="radio__title">{label}</span>
        <span className="radio__mark" />
      </label>
    </span>
  );
};
RadioButton.defaultProps = {
  readOnly: false,
};

export default RadioButton;
