import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import OwlCarousel from 'react-owl-carousel2';
import { IoIosPlay } from 'react-icons/io';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation, withTranslation } from 'react-i18next';

import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { backTop } from '../../utils';
import localStorage from '../../utils/LocalStorage';
import { klavioySrc, klavioySrcEU, klavioySrcDE, klavioySrcFR } from '../../constants';

import './style.scss';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';

import imgAccessories from './assets/images/button/gill_voyager_dry_bag.jpg';
import imgNewCat from './assets/images/button/new.jpg';
import imgSystem from './assets/images/button/gill_aqua_parka_wetsuit.jpg';
import imgSlide0 from './assets/images/slide/Gill_S2_20_ActiveLakes-0479.jpg';
import imgSlide1 from './assets/images/slide/Gill_S1_22_Liestyle_Paddy_Hoodie_4.jpg';
import imgSlide2 from './assets/images/slide/hpb-b2b-website.jpg';
import imgSlide3 from './assets/images/slide/hpb-os1-studio.jpg';
import imgNewJacket from './assets/images/button/gill_versoDrysuit.jpg';
import imgGillVersoBanner from './assets/images/slide/Gill_Verso_System_Web_Banner.jpg';

function HomePage({ appConfig }: any) {
  const { t } = useTranslation();

  const [isLogin, setIsLogin] = useState(false);

  const [homeSlide, setHomeSlide] = useState({});
  const [homeButtons, setHomeButtons] = useState({});
  const [appModules, setAppModules] = useState([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [seasonalDownloads, setSeasonalDownloads] = useState();
  const [customsDocuments, setCustomsDocuments] = useState();

  const appKey = localStorage.get('appKey', '');

  const listAppkey = ['5f0bdcae96f42700019b3745', '5f0721b52469711312b8c198'];
  const listAppkeyFR = ['5f07215c2469711312b8c17a', '5f0bdabc96f42700019b36ed'];
  const listAppkeyDE = ['5f07205e2469711312b8c13d', '5f0bdc2a96f42700019b3722'];
  const listAppkeyUK = ['5e6a0586a9cf1047aefe3b94'];

  const [link1, setLink1] = useState<string>('');
  const [link2, setLink2] = useState<string>('');
  const [link3, setLink3] = useState<string>('');
  const [link4, setLink4] = useState<string>('');
  const [gillVersoBannerLink, setGillVersoBannerLink] = useState<string>('');

  useEffect(() => {
    backTop();

    if (window.localStorage.getItem(Constants.KeyAsyncStore.userNameDev) === null) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }

    let appLang = localStorage.get('appLang', 'en');
    if (appLang == 'fr') {
      setFileUrl('./assets/file/seasonal-downloads-french.docx');
    } else if (appLang == 'de') {
      setFileUrl('./assets/file/seasonal-downloads-germany.docx');
    } else {
      setFileUrl('./assets/file/seasonal-downloads-english.docx');
    }

    let klavioyUrl = '';
    if (_.includes(listAppkeyFR, appKey)) {
      setLink3('business/34'); // Clothing
      setLink4('business/32'); // Activity
      setLink1('business/128'); // Dinghy
      setLink2('business/25'); // Accessories
      setGillVersoBannerLink('business/118');
      klavioyUrl = klavioySrcEU;
    } else if (_.includes(listAppkeyDE, appKey)) {
      setLink3('business/31'); // Clothing
      setLink4('business/29'); // Activity
      setLink1('business/124'); // Dinghy
      setLink2('business/25'); // Accessories
      setGillVersoBannerLink('business/97');
      klavioyUrl = klavioySrcEU;
    } else if (_.includes(listAppkeyUK, appKey)) {
      setLink3('business/33'); // Clothing
      setLink4('business/30'); // Activity
      setLink1('business/125'); // Dinghy
      setLink2('business/25'); // Accessories
      setGillVersoBannerLink('business/97');
      klavioyUrl = klavioySrc;
    } else {
      setLink3('business/25'); // Clothing
      setLink4('business/105'); // Activity
      setLink1('business/125'); // Dinghy
      setLink2('business/63'); // Accessories
      setGillVersoBannerLink('business/28');
      klavioyUrl = klavioySrcEU;
    }

    let customer = localStorage.getObject('customer', {});
    if (customer.id) {
      initKlaviyo(klavioyUrl);
    }
  }, []);

  useEffect(() => {
    if (appConfig && !_.isEmpty(appConfig.response)) {
      let modules = Utils.getSafeValue(appConfig.response['appData'], 'modules', []);

      let homeData = _.find(modules, { moduleType: 'HOME_SCREEN2' }, 0);
      let seasonalDownloads = _.find(modules, { moduleName: t('homeScreen.seasonalDownloads') }, 0);
      let customsDocuments = _.find(modules, { moduleName: t('homeScreen.customsDocuments') }, 0);

      homeData && setHomeSlide(Utils.safeParseJson(homeData['moduleMetaData']['data']));
      homeData && setHomeButtons(homeData['moduleDataItems']);

      setAppModules(modules);
      setSeasonalDownloads(seasonalDownloads);
      setCustomsDocuments(customsDocuments);
    }
  }, [appConfig]);

  const initKlaviyo = klavioyUrl => {
    const existingScript = document.getElementById('klaviyo');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = klavioyUrl;
      script.defer = true;
      script.id = 'klaviyo';
      document.body.appendChild(script);
    }
  };

  const [slideImage, setSlideImage] = useState<any>({});

  const getImages = () => {
    let images = _.map(homeSlide['slides'], (item, index) => {
      let moduleAction = item.action.replace('LOADMODULE ', '');
      let moduleItem = _.find(appModules, { _id: moduleAction });
      let catID = '';
      if (moduleItem) {
        let moduleItemData = Utils.safeParseJson(moduleItem['moduleMetaData']['data']);
        catID = moduleItemData['categoryId'];
      }

      return {
        scalingMethod: item.scalingMethod,
        image: item.image,
        color: item.color,
        text: item.text,
        action: item.action,
        actionData: item.actionData,
        webLink: item.webLink,
        cartID: moduleItem ? catID : '',
        itemType: moduleItem ? moduleItem['moduleType'] : '',
      };
    });
    setSlideImage(images);
  };

  useEffect(() => {
    getImages();
  }, [appModules]);

  const options = {
    items: 1,
    nav: false,
    rewind: false,
    autoplay: true,
    autoplayTimeout: 4000,
    loop: false,
  };

  const itemSlides = [
    // {
    //   image: imgSlide0,
    //   url: _.includes(listAppkeyFR, appKey)
    //     ? '/business/63'
    //     : _.includes(listAppkeyDE, appKey)
    //     ? '/business/71'
    //     : _.includes(listAppkeyUK, appKey)
    //     ? '/business/63'
    //     : '/business/47',
    //   // subTitle: `${t('homeScreen.lblImgSubTitle3')}`,
    //   title: `${t('homeScreen.lblImgTitle0AddNew')}`,
    //   desc: `${t('homeScreen.lblImgDesc0AddNew')}`,
    //   note: `${t('homeScreen.lblImgNote01AddNew')}`,
    //   note2: `${t('homeScreen.lblImgNote02AddNew')}`,
    // },
    {
      image: imgSlide1,
      url: _.includes(listAppkeyFR, appKey)
        ? '/business/118'
        : _.includes(listAppkeyDE, appKey)
        ? '/business/97'
        : _.includes(listAppkeyUK, appKey)
        ? '/business/97'
        : '/business/28',
      // subTitle: `${t('homeScreen.lblImgSubTitle3')}`,
      title: `${t('homeScreen.lblImgTitle0New')}`,
      desc: `${t('homeScreen.lblImgDesc0New')}`,
      style: {
        color: '#333',
      },
    },
    {
      image: imgSlide2,
      url: _.includes(listAppkeyFR, appKey)
        ? '/products/992'
        : _.includes(listAppkeyDE, appKey)
        ? '/products/995'
        : _.includes(listAppkeyUK, appKey)
        ? '/products/856'
        : '/products/1240',
      // subTitle: `${t('homeScreen.lblImgSubTitle3')}`,
      title: `${t('homeScreen.lblImgTitle1New')}`,
      desc: `${t('homeScreen.lblImgDesc1New')}`,
    },
    //GM-2054: move slide 3 to first, and then change text, image in slide
    {
      image: imgSlide3,
      url: _.includes(listAppkeyFR, appKey)
        ? '/business/51'
        : _.includes(listAppkeyDE, appKey)
        ? '/business/51'
        : _.includes(listAppkeyUK, appKey)
        ? '/business/51'
        : '/business/99',
      // subTitle: `${t('homeScreen.lblImgSubTitle3')}`,
      title: `${t('homeScreen.lblImgTitle3')}`,
      desc: ``,
      // note: `${t('homeScreen.lblImgNote3')}`,
      // note2: `${t('homeScreen.lblImgNote23')}`,
    },
  ];

  return (
    <>
      {isLogin ? <Redirect to="/login" /> : <Redirect to="/" />}
      <div className="homeScreen">
        <div className="homeScreenInner">
          <div className="slideHomeWrap">
            <div className="container">
              <Link className="imageButton" to={gillVersoBannerLink}>
                <img src={imgGillVersoBanner} alt={t('homeScreen.imgGillVersoBanner')} />
              </Link>
            </div>
          </div>

          <div className="blockTextIntro">
            <Container>
              <div className="titleBlock">{t('homeScreen.titleBlockDesc')}</div>
            </Container>
          </div>

          <div className="blockButton">
            <Container>
              <Row>
                <div className="col-6">
                  <div className="blockImgButton">
                    <Link
                      className="imageButton"
                      to={`static/${customsDocuments?._id}`}
                      style={{ background: '#AF0000' }}
                    >
                      <span>{t('homeScreen.customsDocuments')}</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blockImgButton">
                    <Link
                      className="imageButton"
                      to={`static/${seasonalDownloads?._id}`}
                      style={{ background: '#AF0000' }}
                    >
                      <span>{t('homeScreen.seasonalDownloads')}</span>
                    </Link>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-6">
                  <Row>
                    <div className="col-12">
                      <div className="blockImgButton">
                        <Link className="imageButton" to={link3}>
                          <img src={imgSystem} alt={t('homeScreen.btnLabel3')} />
                          <span>{t('homeScreen.btnLabel3')}</span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="blockImgButton">
                        <Link className="imageButton" to={link2}>
                          <img src={imgAccessories} alt={t('homeScreen.btnLabel2')} />
                          <span>{t('homeScreen.btnLabel2')}</span>
                        </Link>
                      </div>
                    </div>
                    {/*<div className="col-12">*/}
                    {/*  <div className="blockImgButton">*/}
                    {/*    <Link className="imageButton" to={link1}>*/}
                    {/*      <img src={imgNewCat} alt={t('homeScreen.btnLabel1')} />*/}
                    {/*      <span>{t('homeScreen.btnLabel1')}</span>*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </Row>
                </div>
                <div className="col-6">
                  <div className="blockImgButton">
                    <Link className="imageButton colHeightx2" to={link4}>
                      <img src={imgNewJacket} className="full-height" alt={t('homeScreen.btnLabel4')} />
                      <span>{t('homeScreen.btnLabel4')}</span>
                    </Link>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
  };
}

function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(withTranslation()(HomePage));
