import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import MaskedInput from 'react-text-mask';
// import { isValid, parse, format, addDays } from 'date-fns';
import addDays from 'date-fns/addDays';
import 'react-datepicker/dist/react-datepicker.css';

import './style.scss';
import styled from 'styled-components';

const StyledInput = styled.input.attrs(props => ({ style: props.style }))`
  max-width: 115px;
  width: 100%;
  height: 43px;
  border: solid 1px #b8b8b8;
  background-color: #ffffff;
  text-align: center;
  &::focus {
    border: solid 2px #4c63e0;
  }
`;
interface DatePickerProps extends ReactDatePickerProps {
  inputStyle?: React.CSSProperties;
}
const DatePicker = ({ onChange, selected, inputStyle, ...rest }: DatePickerProps) => {
  return (
    <ReactDatePicker
      selected={selected}
      onChange={onChange}
      placeholderText="dd-mm-yyyy"
      dateFormat="dd-MM-yyyy"
      strictParsing
      customInput={
        <MaskedInput
          mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          guide={false}
          render={(ref, props) => <StyledInput ref={ref} {...props} style={inputStyle} readOnly />}
        />
      }
      calendarClassName="calendar"
      popperPlacement="left-start"
      openToDate={(!!selected && selected) || addDays(new Date(), 1)}
      {...rest}
    />
  );
};

export default DatePicker;
