import React from 'react';
import Select, { components, Props as SelectProps } from 'react-select';
import './styles.scss';

const CustomSelect = (props: SelectProps): JSX.Element => {
  const DropdownIndicator = properties => {
    return (
      <components.DropdownIndicator {...properties}>
        <span className="arrow-dropdown" />
      </components.DropdownIndicator>
    );
  };

  return <Select components={{ DropdownIndicator }} isSearchable={false} {...props} />;
};

export default CustomSelect;
