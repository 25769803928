import localStorage from 'src/utils/LocalStorage';
import * as Constant from 'src/constants';
import { PriceFilterParams, ProductListQueryParams } from 'src/types/productList';

export const initProductsParams: ProductListQueryParams = {
  appKey: Constant.appKey,
  appTypeCode: Constant.appTypeCode,
  deviceKey: Constant.deviceKey,
  filters: {},
  pageNumber: 1,
  pageSize: 10,
  priceFilter: {},
};

export enum ProductsParamsActionType {
  SET_DEFAULT = 'SET_DEFAULT',
  CHANGE_CATEGORY_ID = 'CHANGE_CATEGORY_ID',
  CHANGE_KEYWORD = 'CHANGE_KEYWORD',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
  CHANGE_PERPAGE = 'CHANGE_PERPAGE',
  CHANGE_SORTING = 'CHANGE_SORTING',
  CHANGE_SORTING_DIRECTION = 'CHANGE_SORTING_DIRECTION',
  CHANGE_FILTER_OPTION = 'CHANGE_FILTER_OPTION',
  CHANGE_FILTER_PRICE = 'CHANGE_FILTER_PRICE',
  CLEAR_FILTER = 'CLEAR_FILTER',
}

export type ProductListAction =
  | { type: ProductsParamsActionType.SET_DEFAULT }
  | { type: ProductsParamsActionType.CHANGE_CATEGORY_ID; payload: number | string }
  | { type: ProductsParamsActionType.CHANGE_KEYWORD; payload: string }
  | { type: ProductsParamsActionType.CHANGE_PAGINATION; payload: number }
  | { type: ProductsParamsActionType.CHANGE_PERPAGE; payload: number }
  | {
      type: ProductsParamsActionType.CHANGE_SORTING;
      payload: string;
    }
  | {
      type: ProductsParamsActionType.CHANGE_SORTING_DIRECTION;
      payload: 'asc' | 'desc';
    }
  | { type: ProductsParamsActionType.CHANGE_FILTER_OPTION; payload: any }
  | { type: ProductsParamsActionType.CHANGE_FILTER_PRICE; payload: PriceFilterParams }
  | { type: ProductsParamsActionType.CLEAR_FILTER };
export type ProductListDispatch = (action: ProductListAction) => void;

export const productsParamsReducer = (state: ProductListQueryParams, action: ProductListAction) => {
  switch (action.type) {
    case ProductsParamsActionType.SET_DEFAULT: {
      return {
        ...initProductsParams,
        appKey: localStorage.get('appKey', initProductsParams.appKey),
        deviceKey: localStorage.get('deviceKey', initProductsParams.deviceKey),
        appTypeCode: localStorage.get('appTypeCode', initProductsParams.appTypeCode),
      };
    }
    case ProductsParamsActionType.CHANGE_CATEGORY_ID: {
      const newState: ProductListQueryParams = {
        ...initProductsParams,
        appKey: localStorage.get('appKey', initProductsParams.appKey),
        deviceKey: localStorage.get('deviceKey', initProductsParams.deviceKey),
        appTypeCode: localStorage.get('appTypeCode', initProductsParams.appTypeCode),
        categoryId: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CHANGE_KEYWORD: {
      const newState: ProductListQueryParams = {
        ...initProductsParams,
        appKey: localStorage.get('appKey', initProductsParams.appKey),
        deviceKey: localStorage.get('deviceKey', initProductsParams.deviceKey),
        appTypeCode: localStorage.get('appTypeCode', initProductsParams.appTypeCode),
        query: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CHANGE_PAGINATION: {
      const newState: ProductListQueryParams = {
        ...state,
        pageNumber: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CHANGE_SORTING: {
      const newState: ProductListQueryParams = {
        ...state,
        pageNumber: 1,
        sortBy: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CHANGE_PERPAGE: {
      const newState: ProductListQueryParams = {
        ...state,
        pageNumber: 1,
        pageSize: action.payload,
      };
      return newState;
    }

    case ProductsParamsActionType.CHANGE_SORTING_DIRECTION: {
      const newState: ProductListQueryParams = {
        ...state,
        pageNumber: 1,

        sortDirection: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CHANGE_FILTER_OPTION: {
      const newState: ProductListQueryParams = {
        ...state,

        filters: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CHANGE_FILTER_PRICE: {
      const newState: ProductListQueryParams = {
        ...state,

        priceFilter: action.payload,
      };
      return newState;
    }
    case ProductsParamsActionType.CLEAR_FILTER: {
      const newState: ProductListQueryParams = {
        ...state,
        priceFilter: {},
        filters: {},
      };
      return newState;
    }
    default: {
      return state;
    }
  }
};
