import React, { memo, useState, useEffect } from 'react';
import './style.scss';

function InputWithCounter({ maxLength, methods, inputOptions, ...props }: any) {
  const [inputLength, setInputLength] = useState(0);
  const values = methods.getValues();
  useEffect(() => {
    values && values[props.name] && values[props.name].length > 0 && setInputLength(values[props.name].length);
  }, [values]);

  return (
    <div className="input-counter-wrapper">
      <input
        style={{ paddingRight: '80px' }}
        onChange={e => setInputLength(e.target.value.length)}
        ref={methods.register(inputOptions)}
        maxLength={maxLength}
        {...props}
      />
      {maxLength > 0 && (
        <span className="counter">
          {inputLength}/{maxLength}
        </span>
      )}
    </div>
  );
}

export default memo(InputWithCounter);
