export const Product = {
  ProductType: {
    PRODUCT_TYPE_GROUP: 'grouped',
    PRODUCT_TYPE_SIMPLE: 'simple',
    PRODUCT_TYPE_CONFIGURABLE: 'configurable',
    PRODUCT_TYPE_CONFIGURABLE_SCP: 'configurable_scp',
    PRODUCT_TYPE_CONFIGURABLE_LP: 'configurable_lp',
    PRODUCT_TYPE_BUNDLE: 'bundle',
    PRODUCT_TYPE_PACK: 'pack',
  },

  ProductPriceType: {
    PRODUCT_PRICE_TYPE_FROM_TO: 'from_to',
    PRODUCT_PRICE_TYPE_AS_LOW_AS: 'as_low_as',
    PRODUCT_PRICE_TYPE_STARTING_AT: 'starting_at',
    PRODUCT_PRICE_VIEW_TYPE_DEFAULT: 'default',
  },

  //price display mode in product list
  ProductPriceDisplay: {
    PRODUCT_PRICE_DISPLAY_BASE: 'base',
    PRODUCT_PRICE_DISPLAY_BASE_TIER: 'base_tier',
    PRODUCT_PRICE_DISPLAY_SPECIAL: 'special',
    PRODUCT_PRICE_DISPLAY_SPECIAL_TIER: 'special_tier',
    PRODUCT_PRICE_DISPLAY_STARTING_AT: 'startingAt',
    PRODUCT_PRICE_DISPLAY_BUNDLE_AS_LOW_AS: 'as_low_as',
    PRODUCT_PRICE_DISPLAY_BUNDLE_FROM_TO: 'from_to',
  },

  CustomOptions: {
    INPUTTYPE_CUSTOM_OPTION_FIELD: 'field',
    INPUTTYPE_CUSTOM_OPTION_AREA: 'area',
    INPUTTYPE_CUSTOM_OPTION_DROPDOWN: 'drop_down',
    INPUTTYPE_CUSTOM_OPTION_MULTIPLE: 'multiple',
    INPUTTYPE_CUSTOM_OPTION_DATE: 'date',
    INPUTTYPE_CUSTOM_OPTION_DATETIME: 'date_time',
    INPUTTYPE_CUSTOM_OPTION_TIME: 'time',
    INPUTTYPE_CUSTOM_OPTION_CHECKBOX: 'checkbox',
    INPUTTYPE_DROPDOWN: 'select',
    INPUTTYPE_RADIO: 'radio',
    INPUTTYPE_MULTIPLESELECT: 'multi',
    INPUTTYPE_PASSWORD: 'password',
  },

  BundleOptions: {
    INPUTTYPE_CHECKBOX: 'checkbox',
    INPUTTYPE_DROPDOWN: 'select',
    INPUTTYPE_RADIO: 'radio',
    INPUTTYPE_MULTIPLESELECT: 'multi',
  },

  BundlePriceView: {
    PRICE_VIEW_AS_LOW_AS: 'as_low_as',
    PRICE_VIEW_RANGE: 'price_range',
    PRICE_TYPE_FIXED: 'fixed',
    PRICE_TYPE_DYNAMIC: 'dynamic',
    PRICE_TYPE_PERCENT: 'Percent',
  },

  PriceDisplayType: {
    PRICE: 'price',
    PRICE_AS_CONFIGURED: 'price_as_configured',
    PRICE_FROM: 'price_from',
    LOGIN_TO_VIEW_PRICE: 'login_to_view_price',
    PRICE_INCL_TAX: 'price_incl_tax',
    PRICE_EXCL_TAX: 'price_excl_tax',
    LOGIN_TO_VIEW_PRICE_EXCL_TAX: 'login_to_view_price_excl_tax',
    LOGIN_TO_VIEW_PRICE_INCL_TAX: 'login_to_view_price_incl_tax',
  },

  RatingType: {
    field: 'field',
    radio: 'radio',
    area: 'area',
  },

  RatingCode: {
    ratings: 'ratings',
    detail: 'detail',
    title: 'title',
    nickname: 'nickname',
  },

  ErrorType: {
    MISSING_OPTION: 0,
    INVALID_QUANTITY: 1,
    OUT_OF_QUANTITY: 2,
  },

  ProductMode: {
    ORIGIN: 0,
    UPDATE: 1,
    UPDATE_WISH_LIST: 2,
  },

  ProductVersion: {
    VERSION_1: 1,
    VERSION_2: 2,
  },
  inventoryTracking: {
    Variant: 'variant',
    Product: 'product',
    None: 'none',
  },
  TypeBigCOption: {
    Swatch: 'swatch',
    Rectangles: 'rectangles',
    DropDown: 'dropdown',
    RadioButtons: 'radio_buttons',
    ProductListWithImage: 'product_list_with_images',
    ProductList: 'product_list',
    Checkbox: 'checkbox',
    Date: 'date',
    MultiLineText: 'multi_line_text',
    NumberOnly: 'numbers_only_text',
    Text: 'text',
  },
};
