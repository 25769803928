import { AddToCartStatus } from 'src/components/Button/ActionButton';

export interface ErrorQuantity {
  type?: 'default' | 'error' | 'warning';
  message?: string;
}
export interface SimpleProductState {
  quantity?: number;
  deliveryDate?: Date;
  buttonCartStatus: AddToCartStatus;
  error: ErrorQuantity;
}

export enum SimpleProductAction {
  INIT_DEFAULT_STATUS = 'INIT_DEFAULT_STATUS',
  INIT_BUTTON_STATUS = 'INIT_BUTTON_STATUS',
  INIT_ERROR_QUANTITY = 'INIT_ERROR_QUANTITY',
  CHANGE_QUANTITY = 'CHANGE_QUANTITY',
  CHANGE_DELIVERY_DATE = 'CHANGE_DELIVERY_DATE',
  CHANGE_BUTTON_CART_STATUS = 'CHANGE_BUTTON_CART_STATUS',
  CHANGE_ERROR_QUANTITY = 'CHANGE_ERROR_QUANTITY',
}
export type SimpleProductActionType =
  | { type: SimpleProductAction.CHANGE_QUANTITY; index: number; payload: number }
  | { type: SimpleProductAction.CHANGE_DELIVERY_DATE; index: number; payload: Date }
  | { type: SimpleProductAction.CHANGE_BUTTON_CART_STATUS; index: number; payload: AddToCartStatus }
  | {
      type: SimpleProductAction.CHANGE_ERROR_QUANTITY;
      index: number;
      payload: ErrorQuantity;
    }
  | { type: SimpleProductAction.INIT_BUTTON_STATUS; length: number }
  | { type: SimpleProductAction.INIT_ERROR_QUANTITY; length: number }
  | { type: SimpleProductAction.INIT_DEFAULT_STATUS; length: number };
export const simpleProductReducer = (state: SimpleProductState[], action: SimpleProductActionType) => {
  switch (action.type) {
    case SimpleProductAction.CHANGE_QUANTITY: {
      const newState = { ...state };
      newState[action.index] = { ...newState[action.index], quantity: action.payload };
      return newState;
    }
    case SimpleProductAction.CHANGE_DELIVERY_DATE: {
      const newState = { ...state };
      newState[action.index] = { ...newState[action.index], deliveryDate: action.payload };
      return newState;
    }
    case SimpleProductAction.CHANGE_BUTTON_CART_STATUS: {
      const newState = { ...state };
      const { index, payload } = action;
      newState[index] = { ...newState[index], buttonCartStatus: payload };
      return newState;
    }
    case SimpleProductAction.CHANGE_ERROR_QUANTITY: {
      const newState = { ...state };
      const { index, payload } = action;
      newState[index] = { ...newState[index], error: payload };
      return newState;
    }
    case SimpleProductAction.INIT_BUTTON_STATUS: {
      const newState = Array(action.length)
        .fill('default')
        .map((status, index) => {
          return { ...state[index], buttonCartStatus: status };
        });
      return newState;
    }
    case SimpleProductAction.INIT_ERROR_QUANTITY: {
      const newState = Array(action.length)
        .fill({})
        .map((err, index) => {
          return { ...state[index], error: err };
        });
      return newState;
    }
    case SimpleProductAction.INIT_DEFAULT_STATUS: {
      const newState: SimpleProductState[] = Array(action.length).map(() => {
        return { buttonCartStatus: 'default', error: {} };
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
const updateArray = <T,>(array: T[], index: number, value: T) => {
  const newArray = array.map((item, id) => {
    if (id === index) {
      return value;
    }
    return item;
  });
  return newArray;
};
