import { reducerNameHOR } from '.';
import * as Utils from '../utils';
import * as AppURL from '../services/urlAPI';

export const API_CALL_REQUEST = 'API_CALL_REQUEST';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';
export const API_CALL_FAILURE = 'API_CALL_FAILURE';
export const CHANGE_CART_COUNT = 'CHANGE_CART_COUNT';

const initialState = {
  isFetching: false,
  response: {},
  errMessage: '',
};
export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_REQUEST:
      return {
        ...state,
        isFetching: true,
        errMessage: '',
      };
    case API_CALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        response: action.response,
      };
    case API_CALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        errMessage: action.errMessage,
      };
    case CHANGE_CART_COUNT:
      return { ...state, response: { ...state.response, count: action.count } };
    default:
      return state;
  }
}

export function changeCartCount(count) {
  return {
    type: CHANGE_CART_COUNT,
    count: count,
    name: reducerNameHOR.getCartCount,
  };
}

export function callApiCartCount(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: API_CALL_REQUEST,
        url: Utils.replaceStrUrl(AppURL.getCartCount, [params.appTypeCode, params.appKey, params.deviceKey, '']),
        isFirstTime: false,
        isFetching: true,
        isToastErr: true,
        name: reducerNameHOR.getCartCount,
        params: {},
        typeRequest: 'GET',
        resolve,
        reject,
      });
    });
  };
}

export default reducer;
