import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import './style.scss';
import { Image, Button } from 'react-bootstrap';
import image_banner from '../../assets/login-banner-gb.jpg';
import { Link, Redirect } from 'react-router-dom';
import { callApiACT } from '../../redux/callApiRDC';
import * as AppURL from '../../services/urlAPI';
import { mergeHeader, setGlobalAPI } from '../../services/callDataAPI';
import * as Utils from '../../utils';
import { reducerNameHOR } from '../../redux';
import { HeaderKey, TypeRequest } from '../../constants';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import img_lock from '../../assets/lock-white.png';
import img_user from '../../assets/account.svg';
import logo from '../../assets/logo.svg';
import loading from '../../assets/loadingx43px.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import InputPassword from '../../components/Input/inputPassword';
import { setParams } from '../../redux/indexRDC';
import error_icn from '../../assets/icon/error_icn.svg';
import { IoIosPlay } from 'react-icons/io';
import store from '../../redux';

interface Props {
  callApiACT: (action) => void;
  loginUser?: any;
}

function ChangePassword({ callApiACT, loginUser }: Props) {
  const methods = useForm();

  const [isLoging, setIsLoging] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [permission, setPermission] = useState({});
  const [isCompanyLogin, setIsCompanyLogin] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [isSavedPassword, setIsSavedPassword] = useState<boolean>(false);

  const onUserClickedLoginButton = async data => {
    setIsLoging(true);
    setErrorMessage('');

    let toastMessageSuccess = 'Login Success';

    let emailLogin = Utils.getSafeValue(data['item'], 'email', '');
    let paramLogin = {
      email: emailLogin,
      password: methods.getValues()['password'],
    };
    let postData = _.assign(
      {
        [Constants.KeyAsyncStore.appKey]: Constants.appKey,
        [Constants.KeyAsyncStore.deviceKey]: Constants.deviceKey,
      },
      paramLogin
    );

    try {
      let dataLogin = await callApiACT({
        typeRequest: TypeRequest.POST,
        name: reducerNameHOR.loginUser,
        url: AppURL.userLogin,
        params: postData,
        isToastSuccess: false,
        toastMessageSuccess: toastMessageSuccess,
        isToastErr: false,
      });

      setNewHeader(dataLogin);
      let customer = Utils.getSafeValue(dataLogin, 'customer', {});
      let permission = Utils.getSafeValue(customer, 'permission', {});

      setPermission(permission);

      await localStorage.setObject(Constants.KeyAsyncStore.customer, customer);

      await localStorage.setObject(Constants.KeyAsyncStore.permissions, permission);

      await localStorage.setObject(Constants.KeyAsyncStore.userNameDev, {
        email: data.email,
      });

      setRedirect(true);
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setIsLoging(false);
  };

  const onSubmitChangePassword = async data => {
    setIsChangingPassword(true);
    try {
      let dataLogin = await callApiACT({
        typeRequest: TypeRequest.PUT,
        name: reducerNameHOR.forceChangePassword,
        url: AppURL.forceChangePassword,
        params: data,
        isToastSuccess: false,
        isToastErr: false,
      });

      setTimeout(() => {
        setIsChangingPassword(false);
        setIsSavedPassword(true);
      }, 1000);

      setTimeout(() => {
        setIsSavedPassword(false);
        if (dataLogin['error'] === null) {
          onUserClickedLoginButton(dataLogin);
        }
      }, 3000);
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
      setIsChangingPassword(false);
    }
  };

  useEffect(() => {
    if (loginUser) {
      let customerLocal = localStorage.getObject(Constants.KeyAsyncStore.customer, {});
      let customerRedux = loginUser.response['customer'];
      let customer = !_.isEmpty(customerRedux) ? customerRedux : customerLocal;

      checkToRedirect(customer);
    }
  }, [loginUser]);

  const checkToRedirect = customer => {
    if (!_.isEmpty(customer)) {
      let permissions = Utils.getSafeValue(customer, 'permission', {});

      if (customer['resetPassword'] == 1) {
        if (permissions['loginAsCustomer'] == false) {
          setIsCompanyLogin(true);
          localStorage.setObject(Constants.KeyAsyncStore.customerAssign, customer);
        } else if (permissions['loginAsCustomer'] == true) {
          setIsCompanyLogin(false);
        }
        setRedirect(true);
      }
    } else {
      setRedirect(false);
    }
  };

  const setNewHeader = dataLogin => {
    let keypairVersion = Utils.getSafeValue(dataLogin.kpData, 'keypairVersion', '');
    let keypairData = Utils.getSafeValue(dataLogin.kpData, 'keypairData', '');
    let keyPairExpiration = dataLogin.kpData.keyPairExpiration;
    let newHeader = {
      [Constants.HeaderKey.JM360_KP]: keypairData,
      [Constants.HeaderKey.JM360_SO_KP_EXP]: keyPairExpiration,
      [Constants.HeaderKey.JM360_KP_VER]: keypairVersion,
    };
    setGlobalAPI(undefined, newHeader);
  };

  const clearError = () => {
    let pass = methods.watch('password');
    let confirmPass = methods.watch('confirmPassword');
    if (!methods.errors['password'] && confirmPass != '') {
      if (pass == confirmPass) {
        methods.clearErrors(['confirmPassword']);
      } else {
        methods.setError('confirmPassword', { type: 'validate' });
      }
    }
  };

  const Logout = () => {
    localStorage.clear();
    store.dispatch({ type: 'USER_LOGOUT' });
  };

  return (
    <div className="loginPage forceChangePassword">
      {redirect && !isCompanyLogin && <Redirect to={`/login-as-company`} />}
      {redirect && isCompanyLogin && <Redirect to={`/`} />}

      <div className="loginPageInner">
        <div className="headerLogin">
          <Image src={logo} />
          <div className="paragraph">Online ordering and stock information portal</div>
        </div>

        <Form onSubmit={methods.handleSubmit(onSubmitChangePassword)}>
          <div className="loginForm">
            <h3 className="title">Change Your Password</h3>
            <div className="desc">
              <div>For security reason, you are asked to change password for the first time login.</div>
              <div style={{ paddingTop: '7px' }}>
                Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters.
              </div>
            </div>

            <div className="formField">
              {errorMessage !== '' && (
                <div className="invalid-text d-flex-row">
                  <div className="color-FF321E">{errorMessage}</div>
                </div>
              )}
              <div className="field">
                <div className="fieldLabel">New Password:</div>
                <div className="fieldValue">
                  <InputPassword
                    onFocus={() => {
                      setErrorMessage('');
                    }}
                    className={`${methods.errors && methods.errors['password'] ? 'invalid' : ''}`}
                    name="password"
                    disabled={isLoging || isChangingPassword || isSavedPassword}
                    onChange={() => {
                      clearError();
                    }}
                    propRef={methods.register({
                      required: true,
                      pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,/=-_+`~])[A-Za-z\d@$!%*#?&.,/=-_+`~]{7,}$/,
                    })}
                  />
                </div>
                {methods.errors && methods.errors['password'] && (
                  <div className="errorMsg">
                    {methods.errors['password'].type === 'required' && 'New Password is invalid'}
                    {methods.errors['password'].type === 'pattern' &&
                      'Passwords must be at least 7 characters and contain alphabetic, numeric, and special characters'}
                  </div>
                )}
              </div>
              <div className="field">
                <div className="fieldLabel">Confirm New Password:</div>
                <div className="fieldValue">
                  <InputPassword
                    onFocus={() => {
                      setErrorMessage('');
                    }}
                    name="confirmPassword"
                    className={`${
                      methods.errors && methods.errors['confirmPassword'] && !methods.errors['password']
                        ? 'invalid'
                        : ''
                    }`}
                    disabled={isLoging || isChangingPassword || isSavedPassword}
                    propRef={methods.register({
                      validate: value => value === methods.watch('password'),
                    })}
                  />
                </div>
                {methods.errors && methods.errors['confirmPassword'] && !methods.errors['password'] && (
                  <div className="errorMsg">
                    {methods.errors['confirmPassword'].type === 'validate' && 'Passwords do not match'}
                  </div>
                )}
              </div>
              <div className="field">
                <div className="fieldBtn">
                  <Link
                    className={`forgotPassword ${isLoging || isChangingPassword || isSavedPassword ? 'gray' : ''} `}
                    to={'/login'}
                    onClick={() => {
                      Logout();
                    }}
                  >
                    Back to Login
                  </Link>
                  <button type="submit" className={`btnSubmit ${isLoging || isChangingPassword ? 'loading' : ''}`}>
                    {!isLoging && !isChangingPassword && !isSavedPassword && 'Save'}
                    {isChangingPassword && (
                      <>
                        <img src={loading} width={18} /> Saving...
                      </>
                    )}
                    {isLoging && (
                      <>
                        <img src={loading} width={18} /> Logging in...
                      </>
                    )}
                    {isSavedPassword && 'Saved'}
                    <span className="icnIcon">
                      <IoIosPlay />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loginUser: state.loginUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ChangePassword);
