import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import './style.scss';
import { Image, Button } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useCookies } from 'react-cookie';
import { callApiACT } from '../../redux/callApiRDC';
import * as AppURL from '../../services/urlAPI';
import { mergeHeader, setGlobalAPI } from '../../services/callDataAPI';
import * as Utils from '../../utils';
import { reducerNameHOR } from '../../redux';
import { HeaderKey, TypeRequest } from '../../constants';
import localStorage from '../../utils/LocalStorage';
import * as Constants from '../../constants';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/logo.svg';
import loading from '../../assets/loadingx43px.svg';
import InputPassword from '../../components/Input/inputPassword';
import { setParams } from '../../redux/indexRDC';
import { IoIosPlay } from 'react-icons/io';
import { useTranslation, withTranslation } from 'react-i18next';
import { deleteAllCookies } from '../../utils/CartConvert';
interface Props {
  callApiACT: (action) => void;
  setParams: (params) => void;
  loginUser?: any;
}

function Login({ callApiACT, loginUser, setParams }: any) {
  const methods = useForm();

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [permission, setPermission] = useState({});
  const [isCompanyLogin, setIsCompanyLogin] = useState(false);
  const [redirectResetPassword, setRedirectResetPassword] = useState<boolean>(false);

  useEffect(() => {
    if (loginUser) {
      let customerLocal = localStorage.getObject(Constants.KeyAsyncStore.customer, {});
      let customerRedux = loginUser.response['customer'];
      let customer = !_.isEmpty(customerRedux) ? customerRedux : customerLocal;

      checkToRedirect(customer);
    }
  }, [loginUser]);

  useEffect(() => {
    deleteAllCookies();
    //   setTimeout(() => {
    // }, 2000);
  }, []);

  const changeLanguage = lng => {
    localStorage.set(Constants.KeyAsyncStore.appLang, lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (isCompanyLogin && redirect) {
      window.location.href = `${window.location.origin}/`;
    }
  }, [isCompanyLogin || redirect]);

  const checkToRedirect = customer => {
    if (!_.isEmpty(customer)) {
      // let permissions = Utils.getSafeValue(customer, "permission", {});

      if (customer['resetPassword'] == 1) {
        setRedirectResetPassword(false);
        if (customer['role'] === 'owner' || customer['role'] === 'employee') {
          setIsCompanyLogin(true);
          localStorage.setObject(Constants.KeyAsyncStore.customerAssign, customer);
        } else if (customer['role'] === 'agent' || customer['role'] === 'agent_only') {
          setIsCompanyLogin(false);
        }
        setRedirect(true);
      } else if (customer['resetPassword'] == 0) {
        setRedirectResetPassword(true);
      }
    } else {
      setRedirect(false);
      // changeLanguage('en');
    }
  };

  const onUserClickedLoginButton = async data => {
    setIsLoading(true);
    setErrorMessage('');
    let serverType = AppURL.DEV_REGISTER_SERVER;
    let header = mergeHeader({
      [HeaderKey.JM360_ENV]: Constants.JM360_ENV,
      JM360_APP_TYPE_CODE: 'bcm',
    });

    setGlobalAPI(serverType, header);

    let dataEndPoint = await callApiACT({
      typeRequest: TypeRequest.GET,
      name: reducerNameHOR.getEndPoint,
    });

    let endPoint = Utils.getSafeValue(dataEndPoint, 'Endpoint', '');
    setGlobalAPI(endPoint, header);
    localStorage.set(Constants.KeyAsyncStore.baseUrl, endPoint);

    // let postData = _.assign(
    //     {
    //         [Constants.KeyAsyncStore.appKey]: Constants.appKey,
    //         [Constants.KeyAsyncStore.deviceKey]: Constants.deviceKey
    //     },
    //     data
    // );

    // localStorage.set([Constants.KeyAsyncStore.appKey], Constants.appKey);
    // localStorage.set([Constants.KeyAsyncStore.deviceKey], Constants.deviceKey);
    // localStorage.set([Constants.KeyAsyncStore.appTypeCode], Constants.appTypeCode);

    let toastMessageSuccess = 'Login Success';

    try {
      let dataLogin = await callApiACT({
        typeRequest: TypeRequest.POST,
        name: reducerNameHOR.loginUser,
        url: AppURL.userLogin,
        params: data,
        isToastSuccess: false,
        toastMessageSuccess: toastMessageSuccess,
        isToastErr: false,
      });

      let customer = Utils.getSafeValue(dataLogin, 'customer', {});
      let permission = Utils.getSafeValue(customer, 'permission', {});
      let assignCompany = Utils.getSafeValue(customer, 'assignCompany', {});

      let lang = !_.isEmpty(assignCompany) ? assignCompany.lang : 'en';

      setNewHeader(dataLogin, lang);

      setPermission(permission);

      await localStorage.setObject(Constants.KeyAsyncStore.customer, customer);
      await localStorage.setObject(Constants.KeyAsyncStore.permissions, permission);
      await localStorage.setObject(Constants.KeyAsyncStore.userNameDev, { email: data.email });

      if (!_.isEmpty(assignCompany)) {
        if (customer['role'] === 'owner' || customer['role'] === 'employee') {
          localStorage.set(Constants.KeyAsyncStore.appKey, assignCompany.appKey);
          localStorage.set([Constants.KeyAsyncStore.deviceKey], Constants.deviceKey);
          localStorage.set([Constants.KeyAsyncStore.appTypeCode], Constants.appTypeCode);
          changeLanguage(assignCompany.lang);
        }
      }

      // if (permission["loginAsCustomer"] == false) {
      //     await localStorage.setObject(Constants.KeyAsyncStore.customerAssign, customer);
      // }

      // setRedirect(true);
    } catch (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setIsLoading(false);
  };

  const setNewHeader = (dataLogin, lang) => {
    let keypairVersion = Utils.getSafeValue(dataLogin.kpData, 'keypairVersion', '');
    let keypairData = Utils.getSafeValue(dataLogin.kpData, 'keypairData', '');
    let keyPairExpiration = dataLogin.kpData.keyPairExpiration;
    let newHeader = {
      [Constants.HeaderKey.JM360_KP]: keypairData,
      [Constants.HeaderKey.JM360_SO_KP_EXP]: keyPairExpiration,
      [Constants.HeaderKey.JM360_KP_VER]: keypairVersion,
      [Constants.HeaderKey.JM360_ACCEPT_LANGUAGE]: lang,
    };
    setGlobalAPI(undefined, newHeader);
  };

  const [valueUsername, setValueUsername] = useState('');
  const trimSpaceUsername = e => {
    let value = e.target.value;
    let valueTrim = value.replace(/ /g, '');

    setValueUsername(valueTrim);
  };

  const [cookies, setCookie] = useCookies(['showResetModal']);
  const [showResetModal, setShowResetModal] = useState(cookies.showResetModal === undefined);
  const onResetModalClose = () => {
    setShowResetModal(false);
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 100);
    setCookie('showResetModal', 1, { path: '/', expires: expireDate });
  };

  return (
    <div className="loginPage">
      {redirect && !isCompanyLogin && <Redirect to={`/login-as-company`} />}
      {/* {redirect && isCompanyLogin && <Redirect to={`/`} />} */}
      {redirectResetPassword && <Redirect to={`/change-password`} />}

      <Modal show={showResetModal} centered backdrop="static" className="modal-reset-password">
        <p>
          Welcome to our new B2B Portal. All customers need to reset their passwords. Please click 'Forgot Your
          Password' and enter your login email address.
        </p>
        <div className="buttons">
          <button type="button" onClick={onResetModalClose}>
            OK
          </button>
        </div>
      </Modal>

      <div className="loginPageInner">
        <div className="headerLogin">
          <Image src={logo} />
          <div className="paragraph">Online ordering and stock information portal</div>
        </div>

        <Form onSubmit={methods.handleSubmit(onUserClickedLoginButton)}>
          <div className="loginForm">
            <h3 className="title">Log in</h3>

            <div className="formField">
              {errorMessage !== '' && (
                <div className="invalid-text d-flex-row">
                  {/*<Image src={error_icn} style={{ width: "30px" }} />*/}
                  <div className="color-FF321E">{errorMessage}</div>
                </div>
              )}
              {methods.errors && (methods.errors['email'] || methods.errors['password']) && (
                <div className="invalid-text d-flex-row">
                  {/*<Image src={error_icn} />*/}
                  <div className="msgError">
                    {methods.errors['email'] && methods.errors['email'].message}
                    {!methods.errors['email'] && methods.errors['password'] && methods.errors['password'].message}
                  </div>
                </div>
              )}
              <div className="field">
                <div className="fieldLabel">Username:</div>
                <div className="fieldValue">
                  <input
                    onFocus={() => {
                      setErrorMessage('');
                    }}
                    style={methods.errors.email || errorMessage != '' ? { border: '1px solid #ff321f' } : {}}
                    type="text"
                    name="email"
                    onChange={e => trimSpaceUsername(e)}
                    ref={methods.register({
                      required: `The username and/or password is invalid`,
                    })}
                    value={valueUsername}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="field">
                <div className="fieldLabel">Password:</div>
                <div className="fieldValue">
                  <InputPassword
                    onFocus={() => {
                      setErrorMessage('');
                    }}
                    style={methods.errors.password || errorMessage != '' ? { border: '1px solid #ff321f' } : {}}
                    name="password"
                    disabled={isLoading}
                    propRef={methods.register({
                      required: `The username and/or password is invalid`,
                    })}
                  />
                </div>
              </div>
              <div className="field">
                <div className="fieldBtn">
                  <Link className={`forgotPassword ${isLoading ? 'gray' : ''} `} to={'/request-email'}>
                    Forgot your password?
                  </Link>
                  <button type="submit" className={`btnSubmit ${isLoading ? 'loading' : ''}`}>
                    {isLoading ? <img src={loading} width={18} /> : `Log in`}
                    <span className="icnIcon">
                      <IoIosPlay />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loginUser: state.loginUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApiACT: params => dispatch(callApiACT(params)),
    setParams: params => dispatch(setParams(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
// export default compose(withConnect)(Login);
export default compose(withConnect(withTranslation()(Login)));
