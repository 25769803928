import { reducerNameHOR } from '.';
import * as Utils from '../utils';
import * as AppURL from '../services/urlAPI';

export const API_CALL_REQUEST_WISH_LIST = 'API_CALL_REQUEST_WISH_LIST';
export const API_CALL_SUCCESS_WISH_LIST = 'API_CALL_SUCCESS_WISH_LIST';
export const API_CALL_FAILURE_WISH_LIST = 'API_CALL_FAILURE_WISH_LIST';
export const SET_LOADING_SCREEN = 'SET_LOADING_SCREEN';

export const SET_LOADING = 'SET_LOADING';
export const CLEAR_WISH_LIST = 'CLEAR_WISH_LIST';
const initialState = {
  wishList: [],
  isFetching: true,
  isFirstTime: false,
  refreshing: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_REQUEST_WISH_LIST:
      return {
        ...state,
        isFetching: !action.refreshing,
        isFirstTime: action.isFirstTime ? true : state.isFirstTime,
        refreshing: action.refreshing,
      };
    case API_CALL_SUCCESS_WISH_LIST: {
      let wishList = Utils.getSafeValue(action.response, 'wishList', []);
      return {
        ...state,
        wishList,
        isFetching: !state.isFirstTime,
        refreshing: false,
      };
    }
    case API_CALL_FAILURE_WISH_LIST:
      return {
        ...state,
        isFetching: !state.isFirstTime,
        refreshing: false,
      };

    case CLEAR_WISH_LIST:
      return { ...state, wishList: [] };

    case SET_LOADING_SCREEN:
      return { ...state, isFetching: action.isFetching };
    default:
      return state;
  }
}

export function clearWishList() {
  return {
    type: CLEAR_WISH_LIST,
    name: reducerNameHOR.wishList,
  };
}
export function setLoadingScreen(isFetching) {
  return {
    type: SET_LOADING_SCREEN,
    name: reducerNameHOR.wishList,
    isFetching,
  };
}
export function callApiWishList(action) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: API_CALL_REQUEST_WISH_LIST,
        url: action.url || '',
        isFirstTime: action.isFirstTime || false,
        isFetching: action.isFetching || true,
        isToastErr: action.isToastErr || true,
        name: reducerNameHOR.wishList,
        params: action.params || {},
        typeRequest: action.typeRequest || 'POST',
        resolve,
        reject,
      });
    });
  };
}
//
// export function getWishList(params, isFirstTime = false, refreshing) {
//   let requestGetWishlist = {
//     pageNumber: 1,
//     pageSize: 100
//   };
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       dispatch({
//         type: API_CALL_REQUEST_WISH_LIST,
//         url: Utils.replaceStrUrl(AppURL.getWishList, params.appTypeCode),
//         isFetching: true,
//         isToastErr: true,
//         refreshing,
//         isFirstTime,
//         name: reducerNameHOR.wishList,
//         params: { ...params, requestGetWishlist },
//         typeRequest: "POST",
//         resolve,
//         reject
//       });
//     });
//   };
// }

export default reducer;
