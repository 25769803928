import { reducerNameHOR } from '.';
import produce from 'immer';

export const SET_PARAMS = 'SET_PARAMS';

export const initialState = {
  params: null,
};
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_PARAMS:
        draft.params = action.params.params;
        break;
    }
  });

export function setParams(params) {
  return {
    type: SET_PARAMS,
    params: params,
    name: params.name,
  };
}

export default reducer;
