/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import './style.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { IoIosPlay } from 'react-icons/io';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import { string } from 'prop-types';
import localStorage from '../../utils/LocalStorage';
import * as Utils from '../../utils';
import { backTop } from '../../utils';
import logo from '../../assets/logo.svg';
import { klavioySrc } from '../../constants';
import imgAppStore from './assets/icnBtn_appStore.png';
import imgGooglePlay from './assets/icnBtn_googlePlay.png';

function MainFooter({ appConfig }: any) {
  const { t } = useTranslation();

  const [matchModules, setMatchModules] = useState<any[]>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [corporateResponsibilityLink, setCorporateResponsibilityLink] = useState<string>('');

  const appLang = localStorage.get('appLang', 'en');

  const FOOTER_MENU_TYPES = ['ECOMMERCE_CATALOG', 'ABOUT_US'];

  useEffect(() => {
    if (appLang == 'fr') {
      setFileUrl('./assets/file/seasonal-downloads-french.docx');
      setCorporateResponsibilityLink('/static/60a362b4e756081f547fa54b');
    } else if (appLang == 'de') {
      setFileUrl('./assets/file/seasonal-downloads-germany.docx');
      setCorporateResponsibilityLink('/static/60a36203e756081f547fa50d');
    } else {
      setFileUrl('./assets/file/seasonal-downloads-english.docx');
      setCorporateResponsibilityLink('/static/60a3615ce756081f547fa488');
    }
  }, [appLang]);

  useEffect(() => {
    if (appConfig) {
      const modules = Utils.getSafeValue(appConfig.response.appData, 'modules', []);
      // let matchModule = _.find(modules, (m: any) => m.moduleType == 'ABOUT_US');

      const footerModules = modules.reduce((arr, module) => {
        if (FOOTER_MENU_TYPES.indexOf(module.moduleType) === -1) {
          return arr;
        }
        const dataModule = Utils.getSafeValue(module, 'moduleMetaData.data', '');
        const data = JSON.parse(dataModule || null);
        const parsedModule = {
          ...module,
          moduleMetaData: {
            ...module.moduleMetaData,
            data,
          },
        };
        return arr.concat(parsedModule);
      }, []);

      const mainMenu = _.find(modules, { moduleType: 'MAIN_MENU' }) || [];
      const mainModuleDataItems = Utils.getSafeValue(mainMenu, 'moduleDataItems', []).map(moduleItem => {
        const parsedData = Utils.safeParseJson(moduleItem.data);
        return { ...moduleItem, data: parsedData };
      });

      const showFooterModules = footerModules.reduce((arr, item) => {
        const footerMatchedMainModule = _.find(mainModuleDataItems, (moduleItem: any) => {
          const { action } = moduleItem.data;
          return action.includes(Utils.getSafeValue(item, '_id', ''));
        });
        if (footerMatchedMainModule === undefined) {
          return arr;
        }

        const desktopDisable = Utils.getSafeValue(footerMatchedMainModule, 'data.desktopFooterDisabled', false);

        if (desktopDisable) {
          return arr;
        }
        return arr.concat(item);
      }, []);

      setMatchModules(showFooterModules);
    }
  }, [appConfig]);
  const openModal = () => {
    const Script = document.getElementById('klaviyo');
    if (Script) {
      document.body.removeChild(Script);
    }
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = `${klavioySrc}`;
      script.defer = true;
      script.id = 'klaviyo';
      document.body.appendChild(script);
    }, 300);
  };
  return (
    <div className="footer">
      <Container>
        <div className="groupBtnDownload">
          <div className="title">{t('footer.downloadOurApp')}</div>
          <div className="btnFields">
            <div className="field field1">
              <a href="https://apps.apple.com/nl/app/gill-marine-trade/id1555622345" target="_blank">
                <img src={imgAppStore} />
              </a>
            </div>
            <div className="field field2">
              <a
                href="https://play.google.com/store/apps/details?id=com.gillmarineb2buk.gillmarineb2buk"
                target="_blank"
              >
                <img src={imgGooglePlay} />
              </a>
            </div>
          </div>
        </div>
        <Row>
          <div className="col-2" />
          <div className="col-8">
            <div className="innerFooter">
              <Row>
                <div className="col-3">
                  <div className="footerMenu">
                    <ul>
                      <li>
                        <Link to="/term-condition-sale" target="_blank">
                          {t('footer.saleTerm')}
                        </Link>
                      </li>
                      {matchModules
                        .filter(m => m.moduleType === 'ABOUT_US')
                        .map(m => {
                          return (
                            <li key={m._id}>
                              <Link to={`/static/${m._id}`} target="_blank">
                                {m.moduleName}
                              </Link>
                            </li>
                          );
                        })}
                      {/* <li>
                        <Button
                          onClick={() => {
                            openModal();
                          }}
                        >
                          {t('footer.newsLetter')}
                        </Button>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="col-3">
                  <div className="footerMenu">
                    <ul>
                      {matchModules
                        .filter(m => m.moduleType === 'ECOMMERCE_CATALOG')
                        .map(m => {
                          const menuHref = `/business/${m.moduleMetaData?.data?.categoryId}`;
                          return (
                            // eslint-disable-next-line no-underscore-dangle
                            <li key={m._id}>
                              <Link to={menuHref}>{m.moduleName}</Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-3">
                  <div className="footerMenu">
                    <ul>
                      <li>
                        <Link to="/layering-guide" target="_blank">
                          {t('footer.layeringGuide')}
                        </Link>
                      </li>
                      <li>
                        <Link to="/size-guide" target="_blank">
                          {t('footer.sizeChart')}
                        </Link>
                      </li>
                      <li>
                        <Link to="/fabric-system" target="_blank">
                          {t('footer.fabricSystem')}
                        </Link>
                      </li>
                      <li>
                        <Link to="/product-care" target="_blank">
                          {t('footer.productCare')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-3">
                  <div className="fieldBackToTop">
                    <a
                      href="#"
                      className="btnBackToTop btnWithArrow"
                      onClick={e => {
                        e.preventDefault();
                        backTop();
                      }}
                    >
                      {t('footer.backToTop')}
                      <span className="icnArrow">
                        <IoIosPlay />
                      </span>
                    </a>
                  </div>
                </div>
              </Row>
            </div>
            <div className="footerLogo">
              <div className="logo">
                <Image src={logo} width="90" alt="gillmarine" />
              </div>
            </div>
            <div className="copyRightBlock">
              <div className="copyRightMenu">
                <ul>
                  <li>
                    <Link to="/term-condition">{t('footer.siteTerm')}</Link>
                  </li>
                  <li>
                    <Link to={corporateResponsibilityLink}>{t('footer.corporateResponsibility')}</Link>
                  </li>
                  <li>
                    <Link to="/cookies-policy">{t('footer.cookiePolicy')}</Link>
                  </li>
                </ul>
              </div>
              <div className="copyRightText">
                {t('footer.copyRightLine1')}
                <br />
                {t('footer.copyRightLine2')}
              </div>
            </div>
          </div>
          <div className="col-2" />
        </Row>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
  };
}

const withConnect = connect(mapStateToProps);
// export default compose(withConnect)(MainFooter);
export default compose(withConnect(withTranslation()(MainFooter)));
// export default MainFooter;
